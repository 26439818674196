import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ApiService } from '../_services/api.service';
import { takeWhile } from 'rxjs';
import Chart from 'chart.js/auto';
import * as Highcharts from 'highcharts';
import highcharts3d from 'highcharts/highcharts-3d';
highcharts3d(Highcharts);
import { NgZone } from '@angular/core';
import { ColDef, GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { Router } from '@angular/router';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardComponent implements OnInit {
    weekStatus: string = 'week'
    firstChart: any = {};
    secondChart: any = {};
    thirdChart: any = {};
    acqscgart: boolean = false;
    textualData: any = {};
    isTextualLoader: boolean = false;
    currentYear: any;
    rowData$: any[] = [];
    rowDataTen$: any[] = [];
    rowDataFifty$: any[] = [];
    tableName: string = 'l30_camp';
    defaultColDef: ColDef = { resizable: true, sortable: true, filter: true };
    itemPerPage: number = 14;
    isDneUser: boolean = false;
    pageNumber: number = 1;
    totalCount: number = 1;
    gridApi!: GridApi;
    noDataTemplate =
        '<span class="ag-overlay-no-rows-center">No data found</span>';
    colDefs: ColDef[] = [
        { field: 'codice_cam', headerName: 'ID CAMP', minWidth: 300 },
        {
            field: 'data_campl', headerName: 'DATA CAMP', minWidth: 300, comparator: this.dateComparator,
        },
        { 
            field: 'denominaz', 
            headerName: 'CPS', 
            minWidth: 305,
            valueParser: (params) => Number(params?.data?.denominaz),
            comparator: (valueA, valueB) => Number(valueA) - Number(valueB),
            filter: 'agNumberColumnFilter'
           },
    ]
    colDefsStorico: ColDef[] = [
        { field: 'codice_cam', headerName: 'ID CAMP', minWidth: 300 },
        {
            field: 'data_campl', headerName: 'DATA CAMP', minWidth: 300, comparator: this.dateComparator,
        },
        { 
            field: 'codice_az',
            headerName: 'CODICE AZ', 
            minWidth: 305,
            valueParser: (params) => Number(params?.data?.codice_az),
            comparator: (valueA, valueB) => Number(valueA) - Number(valueB),
            filter: 'agNumberColumnFilter'
        },
        // {field: 'denominaz', headerName: 'CPS', minWidth: 200},
    ]

    columnDefs: ColDef[] = [
        { field: 'codice_cam', headerName: 'ID CAMP', width: 250 },
        { field: 'denominaz', headerName: 'CPS', width: 225 },
        {
            field: 'data_campl', headerName: 'DATA CAMP', width: 215, comparator: this.dateComparator,
        },
        {
            field: 'codice_az',
            headerName: 'ID CPS',
            width: 215,
            valueParser: (params) => Number(params?.data?.codice_az),
            comparator: (valueA, valueB) => Number(valueA) - Number(valueB),
            filter: 'agNumberColumnFilter'      
        },
    ]
    gridOptions: GridOptions<any> = {
        defaultColDef: this.defaultColDef,
        rowSelection: "single",
        animateRows: true,
        pagination: true,
        paginationAutoPageSize: true,
        getRowClass: (params) => {
            return params.node.rowIndex % 2 === 0 ? 'red-row' : 'white-row';
        }
    };

    gridOptions1: GridOptions<any> = {
        defaultColDef: this.defaultColDef,
        rowSelection: "single",
        animateRows: true,
        pagination: true,
        paginationAutoPageSize: true,
        getRowClass: (params) => {
            return params.node.rowIndex % 2 === 0 ? 'red-row' : 'white-row';
        }
    };
    gridOptions2: GridOptions<any> = {
        defaultColDef: this.defaultColDef,
        rowSelection: "single",
        animateRows: true,
        pagination: true,
        paginationAutoPageSize: true,
        getRowClass: (params) => {
            return params.node.rowIndex % 2 === 0 ? 'red-row' : 'white-row';
        }
    };

    constructor(private apiService: ApiService, public cdr: ChangeDetectorRef, private zone: NgZone,
        private el: ElementRef, private renderer: Renderer2, private router: Router,) {
        this.centriCodiceAz = localStorage.getItem('centriCodiceAz') ? localStorage.getItem('centriCodiceAz') : '1';
        this.specie = localStorage.getItem('specie') ? localStorage.getItem('specie') : 'BI';
        this.anno = localStorage.getItem('anna') ? localStorage.getItem('anna') : '2022';
        // this.callOnInit();
        this.checkingPermision();
    }
    // @ViewChild('dashboardChart', { static: true }) dashboardChart!: ElementRef;
    dashChart!: Chart;
    otherCentro: any[] = [];
    selectedCentro: any[] = [];
    CentroLevel: string[] = [];
    isAlive: boolean = true;
    loader: boolean = false;
    centriCodiceAz: string = localStorage.getItem('centriCodiceAz') ? localStorage.getItem('centriCodiceAz') : '1';
    specie: string = localStorage.getItem('specie') ? localStorage.getItem('specie') : 'BI';
    anno: string = localStorage.getItem('anna') ? localStorage.getItem('anna') : '2022';
    Highcharts: typeof Highcharts = Highcharts;
    isHighcharts = typeof Highcharts === 'object';
    updateFlag: boolean = false;
    acqscgart1: number = 0;
    chartOptions: Highcharts.Options = {
        chart: {
            // width: 855,
            renderTo: 'container',
            type: 'column',
            backgroundColor: 'transparent',
            // options3d: {
            //     enabled: true,
            //     alpha: 2,
            //     beta: 16,
            //     depth: 100,
            //     viewDistance: 25
            // },

        },
        accessibility: {
            enabled: false
        },
        title: {
            text: '',
            style: { "color": 'rgb(102, 102, 102)' }
        },
        exporting: {
            enabled: false
        },
        plotOptions: {
            column: {
                depth: 55,
                borderRadius: 10, // Adjust the border-radius value as needed
                borderWidth: 1, // Adjust the border width as needed
                borderColor: 'rgba(0, 0, 0, 0.5)', // Adjust the border color as needed
            },
        },
        xAxis: {
            labels: {
                style: {
                    color: 'rgb(102, 102, 102)',
                }
            },
            title: { text: "SETTIMANA" },
            categories: Array.from({ length: 52 }, (_, i) => `${i + 1}`),
        },
        yAxis: {
            labels: {
                style: {
                    color: 'rgb(102, 102, 102)'
                }
            },
            title: { text: "VALORI" },
        },
        tooltip: {
            useHTML: true,
            headerFormat: '<p><b>SETTIMANA {point.key}</b></p>',
            valueDecimals: 2
        },
        legend: {
            itemStyle: {
                color: 'rgb(102, 102, 102)'
            }
        },
        series: [
            {
                type: 'column',
                name: 'AUTOCERTIFICAZIONI',
                showInLegend: true,
                data: [],
                color: '#45c8ff',

            },
            {
                type: 'column',
                name: 'ISILS',
                showInLegend: true,
                data: [],
                color: '#544fc5'
            }
        ],
        responsive: {
            rules: [{
                condition: {
                    minWidth: 500
                },
                chartOptions: {
                    legend: {
                        enabled: false
                    }
                }
            }],
        }
    };
    chartOptions1: Highcharts.Options;
    chartOptions2: Highcharts.Options;
    chartOptions3: Highcharts.Options;


    ngOnInit(): void {
        this.currentYear = new Date().getFullYear();
        if (this.isDneUser) {
            const elements = this.el.nativeElement.querySelectorAll('.last-chart');
            elements.forEach((element: any) => {
                this.renderer.setStyle(element, 'display', 'none');
            });
            this.apiService.geLastTenRecords({ page_number: this.pageNumber, per_page: this.itemPerPage }).subscribe((res) => {
                if (res?.result?.rows && res?.result?.rows.length) {
                    this.rowDataTen$ = res?.result?.rows;
                    this.cdr.detectChanges();
                    this.totalCount = res?.result?.count ? res?.result?.count : 0;
                }
            })
            this.apiService.getdnaOperData({ page_number: this.pageNumber, per_page: this.itemPerPage }).subscribe((res) => {
                if (res?.result?.rows && res?.result?.rows?.length) {
                    this.zone.run(() => {
                        this.rowData$ = res?.result?.rows;
                        this.cdr.detectChanges();
                        this.totalCount = res?.result?.count ? res?.result?.count : 0;
                    })
                }
            })
            this.apiService.getStoricoData({ page_number: this.pageNumber, currentYear: this.currentYear, per_page: this.itemPerPage }).subscribe((res) => {
                if (res?.result?.rows && res?.result?.rows.length) {
                    this.rowDataFifty$ = res?.result?.rows;
                    this.cdr.detectChanges();
                    this.totalCount = res?.result?.count ? res?.result?.count : 0;
                }
            })


        }
        this.centriCodiceAz = localStorage.getItem('centriCodiceAz') ? localStorage.getItem('centriCodiceAz') : '1';
        this.specie = localStorage.getItem('specie') ? localStorage.getItem('specie') : 'BI';
        this.anno = localStorage.getItem('anna') ? localStorage.getItem('anna') : '2022';
        if (!this.isDneUser) {
            this.callOnInit();
        }

    }

    onGridDataChange(newData: any[]) {
        this.gridApi.setRowData(newData);
    }
    checkingPermision() {
        let data = sessionStorage.getItem("auth-user");
        if (data != null) {
            if (JSON.parse(data).data.user.permission_groups.name === 'DNE') {
                this.isDneUser = true;
            }
        }
    }

    getChartOptions(title: string): Highcharts.Options {
        const chartOptions: Highcharts.Options = JSON.parse(JSON.stringify(this.chartOptions));
        chartOptions.title.text = title;
        return chartOptions;
    }

    callOnInit() {
        this.chartOptions1 = this.getChartOptions('CONC MEDIA PER SETTIMANA');
        this.chartOptions2 = this.getChartOptions('MP MEDIA PER SETTIMANA');
        this.chartOptions3 = this.getChartOptions('NSPM MEDIA PER SETTIMANA');
        this.getDataOnSubscribe();
        this.zone.run(() => {
            // Code that updates data
            this.getChartData(this.specie, this.anno, this.centriCodiceAz);
            this.getTextualData();
            this.andmentoChartData(this.centriCodiceAz, this.specie, this.anno, this.weekStatus);
        });


    }

    // dashChartDrow() {
    //     if (this.dashChart) { this.dashChart.destroy(); }
    //     this.dashChart = new Chart(this.dashboardChart.nativeElement, {
    //         type: 'bar',
    //         data: {
    //             labels: this.CentroLevel,
    //             datasets: [
    //                 {
    //                     label: "GENERALE",
    //                     data: this.otherCentro,
    //                     backgroundColor: '#546953',
    //                     borderWidth: 0,
    //                     borderRadius:10,

    //                 },
    //                 {
    //                     label: "CENTRO",
    //                     data: this.selectedCentro,
    //                     backgroundColor: '#6b9e6a',
    //                     borderWidth: 0,
    //                     borderRadius:10,

    //                 }
    //             ]
    //         },
    //         options: {
    //             responsive: true,
    //             maintainAspectRatio: false,
    //             aspectRatio: 5,
    //             scales: {
    //                 x: { display: true ,grid:{display:false}},
    //                 y: { display: true,grid:{display:false} }
    //             },
    //             plugins: {
    //                 legend: { display: true },
    //                 title: {
    //                     display: true,
    //                     text: 'GENERALE vs CENTRO',
    //                     align: 'center',
    //                     position: 'bottom',
    //                 }
    //             }
    //         }
    //     });
    // }

    getChartData(specie: string, anno: string, centriCodiceAz: string) {
        this.loader = true
        this.apiService.getChartData(specie, anno, centriCodiceAz).subscribe((res: any) => {
            this.loader = false
            this.otherCentro = res?.result?.otherCentro.map((el: string) => el !== null ? el : '0');
            this.selectedCentro = res?.result?.selectedCentro.map((el: string) => el !== null ? el : '0');
            this.CentroLevel = res?.result?.calVal;
            const sum = this.otherCentro.reduce((a, v) => parseInt(a) + parseInt(v), 0);
            const sum1 = this.selectedCentro.reduce((a, v) => parseInt(a) + parseInt(v), 0);
            this.acqscgart1 = sum + sum1;
            // this.dashChartDrow();
            this.cdr.detectChanges();

        });
    }

    collectChartData(res: any) {
        this.firstChart = res?.resultQS?.map((el: any) => {
            return {
                data: el?.conc,
                week: el?.week,
            }
        });
        this.firstChart.mainTitle = 'CONC MEDIA PER SETTIMANA';
        let maxFirst = this.firstChart.reduce((prev, current) => (prev.data > current.data ? prev : current)).data;
        maxFirst = Math.ceil(maxFirst / 5) * 5;
        this.firstChart.maximum = maxFirst;
        this.firstChart.interval = 5;
        this.firstChart.fillColor = getComputedStyle(document.documentElement).getPropertyValue('--Accent');
        this.secondChart = res?.resultQS?.map((el: any) => {
            return {
                data: el?.mp,
                week: el?.week,
            }
        });
        this.secondChart.mainTitle = 'MP MEDIA PER SETTIMANA';
        let maxSecond = this.secondChart.reduce((prev, current) => (prev.data > current.data ? prev : current)).data;
        maxSecond = Math.ceil(maxSecond / 20) * 20;
        this.secondChart.maximum = maxSecond;
        this.secondChart.interval = 20;
        this.secondChart.fillColor = getComputedStyle(document.documentElement).getPropertyValue('--AccentDark');
        this.thirdChart = res?.resultQS?.map((el: any) => {
            return {
                data: el?.nspm,
                week: el?.week,
            }
        });
        this.thirdChart.mainTitle = 'NSPM MEDIA PER SETTIMANA';
        let maxThird = this.thirdChart.reduce((prev, current) => (prev.data > current.data ? prev : current)).data;
        maxThird = Math.ceil(maxThird / 20) * 20;
        this.thirdChart.maximum = maxThird;
        this.thirdChart.interval = 25;
        this.thirdChart.fillColor = getComputedStyle(document.documentElement).getPropertyValue('--Base');
    }

    andmentoChartData(codice_az: any, specie: any, anno_rif: any, dataType: any) {
        this.loader = true
        this.apiService.getAndmentoChartData(codice_az, specie, anno_rif, dataType).subscribe((res: any) => {
            // this.collectChartData(res);
            this.loader = false
            this.acqscgart = res?.resultAc?.length > 0 || res?.resultQS?.length > 0;
            // this.chartOptions1.xAxis['categories'] = this.getCatg(res);
            // this.chartOptions2.xAxis['categories'] = this.getCatg(res);
            // this.chartOptions3.xAxis['categories'] = this.getCatg(res);


            const categories = this.getCatg(res);
            this.chartOptions1.xAxis['categories'] = categories;
            this.chartOptions2.xAxis['categories'] = categories;
            this.chartOptions3.xAxis['categories'] = categories;

            // Ensure data points are aligned with categories
            const mapDataToCategory = (data: any[], categoryProperty: string, valueProperty: string, color: string) => {
                return categories.map((category) => {
                    const dataPoint = data.find(el => el[categoryProperty] === category);
                    return dataPoint ? { y: dataPoint[valueProperty], color: color } : { y: 0, color: color }; // or some default value
                });
            };
            this.chartOptions1.series[0]['data'] = mapDataToCategory(res?.resultAc, 'week', 'conc_c', "#546953");
            this.chartOptions1.series[1]['data'] = mapDataToCategory(res?.resultQS, 'week', 'conc', "#a8e5a9");
            this.chartOptions2.series[0]['data'] = mapDataToCategory(res?.resultAc, 'week', 'mp_c', "#546953");
            this.chartOptions2.series[1]['data'] = mapDataToCategory(res?.resultQS, 'week', 'mp', "#a8e5a9");
            this.chartOptions3.series[0]['data'] = mapDataToCategory(res?.resultAc, 'week', 'nspm_c', "#546953");
            this.chartOptions3.series[1]['data'] = mapDataToCategory(res?.resultQS, 'week', 'nspm', "#a8e5a9");
            // this.chartOptions1.series[0]['data'] = res?.resultAc?.map((el: any) => { return { y: el?.conc_c, color: "#546953" } });
            // this.chartOptions1.series[1]['data'] = res?.resultQS?.map((el: any) => { return { y: el?.conc, color: "#a8e5a9" } });
            // this.chartOptions2.series[0]['data'] = res?.resultAc?.map((el: any) => { return { y: el?.mp_c, color: "#546953" } });
            // this.chartOptions2.series[1]['data'] = res?.resultQS?.map((el: any) => { return { y: el?.mp, color: "#a8e5a9" } });
            // this.chartOptions3.series[0]['data'] = res?.resultAc?.map((el: any) => { return { y: el?.nspm_c, color: "#546953" } });
            // this.chartOptions3.series[1]['data'] = res?.resultQS?.map((el: any) => { return { y: el?.nspm, color: "#a8e5a9" } });
            this.updateFlag = true;
            this.cdr.detectChanges();
        });
    }

    getCatg(res: any): any[] {
        const arr1 = res?.resultAc?.map((el: any) => el?.week);
        const arr2 = res?.resultQS?.map((el: any) => el?.week);
        const mergedSet = new Set([...arr1, ...arr2]);
        return Array.from(mergedSet).sort((a, b) => a - b); // Ensure the categories are sorted
    }


    getTextualData() {
        this.isTextualLoader = true;
        this.apiService.getTextualData(this.centriCodiceAz, this.anno, this.specie).subscribe((res: any) => {
            this.isTextualLoader = false;
            this.textualData = res;
            if (this.textualData?.percentage_lotti_campionati) {
                this.textualData.percentage_lotti_campionati = this.textualData?.percentage_lotti_campionati.toFixed(2);
            }
            this.cdr.detectChanges();
        })
    }

    getDataOnSubscribe() {
        this.apiService.current.pipe(takeWhile(() => this.isAlive)).subscribe((res) => {
            if (res) {
                this.centriCodiceAz = res.codice_az ? res.codice_az : localStorage.getItem('centriCodiceAz')
                this.specie = res.specie ? res.specie : localStorage.getItem('specie');
                this.anno = res.anna ? res.anna : localStorage.getItem('anna')
                this.getChartData(this.specie, this.anno, this.centriCodiceAz);
                this.getTextualData();
                this.andmentoChartData(this.centriCodiceAz, this.specie, this.anno, this.weekStatus);
            }
        })
    }
    onChangeToggle(event) {
        let toggle = event?.target?.checked;
        if (toggle) {
            this.weekStatus = 'allweek'
            this.andmentoChartData(this.centriCodiceAz, this.specie, this.anno, this.weekStatus);
        } else {
            this.weekStatus = 'week'
            this.andmentoChartData(this.centriCodiceAz, this.specie, this.anno, this.weekStatus);
        }
    }

    dateComparator(date1: string | null, date2: string | null): number {
        // Check if either date is null or undefined
        if (!date1 || !date2) {
            // You can decide what to return in case of null values
            // For example, if date1 is null, consider it as smaller than any non-null date2
            return date1 ? 1 : -1;
        }

        const dateParts1 = date1.split('/');
        const dateParts2 = date2.split('/');

        // Create date objects in the format YYYY-MM-DD
        const d1 = new Date(Number(dateParts1[2]), Number(dateParts1[1]) - 1, Number(dateParts1[0]));
        const d2 = new Date(Number(dateParts2[2]), Number(dateParts2[1]) - 1, Number(dateParts2[0]));

        return d1.getTime() - d2.getTime();
    }

    ngOnDestroy() {
        this.isAlive = false;
    }
}

