<fieldset>
    <legend><h3>{{'Gestisci Impostazioni' | uppercase}}</h3></legend>
    <div class="setting">
        <div class="setting-row mb-1">
            <div class="col">
                <label for="change password">Cambia Password :</label>
            </div>
            <div class="col">
                <button class="button1" (click)="changePassword()">Premi Qui</button>
            </div>
        </div>
        <div class="setting-row mb-1">
            <div class="col">
                <label for="change password">Seleziona Anno :</label>
            </div>
            <div class="col">
                <select id="mySelect" style="width: 200px;" [(ngModel)]="this.settingYear" (change)="getYear($event)">
                    <option value="">default</option>
                    <option *ngFor="let year of years" [value]="year">{{ year }}</option>
                </select>
            </div>
        </div>
        <div class="setting-row mb-1">
            <div class="col">
            </div>
            <div class="col">
                <button class="button1" (click)="saveSettingYear()">Salva</button>
            </div>
        </div>

        <!-- New Checkbox for FIRMA RESPONSABILE -->
        <div class="setting-row mb-1">
            <div class="col">
                <label for="firma-responsabile">FIRMA RESPONSABILE </label>
            </div>
            <div class="col">
              <input 
                type="checkbox" 
               
                [(ngModel)]="signatureVal" 
                (change)="onCheckboxChange(signatureVal)"
              />
            </div>
        </div>

    </div>
</fieldset>

<form [formGroup]="settingForm" (ngSubmit)="saveQsGiornoSettimana()">
    <fieldset>
      <legend><h3>{{ 'IMPOSTAZIONI STATISTICHE' | uppercase }}</h3></legend>
      <div class="setting">
        <div class="subgroup">
          <h4>SCALA ANDAMENTI QS GIORNO</h4>
          <div class="setting-row mb-1">
            <div class="col">
              <label for="concDay">CONC:</label>
            </div>
            <div class="col">
              <input id="concDay" formControlName="qs_giorno_conc" type="number" placeholder="0.5" class="button1"/>
            </div>
          </div>
          <div class="setting-row mb-1">
            <div class="col">
              <label for="mpDay">MP:</label>
            </div>
            <div class="col">
              <input id="mpDay" formControlName="qs_giorno_mp" type="number" placeholder="1" class="button1" />
            </div>
          </div>
          <div class="setting-row mb-1">
            <div class="col">
              <label for="nspmDay">NSPM:</label>
            </div>
            <div class="col">
              <input id="nspmDay" formControlName="qs_giorno_nspm" type="number" placeholder="0.5" class="button1"/>
            </div>
          </div>
        </div>
        <div class="subgroup">
          <h4>SCALA ANDAMENTI QS SETTIMANA</h4>
          <div class="setting-row mb-1">
            <div class="col">
              <label for="concWeek">CONC:</label>
            </div>
            <div class="col">
              <input id="concWeek" formControlName="qs_settimana_conc" type="number" placeholder="0.5" class="button1"/>
            </div>
          </div>
          <div class="setting-row mb-1">
            <div class="col">
              <label for="mpWeek">MP:</label>
            </div>
            <div class="col">
              <input id="mpWeek" formControlName="qs_settimana_mp" type="number" placeholder="1" class="button1"/>
            </div>
          </div>
          <div class="setting-row mb-1">
            <div class="col">
              <label for="nspmWeek">NSPM:</label>
            </div>
            <div class="col">
              <input id="nspmWeek" formControlName="qs_settimana_nspm" type="number" placeholder="0.5" class="button1"/>
            </div>
          </div>
        </div>
        <button type="submit" class="button1 mr-1" [disabled]="settingForm.invalid">SALVA</button>
      </div>
    </fieldset>
  </form>
  <form [formGroup]="percentualeForm" (ngSubmit)="savePercentual()">
    <fieldset>
  
      <div class="setting">
        <div class="subgroup">

          <div class="setting-row mb-1">
            <div class="col">
              <label for="concDay"> PERCENTUALE CAMPIONAMENTI:</label>
            </div>
            <div class="col">
              <input id="concDay" formControlName="percentuale" type="number" placeholder="0.5" class="button1"/>
            </div>
          </div>
        
        <button type="submit" class="button1 mr-1" [disabled]="percentualeForm.invalid">SALVA</button>
      </div>
      </div>
    </fieldset>
  </form>