import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mobile-sidebar',
  templateUrl: './mobile-sidebar.component.html',
  styleUrls: ['./mobile-sidebar.component.scss']
})
export class MobileSidebarComponent implements OnInit {
  showSidebar: boolean = false;
  allUserpermision: any = [];
  userPermision: any = [];
  ngOnInit(): void {
    this.checkingPermision();
  }
  toggleSidebar() {
		this.showSidebar = !this.showSidebar;
	}

  checkingPermision() {
		let data = sessionStorage.getItem("auth-user");
		if (data != null) {
			this.userPermision = JSON.parse(data).data.user.permission_groups.permissions;
			this.userPermision.forEach((element) => {
				this.allUserpermision.push(element.name);
			});
		}
	}
}
