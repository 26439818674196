import { Component, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { InnerWorkSheets, WorkSheets } from '../_models';
import { CellClickedEvent, ColDef, GridOptions } from 'ag-grid-community';
import { CustomButtonRendererComponent, CustomButtonRendererComponent1 } from '../table-test/custom-button-renderer.component';
import { ApiService } from '../_services/api.service';
import { takeWhile } from 'rxjs';
import { Router } from '@angular/router';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
@Component({
  selector: 'app-worksheetnsamedna',
  templateUrl: './worksheetnsamedna.component.html',
  styleUrls: ['./worksheetnsamedna.component.scss']
})
export class WorksheetComponent {
  loader: boolean = false;
  popup = false;
  rowData1$: WorkSheets[];
  rowData2$: InnerWorkSheets[];
  rowData3$: WorkSheets[];
  clickedRowData1: any;
  clickedRowData2: any;
  view: any;
  manual:boolean=false
  @ViewChild("popupContainer", { read: TemplateRef })
  popupContainer: TemplateRef<any>;
  table1Name: string = 'newdnadata';
  table2Name: string = 'innerdatatable';
  itemPerPage: number = 10;
  pageNumber: number = 1;
  totalCount: number = 1;
  subjectValue: boolean = false;
  defaultColDef: ColDef = { resizable: true, sortable: true, filter: true };
  showExportButton: boolean;
  isAlive = true;
  centriCodiceAz: string | number;
  anno: string | number;
  concatenatedString: string;
  piastraFrom: FormGroup;
  piastraData:any[]=[]
  colDefs1: ColDef<WorkSheets>[] = [
    {
      field: "codice_cam",
      headerName: "CAMPIONAMENTO",
      width: 175,
      cellRenderer: (params: any) => {
        return `${params.data.codice_cam == 'NULL' || params.data.codice_cam == null ? 'N/A' : params.data.codice_cam}`;
      },
    },
    {
      field: "rcamp_data",
      headerName: "DATA RiCAR",
      width: 140,
      cellRenderer: (params: any) => {
        return `${params.data.rcamp_data == 'NULL' || params.data.rcamp_data == null ? 'N/A' : params.data.rcamp_data}`;
      },
    },
    {
      field: "rcamo_oper",
      headerName: "UTENTE RiCAR",
      width: 140,
      cellRenderer: (params: any) => {
        return `${params.data.rcamo_oper == 'NULL' || params.data.rcamo_oper == null ? 'N/A' : params.data.rcamo_oper}`;
      },
    },
    {
      field: "data_campl",
      headerName: "DATA CAMPl",
      width: 140,
      cellRenderer: (params: any) => {
        return `${params.data.data_campl == 'NULL' || params.data.data_campl == null ? 'N/A' : params.data.data_campl}`;
      },
    },
    {
      field: "data_camp2",
      headerName: "DATA CAMP 2",
      width: 140,
      cellRenderer: (params: any) => {
        return `${params.data.data_camp2 == 'NULL' || params.data.data_camp2 == null ? 'N/A' : params.data.data_camp2}`;
      },
    },
    {
      field: "a_dna_data",
      headerName: "DATA BOLLA/DNA",
      width: 160,
      cellRenderer: (params: any) => {
        return `${params.data.a_dna_data == 'NULL' || params.data.a_dna_data == null ? 'N/A' : params.data.a_dna_data}`;
      },
    },
    {
      field: "opa_camp",
      headerName: "OPA CAMP",
      width: 140,
      cellRenderer: (params: any) => {
        return `${params.data.opa_camp == 'NULL' || params.data.opa_camp == null ? 'N/A' : params.data.opa_camp}`;
      },
    },
    {
      field: "faxva_data",
      headerName: "DATA FAX",
      width: 100,
      cellRenderer: (params: any) => {
        return `${params.data.faxva_data == 'NULL' || params.data.faxva_data == null ? 'N/A' : params.data.faxva_data}`;
      },
    },
    {
      field: "faxva_oper",
      headerName: "UTENTE FAX",
      width: 100,
      cellRenderer: (params: any) => {
        return `${params.data.faxva_oper == 'NULL' || params.data.faxva_oper == null ? 'N/A' : params.data.faxva_oper}`;
      },
    },
    {
      field: "Action",
      headerName: "AZIONE",
      width: 700,
      cellRenderer: 'customButtonRenderer',
      cellRendererParams: {
        tableName: 'newdnadata',
      },
    },
  ];
  colDefs2: ColDef<WorkSheets>[] = [
    {
      field: "codice_cam",
      headerName: "CAMPIONAMENTO",
      width: 175,
      cellRenderer: (params: any) => {
        return `${params.data.codice_cam == 'NULL' || params.data.codice_cam == null ? 'N/A' : params.data.codice_cam}`;
      },
    },
    {
      field: "rcamp_data",
      headerName: "DATA RiCAR",
      width: 140,
      cellRenderer: (params: any) => {
        return `${params.data.rcamp_data == 'NULL' || params.data.rcamp_data == null ? 'N/A' : params.data.rcamp_data}`;
      },
    },
    {
      field: "rcamo_oper",
      headerName: "UTENTE RiCAR",
      width: 140,
      cellRenderer: (params: any) => {
        return `${params.data.rcamo_oper == 'NULL' || params.data.rcamo_oper == null ? 'N/A' : params.data.rcamo_oper}`;
      },
    },
    {
      field: "data_campl",
      headerName: "DATA CAMPl",
      width: 140,
      cellRenderer: (params: any) => {
        return `${params.data.data_campl == 'NULL' || params.data.data_campl == null ? 'N/A' : params.data.data_campl}`;
      },
    },
    {
      field: "data_camp2",
      headerName: "DATA CAMP 2",
      width: 140,
      cellRenderer: (params: any) => {
        return `${params.data.data_camp2 == 'NULL' || params.data.data_camp2 == null ? 'N/A' : params.data.data_camp2}`;
      },
    },
    {
      field: "a_dna_data",
      headerName: "DATA BOLLA/DNA",
      width: 160,
      cellRenderer: (params: any) => {
        return `${params.data.a_dna_data == 'NULL' || params.data.a_dna_data == null ? 'N/A' : params.data.a_dna_data}`;
      },
    },
    {
      field: "opa_camp",
      headerName: "OPA CAMP",
      width: 140,
      cellRenderer: (params: any) => {
        return `${params.data.opa_camp == 'NULL' || params.data.opa_camp == null ? 'N/A' : params.data.opa_camp}`;
      },
    },
    {
      field: "faxva_data",
      headerName: "DATA FAX",
      width: 100,
      cellRenderer: (params: any) => {
        return `${params.data.faxva_data == 'NULL' || params.data.faxva_data == null ? 'N/A' : params.data.faxva_data}`;
      },
    },
    {
      field: "faxva_oper",
      headerName: "UTENTE FAX",
      width: 100,
      cellRenderer: (params: any) => {
        return `${params.data.faxva_oper == 'NULL' || params.data.faxva_oper == null ? 'N/A' : params.data.faxva_oper}`;
      },
    },
    {
      field: "Action",
      headerName: "AZIONE",
      width: 700,
      cellRenderer: 'customButtonRenderer',
      cellRendererParams: {
        tableName: 'newdnadata',
      },
    },
  ];
  gridOptions1: GridOptions<WorkSheets> = {
    columnDefs: this.colDefs1,
    defaultColDef: this.defaultColDef,
    rowSelection: "single",
    animateRows: true,
    pagination: true,
    paginationAutoPageSize: true,
    frameworkComponents: { customButtonRenderer: CustomButtonRendererComponent1 },
    onCellClicked: (event: CellClickedEvent) => {
      this.clickedRowData1 = event?.data;
      this.router.navigate(['detail-page'], { queryParams: { codice_cam: this.clickedRowData1?.codice_cam, codice_az: this.clickedRowData1?.codice_az } })
    },
  };
  gridOptions2: GridOptions<WorkSheets> = {
    columnDefs: this.colDefs2,
    defaultColDef: this.defaultColDef,
    rowSelection: "single",
    animateRows: true,
    pagination: true,
    paginationAutoPageSize: true,
    frameworkComponents: { customButtonRenderer: CustomButtonRendererComponent1 },
    onCellClicked: (event: CellClickedEvent) => {
      this.clickedRowData1 = event?.data;
      this.router.navigate(['detail-page'], { queryParams: { codice_cam: this.clickedRowData1?.codice_cam, codice_az: this.clickedRowData1?.codice_az } })
    },
  };
  fagfilename: any;
  codeseme: any[];
  unusedColumn: any;
  currentDate: string;
  
  constructor(
    private apiService: ApiService,
    private router: Router,
    private viewContainerRef: ViewContainerRef,
    private fb: FormBuilder,
    public toastr: ToastrService,
    private http: HttpClient
  ) { 

    this.initForm()

  }

  ngOnInit() {
    this.apiService.current.pipe(takeWhile(() => this.isAlive)).subscribe((res) => {
      if (res) {
        this.subjectValue = true
        this.centriCodiceAz = res.codice_az ? res.codice_az : localStorage.getItem('centriCodiceAz')
        this.anno = res.anna ? res.anna : localStorage.getItem('anna')
        this.tableData();
      }
    })
    this.tableData();
    const currentDate = new Date(); // Assuming this is your date
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');

    this.currentDate = `${year}-${month}-${day}`;
  }

  initForm(){
    this.piastraFrom = this.fb.group({
      ID_PIASTRA: [null],
      tipo_seq: [null],
      DATA_RIF: [null],
      CELLEPIENE: [null],
      buttonType: ['xlsx'],
      id_seme: this.fb.array([this.createItem()])
    })
  }

  tableData() {
    if (!this.subjectValue) {
      this.centriCodiceAz = localStorage.getItem('centriCodiceAz') ? localStorage.getItem('centriCodiceAz') : 1
      this.anno = localStorage.getItem('anna') ? localStorage.getItem('anna') : 2022
    }
    this.subjectValue = false
    this.apiService.worksheetData(this.pageNumber, this.itemPerPage, this.centriCodiceAz, this.anno).subscribe({
      next: (res) => {
        this.rowData1$ = res?.havingAdnaDatanullResult?.rows;
        this.rowData3$ = res?.havingAdnaDataNotnullResult?.rows
        this.totalCount = res?.totalCount;
      },
      error: (error) => { },
      complete: () => { }
    });
  }

  openPopup(buttonName:any) {
    let codice_az = localStorage.getItem('centriCodiceAz')
    this.piastraFrom.get('tipo_seq').setValue('2');
    this.apiService.getIdPiastraMannul(codice_az,buttonName).subscribe((res:any)=>{
      if(res.status){
        this.piastraData = res?.data
        let id = this.piastraData[0].ID_PIASTRA;
        let codice_az = localStorage.getItem('centriCodiceAz')
        this.piastraFrom.get('ID_PIASTRA').setValue(this.piastraData[0].ID_PIASTRA);
        this.apiService.getPiastraManual(codice_az,id,buttonName).subscribe((res) => {
          if (res?.status) {
            this.piastraFrom.get('ID_PIASTRA').setValue(this.piastraData[0].ID_PIASTRA);
            this.piastraFrom.get('CELLEPIENE').setValue(res.result.CELLEPIENE);            
            this.codeseme = res.COD_SEME
            this.unusedColumn = res.unusedColumn
          } else {
            this.toastr.error(res?.description)
          }
        })
        // this.piastraFrom.get('posti_disponibili').setValue(96-Number(this.piastraData[0].CELLEPIENE));
        // this.popUpa()
      }else{
        this.toastr.error(res?.description)
      }

    })
    this.apiService.modaBackDrop.next(true);
    this.view = this.viewContainerRef.createEmbeddedView(this.popupContainer);
    const domElem = this.view.rootNodes[0] as HTMLElement;
    domElem.classList.add("animate-in");
  }

  closePopup() {

    // this.initializeCheckboxes();
    this.apiService.modaBackDrop.next(false);
    this.viewContainerRef.clear();
    // this.manual = false;
  }

  get id_seme(): FormArray {
    return this.piastraFrom.get('id_seme') as FormArray<any>;
  }

  createItem(): FormGroup {
    return this.fb.group({
      value: ['']
    });
  }
  addItem(): void {
    this.id_seme.push(this.createItem());
  }

  removeItem(index: number): void {
    this.id_seme.removeAt(index);
  }

  
  onSubmitPiastra() {
    let codice_az = localStorage.getItem('centriCodiceAz')
    let DATA_RIF = this.formatDate(this.piastraFrom.value.DATA_RIF)
    let idPiasta = parseInt(this.piastraFrom.value.ID_PIASTRA)
    const formValues = this.id_seme.controls
    .map(control => control.get('value')?.value)
    .filter(value => value?.trim() !== '');

  if (formValues.length === 0) {
    this.toastr.error('Please fill the value of ID Seme');
    return;
  }
    let payload = { ...this.piastraFrom.value,ID_PIASTRA:idPiasta, COD_SEME: this.codeseme, unusedColumn: this.unusedColumn, DATA_RIF: DATA_RIF,id_seme:formValues }
    this.apiService.savePiastraManual(codice_az, payload).subscribe((res) => {
      if (res?.success) {
        let url = environment.url + res.downloadLink;
        this.fagfilename = res.filenames
        if (res.buttonType == "text") {
          this.downloadTextFile(url)
          // this.tabletest()
          this.resetFormArray()
        } else {
          this.downloadExcelFile(url)
          // this.tabletest()
          this.resetFormArray()
        }
        this.closePopup();
        this.resetFormArray()
      } else {
        this.toastr.error(res.description)
        this.closePopup();
        this.resetFormArray()
      }
    })
  }
  resetFormArray() {
    // Clear the FormArray
    while (this.id_seme.length > 0) {
      this.id_seme.removeAt(0);
    }
    // Add a single default item
    this.id_seme.push(this.createItem());
  }


  
  formatDate(inputDate): any {
    if (inputDate != null) {
      const [year, month, day] = inputDate.split('-');
      const formattedDate = `${day}/${month}/${year}`;
      return formattedDate;
    }
  }

  onSelectionChange(data:any){
    let id = data.target.value
    let codice_az = localStorage.getItem('centriCodiceAz')
        this.apiService.getPiastraManual(codice_az,id,'PIASTRA_MANUALE').subscribe((res) => {
        if (res?.status) {
          this.piastraFrom.get('CELLEPIENE').setValue(res.result.CELLEPIENE);
          if(!this.manual){
            this.codeseme = res.COD_SEME
          }else{
            this.codeseme=[]
          }
          this.unusedColumn = res.unusedColumn
        } else {
          this.toastr.error(res?.description)
        }
      })

  }

  downloadExcelFile(url: string): void {
    this.http.get(url, { responseType: 'arraybuffer' }).subscribe((data: ArrayBuffer) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const workbook = XLSX.read(data, { type: 'array' });
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

      // Create a Blob and create a download link
      const excelBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(excelBlob, this.fagfilename);
    });
  }


  previewPisatraButton(){
    let idPiasta = parseInt(this.piastraFrom.value.ID_PIASTRA)
    let codice_az = localStorage.getItem('centriCodiceAz')

    this.apiService.priviewPiastraManual(codice_az,idPiasta).subscribe((res:any)=>{
      if(res.status){
        let url = environment.url + res.downloadLink
        this.fagfilename = res.filenames
        this.downloadTextFile(url);
      }else{
        this.toastr.error(res.message)
      }
    })
  }

  downloadTextFile(url: string): void {
    this.http.get(url, { responseType: 'text' }).subscribe((data: string) => {
      const blob = new Blob([data], { type: 'text/plain' });
      const anchor = document.createElement('a');
      anchor.href = window.URL.createObjectURL(blob);
      anchor.download = this.fagfilename;
      document.body.appendChild(anchor);
      anchor.click();
      window.URL.revokeObjectURL(anchor.href);
      document.body.removeChild(anchor);
    });
  }

}
