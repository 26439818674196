<div class="pagination-btn">
    <!-- <button class="previous-btn" (click)="previousPage()" [disabled]="currentPage === 1">Previous</button>
    <ng-container *ngIf="currentPage>4">
        <button (click)="fistPage(1)">1</button>
        <button>...</button>
    </ng-container>
    <ng-container *ngFor="let page of visiblePages"><button (click)="goToPage(page)"
            [class.active]="page === currentPage">{{ page }}</button></ng-container>
    <ng-container *ngIf="totalPages>3 && (totalPages !== currentPage)">
        <button>...</button>
        <button (click)="lastPage(totalPages)" [class.active]="totalPages === currentPage">{{totalPages}}</button>
    </ng-container>
    <button class="next-btn" (click)="nextPage()" [disabled]="currentPage === totalPages">Next</button> -->


    <span class="ag-paging-page-summary-panel" role="presentation">
        <div ref="btPrevious" class="ag-paging-button ag-disabled" role="button" aria-label="Previous Page" 
        aria-disabled="true" (click)="previousPage()" >
            <span class="ag-icon ag-icon-previous" unselectable="on" role="presentation"></span>
        </div>
        <span class="ag-paging-description left-margin" role="status">
            <span>Page</span>
            <span class="ag-paging-number left-margin"><b>{{currentPage}}</b></span>
            <span class="left-margin">of</span>
            <span class="ag-paging-number left-margin"><b>{{totalPages}}</b></span>
        </span>
        <div class="ag-paging-button left-margin" 
            role="button" aria-label="Next Page" tabindex="0">
                <span class="ag-icon ag-icon-next" unselectable="on" role="presentation" (click)="nextPage()" ></span>
            </div>
    </span>
</div>
