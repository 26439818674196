import { FurnitureComponent } from './../furniture/furniture.component';
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { HomeComponent } from "../home/home.component";
import { AuthGuard } from "../_guards/auth.guard";
import { RiproduttoriComponent } from "../riproduttori/riproduttori.component";
import { TableTestComponent } from "../table-test/table-test.component";
import { CampionamentiComponent } from "../campionamenti/campionamenti.component";
import { ImportaListatiComponent } from "../importa-listati/importa-listati.component";
import { InstrumentDetailsComponent } from "../pages/instrument-details/instrument-details.component";
import { AggiungiAnagraficaComponent } from "../aggiungi-anagrafica/aggiungi-anagrafica.component";
import { TestComponentsComponent } from "../test-components/test-components.component";
import { ChangePasswordComponent } from "../change-password/change-password.component";
import { StatisticsOverviewComponent } from "../statistics-overview/statistics-overview.component";
import { CourierManagementComponent } from "../courier-management/courier-management.component";
import { NewDnaRefComponent } from "../new-dna-ref/new-dna-ref.component";
import { DashboardComponent } from "../dashboard/dashboard.component";
import { WorksheetComponent } from "../worksheetnsamedna/worksheetnsamedna.component";
import { RicarTableComponent } from "../ricar-table/ricar-table.component";
import { FoglioTableComponent } from "../foglio-table/foglio-table.component";
import { BollaTableComponent } from "../bolla-table/bolla-table.component";
import { L30qsTableComponent } from "../l30qs-table/l30qs-table.component";
import { L30operTableComponent } from "../l30oper-table/l30oper-table.component";
import { L30dnaTableComponent } from "../l30dna-table/l30dna-table.component";
import { DetailPageComponent } from "../detail-page/detail-page.component";
import { CreatesampleComponent } from "../createsample/createsample.component";
import { MagazzinoComponent } from "../magazzino/magazzino.component";
import { RegistrosemednaComponent } from "../registrosemedna/registrosemedna.component";
import { SettingPageComponent } from "../setting-page/setting-page.component";
import { ArchivioComponent } from "../archivio/archivio.component";
import { RisultatiComponent } from "../risultati/risultati.component";
import { RisultatidnaComponent } from "../risultatidna/risultatidna.component";
import { AutocertificazioniComponent } from "../autocertificazioni/autocertificazioni.component";
import { ControlloInternoComponent } from "../controllo-interno/controllo-interno.component";
import { StatisticaDescrittivaComponent } from "../statistica-descrittiva/statistica-descrittiva.component";
import { LottiSectionComponent } from "../lotti-section/lotti-section.component";
import { CentriSectionComponent } from "../centri-section/centri-section.component";
import { FornitoriComponent } from "../fornitori/fornitori.component";

const routes: Routes = [
    {
        path: "",
        pathMatch:"full",
        redirectTo:"dashboard",
    },
    {
        path: "dashboard",
        component:DashboardComponent,
        canActivate: [AuthGuard],
    },
    {
        path: "home",
        component: HomeComponent,
        canActivate: [AuthGuard],
    },
    {
        path: "anagrafiche",
        component: RiproduttoriComponent,
        canActivate: [AuthGuard],
    },
    {
        path: "statistiche",
        component: TableTestComponent,
        canActivate: [AuthGuard],
    },
    {
        path: "campionamenti",
        component: CampionamentiComponent,
        canActivate: [AuthGuard],
    },
    {
        path: "importa-listati",
        component: ImportaListatiComponent,
        canActivate: [AuthGuard],
    },
    {
        path: "instrument-details",
        component: InstrumentDetailsComponent,
        canActivate: [AuthGuard],
    },
    {
        path: "aggiungi-anagrafica",
        component: AggiungiAnagraficaComponent,
        canActivate: [AuthGuard],
    },
    {
        path: "test-component",
        component: TestComponentsComponent,
        canActivate: [AuthGuard],
    },
    {
        path: "change-password",
        component: ChangePasswordComponent,
        canActivate: [AuthGuard],
    },
    {
        path: "statistics-overview",
        component: StatisticsOverviewComponent,
        canActivate: [AuthGuard],
    },
    {
        path: "dna-table",
        canActivate: [AuthGuard],
        loadChildren: () => import("../components/dna-table/dna-table.module").then((m) => m.DnaTableModule),
    },
    {
        path: "courier-management",
        component: CourierManagementComponent,
        canActivate: [AuthGuard],
    },
    {
        path: "new-dna-ref",
        component: NewDnaRefComponent,
        canActivate: [AuthGuard],
    },
    {
        path: "worksheet-dna",
        component: WorksheetComponent,
        canActivate: [AuthGuard],
    },
    // {
    //     path:"ricar",
    //     component:RicarTableComponent
    // },
    // {
    //     path:"foglio",
    //     component:FoglioTableComponent
    // },
    // {
    //     path:"bolla",
    //     component:BollaTableComponent
    // },
    {
        path:"l30qs",
        component:L30qsTableComponent
    },
    {
        path:"l30oper",
        component:L30operTableComponent
    },
    {
        path:"l30dna",
        component:L30dnaTableComponent
    },
    {
        path:"detail-page",
        component:DetailPageComponent
    },
    {
        path:"l30loti",
        component:CreatesampleComponent
    },
    {
        path:"magazzino",
        component:MagazzinoComponent
    },
    {
        path:"registrosemedna",
        component:RegistrosemednaComponent
    },
    {
        path: "setting",
        component: SettingPageComponent
    }, 
    {
        path: "archivio",
        component: ArchivioComponent

    },
    {
      path:"risultati",
      component:RisultatiComponent
    },
    {
      path:"risultatidna",
      component:RisultatidnaComponent
    },
    {
      path:"autocertifizion",
      component:AutocertificazioniComponent
    },
    {
        path:"controllo-interno",
        component:ControlloInternoComponent
    },
    {
        path:"statistica-descrittiva",
        component:StatisticaDescrittivaComponent
    },
    {
        path:"lotti-section",
        component:LottiSectionComponent
    },
    {
        path:"centri-section",
        component:CentriSectionComponent
    },
    {
        path:"fornitori-section",
        component:FornitoriComponent
    },
    {
        path:"fornituri-section/:id",
        component:FurnitureComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class DashRoutingModule { }
