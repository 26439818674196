<fieldset>
    <legend><h3>{{'Gestisci Impostazioni'|uppercase}}</h3></legend>
    <div class="setting">
        <div class="setting-row mb-1">
            <div class="col">
                <label for="change password">Cambia Password :</label>
            </div>
            <div class="col">
                <button class="button1" (click)="changePassword()">Premi Qui</button>
            </div>
        </div>
        <div class="setting-row mb-1">
            <div class="col">
                <label for="change password">Seleziona Anno :</label>
            </div>
            <div class="col">
                <select id="mySelect" style="width: 200px;" [(ngModel)]="this.settingYear" (change)="getYear($event)">
                    <option value="">default</option>
                    <option *ngFor="let year of years" [value]="year">{{ year }}</option>
                </select>
            </div>
        </div>
        <div class="setting-row mb-1">
            <div class="col">
            </div>
            <div class="col">
                <button class="button1" (click)="saveSettingYear()">Salva</button>
            </div>
        </div>
    </div>
    

</fieldset>