<div>
    <!-- <app-loader *ngIf="loader"></app-loader> -->
    <app-conform [message]="'Are you sure you want to delete?'" (confirm)="confirm($event)"></app-conform>

    <div class="header d-flex mt-2">
        <h1 class="semi-bold mb-1">CRIOBANCA</h1>
        <!-- <button class="button mr-1" (click)="openPopup();openPopup1()">Create</button> -->
    </div>

    <ag-grid-angular style="height: 700px;"
        class="ag-theme-alpine-dark basic-table" [rowData]="rowData$" [gridOptions]="gridOptions" (gridReady)="onGridReady($event)">
    </ag-grid-angular>

    <div class="pagination">
        		<app-pagination [dataLength]="totalCount" [tableName]="tableName" [itemPerPage]="itemPerPage"
                [columnName]="columnName" [filterValue]="filterValue" [sortingOrder]="sortingOrder"
				(dataOnPage)="receiveData($event)"></app-pagination>
    </div>

    <ng-template #popupContainer let-options>
        <div class="popup-window animate-in">
            <div class="form-container">
                <form [formGroup]="addL30Qs" (ngSubmit)="onSubmit()">
                    <div class="row">
                        <div class="column field">
                            <label>*COD DNA:</label>
                            <input type="text" formControlName="cod_dna" required />
                            <div *ngIf="(formSubmitted || fc['cod_dna'].touched) && fc['cod_dna'].errors" class="alert">
                                <div *ngIf="(formSubmitted || fc['cod_dna'].touched)"> Please enter cod dna </div>
                            </div>
                        </div>
                        <div class="column field">
                            <label>*DATA:</label>
                            <input type="date" formControlName="data" required />
                        </div>
                    </div>
                    <div class="row">
                        <div class="column field">
                            <label>DNA SEME:</label>
                            <input type="text" formControlName="dna_seme" />
                        </div>
                        <div class="column field">
                            <label>Ricevimento:</label>
                            <input type="number" formControlName="riceviment" />
                        </div>
                    </div>

                    <div class="row">
                        <div class="column field">
                            <label>Estrazione:</label>
                            <input type="text" formControlName="estrazione" />
                        </div>
                        <div class="column field">
                            <label>Diluizione:</label>
                            <input type="number" formControlName="diluizione" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="column field">
                            <label>Contenitore:</label>
                            <input type="number" formControlName="contenit" />
                        </div>
                        <div class="column field">
                            <label>Settore:</label>
                            <input type="text" formControlName="settore" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="column field">
                            <label>Cilindro:</label>
                            <input type="number" formControlName="cilindro" />
                        </div>
                        <div class="column field">
                            <label>Piano:</label>
                            <input type="number" formControlName="piano" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="column field">
                            <label>TIPO SEQUENZA:</label>
                            <input type="text" formControlName="tipo_seq" />
                        </div>
                        <div class="column field">
                            <label>Note:</label>
                            <input type="text" formControlName="note" />
                        </div>
                    </div>
                    
                    <div class="main-footer" [ngClass]="changeButtun?'main-footer':'main-footer1'">
                        <div class="delete" *ngIf="changeButtun">
                            <i class="fa fa-trash customtrash" aria-hidden="true" (click)="openmodel()"></i>
                        </div>
                        <div class="popup-footer">
                            <div><button class="button danger" type="submit">{{changeButtun?'Aggiorna':'Salva'}}</button>
                            </div>
                            <div><button class="button" type="button" (click)="closePopup()">Chiudi</button></div>
                        </div>
                    </div>

                </form>
            </div>
        </div>
    </ng-template>
</div>