<app-loader *ngIf="loader"></app-loader>
<div class="header d-flex mt-2">
    <h1 class="title">ARCHIVIO</h1>
      
    <!-- <div class="column radio">
        <label>File Type:</label>
        <input type="hidden" formControlName="CELLEPIENE" />

        <label class="container">xlsx
            <input type="radio"  value="xlsx" formControlName="buttonType" />
            <span class="check"></span>
          </label>
        <label class="container">Txt
            <input type="radio"  value="text" formControlName="buttonType" checked />
            <span class="check"></span>
          </label>
   
    </div> -->
    <div class="export">
        <button class="button1" (click)="export(selectedDbName)">Esporta</button>
        <select class="button mr-1" (change)="getDbTables(selectedDbName,'change')" [(ngModel)]="selectedDbName">
            <option [value]="tb?.value" *ngFor="let tb of tableList;">{{tb?.name}}</option>
        </select>
    </div>
</div>

<ag-grid-angular style="height: 700px;" class="ag-theme-alpine-dark basic-table" [rowData]="rowData$"
    [gridOptions]="gridOptions" (gridReady)="onGridReady($event)">
</ag-grid-angular>

<div class="pagination">
    <app-pagination [dataLength]="totalCount" [tableName]="tableName" [itemPerPage]="20" [selectedDbName]="selectedDbName"
    [filterValue]="filterValue" [columnName]="columnName" [sortingOrder]="sortingOrder"
    (dataOnPage)="onReceiveData($event)"></app-pagination>
</div>

<ng-template #popupContainer let-options>
    <div class="popup-window animate-in">
        <div class="form-container">
            <form  class="popForm" [formGroup]="form" (ngSubmit)="onSubmitDownload()">
                <div class="column radio ">
                    <label>File Type:</label>
                    
                    <label class="container">XLSX
                        <input type="radio" value="excel" formControlName="buttonType" name="buttonType" checked/>
                        <span class="check"></span>
                    </label>
                    <label class="container">CSV
                        <input type="radio" value="csv" formControlName="buttonType" name="buttonType"/>
                        <span class="check"></span>
                    </label>
                </div>
                <div class="main-footer">
                    <div class="popup-footer" >
                        <div><button class="button customSave" type="submit">Export</button></div>
                        <div><button class="button customtrash" type="button" (click)="closePopup()">Chiudi</button></div>
                    </div>
                </div>
                
            </form>
        </div>
    </div>
</ng-template>