<!-- <app-loader *ngIf="loader"></app-loader> -->

<!-- <app-conform [message]="message" (confirm)="confirm($event)"></app-conform> -->
<div class="header d-flex mt-2">
	<h1 class="semi-bold">FORNITORI</h1>
	<div class="export">
		<button class="button1 mr-1" (click)="openCqFormPopup(); openPopup()">CREA</button>
	</div>
</div>

<div>
	<ag-grid-angular
		style="height: 700px"
		class="ag-theme-alpine-dark basic-table"
		[rowData]="rowdata$"
		[gridOptions]="GridOptions"
		(gridReady)="onGridReady($event)"
		(cellClicked)="onCellClicked($event)"
	>
	</ag-grid-angular>
</div>

<ng-template #PopupContainer let-options>
	<div class="popup-window animate-in popup-form p-1">
		<form [formGroup]="Form" (ngSubmit)="onSubmitCqForm()">
			<div class="d-flex gap-16">
				<div class="field w-100">
					<label class="d-block">ID Fornitore:</label>
					<input type="text" class="w-100" formControlName="id_for" required readonly />
					<div *ngIf="(cqFormSubmitted || fc['id_for'].touched) && fc['id_for'].errors" class="alert">
						<div class="alert" *ngIf="cqFormSubmitted || fc['id_for'].touched">Please enter id_for</div>
					</div>
				</div>
				<div class="field w-100">
					<label class="d-block">QUALIFICA:</label>
					<input type="text" class="w-100" formControlName="stato" />
					<div *ngIf="(cqFormSubmitted || fc['stato'].touched) && fc['stato'].errors" class="alert">
						<div class="alert" *ngIf="cqFormSubmitted || fc['stato'].touched">Please enter stato</div>
					</div>
				</div>
			</div>
			<div class="d-flex gap-16">
				<div class="field w-100">
					<label class="d-block">TIPO:</label>
					<input type="text" class="w-100" formControlName="for_unico" />
					<div *ngIf="(cqFormSubmitted || fc['for_unico'].touched) && fc['for_unico'].errors" class="alert">
						<div class="alert" *ngIf="cqFormSubmitted || fc['for_unico'].touched">Please enter TIPO</div>
					</div>
				</div>
				<div class="field w-100">
					<label class="d-block">Ragione Sociale:</label>
					<input type="text" class="w-100" formControlName="rag_soc" />
					<div *ngIf="(cqFormSubmitted || fc['rag_soc'].touched) && fc['rag_soc'].errors" class="alert">
						<div class="alert" *ngIf="cqFormSubmitted || fc['rag_soc'].touched">Please enter Ragione Sociale</div>
					</div>
				</div>
			</div>
			<div class="d-flex gap-16">
				<div class="field w-100 field">
					<label class="d-block">Via:</label>
					<input type="text" class="w-100" formControlName="via" />
				</div>
				<div class="field w-100">
					<label class="d-block">Comune:</label>
					<input type="text" class="w-100" formControlName="comune" />
				</div>
			</div>
			<div class="d-flex gap-16">
				<div class="field w-100">
					<label class="d-block">CAP:</label>
					<input type="text" class="w-100" formControlName="cap" />
				</div>
				<div class="field w-100">
					<label class="d-block">Nazione:</label>
					<input type="text" class="w-100" formControlName="nazione" />
				</div>
			</div>
			<div class="d-flex gap-16">
				<div class="field w-100">
					<label class="d-block">FAX:</label>
					<input type="tel" class="w-100" formControlName="fax" />
				</div>
				<div class="field w-100">
					<label class="d-block">Email:</label>
					<input type="email" class="w-100" formControlName="email" />
				</div>
			</div>
			<div class="d-flex gap-16">
				<div class="field w-100">
					<label class="d-block">Riferimento:</label>
					<input type="text" class="w-100" formControlName="ref" />
				</div>
				<div class="field w-100">
					<label class="d-block">Note:</label>
					<input type="tel" class="w-100" formControlName="note" />
				</div>
			</div>
			<div class="main-footer">
				<div class="delete">
					<!-- <i class="fa fa-trash customtrash" aria-hidden="true" (click)="openmodel()"></i> -->
				</div>
				<div class="popup-footer">
					<button class="button1 mr-1" type="submit">{{ changeButtun ? "Aggiorna" : "Salva" }}</button>
					<button class="button1" (click)="closePopup()" type="button">ANNULLA</button>
				</div>
			</div>
		</form>
	</div>
</ng-template>
<div *ngIf="showPopup" class="overlay" (click)="closeSituazioniPopup()"></div>
<div class="popup-window animate-in popup-form p-1" *ngIf="showPopup">
	<form [formGroup]="FormSituazioni">
		<div class="d-flex gap-16">
			<div class="field w-100">
				<label class="d-block">RICHIESTE</label>
				<input type="text" class="w-100" formControlName="RICHIESTE" readonly />
			</div>
			<div class="field w-100">
				<label class="d-block">PREVENTIVI:</label>
				<input type="text" class="w-100" formControlName="PREVENTIVI" readonly />
			</div>
		</div>
		<div class="d-flex gap-16">
			<div class="field w-100">
				<label class="d-block">ORDINI:</label>
				<input type="text" class="w-100" formControlName="ORDINI" readonly />
			</div>
			<div class="field w-100">
				<label class="d-block">RICEVIMENTI:</label>
				<input type="text" class="w-100" formControlName="RICEVIMENTI" readonly />
			</div>
		</div>
		<div class="d-flex gap-16">
			<div class="field w-100">
				<label class="d-block">RIMANENZE:</label>
				<input type="text" class="w-100" formControlName="RIMANENZE" readonly />
			</div>
			<div class="field w-100">
				<label class="d-block">FINE:</label>
				<input type="text" class="w-100" formControlName="FINE" readonly />
			</div>
		</div>
		<div class="d-flex gap-16">
			<div class="field w-100">
				<label class="d-block">PUNTI:</label>
				<input type="text" class="w-100" formControlName="PUNTI" readonly />
			</div>
			<div class="field w-100">
				<label class="d-block">NCP:</label>
				<input type="text" class="w-100" formControlName="NCP" readonly />
			</div>
		</div>
		<label class="d-block" *ngIf="movimenti.length>0">MOVIMENTI</label>
		<div *ngFor="let movimento of movimenti" class="d-flex gap-16">
			
			<div class="field w-100">
				<label class="d-block">DATA:</label>
				<input type="text" class="w-100" [value]="movimento.data_spost" readonly />
			</div>
			<div class="field w-100">
				<label class="d-block">MOVIMENTO:</label>
				<input type="text" class="w-100" [value]="movimento.tipo_spost" readonly />
			</div>
		</div>
		<div class="main-footer">
			<div class="delete"></div>
			<div class="popup-footer">
				<button class="button1" (click)="closeSituazioniPopup()" type="button">ANNULLA</button>
			</div>
		</div>
	</form>
</div>
