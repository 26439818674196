<!-- <app-loader *ngIf="loader"></app-loader> -->

<!-- <app-conform [message]="message" (confirm)="confirm($event)"></app-conform> -->
<div class="header d-flex mt-2">
    <h1 class="semi-bold">CENTRI</h1>
    <div class="export">
        <button class="button1 mr-1" (click)="openCqFormPopup(); openPopup()">CREA</button>
    </div>
</div>

<div>
    <ag-grid-angular style="height: 700px;" class="ag-theme-alpine-dark basic-table" [rowData]="rowdata$"
        [gridOptions]="GridOptions" (gridReady)="onGridReady($event)" (cellClicked)="onCellClicked($event)">
    </ag-grid-angular>
</div>

<div>
    <ag-grid-angular style="height: 700px;" class="ag-theme-alpine-dark basic-table" [rowData]="rowdata1$"
        [gridOptions]="GridOptions" (gridReady)="onGridReady($event)" (cellClicked)="onCellClicked($event)">
    </ag-grid-angular>
</div>


<ng-template #PopupContainer let-options>
    <div class="popup-window animate-in popup-form p-1">
        <form [formGroup]="Form" (ngSubmit)="onSubmitCqForm()">
            <div class="d-flex gap-16">
                <div class="field w-100">
                    <label class="d-block">Codifica:</label>
                    <input type="text" class="w-100" formControlName="codifica" required />
                    <div *ngIf="(cqFormSubmitted || fc['codifica'].touched) && fc['codifica'].errors" class="alert">
                        <div *ngIf="(cqFormSubmitted || fc['codifica'].touched)"> Please enter codifica</div>
                    </div>
                </div>
                <div class="field w-100">
                    <label class="d-block">Codice az:</label>
                    <input type="number" class="w-100" formControlName="codice_az" readonly />
                    <div *ngIf="(cqFormSubmitted || fc['codice_az'].touched) && fc['codice_az'].errors" class="alert">
                        <div *ngIf="(cqFormSubmitted || fc['codice_az'].touched)"> Please enter codice_az</div>
                    </div>
                </div>
            </div>
            <div class="d-flex gap-16">
                <div class="field w-100">
                    <label class="d-block">Cod reg:</label>
                    <input type="number" class="w-100" formControlName="cod_reg" />
                    <div *ngIf="(cqFormSubmitted || fc['cod_reg'].touched) && fc['cod_reg'].errors" class="alert">
                        <div *ngIf="(cqFormSubmitted || fc['cod_reg'].touched)"> Please enter cod_reg</div>
                    </div>
                </div>
                <div class="field w-100">
                    <label class="d-block">Appoggio:</label>
                    <input type="text" class="w-100" formControlName="appoggio" />
                    <div *ngIf="(cqFormSubmitted || fc['appoggio'].touched) && fc['appoggio'].errors" class="alert">
                        <div *ngIf="(cqFormSubmitted || fc['appoggio'].touched)"> Please enter appoggio</div>
                    </div>
                </div>
            </div>
            <div class="d-flex gap-16">
                <div class="field w-100 field">
                    <label class="d-block">*Attivo:</label>
                	<select class="w-100" formControlName="attivo">
						<option value="S">S</option>
						<option value="N">N</option>
					</select>
                </div>
                <div class="field w-100">
                    <label class="d-block">Denominaz:</label>
                    <input type="text" class="w-100" formControlName="denominaz" />
                </div>
            </div>
            <div class="d-flex gap-16">
                <div class="field w-100">
                    <label class="d-block">*Indirizzo:</label>
                    <input type="text" class="w-100" formControlName="indirizzo" />
                </div>
                <div class="field w-100">
                    <label class="d-block">Comune:</label>
                    <input type="text" class="w-100" formControlName="comune" />
                </div>
            </div>
            <div class="d-flex gap-16">
                <div class="field w-100">
                    <label class="d-block">Tel:</label>
                    <input type="tel" class="w-100" formControlName="tel" />
                </div>
                <div class="field w-100">
                    <label class="d-block">Fax:</label>
                    <input type="tel" class="w-100" formControlName="fax" />
                </div>
            </div>
            <div class="d-flex gap-16">
                <div class="field w-100">
                    <label class="d-block">Ora camp:</label>
                    <input type="text" class="w-100" formControlName="ora_camp" />
                </div>
                <div class="field w-100">
                    <label class="d-block">Stampa:</label>
                    <input type="tel" class="w-100" formControlName="stampa" />
                </div>
            </div>
            <div class="d-flex gap-16">
                <div class="field w-100">
                    <label class="d-block">Form data:</label>
                    <input type="text" class="w-100" formControlName="form_data" />
                </div>
                <div class="field w-100">
                    <label class="d-block">Note:</label>
                    <input type="tel" class="w-100" formControlName="note" />
                </div>
            </div>


            <div class="main-footer">
                <div class="delete">
                    <!-- <i class="fa fa-trash customtrash" aria-hidden="true" (click)="openmodel()"></i> -->
                </div>
                <div class="popup-footer">
                    <button class="button1 mr-1" type="submit">{{changeButtun?'Aggiorna':'Salva'}}</button>
                    <button class="button1" (click)="closePopup()" type="button">ANNULLA</button>
                </div>
            </div>
        </form>
    </div>
</ng-template>
