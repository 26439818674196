<!-- <div class="loader">
    <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
    </div>
</div> -->
<div class="custom-overlay">
    <div class="spinner"></div>
 </div>


