<div class="main-overview">
	<div class="main">
	  <div class="specie">
		<div class="export">
		  <button class="button1" (click)="generateFrequency()">FREQUENZA NON CONFORMITÀ</button>
		  <button class="button1" (click)="genrateReferto()">TEMPI DI EMISSIONE DEL REFERTO</button>
		  <button class="button1" (click)="getDescrittiva()">STATISTICA DESCRITTIVA</button>
		  <button class="button1" (click)="getmipaafe()">MiPAAF</button>
		  <button class="button1" (click)="getvcaDelCentro()">VCA del CENTRO</button>
		  <button class="button1" (click)="openFieldSelectionModal()">CARTA di CONTROLLO STANDARDIZZATA</button>
		  <button class="button1" (click)="genrateLiberto()">LIBRETTO</button>
		  <button class="button1" (click)="getDosiEterospermiche()" *ngIf="userType === 'DNE'">DOSI ETEROSPERMICHE</button>
		  <button class="button1" (click)="getsospesi()" *ngIf="userType === 'DNE'">SOSPESI</button>
		  <button class="button1" (click)="getIE()" *ngIf="userType === 'DNE'">IE</button>
		  <button class="button1" (click)="getandamenti()" >ANDAMENTI Qualità Seme</button>
		  <!--  -->
		  
		</div>
	  </div>
	</div>
  </div>
  
  <!-- Modal for field selection -->
  <div *ngIf="isModalOpen" class="modal-overlay">
	<div class="modal-content">
	  <!-- <h3>Select Field for Calculations</h3> -->
	  <div class="field-options">
		<label *ngFor="let field of objectKeys(fields)">
			<input type="radio" name="field" [value]="fields[field]" [(ngModel)]="selectedField" />
			{{ field }}
		  </label>
	  </div>
	  <div class="modal-actions">
		<button (click)="closeModal()">ANNULLA</button>
		<button (click)="confirmSelection()" [disabled]="!selectedField">CONFERMA</button>
	  </div>
	</div>
  </div>
  