import { AfterViewInit, Component, TemplateRef, ViewChild, ViewContainerRef } from "@angular/core";
import { Razza, Toro } from "../_models";
import { CellClickedEvent, ColDef, GridOptions } from "ag-grid-community";
import { takeWhile } from "rxjs";
import { ApiService } from "../_services/api.service";
import { ToastrService } from 'ngx-toastr';
import { ModalService } from "../_services/modal.service";
import { CustomButtonRendererComponent } from "../table-test/custom-button-renderer.component";
import { ActivatedRoute, Router } from "@angular/router";
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
@Component({
  selector: 'app-registrosemedna',
  templateUrl: './registrosemedna.component.html',
  styleUrls: ['./registrosemedna.component.scss']
})
export class RegistrosemednaComponent {
  private gridApi;
  private gridColumnApi;
  rowData: any[];
  columnDefs: any[];
  selectedRows: any[] = [];
  rowSelection = 'multiple';
  selCurrBelowRows = [];
  selectedStatus: number;
  statusOptions = [
    { id: 1, label: 'CHIUSO' },
    { id: 2, label: 'SOSPESO' },
    { id: 3, label: 'APERTO' },
    { id: 4, label: 'NULLO' },
    { id: 5, label: 'ASSENTE' },
    { id: 6, label: 'RIFERIMENTO' },
    { id: 7, label: 'DUBBIO' },
    { id: 8, label: 'APERTO+' },
    { id: 9, label: 'CHIUSO+' },
    { id: 10, label: 'APERTO2' },
    { id: 11, label: 'CHIUSO2' },
  ];
  pressedButtonName: string = '';
  btnPopup: boolean = false;
  manual:boolean = false;
  form: FormGroup;
  formupdate: FormGroup;
  razze: Razza[] = [];
  razzeData: Razza[] = [];
  riferBtn: boolean = false;
  piastrabutton: boolean = false;
  @ViewChild("popupContainer", { read: TemplateRef })
  popupContainer: TemplateRef<any>;
  @ViewChild("popupContainer1", { read: TemplateRef })
  popupContainer1: TemplateRef<any>;

  formIndietro: FormGroup;
  piastraFrom: FormGroup;
  spaciesData: any[] = [
    { value: null, name: "Scegli Specie" },
    { value: "B", name: "B - Bovini" },
    { value: "BI", name: "BI - Bovini Importazione" },
    { value: "E", name: "E - Equini" },
    { value: "EI", name: "EI - Equini Importazione" },
    { value: "S", name: "S - Suini" },
    { value: "C", name: "C - Caprini" },
    { value: "CI", name: "CI - Caprini Importazione" },
    { value: "BU", name: "BU - Bufali" },
    { value: "O", name: "O - Ovini" },
  ];
  items = [
    { id: 1, value: 'op_ricev', name: 'Ric OP' },
    { id: 2, value: 'data_ricev', name: 'Ric Data' },
    { id: 3, value: 'estrazione', name: 'Estr OP' },
    { id: 4, value: 'data_estr', name: 'Estr Data' },
    { id: 5, value: 'pcr', name: 'PCR OP' },
    { id: 6, value: 'data_pcr', name: 'PCR Data' },
    { id: 7, value: 'mix_car', name: 'Run OP' },
    { id: 8, value: 'num_gel', name: 'Run Num' },
    { id: 9, value: 'data_gel', name: 'Run Data' },
    { id: 10, value: 'sequenziat', name: 'Sequenziat' },
    { id: 11, value: 'op_mac', name: 'Seq OP' },
    { id: 12, value: 'op_dos', name: 'ML30 OP' },
    { id: 13, value: 'faxvi_data', name: 'Data Faxvi' },
    { id: 14, value: 'id_pcr', name: 'ID PCR' },
    { id: 15, value: 'mix_car', name: 'Op car' },
    { id: 16, value: 'risultati', name: 'Op seq' },
    { id: 17, value: 'faxvi_oper', name: 'Op VCI' },
  ];
  rowData$: Toro[];
  colDefs: ColDef<Toro>[] = [
    { field: "codice_cam", headerName: "Codice Camp", width: 200 },
    {
      field: "stato", headerName: "Stato",
      width: 150,
      // cellRenderer: (params: any) => { 
      //   if(params.data.ripetizion !== null && params.data.ripetizion !==""){
      //     return this.getStato(params.data.stato) + (parseInt(params.data.ripetizion) + 1);
      //   }else{ 
      //     return this.getStato(params.data.stato) 
      //   }
      // },

    },
    { field: "cod_seme", headerName: "ID Seme", width: 150 },
    { field: "ripetizion", headerName: "Ripetizione", width: 150 },
    { field: "data_ricev", headerName: "Data Ricevimento", width: 250, cellRenderer: (params: any) => { return `${params?.data?.data_ricev == null ? 'N/A' : params?.data?.data_ricev}` } },
    { field: "op_ricev", headerName: "Operatore Ricevimento", width: 250 },
    // { field: "autocertif", headerName: "autocertif", width:150, cellRenderer: (params: any) => { return `${params?.data?.autocertif == null||params?.data?.autocertif == null?'N/A':params?.data?.autocertif}`} },
    // { field: "cod_dna", headerName: "cod_dna" , width:150 },
    { field: "data_estr", headerName: "Data Estrazione", width: 200 },
    { field: "estrazione", headerName: "Operatore Estrazione ", width: 250 },

    // { field: "diluizione", headerName: "diluizione", width: 150 },
    // { field: "data_dil", headerName: "data_dil", width: 150 },
    { field: "id_pcr", headerName: "ID PCR", width: 150 },
    { field: "data_pcr", headerName: "Data PCR", width: 150 },
    { field: "pcr", headerName: "Operatore PCR", width: 150 },
    { field: "mix_car", headerName: "Operatore Caricamento", width: 150 },
    { field: "num_gel", headerName: "N° Run", width: 150 },
    { field: "data_gel", headerName: "Data Run", width: 150 },
    { field: "sequenziat", headerName: "Tipo Sequenziatore", width: 150 },
    { field: "risultati", headerName: "Operatore Sequenziatore", width: 150 },
    // { field: "data_ris", headerName: "data_ris", width: 150 },
    // { field: "mestruo", headerName: "mestruo" , width:150},
    { field: "op_dos", headerName: "OP L30", width: 150 },
    // { field: "op_mac", headerName: "OP_SEQ", width: 150 },
    // { field: "conc_um", headerName: "conc_um" , width:150},
    // { field: "analisi_ok", headerName: "analisi_ok", width: 150 },
    { field: "faxvi_data", headerName: "Data VCI ", width: 150 },
    { field: "faxvi_oper", headerName: "Operatore VCI", width: 150 },
    { field: "faxvi2data", headerName: "Data VCI2", width: 150 },
    { field: "faxvi2oper", headerName: "Operatore VCI2", width: 150 },
    { field: "regvi_data", headerName: "Data invio Reg", width: 200 },
    { field: "regvi_oper", headerName: "Operatore invio Reg", width: 250 },

    { field: "id_piastra", headerName: "ID piastra", width: 150 },
    // { field: "tank_rif", headerName: "tank_rif", width: 150, cellRenderer: (params: any) => { return `${params?.data?.tank_rif == null ? 'N/A' : params?.data?.tank_rif}` } },
    { field: "non_conf", headerName: "N.C.", width: 150 },
  ];
  defaultColDef: ColDef = { resizable: true, sortable: true, filter: true };
  gridOptions: GridOptions<Toro> = {
    columnDefs: this.colDefs,
    defaultColDef: this.defaultColDef,
    rowSelection: "single",
    animateRows: true,
    pagination: true,
    paginationAutoPageSize: true,
    frameworkComponents: {
      customButtonRenderer: CustomButtonRendererComponent,
    },
    onCellClicked: (event: CellClickedEvent) => {
      this.clickedRowData = event.data
      // this.openPopup()
      this.specieValue = this.clickedRowData.specie;
    },
    onRowDoubleClicked: this.onRowDoubleClicked.bind(this),

    navigateToNextCell: (params) => this.navigateToNextCell(params),
  };
  clickedRowData: any;
  loader: boolean = false;
  data: any = 'B';
  centriCodiceAz: string | number;
  specie: string;
  anno: string | number;
  isAlive = true;
  subjectValue: boolean = false;
  specieValue: any;
  deleteId: any;
  codice_cam: any;
  codice_az: any;
  view: any;
  fagfilename: string;
  currentDate: string;
  codeseme: any;
  unusedColumn: any;
  doubleClick: boolean = false;
  rowdataclicked: any;
  cod_toro: any;
  piastraData:any[]=[]
  postiValue: any;
  constructor(
    public apiService: ApiService,
    public toastr: ToastrService,
    private modalService: ModalService,
    private activate: ActivatedRoute,
    private viewContainerRef: ViewContainerRef,
    private fb: FormBuilder,
    private http: HttpClient,
  ) {
    // this.getSubscribed();
    this.formIndietro = this.fb.group({
      toro: [null, Validators.required],
      matricola: [null, Validators.required],
      matricola2: [null],
      specie: [null, Validators.required],
      razza: [null],
      cod_dna: [null],
      provato: [null],
      top100: [null],
      data_nasc: [null],
      matric_ana: [null],
      // magzinno form
      cilindro: [null],
      contenit: [null],
      data: [null],
      diluizione: [null],
      dna_seme: [null],
      estrazione: [null],
      note: [null],
      piano: [null],
      riceviment: [null],
      settore: [null],
      tipo_seq: [null],
    });

    this.piastraFrom = this.fb.group({
      ID_PIASTRA: [null],
      tipo_seq: [null],
      DATA_RIF: [null],
      CELLEPIENE: [null],
      buttonType: ['xlsx'],
      id_seme: this.fb.array([this.createItem()])
      // posti_disponibili:[null]

    })
    
    this.formupdate = this.fb.group({
      codice_cam: [null, Validators.required],
      cod_seme: [null],
      stato: [null],
      ripetizion: [null],
      data_ricev: [null],
      estrazione: [null],
      data_estr: [null],
      id_pcr: [null],
      pcr: [null],
      data_pcr: [null],
      num_gel: [null],
      data_gel: [null],
      op_mac: [null],
      mix_car: [null],
      risultati: [null],
      sequenziat: [null],
      regvi_oper: [null],
      op_dos: [null],
      analisi_ok: [null],
      faxvi_data: [null],
      faxvi_oper: [null],
      regvi_data: [null],
      op_ricev: [null],
      id_piastra: [null],
      tank_rif: [null],
      non_conf: [null],
      faxvi2data: [null],
      faxvi2oper: [null]

    })
  }

  ngOnInit() {
    this.retrieveRazze()
    this.initializeCheckboxes();
    this.activate.queryParams.subscribe((res: any) => {
      this.codice_cam = res.codice_cam;
      this.codice_az = res.codice_az
      // this.centriCodiceAzName=res.centriCodiceAzName
    })
    this.tabletest();
    const currentDate = new Date(); // Assuming this is your date
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');

    this.currentDate = `${year}-${month}-${day}`;
  }

  get id_seme(): FormArray {
    return this.piastraFrom.get('id_seme') as FormArray<any>;
  }

  createItem(): FormGroup {
    return this.fb.group({
      value: ['']
    });
  }
  addItem(): void {
    this.id_seme.push(this.createItem());
  }

  removeItem(index: number): void {
    this.id_seme.removeAt(index);
  }
  onSubmitIndietro() {
    this.loader = true;
    if (this.formIndietro.invalid) {
      return
    } else {
      let dataNasc = this.formatDate(this.formIndietro.value.data_nasc)
      const formValues = { ...this.formIndietro.value, data_nasc: dataNasc, cod_toro: this.cod_toro };
      const objToBeSubmitted = {
        selectedRow: this.clickedRowData,
        submittedForm: formValues
      }
      this.apiService.addNewOperData(objToBeSubmitted, this.pressedButtonName).subscribe((res: any) => {
        if (res.success) {
          this.tabletest();
          this.toastr.success(res?.description);
        } else {
          this.toastr.error(res.description);
        }
        this.formIndietro.reset();
        this.closePopup('Rifer');
        this.loader = false;
      })
    }
  }

  formatDate(inputDate): any {
    if (inputDate != null) {
      const [year, month, day] = inputDate.split('-');
      const formattedDate = `${day}/${month}/${year}`;
      return formattedDate;
    }
  }

  selectSpecie(event) {
    const specie = event?.target?.value;
    this.razzeData = this.razze.filter((sp: any) => sp?.specie === specie);
  }

  navigateToNextCell = (params) => {
    const suggestedNextCell = params.nextCellPosition;
    const KEY_UP = 'ArrowUp';
    const KEY_DOWN = 'ArrowDown';

    const noUpOrDownKey = params.key !== KEY_DOWN && params.key !== KEY_UP;
    if (noUpOrDownKey || !suggestedNextCell) {
      return suggestedNextCell;
    }
    const nodeToSelect = params.api.getDisplayedRowAtIndex(suggestedNextCell.rowIndex);
    if (nodeToSelect) {
      nodeToSelect.setSelected(true);
      const selectedRows = params.api.getSelectedRows();
      this.clickedRowData = selectedRows[0];
    }
    return suggestedNextCell;
  }
  private initializeCheckboxes(): void {
    this.form = this.fb.group({
      checkboxItems: this.fb.array([]),
    });
    const checkboxItemsFormArray = this.form.get('checkboxItems') as FormArray;

    this.items.forEach((item) => {
      const control = this.fb.control(false);
      checkboxItemsFormArray.push(control);
    });
  }

  onSubmit(): void {
    const selectedCols = this.form.value.checkboxItems
      .map((checked, index) => (checked ? { name: this.items[index].name, value: this.items[index].value } : null))
      .filter((item) => item !== null);
    let reqPayload: any = {};

    if (this.pressedButtonName === 'fillDown') {
      this.selCurrBelowRows
      const modifiedArray = this.selCurrBelowRows.map(({ created_at, updated_at, ...rest }) => rest);
      reqPayload = {
        selectedRows: modifiedArray,
        selectedCols: selectedCols
      }
      this.apiService.fillDownPost(reqPayload).subscribe((res: any) => {
        this.toastr.success(res?.description)
        this.closePopup('fillDown');
        this.tabletest();
      })
    } else {
      const { created_at, updated_at, ...updatedObject } = this.clickedRowData;
      reqPayload = {
        selectedRow: updatedObject,
        selectedCols: selectedCols
      }
      this.apiService.addNewOperData(reqPayload, this.pressedButtonName).subscribe((res: any) => {
        this.toastr.success(res?.description)
        this.closePopup('Replica');
        this.tabletest();
      })
    }
  }

  tabletest() {
    this.loader = true;
    // if (!this.subjectValue) {
    //   this.centriCodiceAz = localStorage.getItem('centriCodiceAz') ? localStorage.getItem('centriCodiceAz') : 1
    //   this.specie = localStorage.getItem('specie') ? localStorage.getItem('specie') : "BI"
    //   this.anno = localStorage.getItem('anna') ? localStorage.getItem('anna') : 2022
    // }
    // this.subjectValue = false
    this.apiService.getRegSem(this.codice_cam).subscribe((res: any) => {
      this.loader = false;
      // if (res?.result?.rows) {
      //   // res?.result?.rows.forEach(e => {
      //   //   if (e.ripetizion !== null) {
      //   //     e.stato = this.getStato(e.stato) + (parseInt(e.ripetizion) + 1);
      //   //   }
      //   // });
      // }
      this.rowData$ = res?.result;
      this.clickedRowData = null;
    });
  }

  // getSubscribed() {
  //   this.apiService.current.pipe(takeWhile(() => this.isAlive)).subscribe((res) => {
  //     if (res) {
  //       this.subjectValue = true
  //       this.centriCodiceAz = res.codice_az ? res.codice_az : localStorage.getItem('centriCodiceAz')
  //       this.specie = res.specie ? res.specie : "BI";
  //       this.anno = res.anna ? res.anna : 2022
  //       this.tabletest();
  //     }
  //   });

  //   this.apiService.delete.subscribe((res) => {
  //     if ((res?.data?.id) && (res?.table === 'statistic')) {
  //       this.deleteId = res?.data
  //       this.modalService.openModal('statistic');
  //     }
  //   });
  // }

  ngOnDestroy() {
    this.isAlive = false;
  }
  openPopup(buttonName: string) {
    if (typeof this.clickedRowData === 'undefined' || this.clickedRowData === null && buttonName != "Piastra") {
      this.toastr.info('Selezionare la riga dalla tabella su cui si vuole effettuare l’operazione');
      return;
    }

    if (buttonName === 'Replica' || buttonName === 'RPlus' || buttonName === 'fillDown') {
      if (buttonName === 'fillDown') {

      }
      this.piastrabutton = false
      this.btnPopup = true;
      this.popUpa()

    } else if (buttonName === "Piastra") {
      this.manual = false
      this.btnPopup = false
      
      this.piastrabutton = true
      this.piastraFrom.get('tipo_seq').setValue('2');
      let codice_az = localStorage.getItem('centriCodiceAz')

      this.apiService.getIdPiastra(this.codice_cam, codice_az,buttonName).subscribe((res:any)=>{
        if(res.status){
          console.log(res)
          this.piastraData = res?.data
          this.piastraFrom.get('ID_PIASTRA').setValue(this.piastraData[0].ID_PIASTRA);
          let id = this.piastraData[0].ID_PIASTRA;
          this.apiService.getPiastra(this.codice_cam, this.codice_az,id,buttonName).subscribe((res) => {
            if (res?.status) {
              // this.popUpa()
              // this.piastraFrom.get('ID_PIASTRA').setValue(this.piastraData[0]);
              this.piastraFrom.get('CELLEPIENE').setValue(res.result.CELLEPIENE);
              // this.piastraFrom.get('posti_disponibili').setValue(96-Number(res.result.CELLEPIENE));
              
              this.codeseme = res.COD_SEME
              this.unusedColumn = res.unusedColumn
            } else {
              this.toastr.error(res?.description)
            }
          })
          // this.piastraFrom.get('posti_disponibili').setValue(96-Number(this.piastraData[0].CELLEPIENE));
          this.popUpa()
        }else{
          this.toastr.error(res?.description)
        }

      })
      // this.apiService.getPiastra(this.codice_cam, codice_az).subscribe((res) => {
      //   if (res?.status) {
      //     this.popUpa()
      //     this.piastraFrom.get('ID_PIASTRA').setValue(this.piastraData[0]);
      //     this.piastraFrom.get('CELLEPIENE').setValue(res.result.CELLEPIENE);
      //     this.codeseme = res.COD_SEME
      //     this.unusedColumn = res.unusedColumn
      //   } else {
      //     this.toastr.error(res?.description)
      //   }
      // })

    } else if (buttonName == 'Rifer') {

      this.loader = true;
      this.apiService.getDnaData(this.clickedRowData?.cod_seme).subscribe(res => {
        this.cod_toro = res?.toriData?.cod_toro;
        let cod_dna = res?.maxCodDna
        const specie = res?.result?.specie
        this.razzeData = this.razze.filter((sp: any) => sp?.specie === specie);
        if (res?.success) {
          this.riferBtn = true;
          this.popUpa()
          this.formIndietro.patchValue({
            toro: res?.toriData?.toro,
            matricola: res?.toriData?.matricola,
            matricola2: res?.toriData?.matricola2,
            specie: res?.toriData?.specie,
            razza: res?.toriData?.specie,
            provato: res?.toriData?.provato,
            top100: res?.toriData?.top100,
            data_nasc: res?.toriData?.data_nasc,
            matric_ana: res?.toriData?.matric_ana,
            cod_dna: parseInt(cod_dna),
            cilindro: parseInt(res?.toriData?.locationData?.cilindro),
            contenit: res?.toriData?.locationData?.contenit,
            data: res?.toriData?.data,
            // diluizione: parseFloat(res?.toriData?.locationData?.diluizione),
            dna_seme: res?.result?.dna_seme,
            // estrazione: parseFloat(res?.toriData?.estrazione),
            note: res?.toriData?.note,
            piano: parseInt(res?.toriData?.locationData?.piano),
            // riceviment: parseInt(res?.toriData?.locationData.riceviment),
            settore: res?.toriData?.locationData?.settore,
            tipo_seq: res?.toriData?.l30Data?.tipo_seq,
          });
        } else {
          this.toastr.error(res?.description)
          this.loader = false;
        }
        this.loader = false;

      });
    } else if (buttonName == 'R2') {
      const objToBeSubmitted = {
        selectedRow: this.clickedRowData,
      }
      this.apiService.addNewOperData(objToBeSubmitted, buttonName).subscribe((res: any) => {
        if (res.success) {
          this.tabletest();
          this.toastr.success(res?.description);
        } else {
          this.toastr.error(res.description);
        }
        this.formIndietro.reset();
        this.closePopup('R2');
        this.loader = false;
      })
      return
    }


    this.pressedButtonName = buttonName;

  }
  openPopupPiastra(button:string){
    this.codeseme = []
    this.popUpa()
    this.btnPopup = false  
    this.piastrabutton = true
    this.manual = true

    this.piastraFrom.get('tipo_seq').setValue('2');
    let codice_az = localStorage.getItem('centriCodiceAz')

    this.apiService.getIdPiastra(this.codice_cam, codice_az,button).subscribe((res:any)=>{
      if(res.status){
        console.log(res)
        this.piastraData = res?.data
        this.piastraFrom.get('ID_PIASTRA').setValue(this.piastraData[0].ID_PIASTRA);
        let id = this.piastraData[0].ID_PIASTRA;
        this.apiService.getPiastra(this.codice_cam, this.codice_az,id,'').subscribe((res) => {
          if (res?.status) {
            // this.popUpa()
            // this.piastraFrom.get('ID_PIASTRA').setValue(this.piastraData[0]);
            this.piastraFrom.get('CELLEPIENE').setValue(res.result.CELLEPIENE);
            // this.piastraFrom.get('posti_disponibili').setValue(96-Number(res.result.CELLEPIENE));
            this.codeseme = []
            this.unusedColumn = res.unusedColumn
          } else {
            this.toastr.error(res?.description)
          }
        })
        // this.piastraFrom.get('posti_disponibili').setValue(96-Number(this.piastraData[0].CELLEPIENE));
        this.popUpa()
      }else{
        this.toastr.error(res?.description)
      }

    })
  }
  

  popUpa() {
    this.apiService.modaBackDrop.next(true);
    this.view = this.viewContainerRef.createEmbeddedView(this.popupContainer);
    const domElem = this.view.rootNodes[0] as HTMLElement;
    domElem.classList.add("animate-in");
  }

  closePopup(buttonName: string) {
    if (buttonName === 'Replica' || buttonName === 'RPlus' || buttonName === 'R2') {
      this.btnPopup = false;
    } else if (buttonName === "Piastra") {
      this.piastrabutton = false
      // this.piastraFrom.value
    } else {
      this.riferBtn = false;
    }
    this.initializeCheckboxes();
    this.apiService.modaBackDrop.next(false);
    this.viewContainerRef.clear();
    this.manual = false;
  }
  getStato(data: any): any {
    const statoMap: { [key: number]: string } = {
      1: 'CHIUSO',
      2: 'SOSPESO',
      3: 'APERTO',
      4: 'NULLO',
      5: 'ASSENTE',
      6: 'RIFERIMENTO',
      7: 'DUBBIO',
      8: 'APERTO+',
      9: 'CHIUSO+'
    };

    return statoMap[data] || 'UNKNOWN';
  }

  downlaodEstratto() {
    let codice_az = localStorage.getItem('centriCodiceAz')
    this.apiService.getEstrato(this.codice_cam, codice_az).subscribe((res) => {
      if (res.success) {
        let url = environment.url + res.downloadLink;
        this.fagfilename = res.filenames
        this.downloadTextFile(url)
      }
    })
  }

  downloadTextFile(url: string): void {
    this.http.get(url, { responseType: 'text' }).subscribe((data: string) => {
      const blob = new Blob([data], { type: 'text/plain' });
      const anchor = document.createElement('a');
      anchor.href = window.URL.createObjectURL(blob);
      anchor.download = this.fagfilename;
      document.body.appendChild(anchor);
      anchor.click();
      window.URL.revokeObjectURL(anchor.href);
      document.body.removeChild(anchor);
    });
  }


  downloadExcelFile(url: string): void {
    this.http.get(url, { responseType: 'arraybuffer' }).subscribe((data: ArrayBuffer) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const workbook = XLSX.read(data, { type: 'array' });
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

      // Create a Blob and create a download link
      const excelBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(excelBlob, this.fagfilename);
    });
  }
  onSubmitPiastra() {
    let codice_az = localStorage.getItem('centriCodiceAz')
    let DATA_RIF = this.formatDate(this.piastraFrom.value.DATA_RIF)
    let idPiasta = parseInt(this.piastraFrom.value.ID_PIASTRA)
    const formValues = this.id_seme.controls
    .map(control => control.get('value')?.value)
    .filter(value => value?.trim() !== '');

  if (this.manual && formValues.length === 0) {
    this.toastr.error('Please fill the value of ID Seme');
    return;
  }
    let payload = { ...this.piastraFrom.value,ID_PIASTRA:idPiasta, COD_SEME: this.codeseme, unusedColumn: this.unusedColumn, DATA_RIF: DATA_RIF,id_seme:formValues }
    this.apiService.savePiastra(this.codice_cam, codice_az, payload).subscribe((res) => {
      if (res?.success) {
        let url = environment.url + res.downloadLink;
        this.fagfilename = res.filenames
        if (res.buttonType == "text") {
          this.downloadTextFile(url)
          this.tabletest()
          this.resetFormArray()
        } else {
          this.downloadExcelFile(url)
          this.tabletest()
          this.resetFormArray()
        }
        this.closePopup('Piastra');
        this.resetFormArray()
      } else {
        this.toastr.error(res.description)
        this.closePopup('Piastra');
        this.resetFormArray()
      }
    })
  }

  resetFormArray() {
    // Clear the FormArray
    while (this.id_seme.length > 0) {
      this.id_seme.removeAt(0);
    }
    // Add a single default item
    this.id_seme.push(this.createItem());
  }
  retrieveRazze() {
    this.loader = true;
    this.apiService.retrieveRazze().subscribe({
      next: (data) => {
        this.loader = false;
        this.razze = data.result as Razza[];
      },
      error: (err) => { },
    });
  }
  openPopup1() {
    this.apiService.modaBackDrop.next(true);
    this.view = this.viewContainerRef.createEmbeddedView(this.popupContainer1);
    const domElem = this.view.rootNodes[0] as HTMLElement;
    domElem.classList.add("animate-in");
  }
  onRowDoubleClicked(event: any) {
    // Handle the double-click event
    const rowData = event.data;

    const defaultOption = this.statusOptions.find(option => option.label === rowData.stato);
    this.selectedStatus = defaultOption.id
    this.rowdataclicked = rowData
    this.doubleClick = true
    if (rowData.faxvi_data != null) {
      if (this.selectedStatus == 3) {
        this.selectedStatus = 1
      } else if (this.selectedStatus == 8) {
        this.selectedStatus = 9
      } else if (this.selectedStatus == 10) {
        this.selectedStatus = 11
      }
    }
    this.openPopup1()
    let dataEstr = this.formatDate1(rowData.data_estr)
    let data_ricev = this.formatDate1(rowData.data_ricev)
    let data_pcr = this.formatDate1(rowData.data_pcr)
    let data_gel = this.formatDate1(rowData.data_gel)
    let faxvi_data = this.formatDate1(rowData.faxvi_data)
    this.formupdate.patchValue({
      codice_cam: rowData.codice_cam,
      cod_seme: rowData.cod_seme,
      stato: defaultOption.id,
      ripetizion: rowData.ripetizion,
      data_ricev: data_ricev,
      estrazione: rowData.estrazione,
      data_estr: dataEstr,
      id_pcr: rowData.id_pcr,
      pcr: rowData.pcr,
      data_pcr: data_pcr,
      mix_car: rowData.mix_car,
      sequenziat: rowData.sequenziat,
      risultati: rowData.risultati,
      num_gel: rowData.num_gel,
      data_gel: data_gel,
      regvi_oper: rowData.regvi_oper,
      op_mac: rowData.op_mac,
      op_dos: rowData.op_dos,
      analisi_ok: rowData.analisi_ok,
      faxvi_data: faxvi_data,
      faxvi_oper: rowData.faxvi_oper,
      regvi_data: rowData.regvi_data,
      op_ricev: rowData.op_ricev,
      id_piastra: rowData.id_piastra,
      tank_rif: rowData.tank_rif,
      non_conf: rowData.non_conf,
      faxvi2data: rowData.faxvi2data,
      faxvi2oper: rowData.faxvi2oper
    })

  }

  formatDate1(inputDate: string): string | null {
    if (inputDate != null) {
      const [day, month, year] = inputDate.split('/');
      const formattedDate = `${year}-${month}-${day}`;
      return formattedDate;
    }
    return null; // Return null if inputDate is null
  }

  updateDnaRow() {
    let dataEstr = this.formatDate(this.formupdate.value.data_estr)
    let data_ricev = this.formatDate(this.formupdate.value.data_ricev)
    let data_pcr = this.formatDate(this.formupdate.value.data_pcr)
    let data_gel = this.formatDate(this.formupdate.value.data_gel)
    let faxvi_data = this.formatDate(this.formupdate.value.faxvi_data)
    this.apiService.updateDnaOpr({ ...this.formupdate.value, id: this.rowdataclicked.id, data_estr: dataEstr, data_ricev: data_ricev, data_pcr: data_pcr, data_gel: data_gel, faxvi_data: faxvi_data }).subscribe((res) => {
      this.closePopup('')
      this.tabletest()
    })
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  onRowClicked(event) {
    this.selCurrBelowRows = this.gridApi.getModel().rowsToDisplay.map(row => row.data);
    this.selCurrBelowRows.splice(0, event?.rowIndex);

    // this.selectedRows = this.gridApi.getSelectedRows();
  }

  selectAllBelowRows() {

    const selectedNode = this.gridApi.getSelectedNodes()[0];
    const clickedRowIndex = selectedNode ? selectedNode.rowIndex : -1;

    if (clickedRowIndex !== -1) {
      this.gridApi.forEachNode((node, index) => {
        if (index >= clickedRowIndex) {
          node.setSelected(true, true); // Set to true to add to selection
        }
      });

      this.selectedRows = this.gridApi.getSelectedRows();
    }
  }

  onClickStatoRif() {
    this.loader = true;
    this.apiService.getStatoRif(this.codice_cam, this.codice_az).subscribe((res) => {
      this.loader = false;
      if (res.success) {
        this.toastr.success(res?.description)
        let url = environment.url + res.downloadLink;
        this.fagfilename = res.filenames
        this.downloadTextFile(url);
      } else {
        this.toastr.error(res?.description)

      }
    })
  }
  onSelectionChange(data:any){
    let id = data.target.value
        this.apiService.getPiastra(this.codice_cam, this.codice_az,id,'Piastra').subscribe((res) => {
        if (res?.status) {
          this.piastraFrom.get('CELLEPIENE').setValue(res.result.CELLEPIENE);
          if(!this.manual){
            this.codeseme = res.COD_SEME
          }else{
            this.codeseme=[]
          }
          this.unusedColumn = res.unusedColumn
        } else {
          this.toastr.error(res?.description)
        }
      })

  }

  previewPisatraButton(){
    let idPiasta = parseInt(this.piastraFrom.value.ID_PIASTRA)
    this.apiService.priviewPiastra(this.codice_cam,this.codice_az,idPiasta).subscribe((res:any)=>{
      if(res.status){
        let url = environment.url + res.downloadLink
        this.fagfilename = res.filenames
        this.downloadTextFile(url);
      }else{
        this.toastr.error(res.message)
      }
    })
  }


}



