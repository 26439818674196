<div class="specie-main">
    <h1 class="semi-bold">RISULTATI</h1>
    <div class="head-btn">
        <button class="button1" (click)="getButtonFile('Analisi1')">Risultati SEME Analisi 1</button>
        <button class="button1" (click)="getButtonFile('Analisi2')">Risultati SEME Analisi 2</button>
        <button class="button1" (click)="getButtonFile('vca')">Com. Regione VCA</button>
        <button class="button1" (click)="getButtonFile(1)">SEME Let Regione INAD. 1</button>
        <button class="button1" (click)="getButtonFile(2)">SEME Let REGIONE INAD. 2</button>
    </div>
</div>
<div>
    <h1 class="semi-bold heading">Prima Analisi</h1>
    <ag-grid-angular style="height: 600px;" class="ag-theme-alpine-dark basic-table" [rowData]="rowData$"
        [gridOptions]="gridOptions">
    </ag-grid-angular>
    <h1 class="semi-bold heading">Seconda Analisi</h1>
    <ag-grid-angular style="height: 600px;" class="ag-theme-alpine-dark basic-table" [rowData]="rowData2"
        [gridOptions]="gridOptions">
    </ag-grid-angular>
</div>