// import { ChangeDetectorRef, Component, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
// import { NewDNA, Razza1 } from '../_models';
// import { CellClickedEvent, ColDef, GridOptions } from "ag-grid-community";
// import { CustomButtonRendererComponent, CustomButtonRendererComponent2 } from '../table-test/custom-button-renderer.component';
// import { ApiService } from '../_services/api.service';
// import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// import { ToastrService } from 'ngx-toastr';
// import { ModalService } from '../_services/modal.service';

// @Component({
//   selector: 'app-new-dna-ref',
//   templateUrl: './new-dna-ref.component.html',
//   styleUrls: ['./new-dna-ref.component.scss']
// })
// export class NewDnaRefComponent {
//   available:boolean = false;
//   showModal = false;
//   selectedFile: File;
//   loader: boolean = false;
//   rowData$: NewDNA[];
//   clickedRowData: any;
//   addDNAForm: FormGroup;
//   view: any;
//   @ViewChild("popupContainer", { read: TemplateRef })
//   popupContainer: TemplateRef<any>;
//   tableName: string = 'newdnadata';
//   formSubmitted: boolean = false;
//   defaultColDef: ColDef = {
//     resizable: true,
//     sortable: true,
//     filter: true,
//   };

//   colDefs: ColDef<NewDNA>[] = [
//     { field: "cod_dna", headerName: "CODICE DNA", width:350 ,cellRenderer: (params: any) => { return `${params?.data?.cod_dna == ''?'N/A':params?.data?.cod_dna}`}},
//     // { field: "cod_seme", headerName: "CODICE SEME", width:350, cellRenderer: (params: any) => { return `${params?.data?.cod_seme == ''||params?.data?.cod_seme == null?'N/A':params?.data?.cod_seme}`} },
//     { field: "tipo_seq", headerName: "SEQUENZIATORE" , width:350 },
//     { field: "microsat", headerName: "MICROSATELLITE", width:350 },
//     { field: "al1_rif", headerName: "RIF AL1" , width:350},
//     { field: "al2_rif", headerName: "RIF AL2" , width:350},

//     // {
//     //   field: "Action",
//     //   headerName: "AZIONE",
//     //   // flex: 1,
//     //   width:275,
//     //   cellRenderer: 'customButtonRenderer',
//     //   cellRendererParams: {
//     //     onClick: this.onButtonClicked.bind(this),
//     //     tableName: 'newdnadata',
//     //   },
//     // },
//   ];

//   gridOptions: GridOptions<NewDNA> = {
//     columnDefs: this.colDefs,
//     defaultColDef: this.defaultColDef,
//     rowSelection: "single",
//     animateRows: true,
//     // pagination: true,
//     // paginationAutoPageSize: true,
//     frameworkComponents: { customButtonRenderer: CustomButtonRendererComponent2 },
//     onCellClicked: (event: CellClickedEvent) => {
//       this.clickedRowData = event?.data
//       this.patchDNAForm();
//       this.openPopup();
//       this.changeButtun = true;
//     },
//   };

//   razze: Razza1[] = [];
//   changeButtun: boolean = false;
//   itemPerPage: number = 15;
//   pageNumber: number = 1;
//   totalCount: number = 1;
//   deleteId: any;
//   codeDna: any;
//   constructor(
//     public apiService: ApiService,
//     public viewContainerRef: ViewContainerRef,
//     public formBuilder: FormBuilder,
//     public toastr: ToastrService,
//     public modalService: ModalService,
//     private cdr: ChangeDetectorRef
//   ) {
//     this.newDNAForm();
//     this.deleteRow();
//   }

//   ngOnInit() {
//     this.tableData();
//     this.addDNAForm.controls['tipo_seq'].setValue('D');
//   }

//   onButtonClicked(data: any) {
//     this.loader = true;
//     this.apiService.deleteNewDNA(data?.id).subscribe((res: any) => {
//       if (res) {
//         this.closePopup()
//         this.tableData();
//         this.loader = false;
//         this.toastr.success(res.description);
//       }
//     });
//   }

//   tableData() {
//     this.apiService.getNewDNAData(this.pageNumber, this.itemPerPage).subscribe({
//       next: (res) => {
//         this.rowData$ = res?.result;
//         this.totalCount = res?.totalCount;
//         this.codeDna = res.maxValCod_dna +1
//       }, error: (error) => { },
//       complete: () => { }
//     });
//   }

//   openPopup() {
//     this.apiService.modaBackDrop.next(true);
//     this.view = this.viewContainerRef.createEmbeddedView(this.popupContainer);
//     const domElem = this.view.rootNodes[0] as HTMLElement;
//     domElem.classList.add("animate-in");
//   }
//   openPopup1(){
//     this.addDNAForm.controls['cod_dna'].setValue(this.codeDna);
//     this.addDNAForm.controls['tipo_seq'].setValue('D');

//   }

//   patchDNAForm() {
//     this.addDNAForm.patchValue({
//       id: this.clickedRowData.id,
//       cod_dna: this.clickedRowData.cod_dna,
//       // cod_seme: this.clickedRowData.cod_seme,
//       tipo_seq: this.clickedRowData.tipo_seq,
//       microsat: this.clickedRowData.microsat,
//       al1_rif: this.clickedRowData.al1_rif,
//       al2_rif: this.clickedRowData.al2_rif
//     });
//   }

//   newDNAForm() {
//     this.addDNAForm = this.formBuilder.group({
//       cod_dna: [null],
//       // cod_seme: [null, Validators.required],
//       tipo_seq: [null, Validators.required],
//       myfile:[null],
//       microsat: [null],
//       al1_rif: [null],
//       al2_rif: [null]
//     });
//   }

//   deleteRow() {
//     this.apiService.delete.subscribe((res) => {
//       this.deleteId = res.data
//       if ((res?.data?.id) && (res?.table === 'newdnadata')) {
//         this.modalService.openModal('newdnadata');
//       }
//     })
//   }
//   openmodel() {
//     this.modalService.openModal('newdnadata');
//   }
//   confirm(event) {
//     this.onButtonClicked(this.clickedRowData);
//   }

//   receiveData(event: any) {
//     if (event?.table === this.tableName) {
//       this.rowData$ = event?.data?.result;
//       this.totalCount = event?.data?.totalCount;
//     }
//   }
//   get fc() { return this.addDNAForm.controls; }

//   onSubmit() {
//     this.loader = true;

//     this.formSubmitted = true;
//     if (this.addDNAForm.invalid) {
//       this.loader = false;
//       return
//     } else {
//       const payload = this.addDNAForm.value;
//       if (this.changeButtun) {
//         this.updateRecord({ id: this.clickedRowData.id, ...payload });
//         this.formSubmitted = false;
//       } else {
//         const formData = new FormData();
//         formData.append('file', this.selectedFile);
//         formData.append('tipo_seq', this.addDNAForm.value.tipo_seq);
//         formData.append('isUpdateData', 'false');

//         this.createRecord(formData);
//         this.formSubmitted = false;
//       }
//     }
//   }

//   updateCreatedRecord(){
//     const formData = new FormData();
//     formData.append('file', this.selectedFile);
//     formData.append('tipo_seq', this.addDNAForm.value.tipo_seq);
//     formData.append('isUpdateData', 'true');

//     this.newCreateFile(formData);
//     this.formSubmitted = false;
//   }

//   createRecord(payload: any) {
//     this.loader = true
//     this.apiService?.createNewDNARecord(payload).subscribe({
//       next: (res) => {
//         if (res.status) {
//           this.loader = false;
//           this.toastr.success(res.message);
//           this.closePopup();
//           this.tableData();
//         }else{
//           this.toastr.error(res.message);
//           this.loader = false;
//           this.showModal = true
//         }
//       },
//       error: (err) => {
//         this.loader = false;
//         this.toastr.error('An error occurred');
//       }
//     });
//   }

//   newCreateFile(payload: any) {
//     this.loader = true
//     this.apiService?.createNewDNARecord(payload).subscribe({
//       next: (res) => {
//         if (res.status) {
//           this.loader = false;
//           this.toastr.success(res.message);
//           this.closePopup();
//           this.tableData();
//         }else{
//           this.loader = false;
//           this.showModal = false;
//           this.closePopup();

//         }
//       },
//       error: (err) => {
//         this.loader = false;
//         this.toastr.error('An error occurred');
//       }
//     });
//   }

//   updateRecord(payload: any) {
//     this.loader = true;
//     this.apiService?.updateNewDNARecord(payload).subscribe({
//       next: (res) => {
//         if (res) {
//           this.loader = false;
//           this.toastr.success(res.description);
//           this.closePopup();
//           this.tableData();
//         }
//       },
//       error: (err) => {
//         this.loader = false;
//         this.toastr.error('An error occurred');
//       }
//     });
//   }

//   retrieveRazze() {
//     this.apiService.retrieveRazze().subscribe({
//       next: (data) => {
//         this.razze = data.result as Razza1[];
//       },
//       error: (err) => {
//       },
//     });
//   }

//   closePopup() {
//     this.addDNAForm.reset();
//     this.changeButtun = false;
//     const domElem = this.view.rootNodes[0] as HTMLElement;
//     domElem.classList.add("animate-out");
//     domElem.addEventListener("animationend", (event: AnimationEvent) => {
//       this.viewContainerRef.clear();
//       this.apiService.modaBackDrop.next(false);
//     });
//   }

//   openFormModel() {
//     this.apiService.new_dna_cod.subscribe({
//       next: (res) => {
//         if (res) {
//           this.addDNAForm.controls['cod_dna'].setValue(res);
//           setTimeout(() => {
//             this.openPopup();
//             this.cdr.detectChanges();
//           });
//         }
//       }, error: (error) => { },
//       complete: () => { }
//     });
//   }

//   ngAfterViewInit() {
//     this.openFormModel();
//   }
//   onFileSelected(event: any): void {
//     this.selectedFile = event.target.files[0];
//   }

//   getUnique(){
//     this.loader = true;
//     this.apiService?.getUniqueUgDna().subscribe({
//       next: (res:any) => {
//         if (res) {
//           this.loader = false;
//           this.toastr.info(res.message);
//         }
//       },
//       error: (err) => {
//         this.loader = false;
//         this.toastr.error('An error occurred');
//       }
//     });
//   }

//   onOkClick() {
//     this.showModal = false;
//     this.updateCreatedRecord()

//   }

//   onCancelClick() {
//     this.showModal = false;
//   }

//   onModalClick(event: Event) {
//     // Prevent clicks inside the modal from closing it
//     event.stopPropagation();
//   }
// }

import { ChangeDetectorRef, Component, TemplateRef, ViewChild, ViewContainerRef } from "@angular/core";
import { NewDNA, Razza1 } from "../_models";
import { CellClickedEvent, ColDef, ColumnApi, GridOptions } from "ag-grid-community";
import { CustomButtonRendererComponent, CustomButtonRendererComponent2 } from "../table-test/custom-button-renderer.component";
import { ApiService } from "../_services/api.service";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { ModalService } from "../_services/modal.service";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";

@Component({
	selector: "app-new-dna-ref",
	templateUrl: "./new-dna-ref.component.html",
	styleUrls: ["./new-dna-ref.component.scss"],
})
export class NewDnaRefComponent {
	available: boolean = false;
	showModal = false;
	selectedFile: File;
	loader: boolean = false;
	rowData$: NewDNA[];
	clickedRowData: any;
	addDNAForm: FormGroup;
	fagfilename: string;
	columnName: string = "";
	filterValue: string = "";
	view: any;
	@ViewChild("popupContainer", { read: TemplateRef })
	popupContainer: TemplateRef<any>;

	@ViewChild("popupContainer1", { read: TemplateRef }) popupContainer1: TemplateRef<any>;

	tableName: string = "newdnadata";
	formSubmitted: boolean = false;
	defaultColDef: ColDef = {
		resizable: true,
		sortable: true,
		filter: true,
	};

	colDefs: ColDef<NewDNA>[] = [
		{
			field: "cod_dna",
			headerName: "CODICE DNA",
			width: 350,
			cellRenderer: (params: any) => {
				return `${params?.data?.cod_dna == "" ? "N/A" : params?.data?.cod_dna}`;
			},
			comparator: this.customSortFunction.bind(this),
		},
		{ field: "tipo_seq", headerName: "SEQUENZIATORE", width: 350 },
		{ field: "microsat", headerName: "MICROSATELLITE", width: 350 },
		{ field: "al1_rif", headerName: "RIF AL1", width: 390, comparator: this.customSortFunction.bind(this) },
		{ field: "al2_rif", headerName: "RIF AL2", width: 400, comparator: this.customSortFunction.bind(this) },
	];

	gridOptions: GridOptions<NewDNA> = {
		onFilterChanged: this.onFilterChanged.bind(this),
		columnDefs: this.colDefs,
		defaultColDef: this.defaultColDef,
		rowSelection: "single",
		animateRows: true,
		// pagination: true,
		// paginationAutoPageSize: true,
		frameworkComponents: { customButtonRenderer: CustomButtonRendererComponent2 },
		onCellClicked: (event: CellClickedEvent) => {
			this.clickedRowData = event?.data;
			this.patchDNAForm();
			this.openPopup();
			this.changeButtun = true;
		},
	};

	razze: Razza1[] = [];
	changeButtun: boolean = false;
	itemPerPage: number = 15;
	pageNumber: number = 1;
	totalCount: number = 1;
	sortingOrder: string = "";
	deleteId: any;
	codeDna: any;
	columnApi: ColumnApi;
	gridApi: any;
	col: any = 0;
	colValue: boolean = false;
	codDnaArray: any;
	tipo_seq: any;
	dnarifForm: any;
	submitted: boolean = false;
	constructor(
		public apiService: ApiService,
		public viewContainerRef: ViewContainerRef,
		public formBuilder: FormBuilder,
		public toastr: ToastrService,
		public modalService: ModalService,
		private cdr: ChangeDetectorRef,
		private http: HttpClient
	) {
		this.gridOptions.onSortChanged = this.onSortChanged.bind(this);
		this.newDNAForm();
		this.deleteRow();
		this.dnaRifForm();
	}

	ngOnInit() {
		this.tableData();
		this.addDNAForm.controls["tipo_seq"].setValue("D");
	}
	customSortFunction(valueA: any, valueB: any, nodeA: any, nodeB: any, isInverted: boolean): number {
		// Convert values to numbers for comparison
		const numA = Number(valueA);
		const numB = Number(valueB);

		// Perform custom comparison based on ascending order
		if (numA < numB) return -1;
		if (numA > numB) return 1;
		return 0;
	}

	onGridReady(params) {
		this.columnApi = params.columnApi;
		this.gridApi = params.api;
	}
	dnaRifForm() {
		this.dnarifForm = this.formBuilder.group({
			op_run: new FormControl("", [Validators.required]),
			data_run: new FormControl(this.getToday(), [Validators.required]),
			num_run: new FormControl("", [Validators.required]),
		});
	}
	getToday(): string {
		const today = new Date();
		const year = today.getFullYear();
		const month = ("0" + (today.getMonth() + 1)).slice(-2);
		const day = ("0" + today.getDate()).slice(-2);
		return `${year}-${month}-${day}`;
	}

	submitDnaRefForm() {
		this.submitted = true;
		console.log(this.dnarifForm.value);

		const payload = {
			cod_dna: this.codDnaArray,
			tipo_seq: this.tipo_seq,
			op_run: this.dnarifForm.value.op_run,
			data_run: this.formatDate(this.dnarifForm.value.data_run),
			num_run: this.dnarifForm.value.num_run,
		};
		if (this.dnarifForm.valid) {
			this.apiService?.create(payload).subscribe({
				next: (res) => {
					this.toastr.success(res.description);
					this.submitted = false;
					this.closePopup();
				},
				error: (err) => {
					this.loader = false;
					this.toastr.error("An error occurred");
				},
			});
		}
	}
	onSortChanged(event) {
		if (this.col == 0) {
			const sortedColumns: any = this.columnApi.getAllColumns().filter((column) => column.getSort())[0];
			this.sortingOrder = sortedColumns?.getSort() ?? "";
			this.columnName = sortedColumns?.getColDef().field ?? "";
			this.tableData();
		}
	}
  formatDate(inputDate): any {
    if (inputDate != null) {
      const [year, month, day] = inputDate.split('-');
      const formattedDate = `${day}/${month}/${year}`;
      return formattedDate;
    }
  }

	onButtonClicked(data: any) {
		this.loader = true;
		this.apiService.deleteNewDNA(data?.id).subscribe((res: any) => {
			if (res) {
				this.closePopup();
				this.tableData();
				this.loader = false;
				this.toastr.success(res.description);
			}
		});
	}

	tableData() {
		this.apiService.getNewDNAData(this.pageNumber, this.itemPerPage, this.columnName, this.filterValue, this.sortingOrder).subscribe({
			next: (res) => {
				this.rowData$ = res?.result;
				this.totalCount = res?.totalCount;
				this.codeDna = res.maxValCod_dna + 1;
			},
			error: (error) => {},
			complete: () => {},
		});
	}

	openPopup() {
		this.apiService.modaBackDrop.next(true);
		this.view = this.viewContainerRef.createEmbeddedView(this.popupContainer);
		const domElem = this.view.rootNodes[0] as HTMLElement;
		domElem.classList.add("animate-in");
	}
	openPopup1() {
		this.addDNAForm.controls["cod_dna"].setValue(this.codeDna);
		this.addDNAForm.controls["tipo_seq"].setValue("D");
	}

	patchDNAForm() {
		this.addDNAForm.patchValue({
			id: this.clickedRowData.id,
			cod_dna: this.clickedRowData.cod_dna,
			// cod_seme: this.clickedRowData.cod_seme,
			tipo_seq: this.clickedRowData.tipo_seq,
			microsat: this.clickedRowData.microsat,
			al1_rif: this.clickedRowData.al1_rif,
			al2_rif: this.clickedRowData.al2_rif,
		});
	}

	newDNAForm() {
		this.addDNAForm = this.formBuilder.group({
			cod_dna: [null],
			// cod_seme: [null, Validators.required],
			tipo_seq: [null, Validators.required],
			myfile: [null],
			microsat: [null],
			al1_rif: [null],
			al2_rif: [null],
		});
	}

	deleteRow() {
		this.apiService.delete.subscribe((res) => {
			this.deleteId = res.data;
			if (res?.data?.id && res?.table === "newdnadata") {
				this.modalService.openModal("newdnadata");
			}
		});
	}
	openmodel() {
		this.modalService.openModal("newdnadata");
	}
	confirm(event) {
		this.onButtonClicked(this.clickedRowData);
	}

	receiveData(event: any) {
		if (event?.table === this.tableName) {
			this.rowData$ = event?.data?.result;
			this.totalCount = event?.data?.totalCount;
			this.pageNumber = event?.page;
		}
	}
	get fc() {
		return this.addDNAForm.controls;
	}

	onSubmit() {
		this.loader = true;

		this.formSubmitted = true;
		if (this.addDNAForm.invalid) {
			this.loader = false;
			return;
		} else {
			const payload = this.addDNAForm.value;
			if (this.changeButtun) {
				this.updateRecord({ id: this.clickedRowData.id, ...payload });
				this.formSubmitted = false;
			} else {
				const formData = new FormData();
				formData.append("file", this.selectedFile);
				formData.append("tipo_seq", this.addDNAForm.value.tipo_seq);
				formData.append("isUpdateData", "false");

				this.createRecord(formData);
				this.formSubmitted = false;
			}
		}
	}

	updateCreatedRecord() {
		const formData = new FormData();
		formData.append("file", this.selectedFile);
		formData.append("tipo_seq", this.addDNAForm.value.tipo_seq);
		formData.append("isUpdateData", "true");

		this.newCreateFile(formData);
		this.formSubmitted = false;
	}

	createRecord(payload: any) {
		this.loader = true;
		this.apiService?.createNewDNARecord(payload).subscribe({
			next: (res) => {
				this.codDnaArray = res.result.map((item: any) => item.cod_dna);
				this.tipo_seq = this.addDNAForm.value.tipo_seq;
				console.log(this.codDnaArray, this.tipo_seq,'kjj');
   
				if (res.status) {
					this.loader = false;
          this.addDNAForm.patchValue({
            myfile:null
          });
          this.selectedFile=null;
					// this.toastr.success(res.message);
					this.closePopup();
					this.tableData();
					this.openPopupContainer();
				} else {
					this.toastr.error(res.message);
          
					this.loader = false;
					this.showModal = true;
				}
			},
			error: (err) => {
				this.loader = false;
				this.toastr.error("An error occurred");
			},
		});
	}

	newCreateFile(payload: any) {
		this.loader = true;
		this.apiService?.createNewDNARecord(payload).subscribe({
			next: (res) => {
				if (res.status) {
					this.loader = false;
					// this.toastr.success(res.message);
          this.addDNAForm.patchValue({
            myfile:null
          });
          this.selectedFile=null;
					this.closePopup();
					this.tableData();
					this.openPopupContainer();
				} else {
					this.loader = false;
					this.showModal = false;
					this.closePopup();
				}
			},
			error: (err) => {
				this.loader = false;
				this.toastr.error("An error occurred");
			},
		});
	}

	updateRecord(payload: any) {
		this.loader = true;
		this.apiService?.updateNewDNARecord(payload).subscribe({
			next: (res) => {
				if (res) {
					this.loader = false;
					this.toastr.success(res.description);
          this.addDNAForm.value.myfile.reset();
					this.closePopup();
					this.tableData();
				}
			},
			error: (err) => {
				this.loader = false;
				this.toastr.error("An error occurred");
			},
		});
	}

	retrieveRazze() {
		this.apiService.retrieveRazze().subscribe({
			next: (data) => {
				this.razze = data.result as Razza1[];
			},
			error: (err) => {},
		});
	}

	closePopup() {
    this.addDNAForm.patchValue({
      myfile:null
    });
    this.selectedFile=null;
		this.addDNAForm.reset();
		this.changeButtun = false;
		const domElem = this.view.rootNodes[0] as HTMLElement;
		domElem.classList.add("animate-out");
		domElem.addEventListener("click", (event: AnimationEvent) => {
			this.viewContainerRef.clear();
			this.apiService.modaBackDrop.next(false);
		});
	}
	openPopupContainer() {
		this.viewContainerRef.clear();
		this.dnarifForm.reset();
		this.dnarifForm.patchValue({ data_run: this.getToday() });
		// Render the popup template into the DOM
		this.view = this.viewContainerRef.createEmbeddedView(this.popupContainer1);

		// Optionally, add custom animations or listeners here
		if (this.view && this.view.rootNodes.length > 0) {
			const domElem = this.view.rootNodes[0] as HTMLElement;

			if (domElem && domElem.classList) {
				domElem.classList.add("animate-in");
			}
		}
	}
	openFormModel() {
		this.apiService.new_dna_cod.subscribe({
			next: (res) => {
				if (res) {
					this.addDNAForm.controls["cod_dna"].setValue(res);
					setTimeout(() => {
						// this.openPopup();
						this.cdr.detectChanges();
					});
				}
			},
			error: (error) => {},
			complete: () => {},
		});
	}

	ngAfterViewInit() {
		this.openFormModel();
	}
	onFileSelected(event: any): void {
		this.selectedFile = event.target.files[0];
	}

	downloadTextFile(url: string): void {
		this.http.get(url, { responseType: "text" }).subscribe((data: string) => {
			const blob = new Blob([data], { type: "text/plain" });
			const anchor = document.createElement("a");
			anchor.href = window.URL.createObjectURL(blob);
			anchor.download = this.fagfilename;
			document.body.appendChild(anchor);
			anchor.click();
			window.URL.revokeObjectURL(anchor.href);
			document.body.removeChild(anchor);
		});
	}

	getUnique() {
		this.loader = true;
		this.apiService?.getUniqueUgDna().subscribe({
			next: (res: any) => {
				if (res) {
					this.loader = false;
					if (res.status) {
						this.toastr.success(res.message);
						let url = environment.url + res.filePath;
						this.fagfilename = res.fileName;
						this.downloadTextFile(url);
					} else {
						this.toastr.error(res.message);
					}
				}
			},
			error: (err) => {
				this.loader = false;
				this.toastr.error("An error occurred");
			},
		});
	}

	onOkClick() {
		this.showModal = false;
		this.updateCreatedRecord();

	}

	onCancelClick() {
		this.showModal = false;
    this.addDNAForm.patchValue({
      myfile:null
    });
    this.selectedFile=null;
	}

	onModalClick(event: Event) {
		// Prevent clicks inside the modal from closing it
		event.stopPropagation();
	}

	onFilterChanged(params: any): void {
		this.filterValue = "";
		this.columnName = params.columns[0].colId;

		const filterModel = params.api.getFilterModel();
		const activeFilters = Object.keys(params.api.getFilterModel());
		console.log(activeFilters);
		this.col = activeFilters.length;
		for (const columnId in filterModel) {
			if (filterModel.hasOwnProperty(columnId)) {
				const specificColumnFilterModel = filterModel[columnId];
				if (specificColumnFilterModel) {
					this.columnName = columnId;
					this.filterValue = specificColumnFilterModel.filter;
					break; // Exit loop after finding the first column with a filter
				}
			}
		}

		if (this.col <= 1) {
			this.apiService.getNewDNAData((this.pageNumber = 1), this.itemPerPage, this.columnName, this.filterValue, this.sortingOrder).subscribe({
				next: (res) => {
					this.rowData$ = res?.result;
					this.colValue = true;
					this.gridApi.setRowData(this.rowData$);
					this.totalCount = res?.totalCount;
					this.codeDna = res.maxValCod_dna + 1;
					this.apiService.selectedPage.next(1);
				},
				error: (error) => {},
				complete: () => {},
			});
		}
	}
}
