import { Component, ElementRef, OnDestroy, OnInit, Renderer2, TemplateRef, ViewChild, ViewContainerRef } from "@angular/core";
import { ApiService } from "../_services/api.service";
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { GridOptions } from "ag-grid-community";
import { CustomButtonRendererComponent } from "../table-test/custom-button-renderer.component";
import { ToastrService } from 'ngx-toastr';
import { ModalService } from "../_services/modal.service";
import { Subject, take, takeUntil, takeWhile } from "rxjs";
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment'
import { saveAs } from 'file-saver';
@Component({
	selector: "app-campionamenti",
	templateUrl: "./campionamenti.component.html",
	styleUrls: ["./campionamenti.component.scss"],
})
export class CampionamentiComponent implements OnInit, OnDestroy {
	private componentDestroyed$: Subject<void> = new Subject<void>();
	dnaTableData = [];
	pupulatedData: any[];
	paginationPageSize = 15;
	totalCount: any;
	year = 'campionamentic'
	changeButtun: boolean = false;
	formSubmitted: boolean = false;
	columnDefs = [
		{
			field: "codice_cam",
			headerName: "CAMPIONAMENTO",
			// flex: 1,
			width: 160,
			cellRenderer: (params: any) => {
				return `${params.data.codice_cam == 'NULL' || params.data.codice_cam == null || params.data.codice_cam == '' ? 'N/A' : params.data.codice_cam}`;
			},
		},
		{
			field: "l30_qs",
			headerName: "SPECIE",
			width: 160,
			cellRenderer: (params: any) => {
				return params.data?.l30_qs[0]?.specie;
			},
		},
		// {
		// 	field: "codice_az",
		// 	headerName: "CENTRO",
		// 	width: 120,
		// 	// flex: 1,
		// 	cellRenderer: (params: any) => {
		// 		return `${params.data.codice_az == 'NULL' ? 'N/A' : params.data.codice_az}`;
		// 	},
		// },
		{
			field: "rcamp_data",
			headerName: "DATA RiCAR",
			// flex: 1,
			width: 160,
			cellRenderer: (params: any) => {
				return `${params.data.rcamp_data == 'NULL' || params.data.rcamp_data == null || params.data.rcamp_data == '' ? 'N/A' : params.data.rcamp_data}`;
			},
		},
		{
			field: "rcamo_oper",
			headerName: "UTENTE RiCAR",
			width: 160,
			// flex: 1,
			cellRenderer: (params: any) => {
				return `${params.data.rcamo_oper == 'NULL' || params.data.rcamo_oper == null || params.data.rcamo_oper == '' ? 'N/A' : params.data.rcamo_oper}`;
			},
		},
		{
			field: "data_campl",
			headerName: "DATA CAMPl",
			width: 160,
			comparator: this.dateComparator,
			cellRenderer: (params: any) => {
				return `${params.data.data_campl == 'NULL' || params.data.data_campl == null || params.data.data_campl == '' ? 'N/A' : params.data.data_campl}`;
			},

		},
		{
			field: "data_camp2",
			headerName: "DATA CAMP 2",
			width: 160,
			comparator: this.dateComparator,
			cellRenderer: (params: any) => {
				return `${params.data.data_camp2 == 'NULL' || params.data.data_camp2 == null || params.data.data_camp2 == '' ? 'N/A' : params.data.data_camp2}`;
			},
		},
		{
			field: "a_dna_data",
			headerName: "DATA BOLLA/DNA",
			width: 160,
			comparator: this.dateComparator,
			cellRenderer: (params: any) => {
				return `${params.data.a_dna_data == 'NULL' || params.data.a_dna_data == null || params.data.a_dna_data == '' ? 'N/A' : params.data.a_dna_data}`;
			},
		},
		{
			field: "opa_camp",
			headerName: "OPA CAMP",
			width: 160,
			// flex: 1,
			cellRenderer: (params: any) => {
				return `${params.data.opa_camp == 'NULL' || params.data.opa_camp == null || params.data.opa_camp == '' ? 'N/A' : params.data.opa_camp}`;
			},
		},
		{
			field: "faxva_data",
			headerName: "DATA MAIL",
			width: 150,
			comparator: this.dateComparator,
			// flex: 1,
			cellRenderer: (params: any) => {
				return `${params.data.faxva_data == 'NULL' || params.data.faxva_data == null || params.data.faxva_data == '' ? 'N/A' : params.data.faxva_data}`;
			},
		},
		{
			field: "faxva_oper",
			headerName: "UTENTE FAX",
			width: 120,
			valueParser: (params) => Number(params?.data?.faxva_oper),
      		comparator: (valueA, valueB) => Number(valueA) - Number(valueB),
     		filter: 'agNumberColumnFilter',
			// flex: 1,
			cellRenderer: (params: any) => {
				return `${params.data.faxva_oper == 'NULL' || params.data.faxva_oper == null || params.data.faxva_oper == '' ? 'N/A' : params.data.faxva_oper}`;
			},
		},
		{
			field: "Action",
			headerName: "AZIONE",
			width: 500,
			// flex: 1,
			cellRenderer: 'customButtonRenderer',
			cellRendererParams: {
				onClick: this.onButtonClicked.bind(this),
				tableName: 'sample_management',
			},
		},
	];
	columnDefs1 = [
		{
			field: "codice_cam",
			headerName: "CAMPIONAMENTO",
			// flex: 1,
			width: 160,
			cellRenderer: (params: any) => {
				return `${params.data.codice_cam == 'NULL' || params.data.codice_cam == null || params.data.codice_cam == '' ? 'N/A' : params.data.codice_cam}`;
			},
		},
		{
			field: "l30_qs",
			headerName: "SPECIE",
			width: 160,
			cellRenderer: (params: any) => {
				return params.data?.l30_qs[0]?.specie;
			},
		},
		// {
		// 	field: "codice_az",
		// 	headerName: "CENTRO",
		// 	width: 120,
		// 	// flex: 1,
		// 	cellRenderer: (params: any) => {
		// 		return `${params.data.codice_az == 'NULL' ? 'N/A' : params.data.codice_az}`;
		// 	},
		// },
		{
			field: "rcamp_data",
			headerName: "DATA RiCAR",
			// flex: 1,
			width: 160,
			cellRenderer: (params: any) => {
				return `${params.data.rcamp_data == 'NULL' || params.data.rcamp_data == null || params.data.rcamp_data == '' ? 'N/A' : params.data.rcamp_data}`;
			},
		},
		{
			field: "rcamo_oper",
			headerName: "UTENTE RiCAR",
			width: 160,
			// flex: 1,
			cellRenderer: (params: any) => {
				return `${params.data.rcamo_oper == 'NULL' || params.data.rcamo_oper == null || params.data.rcamo_oper == '' ? 'N/A' : params.data.rcamo_oper}`;
			},
		},
		{
			field: "data_campl",
			headerName: "DATA CAMPl",
			width: 160,
			comparator: this.dateComparator,
			// flex: 1,
			cellRenderer: (params: any) => {
				return `${params.data.data_campl == 'NULL' || params.data.data_campl == null || params.data.data_campl == '' ? 'N/A' : params.data.data_campl}`;
			},
		},
		{
			field: "data_camp2",
			headerName: "DATA CAMP 2",
			comparator: this.dateComparator,
			width: 160,
			// flex: 1,
			cellRenderer: (params: any) => {
				return `${params.data.data_camp2 == 'NULL' || params.data.data_camp2 == null || params.data.data_camp2 == '' ? 'N/A' : params.data.data_camp2}`;
			},
		},
		{
			field: "a_dna_data",
			headerName: "DATA BOLLA/DNA",
			width: 160,
			// flex: 1,
			cellRenderer: (params: any) => {
				return `${params.data.a_dna_data == 'NULL' || params.data.a_dna_data == null || params.data.a_dna_data == '' ? 'N/A' : params.data.a_dna_data}`;
			},
		},
		{
			field: "opa_camp",
			headerName: "OPA CAMP",
			width: 160,
			// flex: 1,
			cellRenderer: (params: any) => {
				return `${params.data.opa_camp == 'NULL' || params.data.opa_camp == null || params.data.opa_camp == '' ? 'N/A' : params.data.opa_camp}`;
			},
		},
		{
			field: "faxva_data",
			headerName: "DATA FAX",
			comparator: this.dateComparator,
			width: 150,
			// flex: 1,
			cellRenderer: (params: any) => {
				return `${params.data.faxva_data == 'NULL' || params.data.faxva_data == null || params.data.faxva_data == '' ? 'N/A' : params.data.faxva_data}`;
			},
		},
		{
			field: "faxva_oper",
			headerName: "UTENTE FAX",
			width: 120,
			// flex: 1,
			 filter: 'agNumberColumnFilter',
			 valueParser: (params) => {
				return Number(params.faxva_oper); // Ensure the data is parsed as a number
			  },
			cellRenderer: (params: any) => {
				return `${params.data.faxva_oper == 'NULL' || params.data.faxva_oper == null || params.data.faxva_oper == '' ? 'N/A' : params.data.faxva_oper}`;
			},
		},
		{
			field: "Action",
			headerName: "AZIONE",
			width: 500,
			// flex: 1,
			cellRenderer: 'customButtonRenderer',
			cellRendererParams: {
				onClick: this.onButtonClicked.bind(this), // Define your custom click handler,
				tableName: 'sample_management1',
			},
		},
	];

	rowData: any[];
	tableName: string = "sample_management";
	view;
	timeRange: string = "00.00/00.00"; // Default time range
	invalidTimeRange: boolean = false;
	p: number = 1;
	form: FormGroup;
	loader: boolean = false;

	@ViewChild("popupContainer", { read: TemplateRef })
	popupContainer: TemplateRef<any>;
	rowId: any;
	codeic: string | number;
	code: string;
	numberRowCount: any;
	years: number[] = [];
	lastcodiccam: any;
	centriCodiceAz: string | number;
	specie: string;
	anno: string | number;
	isAlive = true;
	percentage: any;
	subjectValue: boolean = false;
	lotti: number;
	deleteId: any;
	codice_cam: any;
	codice_az: any;
	fagliofile: string;

	constructor(public apiService: ApiService, private router: Router, private viewContainerRef: ViewContainerRef, private formBuilder: FormBuilder, private toast: ToastrService, private modalService: ModalService, private http: HttpClient,private renderer: Renderer2, private elementRef: ElementRef) {

		const currentYear = new Date().getFullYear();
		for (let i = 0; i < 4; i++) {
			this.years.push(currentYear - i);
		}
		this.code = localStorage.getItem('centriCodiceAz')
		this.form = this.formBuilder.group({
			codice_cam: [null, Validators.required],
			codice_az: [this.code, Validators.required],
			anno_riferimento: [null],
			rcamp_data: [null],
			rcamo_oper: [null],
			data_campl: [null],
			data_camp2: [null],
			a_dna_data: [null],
			ora_camp : [null],
			faxva_data: [null],
			faxva_oper: [null],
			faxva2data:[null],
			faxva2oper:[null],
			regva_data :[null],
			regva_oper:[null],
			regi_data:[null],
			regi_oper:[null],
			regi2data:[null],
			regi2oper:[null],
			faxvi_data:[null],
			faxvi_oper:[null],
			regvi_data:[null],
			regvi_oper:[null],
			faxie_data:[null],
			faxie_oper:[null],
			faxvi2data:[null],
			faxvi2oper:[null],
			regie_data:[null],
			regie_oper:[null]

			// lotti_cam: ['50'],
			// minumo_dosi: ['10'],
			// massimo_tentivi: ['50'],
			// darecord: [null],
			// arecord: [null],

		});

	}
	defaultColDef = {
		// flex: 1,
		resizable: true,
		sortable: true,
		filter: true,
	};
	gridOptions: GridOptions = {
		defaultColDef: this.defaultColDef,
		pagination: true,
		paginationAutoPageSize: true,
		frameworkComponents: {
			customButtonRenderer: CustomButtonRendererComponent, // Register the custom cell renderer
		},
	}
	gridOptions2: GridOptions = {
		defaultColDef: this.defaultColDef,
		pagination: true,
		paginationAutoPageSize: true,
		frameworkComponents: {
			customButtonRenderer: CustomButtonRendererComponent, // Register the custom cell renderer
		},
	}

	ngOnInit(): void {
		this.apiService.redirectButton.next(true)
		this.click()
		this.apiService.current.pipe(takeWhile(() => this.isAlive)).subscribe((res) => {
			if (res) {
				this.subjectValue = true
				this.centriCodiceAz = res.codice_az ? res.codice_az : localStorage.getItem('centriCodiceAz')
				this.specie = res.specie ? res.specie : localStorage.getItem('specie');
				this.anno = res.anna ? res.anna : localStorage.getItem('anna')
				this.getSampleTable();
			}
		})
		this.getSampleTable();
		this.apiService.delete.subscribe((res) => {
			this.deleteId = res?.data
			if ((res?.data?.id) && (res?.table == "sample_management")) {
				this.modalService.openModal("sample_management");
				// this.modalService.isConfirmed$.pipe(takeUntil(this.componentDestroyed$)).subscribe((con: any) => {
				// 	if (con?.modal == "sample_management" && con?.value) {
				// 		this.onButtonClicked(res?.data);
				// 	}
				// });
			} else if ((res?.data?.id) && (res?.table == "sample_management1")) {
				this.modalService.openModal("sample_management1");
				// this.modalService.isConfirmed$.pipe(take(1)).subscribe((con: any) => {
				// 	// this.modalService.isConfirmed$.next(null);
				// 	// if (con?.modal == "sample_management1" && con?.value) {
				// 	// 	this.onButtonClicked(res?.data);
				// 	// }
				// });
			}
		})
	}
	ngOnDestroy() {
		this.isAlive = false;
		this.apiService.redirectButton.next(false)
		this.componentDestroyed$.next();
		this.componentDestroyed$.complete();
		this.apiService.ricar.next(null)
	}


	openmodel() {
		this.modalService.openModal("sample_management");
	}
	confirm(event) {
		// if (event?.modal == "sample_management" && event?.value) {
		this.onButtonClicked(this.rowId);
		// }else if(event?.modal == "sample_management1" && event?.value){
		// 	this.onButtonClicked(this.rowId);
		// }

	}
	click() {
		this.apiService.ricar.pipe(takeUntil(this.componentDestroyed$)).subscribe((res) => {
			this.codice_cam = res?.data?.codice_cam
			this.codice_az = res?.data?.codice_az
			if (res?.type == "ricar") {
				this.getRicarData(this.codice_cam, this.codice_az)
			} else if (res?.type == "foglio") {
				this.getfaglio(this.codice_cam, this.codice_az,res?.type)
			} else if (res?.type == "foglio2"){
				this.getfaglio(this.codice_cam, this.codice_az,res?.type)
			}else if (res?.type == "bolla") {
				this.getbolla(this.codice_cam, this.codice_az)
			} else if (res?.type == "RISULTATI") {
				console.log(res.codice_cam)
				this.router.navigate(['risultati'], { queryParams: { codice_cam: res.data.codice_cam } })
			}
		})
	}

	getRicarData(codice_cam, codice_az) {
		this.loader = true
		this.apiService.getRicarData(codice_cam, codice_az).subscribe({
			next: (data) => {
				if (data.success) {
					let url = environment.url + data.filePath
					this.downloadRicardoc(url)
					this.fagliofile = data.filenames
					this.loader = false
				} else {
					this.toast.error(data.description)
					this.loader = false

				}
			},
			error: (err) => {
				console.log(err)
				this.toast.error(err)
			}
		});
	}
	getfaglio(codice_cam, codice_az,type) {
		this.loader = true
		this.apiService.getFaglio(codice_cam, codice_az,type).subscribe({
			next: (data) => {
				if (data.status) {
					let url = environment.url + data.dowloadLink
					// this.downloadTextFile(url)
					this.downloadPdfFile(url)
					this.fagliofile = data.filenames
					this.loader = false

				} else {
					this.toast.error(data.message)
					this.loader = false

				}
			},
		});
	}
	getbolla(codice_cam, codice_az) {
		this.loader = true
		this.apiService.getBolla(codice_cam, codice_az).subscribe({
			next: (data) => {
				if (data.status) {
					let url = environment.url + data.dowloadLink
					// this.downloadTextFile(url)
					this.downloadPdfFile(url)
					this.fagliofile = data.filenames
					this.loader = false
				} else {
					this.toast.error(data.message)
					this.loader = false

				}
			},
		});
	}

	downloadRicardoc(url: string): void {
		this.http.get(url, { responseType: 'blob' })
		  .subscribe(
			(data) => {
			  const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
			  saveAs(blob, this.fagliofile);
			  this.getSampleTable();
			},
			(error) => {
			  console.error('Error downloading file:', error);
			}
		  );
	  }
	  
	downloadTextFile(url: string): void {
		this.http.get(url, { responseType: 'text' }).subscribe((data: string) => {
			// Create a Blob from the text data
			const blob = new Blob([data], { type: 'text/plain' });

			// Create a download link
			const anchor = document.createElement('a');
			anchor.href = window.URL.createObjectURL(blob);
			anchor.download = this.fagliofile;

			// Trigger the download
			document.body.appendChild(anchor);
			anchor.click();

			// Clean up
			window.URL.revokeObjectURL(anchor.href);
			document.body.removeChild(anchor);
		});
	}


	downloadPdfFile(url: string): void {
		this.http.get(url, { responseType: 'blob' }).subscribe((data: Blob) => {
			// Create a Blob from the text data
			const blob = new Blob([data], { type: 'application/pdf' });

			// Create a download link
			const anchor = document.createElement('a');
			anchor.href = window.URL.createObjectURL(blob);
			anchor.download = this.fagliofile;

			// Trigger the download
			document.body.appendChild(anchor);
			anchor.click();

			// Clean up
			window.URL.revokeObjectURL(anchor.href);
			document.body.removeChild(anchor);
		});
	}
	onCellClicked(event: any) {
		this.changeButtun = true;
		const data = event.data;
		this.lotti = event.data.lotti_cam
		const date1 = this.formatDate(data.data_campl)
		const date2 = this.formatDate(data.data_camp2)
		const date3 = this.formatDate(data.rcamp_data)
		const date4 = this.formatDate(data.a_dna_data)
		const date5 = this.formatDate(data.faxva_data)
		let faxva2data = this.formatDate(data.faxva2data) 
		let regva_data = this.formatDate(data.regva_data) 
		let regi_data = this.formatDate(data.regi_data)
		let regi2data = this.formatDate(data.regi2data)
		let faxvi2data = this.formatDate(data.faxvi2data)
		let regie_data = this.formatDate(data.regie_data)
		let faxie_data = this.formatDate(data.faxie_data)
		let regvi_data = this.formatDate(data.regvi_data)
		let faxvi_data = this.formatDate(data.faxvi_data)

		this.form.patchValue({
			codice_cam: data.codice_cam == 'NULL' ? '' : data.codice_cam,
			codice_az: data.codice_az == 'NULL' ? '' : localStorage.getItem('centriCodiceAz'),
			rcamp_data: data.rcamp_data == 'NULL' ? '' : date3,
			rcamo_oper: data.rcamo_oper == 'NULL' ? '' : data.rcamo_oper,
			data_campl: data.data_campl == 'NULL' ? '' : date1,
			data_camp2: data.data_camp2 == 'NULL' ? '' : date2,
			a_dna_data: data.a_dna_data == 'NULL' ? '' : date4,
			ora_camp: data.opa_camp == 'NULL' ? '' : data.ora_camp,
			faxva_data: data.faxva_data == 'NULL' ? '' : date5,
			faxva_oper: data.faxva_oper == 'NULL' ? '' : data.faxva_oper,
			lotti_cam: data.lotti_cam == 'NULL' ? '' : data.lotti_cam,
			minumo_dosi: data?.minumo_dosi == 'NULL' ? '' : data?.minumo_dosi,
			massimo_tentivi: data?.massimo_tentivi == 'NULL' ? '' : data?.massimo_tentivi,
			darecord: data?.darecord == 'NULL' ? '' : data?.darecord,
			arecord: data?.arecord == 'NULL' ? '' : data?.arecord,
			faxva2data: faxva2data == 'NULL' ? '' : faxva2data,
			regva_data: regva_data == 'NULL' ? '' : regva_data,
			regi_data: regi_data == 'NULL' ? '' : regi_data,
			regi2data: regi2data == 'NULL' ? '' : regi2data,
			faxvi2data: faxvi2data == 'NULL' ? '' : faxvi2data,
			regie_data: regie_data == 'NULL' ? '' : regie_data,
			faxie_data: faxie_data == 'NULL' ? '' : faxie_data,
			regvi_data: regvi_data == 'NULL' ? '' : regvi_data,
			faxvi_data: faxvi_data == 'NULL' ? '' : faxvi_data,
			faxva2oper:data.faxva2oper == 'NULL' ? '':data.faxva2oper,
			regva_oper:data.regva_oper == 'NULL' ? '':data.regva_oper,
			regi_oper:data.regi_oper == 'NULL' ? '':data.regi_oper,
			regi2oper:data.regi2oper == 'NULL' ? '':data.regi2oper,
			faxvi_oper:data.faxvi_oper == 'NULL' ? '':data.faxvi_oper,
			faxvi2oper:data.faxvi2oper == 'NULL' ? '':data.faxvi2oper,
			regvi_oper:data.regvi_oper == 'NULL' ? '':data.regvi_oper,
			faxie_oper:data.faxie_oper == 'NULL' ? '':data.faxie_oper,
			regie_oper:data.regie_oper == 'NULL' ? '':data.regie_oper,
		});
		this.rowId = data.id;

		this.openPopup('patch');
		// this.router.navigate(["/dna-table/micropipetta"], { queryParams: { id_lab: data.id_lab } });
	}
	formatDate(date: any): any {
		if (typeof date === 'string' && date !== 'NULL' && date !== null) {
			const dateComponents = date.split('/');
			const formattedDate = new Date(`${dateComponents[2]}-${dateComponents[1]}-${dateComponents[0]}`);
	
			if (!isNaN(formattedDate.getTime())) {
				// Check if the formattedDate is a valid Date object
				const timeZoneOffset = formattedDate.getTimezoneOffset() * 60000;
				const adjustedDate = new Date(formattedDate.getTime() - timeZoneOffset);
				const formattedDateString = adjustedDate.toISOString().split('T')[0];
				return formattedDateString;
			} else {
				// Handle invalid date
				return 'Invalid Date';
			}
		} else {
			// Handle the case where date is not a string or is 'NULL' or null
			return null; // or whatever makes sense in your context
		}
	}
	

	convertDateFormat(inputDate:any): any {
		if (inputDate != null) {
			const parts = inputDate.split('-');
			const yyyy = parts[0];
			const mm = parts[1];
			const dd = parts[2];
			const formattedDate = `${dd}-${mm}-${yyyy}`;
			return formattedDate;
		}
	}
	isValidDate(d: any) {
		return d instanceof Date && !isNaN(+d);
	}
	getSampleTable(page = 1, limit = this.paginationPageSize) {
		this.loader = true
		if (!this.subjectValue) {
			this.centriCodiceAz = localStorage.getItem('centriCodiceAz') ? localStorage.getItem('centriCodiceAz') : 1
			this.specie = localStorage.getItem('specie') ? localStorage.getItem('specie') : "BI"
			this.anno = localStorage.getItem('anna') ? localStorage.getItem('anna') : 2022
		}
		this.subjectValue = false

		this.codeic = this.centriCodiceAz
		this.apiService.getSampleData(page, limit, this.centriCodiceAz, this.specie, this.anno).subscribe({
			next: (data) => {
				if (data.success) {
					this.numberRowCount = data.result.count
					this.dnaTableData = data.result.notPopulatedData.result;
					this.pupulatedData = data.result.populatedData.result
					this.totalCount = data.totalCount;
					this.rowData = this.dnaTableData;
					this.loader = false
					this.lastcodiccam = this.incrementString(data?.lastCodiceCam?.codice_cam);
					localStorage.setItem('lastcodiccam', this.lastcodiccam);
					this.percentage = data?.percentage
				}
			},
		});
	}

	incrementString(inputString) {
		if (inputString !== null) {
			const numericPartMatch = inputString.match(/\d+/);
			if (numericPartMatch) {
				const numericPart = parseInt(numericPartMatch[0], 10);
				const incrementedNumericPart = numericPart + 1;
				const outputString = inputString.replace(/\d+/, incrementedNumericPart);
				return outputString;
			} else {
				return inputString;
			}
		}
	}

	onGridReady(params: any) { }

	receiveData(event: any) {
		if (event?.table === this.tableName) {
			this.rowData = event?.data?.result?.rows;
		}
	}

	openPopup(type: string) {
		if (type === 'crea') {
			this.router.navigate(["/l30loti"]);
			this.form.controls['codice_az'].setValue(this.code)
			this.form.controls['codice_cam'].setValue(this.lastcodiccam)
			return
		}
		if (type === 'patch') {
			// do some task for patch
		}
		this.code = localStorage.getItem('centriCodiceAz')
		this.apiService.modaBackDrop.next(true);
		this.lotti = 50
		const bodyElement = this.elementRef.nativeElement.ownerDocument.body;
		this.renderer.setStyle(bodyElement, 'overflow', 'hidden');
		this.view = this.viewContainerRef.createEmbeddedView(this.popupContainer);
		const domElem = this.view.rootNodes[0] as HTMLElement;
		domElem.classList.add("animate-in");
	}

	closePopup() {
		this.form.reset()
		this.changeButtun = false
		const bodyElement = this.elementRef.nativeElement.ownerDocument.body;
		this.renderer.setStyle(bodyElement, 'overflow', 'auto');
		const domElem = this.view.rootNodes[0] as HTMLElement;
		domElem.classList.add("animate-out");

		// Ascoltare l'evento di fine animazione
		domElem.addEventListener("click", (event: AnimationEvent) => {
			// Rimuovere l'elemento dal DOM
			this.viewContainerRef.clear();
			// this.gridOptions.api.deselectAll();
			this.apiService.modaBackDrop.next(false);
		});

		// this.viewContainerRef.clear();
	}
	get fc() { return this.form.controls; }

	onSubmit() {
		this.formSubmitted = true;
		if (this.form.invalid) {
			return;
		}
		if (this.changeButtun) {
			if (this.form.value.rcamp_data && this.form.value.faxvi_data && new Date(this.form.value.faxvi_data) < new Date(this.form.value.rcamp_data)) {
				this.toast.error('FAXVI_DATA can’t be earlier than RCAMP_DATA');
				return
			  } 
			this.loader = true;
			let datadate1 = this.form.value.data_campl == '' ? null : this.form.value.data_campl;
			let datadate2 = this.form.value.data_camp2 == '' ? null : this.form.value.data_camp2;
			let datadate3 = this.form.value.rcamp_data == '' ? null : this.form.value.rcamp_data;
			let datadate4 = this.form.value.a_dna_data == '' ? null : this.form.value.a_dna_data;
			let datadate5 = this.form.value.faxva_data == '' ? null : this.form.value.faxva_data;
			let dataDate6 = this.form.value.faxvi_data == '' ? null : this.form.value.faxvi_data;
			let datadate7 = this.form.value.faxva2data == '' ? null : this.form.value.faxva2data;
			let datadate8 = this.form.value.regva_data == '' ? null : this.form.value.regva_data;
			let datadate9 = this.form.value.regi_data == '' ? null : this.form.value.regi_data;
			let datadate10 = this.form.value.regi2data == '' ? null : this.form.value.regi2data;
			let datadate11 = this.form.value.faxvi2data == '' ? null : this.form.value.faxvi2data;
			let datadate12 = this.form.value.regie_data == '' ? null : this.form.value.regie_data;
			let datadate13 = this.form.value.faxie_data == '' ? null : this.form.value.faxie_data;
			let datadate14 = this.form.value.regvi_data == '' ? null : this.form.value.regvi_data;


			let data1 = this.formatDate1(datadate1)
			let data2 = this.formatDate1(datadate2)
			let data3 = this.formatDate1(datadate3)
			let data4 = this.formatDate1(datadate4)
			let data5 = this.formatDate1(datadate5)
			let date6 = this.formatDate1(dataDate6) 
			let faxva2data = this.formatDate1(datadate7) 
			let regva_data = this.formatDate1(datadate8) 
			let regi_data = this.formatDate1(datadate9)
			let regi2data = this.formatDate1(datadate10)
			let faxvi2data = this.formatDate1(datadate11)
			let regie_data = this.formatDate1(datadate12)
			let faxie_data = this.formatDate1(datadate13)
			let regvi_data = this.formatDate1(datadate14)


			//  let data =this.convertDateFormat(datadate) 
			//  let data1 = this.convertDateFormat(dataDate)
			let payload = { ...this.form.value, id: this.rowId, data_campl: data1, data_camp2: data2, rcamp_data: data3,a_dna_data: data4, faxva_data: data5,faxvi_data:date6,faxva2data:faxva2data,regva_data:regva_data,regi_data:regi_data,regi2data:regi2data,faxvi2data:faxvi2data,regie_data:regie_data,faxie_data:faxie_data,regvi_data:regvi_data}
			this.apiService.updateSampleData(payload).subscribe((res) => {
				this.getSampleTable();
				this.closePopup()
				this.loader = false;
				this.formSubmitted = false;
			});
		} else {
			this.loader = true;
			let data = this.formatDate1(this.form.value.data_campl)
			let data1 = this.formatDate1(this.form.value.data_camp2)
			// let data = this.convertDateFormat(this.form.value.data_campl)
			// let data1 = this.convertDateFormat(this.form.value.data_camp2)
			let payload = {
				...this.form.value, data_campl: data, data_camp2: data1
			}
			this.apiService.createSampleData(payload).subscribe((res) => {
				this.getSampleTable();
				this.closePopup()
				this.loader = false;
				this.formSubmitted = false;
			})
		}

	}
	validateTimeRange() {
		// Regular expression for matching time range in the format "HH:mm AM/PM - HH:mm AM/PM"
		// const regex = /^(\d{1,2}:\d{2})\/(\d{1,2}:\d{2})$/;
		const regex = /^(\d{2}\.\d{2})\/(\d{2}\.\d{2})$/;

		if (!regex.test(this.timeRange)) {
			this.invalidTimeRange = true;
		} else {
			this.invalidTimeRange = false;
		}
	}
	onButtonClicked(data) {
		let payload = {
			id: data
		}
		this.apiService.deleteSampleData(payload).subscribe((res: any) => {
			if (res) {
				this.toast.success(res.description)
				this.closePopup()
				this.getSampleTable();
			}
		})
	}

	formatDate1(inputDate:any): any {
		if (inputDate != null) {
			const [year, month, day] = inputDate.split('-');

			// Create the formatted date string in dd/mm/yyyy format
			const formattedDate = `${day}/${month}/${year}`;

			return formattedDate;
		}
	}

	dateComparator(date1: string | null, date2: string | null): number {
		// Check if either date is null or undefined
		if (!date1 || !date2) {
		  // You can decide what to return in case of null values
		  // For example, if date1 is null, consider it as smaller than any non-null date2
		  return date1 ? 1 : -1;
		}
	  
		const dateParts1 = date1.split('/');
		const dateParts2 = date2.split('/');
		
		// Create date objects in the format YYYY-MM-DD
		const d1 = new Date(Number(dateParts1[2]), Number(dateParts1[1]) - 1, Number(dateParts1[0]));
		const d2 = new Date(Number(dateParts2[2]), Number(dateParts2[1]) - 1, Number(dateParts2[0]));
		
		return d1.getTime() - d2.getTime();
	  }
	  
}
