<app-loader *ngIf="loader"></app-loader>
<div class="header d-flex mt-2">
    <h1 class="semi-bold"><button class="btn backbtn fs-18 fw-600" (click)="back()"><i class="fa-solid fa-arrow-left pr-1"></i></button>STATISTICA DESCRITTIVA</h1>
    <div class="export">
        <button class="button1 mr-1" (click)="openPopup(tableName)">ESPORTA</button>
    </div>
</div>

<ag-grid-angular style="height: 600px;" class="ag-theme-alpine-dark basic-table" [rowData]="statisticaTableData$"
    [gridOptions]="statisticaGridOptions">
</ag-grid-angular>

<ng-template #popupContainer let-options>
    <div class="popup-window animate-in popup-form">
        <h3 class="mb-1">ESPORTA</h3>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div formArrayName="checkboxItems">
              <label *ngFor="let item of items; let i = index" class="container-checkbox">{{ item.name }}
                <input type="checkbox" [formControlName]="i"/>
                <span class="checkmark"></span>
              </label>
            </div>
            <div class="popup-footer mt-1">
                <button type="submit" class="button1 mr-1">Submit</button>
                <button type="button" class="button1" (click)="closePopup()">Close</button>
            </div>
          </form>      
    </div>
</ng-template>