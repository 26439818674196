<!-- <app-loader *ngIf="loader"></app-loader> -->
<div class="custom-overlay" *ngIf="loader">
    <div class="spinner"></div>
 </div>
<app-conform [message]="'Are you sure you want to delete?'" (confirm)="confirm($event)"></app-conform>
<div class="header d-flex mt-2">
    <h1 class="semi-bold">CONTROLLO DI QUALITÀ INTERNO</h1>
    <div class="export">
        <button class="button1 mr-1" (click)="openCqFormPopup()">AGGIUNGI CQ</button>
        <button class="button1 mr-1" routerLink="/statistica-descrittiva" [queryParams]="{ currentYear: currentYear }">STATISTICA DESCRITTIVA</button>

        <button class="button1 mr-1" (click)="openCarta()">CARTA DI CONTROLLO</button>
        <button class="button1" (click)="openAnalisi()">ANALISI DELLA FREQUENZA</button>
    </div>
</div>
<div class="text-right pr-1 pt-1">
    <div class="toggle-wrap">
        <small>TUTTI GLI ANNI</small>
        <label class="switch">
            <input type="checkbox" [(ngModel)]="isToggleCq" (change)="onChangeToggle()">
            <span class="slider round"></span>
        </label>
        <small>ANNO CORRENTE</small>
    </div>
</div>
<ag-grid-angular style="height: 700px;" class="ag-theme-alpine-dark basic-table" [rowData]="cqTableData$"
    [gridOptions]="cqGridOptions" (gridReady)="onGridReady($event)" (cellClicked)="onCellClicked($event)">
</ag-grid-angular>

<div class="pagination">
    <app-pagination [dataLength]="totalCount" [tableName]="tableName" [itemPerPage]="itemPerPage" [currentYear]="currentYear"
    (dataOnPage)="onReceiveData($event)" [columnName]="columnName" [sortingOrder]="sortingOrder"></app-pagination>
</div>

<ng-template #cqPopupContainer let-options>
    <div class="popup-window animate-in popup-form p-1">
        <form [formGroup]="cqForm" (ngSubmit)="onSubmitCqForm()">
            <div class="d-flex gap-16">
                <div class="field w-100">
                    <label class="d-block">data:</label>
                    <input type="date" class="w-100" formControlName="data"  />
                    <!-- <div *ngIf="(cqFormSubmitted || fc['data'].touched) && fc['data'].errors" class="alert">
                        <div *ngIf="(cqFormSubmitted || fc['data'].touched)"> Please enter data</div>
                    </div> -->
                </div>  
                <div class="field w-100">
                    <label class="d-block">settimana:</label>
                    <input type="number" class="w-100" formControlName="settimana" />
                    <!-- <div *ngIf="(cqFormSubmitted || fc['settimana'].touched) && fc['settimana'].errors" class="alert">
                        <div *ngIf="(cqFormSubmitted || fc['settimana'].touched)"> Please enter settimana</div>
                    </div> -->
                </div>
            </div>
            <div class="d-flex gap-16">
                <div class="field w-100">
                    <label class="d-block">ID.Riferimento:</label>
                    <input type="number" class="w-100" formControlName="id_rif" />
                    <!-- <div *ngIf="(cqFormSubmitted || fc['id_rif'].touched) && fc['id_rif'].errors" class="alert">
                        <div *ngIf="(cqFormSubmitted || fc['id_rif'].touched)"> Please enter id_rif</div>
                    </div> -->
                </div>
                <div class="field w-100">
                    <label class="d-block">OP.SP100-1:</label>
                    <input type="number" class="w-100" formControlName="op_sp100" />
                    <!-- <div *ngIf="(cqFormSubmitted || fc['op_sp100'].touched) && fc['op_sp100'].errors" class="alert">
                        <div *ngIf="(cqFormSubmitted || fc['op_sp100'].touched)"> Please enter op_sp100</div>
                    </div> -->
                </div>
            </div>
            <div class="d-flex gap-16">
                <div class="field w-100">
                    <label class="d-block">CONC.SP100-1:</label>
                    <input type="number" class="w-100" formControlName="conc_sp100" />
                    <!-- <div *ngIf="(cqFormSubmitted || fc['conc_sp100'].touched) && fc['conc_sp100'].errors" class="alert">
                        <div *ngIf="(cqFormSubmitted || fc['conc_sp100'].touched)"> Please enter conc_sp100</div>
                    </div> -->
                </div>
                <div class="field w-100">
                    <label class="d-block">OP.SP100-2:</label>
                    <input type="number" class="w-100" formControlName="op2sp100" />
                    <!-- <div *ngIf="(cqFormSubmitted || fc['op2sp100'].touched) && fc['op2sp100'].errors" class="alert">
                        <div *ngIf="(cqFormSubmitted || fc['op2sp100'].touched)"> Please enter op2sp100</div>
                    </div> -->
                </div>
            </div>
            <div class="d-flex gap-16">
                <div class="field w-100">
                    <label class="d-block">CONC.SP100-2:</label>
                    <input type="number" class="w-100" formControlName="conc2sp100" />
                    <!-- <div *ngIf="(cqFormSubmitted || fc['conc2sp100'].touched) && fc['conc2sp100'].errors" class="alert">
                        <div *ngIf="(cqFormSubmitted || fc['conc2sp100'].touched)"> Please enter conc2sp100</div>
                    </div> -->
                </div>
                <!-- <div class="field w-100">
                    <label class="d-block">OP.Z2:</label>
                    <input type="number" class="w-100" formControlName="op_z2" /> -->
                    <!-- <div *ngIf="(cqFormSubmitted || fc['op_z2'].touched) && fc['op_z2'].errors" class="alert">
                        <div *ngIf="(cqFormSubmitted || fc['op_z2'].touched)"> Please enter op_z2</div>
                    </div> -->
                <!-- </div> -->
            </div>
            <div class="d-flex gap-16">
                <!-- <div class="field w-100">
                    <label class="d-block">CONC.Z2:</label>
                    <input type="number" class="w-100" formControlName="conc_z2" /> -->
                    <!-- <div *ngIf="(cqFormSubmitted || fc['conc_z2'].touched) && fc['conc_z2'].errors" class="alert">
                        <div *ngIf="(cqFormSubmitted || fc['conc_z2'].touched)"> Please enter conc_z2</div>
                    </div> -->
                <!-- </div> -->
                <div class="field w-100">
                    <label class="d-block">OP.IVOS12:</label>
                    <input type="number" class="w-100" formControlName="op_ivos12" />
                    <!-- <div *ngIf="(cqFormSubmitted || fc['op_ivos12'].touched) && fc['op_ivos12'].errors" class="alert">
                        <div *ngIf="(cqFormSubmitted || fc['op_ivos12'].touched)"> Please enter op_ivos12</div>
                    </div> -->
                </div>
            </div>
            <div class="d-flex gap-16">
                <div class="field w-100">
                    <label class="d-block">MT.IVOS12:</label>
                    <input type="number" class="w-100" formControlName="mt_ivos12" />
                    <!-- <div *ngIf="(cqFormSubmitted || fc['mt_ivos12'].touched) && fc['mt_ivos12'].errors" class="alert">
                        <div *ngIf="(cqFormSubmitted || fc['mt_ivos12'].touched)"> Please enter mt_ivos12</div>
                    </div> -->
                </div>
                <div class="field w-100">
                    <label class="d-block">MP.IVOS12:</label>
                    <input type="number" class="w-100" formControlName="mp_ivos12" />
                    <!-- <div *ngIf="(cqFormSubmitted || fc['mp_ivos12'].touched) && fc['mp_ivos12'].errors" class="alert">
                        <div *ngIf="(cqFormSubmitted || fc['mp_ivos12'].touched)"> Please enter mp_ivos12</div>
                    </div> -->
                </div>
            </div>
            <div class="d-flex gap-16">
                <div class="field w-100">
                    <label class="d-block">VM.IVOS12:</label>
                    <input type="number" class="w-100" formControlName="vm_ivos12" />
                    <!-- <div *ngIf="(cqFormSubmitted || fc['vm_ivos12'].touched) && fc['vm_ivos12'].errors" class="alert">
                        <div *ngIf="(cqFormSubmitted || fc['vm_ivos12'].touched)"> Please enter vm_ivos12</div>
                    </div> -->
                </div>
                <div class="field w-100">
                    <label class="d-block">OP.IVOS14:</label>
                    <input type="number" class="w-100" formControlName="op_ivos14" />
                    <!-- <div *ngIf="(cqFormSubmitted || fc['op_ivos14'].touched) && fc['op_ivos14'].errors" class="alert">
                        <div *ngIf="(cqFormSubmitted || fc['op_ivos14'].touched)"> Please enter op_ivos14</div>
                    </div> -->
                </div>
            </div>
            <div class="d-flex gap-16">
                <div class="field w-100">
                    <label class="d-block">MT.IVOS14:</label>
                    <input type="number" class="w-100" formControlName="mt_ivos14" />
                    <!-- <div *ngIf="(cqFormSubmitted || fc['mt_ivos14'].touched) && fc['mt_ivos14'].errors" class="alert">
                        <div *ngIf="(cqFormSubmitted || fc['mt_ivos14'].touched)"> Please enter mt_ivos14</div>
                    </div> -->
                </div>
                <div class="field w-100">
                    <label class="d-block">mp_ivos14:</label>
                    <input type="number" class="w-100" formControlName="mp_ivos14" />
                    <!-- <div *ngIf="(cqFormSubmitted || fc['mp_ivos14'].touched) && fc['mp_ivos14'].errors" class="alert">
                        <div *ngIf="(cqFormSubmitted || fc['mp_ivos14'].touched)"> Please enter mp_ivos14</div>
                    </div> -->
                </div>
            </div>
            <div class="d-flex gap-16">
                <div class="field w-100">
                    <label class="d-block">VM.IVOS14:</label>
                    <input type="number" class="w-100" formControlName="vm_ivos14" />
                    <!-- <div *ngIf="(cqFormSubmitted || fc['vm_ivos14'].touched) && fc['vm_ivos14'].errors" class="alert">
                        <div *ngIf="(cqFormSubmitted || fc['vm_ivos14'].touched)"> Please enter vm_ivos14</div>
                    </div> -->
                </div>
                <!-- <div class="field w-100">
                    <label class="d-block">sp100_ok:</label>
                    <input type="text" class="w-100" formControlName="sp100_ok" />
                    <div *ngIf="(cqFormSubmitted || fc['sp100_ok'].touched) && fc['sp100_ok'].errors" class="alert">
                        <div *ngIf="(cqFormSubmitted || fc['sp100_ok'].touched)"> Please enter sp100_ok</div>
                    </div>
                </div> -->
            </div>
            <div class="d-flex gap-16">
                <!-- <div class="field w-100">
                    <label class="d-block">sp1002_ok:</label>
                    <input type="text" class="w-100" formControlName="sp1002_ok" />
                    <div *ngIf="(cqFormSubmitted || fc['sp1002_ok'].touched) && fc['sp1002_ok'].errors" class="alert">
                        <div *ngIf="(cqFormSubmitted || fc['sp1002_ok'].touched)"> Please enter sp1002_ok</div>
                    </div>
                </div>
                <div class="field w-100">
                    <label class="d-block">z2_ok:</label>
                    <input type="text" class="w-100" formControlName="z2_ok" />
                    <div *ngIf="(cqFormSubmitted || fc['z2_ok'].touched) && fc['z2_ok'].errors" class="alert">
                        <div *ngIf="(cqFormSubmitted || fc['z2_ok'].touched)"> Please enter z2_ok</div>
                    </div>
                </div> -->
            </div>
            <div class="d-flex gap-16">
                <!-- <div class="field w-100">
                    <label class="d-block">ivos12_ok:</label>
                    <input type="text" class="w-100" formControlName="ivos12_ok" />
                    <div *ngIf="(cqFormSubmitted || fc['ivos12_ok'].touched) && fc['ivos12_ok'].errors" class="alert">
                        <div *ngIf="(cqFormSubmitted || fc['ivos12_ok'].touched)"> Please enter ivos12_ok</div>
                    </div>
                </div>
                <div class="field w-100">
                    <label class="d-block">ivos14_ok:</label>
                    <input type="text" class="w-100" formControlName="ivos14_ok" />
                    <div *ngIf="(cqFormSubmitted || fc['ivos14_ok'].touched) && fc['ivos14_ok'].errors" class="alert">
                        <div *ngIf="(cqFormSubmitted || fc['ivos14_ok'].touched)"> Please enter ivos14_ok</div>
                    </div>
                </div> -->
            </div>
            <div class="main-footer">
                <div class="delete">
					<i class="fa fa-trash customtrash" aria-hidden="true" (click)="openmodel()" ></i>
				</div>
                <div class="popup-footer">
                    <button class="button1 mr-1" type="submit">{{changeButtun?"Aggiorna":"SALVA"}}</button>
                    <button class="button1" (click)="closePopup()" type="button">ANNULLA</button>
                </div>
            </div>
        </form>
    </div>
</ng-template>