<app-loader *ngIf="loader"></app-loader>
<div class="overlay" id="overlay">
    <app-conform [message]="'Are you sure you want to delete?'" (confirm)="confirm($event)"></app-conform>

    <div class="header d-flex mt-2">
        <h1 class="semi-bold mb-1">L30 VCI</h1>
        <button class="button mr-1" (click)="openPopup();openPopup1()">Import</button>
    </div>
    <ag-grid-angular style="height: 750px;" class="ag-theme-alpine-dark basic-table" [rowData]="rowData$" [gridOptions]="gridOptions" (gridReady)="onGridReady($event)" >
    </ag-grid-angular>

    <div class="pagination">
        <app-pagination [dataLength]="totalCount" [tableName]="tableName" [itemPerPage]="itemPerPage"
        [columnName]="columnName" [filterValue]="filterValue" [sortingOrder]="sortingOrder"
            (dataOnPage)="receiveData($event)"></app-pagination>
    </div>

    <ng-template #popupContainer let-options>
        <div class="popup-window animate-in">
            <div class="form-container">
                <form [formGroup]="addL30Qs" (ngSubmit)="onSubmit()">
                    <div class="row" *ngIf="changeButtun">
                        <div class="column field">
                            <label>*cod_dna:</label>
                            <input type="text" formControlName="cod_dna" required />
                            <div *ngIf="(formSubmitted || fc['cod_dna'].touched) && fc['cod_dna'].errors" class="alert">
                                <div *ngIf="(formSubmitted || fc['cod_dna'].touched)"> Please enter cod dna </div>
                            </div>
                        </div>
                        <div class="column field">
                            <label>*cod_seme:</label>
                            <input type="number" formControlName="cod_seme" required />
                            <!-- <div *ngIf="(formSubmitted || fc['codice_az'].touched) && fc['codice_az'].errors" class="alert">
                                <div *ngIf="(formSubmitted || fc['codice_az'].touched)"> Please enter seme code name</div>
                            </div> -->
                        </div>
                    </div>
                    <div class="row">
                        <div class="column field">
                            <label>sequenziat:</label>
                            <!-- <select  (change)="selectSpecie($event)" class="specieSelect" formControlName="specie">
                                <option [value]="specie?.value" *ngFor="let specie of spaciesData;">{{specie?.name}}</option>
                            </select> -->
                            <input type="text" formControlName="sequenziat" />
                        </div>
                        <div class="column field" *ngIf="!changeButtun">
                            <label for="myfile">Select a file:</label>
                            <input type="file" id="myfile" formControlName="myfile" (change)="onFileSelected($event)"
                                accept=".txt">
                        </div>
                        <div class="column field" *ngIf="changeButtun">
                            <label>microstat:</label>
                            <input type="text" formControlName="microstat" />
                        </div>
                    </div>

                    <div class="row" *ngIf="changeButtun">
                        <div class="column field">
                            <label>al2_seme:</label>
                            <!-- <select id="yearSelect"  name="year" class="specieSelect2" (change)="selectYear($event)" formControlName="anno_rif">
                                <option *ngFor="let year of years" [value]="year">{{ year }}</option>
                            </select> -->
                            <input type="number" formControlName="al2_seme" />
                        </div>
                        <div class="column field">
                            <label>dif_al1:</label>
                            <input type="number" formControlName="dif_al1" />
                        </div>
                    </div>
                    <div class="row" *ngIf="changeButtun">
                        <div class="column field">
                            <label>dif_al2:</label>
                            <input type="number" formControlName="dif_al2" />
                        </div>
                        <div class="column field">
                            <label>vci:</label>
                            <input type="number" formControlName="vci" />
                        </div>
                    </div>
                    <div class="row" *ngIf="changeButtun">
                        <div class="column field">
                            <label>cod_cam:</label>
                            <input type="number" formControlName="cod_cam" />
                        </div>
                        <div class="column field">
                            <label>num_cam:</label>
                            <input type="number" formControlName="num_cam" />
                        </div>
                    </div>
                    <!-- <div class="row">
                        <div class="column field">
                            <label>a:</label>
                            <input type="number" formControlName="a" />
                        </div>
                        <div class="column field">
                            <label>b:</label>
                            <input type="text" formControlName="b" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="column field">
                            <label>c:</label>
                            <input type="text" formControlName="c" />
                        </div>
                        <div class="column field">
                            <label>d:</label>
                            <input type="number" formControlName="d" />
                        </div>
                    </div> -->
                    <div class="row" *ngIf="changeButtun">
                        <div class="column field">
                            <label>al1_seme:</label>
                            <input type="text" formControlName="al1_seme" />
                        </div>
                    </div>
                    <!-- <div class="popup-footer">
                        <div><button class="button danger" type="submit">{{changeButtun?'Update':'Save'}}</button></div>
                        <div><button class="button" type="button" (click)="closePopup()">Chiudi</button></div>
                    </div> -->

                    <div class="main-footer" [ngClass]="changeButtun?'main-footer':'main-footer1'">
                        <div class="delete" *ngIf="changeButtun">
                            <i class="fa fa-trash customtrash" aria-hidden="true" (click)="openmodel()"></i>
                        </div>
                        <div class="popup-footer">
                            <div><button class="button danger" type="submit">{{changeButtun?'Aggiorna':'Salva'}}</button>
                            </div>
                            <div><button class="button" type="button" (click)="closePopup()">Chiudi</button></div>
                        </div>
                    </div>

                </form>
            </div>

        </div>
    </ng-template>
</div>

<div *ngIf="showModal" class="modal-overlay" (click)="onCancelClick()">
    <div class="modal-content" (click)="onModalClick($event)">
        <p>Sei sicuro di voler aggiornare la registrazione</p>
        <button class="confirm-button mbutton" (click)="onOkClick()">Conferma</button>
        <button class="cancel-button mbutton" (click)="onCancelClick()">Annulla</button>
    </div>
</div>
