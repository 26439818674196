import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../_services/authentication.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  userPermision: any = [];
  allUserpermision: any = [];
  user: any;
  form: any = {
    username: null,
    new_password: null,
  };
  constructor(
    public authService: AuthenticationService,
    public toastr: ToastrService
  ) { }
  ngOnInit(): void {
    this.checkingPermision()
  }
  checkingPermision() {
    let data = sessionStorage.getItem("auth-user");
    if (data != null) {
      this.userPermision = JSON.parse(data);
      this.user = this.userPermision.data.user.email
    }
  }
  resetPassword() {
    let payload = {
      new_password: this.form.new_password
    }
    this.authService.changePassword(payload).subscribe((res: any) => {
      if (res.status == true) {
        this.toastr.success(res.message)
      }
    });

  }

}
