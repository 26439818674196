import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PaginationComponent } from "./pagination/pagination.component";
import { LoaderComponent } from './loader/loader.component';
import { SpeciedetailComponent } from './speciedetail/speciedetail.component';
import { FormsModule } from "@angular/forms";

@NgModule({
	declarations: [PaginationComponent, LoaderComponent, SpeciedetailComponent],
	imports: [CommonModule, FormsModule],
	exports: [PaginationComponent, LoaderComponent, SpeciedetailComponent, FormsModule],
})
export class SharedModule { }
