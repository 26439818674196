<!-- <app-loader *ngIf="loader"></app-loader> -->
<app-conform [message]="'Are you sure you want to delete?'" (confirm)="confirm($event)"></app-conform>
<div class="pl-2 pr-2 pb-2 pt-1">
	<h2 class="semi-bold">IN CORSO</h2>
	<div class="mt-1">
		<ag-grid-angular style="width: 100%; height: 600px" class="ag-theme-alpine-dark" [columnDefs]="columnDefs"
			[rowSelection]="'single'" [rowData]="rowData" [animateRows]="true" (cellClicked)="onCellClicked($event)"
			[gridOptions]="gridOptions">
		</ag-grid-angular>
		<div class="mt-1 text-end">
			<!-- <app-pagination [dataLength]="totalCount" [tableName]="tableName" [itemPerPage]="20"
				(dataOnPage)="receiveData($event)"></app-pagination> -->
		</div>
	</div>
	<h2 class="semi-bold mt-1">COMPLETATI</h2>
	<div class="mt-1">
		<ag-grid-angular style="width: 100%; height: 600px" class="ag-theme-alpine-dark" [columnDefs]="columnDefs1"
			[rowSelection]="'single'" [rowData]="populatedData" [animateRows]="true" (cellClicked)="onCellClicked($event)"
			[gridOptions]="gridOptions1">
		</ag-grid-angular>
		<div class="mt-1 text-end">
			<!-- <app-pagination [dataLength]="totalCount" [tableName]="tableName" [itemPerPage]="20"
				(dataOnPage)="receiveData($event)"></app-pagination> -->
		</div>
	</div>
</div>

<ng-template #popupContainer let-options>
	<div class="popup-window animate-in">
			<form [formGroup]="form" (ngSubmit)="onSubmit()">

			<div class="d-flex gap-16">
				<div class="field w-100">
					<label class="d-block">*CODICE CAMPIONAMENTO:</label>
					<input type="text" class="w-100" formControlName="codice_cam" required />
					<div *ngIf="(form?.controls['codice_cam']?.invalid) && (form?.controls['codice_cam']?.dirty || form?.controls['codice_cam']?.touched)"
					class="alert">
					<div *ngIf="form?.controls['codice_cam']?.hasError('required')">
						Please enter CODICE CAMPIONAMENTO
					</div>
				</div>
				</div>
				<div class="field w-100">
					<label  class="d-block">CODICE CORRIERE:</label>
					<input type="text" class="w-100"  formControlName="codice_cor" required />
				</div>
			</div>

			<div class="d-flex gap-16">
				<div class="field w-100">
					<label  class="d-block">DATA ARRIVO CORRIERE:</label>
					<input type="date" class="w-100"  formControlName="cor_data"/>
				</div>
				<div class="field w-100">
					<label  class="d-block">CODICE TANK CONSEGNATO:</label>
					<input type="number" class="w-100"  formControlName="cor_tank"/>
				</div>
			</div>

			<div class="d-flex gap-16">
				<div class="field w-100">
					<label  class="d-block">DATA RICEVIMENTO TANK:</label>
					<input type="date" class="w-100"  formControlName="ric_data"/>
				</div>
				<div class="field w-100">
					<label  class="d-block">OPERATORE CHE RICEVE TANK:</label>
					<input type="number" class="w-100"  formControlName="ric_oper"/>
				</div>
			</div>

			<div class="d-flex gap-16">
				<div class="field w-100">
					<label  class="d-block">TEMPERATURA TANK (C°):</label>
					<input type="number" class="w-100"  formControlName="ric_temp"/>
				</div>
				<div class="field w-100">
					<label  class="d-block">MAGAZZINO TANK:</label>
					<input type="number" class="w-100"  formControlName="tank_mag"/>
				</div>
			</div>

			<div class="d-flex gap-16">
				<div class="field w-100">
					<label  class="d-block">MAGAZZINO CEST:</label>
					<input type="number" class="w-100"  formControlName="cest_mag"/>
				</div>
				<div class="field w-100">
					<label  class="d-block">MAGAZZINO PIANO:</label>
					<input type="number" class="w-100"  formControlName="piano_mag"/>
				</div>
			</div>

			<div class="d-flex gap-16">
				<div class="field w-100">
					<label  class="d-block">MAGAZZINO TANK 2:</label>
					<input type="number" class="w-100"  formControlName="tank2mag"/>
				</div>
				<div class="field w-100">
					<label  class="d-block">MAGAZZINO CEST 2:</label>
					<input type="number" class="w-100"  formControlName="cest2mag"/>
				</div>
			</div>

			<div class="d-flex gap-16">
				<div class="field w-100">
					<label  class="d-block">MAGAZZINO PIANO 2:</label>
					<input type="number" class="w-100"  formControlName="piano2mag"/>
				</div>
				<div class="field w-100">
					<label  class="d-block">NOTE:</label>
					<input type="text" class="w-100"  formControlName="note"/>
				</div>
			</div>
			<div class="d-flex gap-16">
				<div class="field w-100">
					<label  class="d-block">TEMPERATURA:</label>
					<input type="number" class="w-100"  formControlName="ric_azoto"/>
				</div>
			</div>
			<!-- <div class="popup-footer">
				<div><button class="button danger" type="submit">Update</button></div>
				<div><button class="button" type="button" (click)="closePopup()">Chiudi</button></div>
			</div> -->

			<div class="main-footer">
				<div class="delete">
					<i class="fa fa-trash customtrash" aria-hidden="true" (click)="openmodel()"></i>
				</div>
				<div class="popup-footer">
					<div><button class="button danger" type="submit">Aggiorna</button></div>
					<div><button class="button" type="button" (click)="closePopup()">Chiudi</button></div>
				</div>
			</div>
		</form>
		</div>
</ng-template>