import { Component } from "@angular/core";
import { AgGridAngular } from "ag-grid-angular";
import {
  CellClickedEvent,
  ColDef,
  GridReadyEvent,
  RowSelectedEvent,
  Grid,
  GridOptions,
} from "ag-grid-community";
import { ApiService } from "../_services/api.service";
import { Toro } from "../_models/index";

interface Response {
  success: boolean;
  affected_rows: number;
  result: Toro[];
}
var rowData: Toro[] = [];
const columnDefs: ColDef<Toro>[] = [
  { field: "toro" },
  { field: "matricola" },
  { field: "matricola2" },
  { field: "specie" },
  { field: "razza" },
  { field: "cod_dna" },
  { field: "data_nasc" },
  { field: "provato" },
  { field: "top100" },
  { field: "matric_ana" },
];
const defaultColDef: ColDef = {
  flex: 1,
  resizable: true,
  sortable: true,
};
const gridOptions: GridOptions<Toro> = {
  columnDefs: columnDefs,
  defaultColDef: defaultColDef,
  rowSelection: "single",
  animateRows: true,
  rowModelType: "infinite",
};

@Component({
  selector: "app-riproduttori",
  templateUrl: "./riproduttori.component.html",
  styleUrls: ["./riproduttori.component.scss"],
})
export class RiproduttoriComponent {
  constructor(private http: ApiService) {
    // var gridDiv = document.querySelector<HTMLElement>("#myGrid")!;
    // new Grid(gridDiv, gridOptions);
  }

  ngOnInit(): void {
    var gridDiv = document.querySelector<HTMLElement>("#myGrid")!;
    new Grid(gridDiv, gridOptions);
    this.http.retrieveRiproduttori().subscribe({
      next: (data:any) => {
        var d = data;
        gridOptions.api!.setRowData(d.result);
      },
      error: (err) => {
      },
    });
  }

  onCellClicked(e: CellClickedEvent): void {
    
  }
}
