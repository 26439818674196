import { Component, ElementRef, OnInit, Renderer2, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ApiService } from '../_services/api.service';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { ColDef, ColumnApi, GridOptions, SortDirection } from 'ag-grid-community';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalService } from '../_services/modal.service';
import { takeWhile } from 'rxjs';

@Component({
	selector: 'app-lotti-section',
	templateUrl: './lotti-section.component.html',
	styleUrls: ['./lotti-section.component.scss']
})
export class LottiSectionComponent {
	cqTableData$: any[];
	defaultColDef: ColDef = { resizable: true, sortable: true, filter: true, sortingOrder: ['asc', 'desc'] };
	sortingOrder: string = '';
	columnApi: ColumnApi;
	columnName: string = '';
	changeButtun: boolean = false;
	message: string = ''
	cqColDefs = [
		{
			field: "cod_toro",
			headerName: "cod_toro",
			width: 300,
			sort: 'asc' as SortDirection
		},
		{
			field: "data_prot",
			headerName: "data_prot",
			width: 300,
			comparator: this.dateComparator,

		},
		{
			field: "centri",
			headerName: "codice_az",
			cellRenderer: (params: any) => {
				return `${params?.data?.centri.denominaz == null ? 'N/A' : params?.data?.centri.denominaz}`
			}
			// width: 150,
		},
		// {
		// 	field: "specie",
		// 	headerName: "specie",
		// 	// width: 150,
		// },
		// {
		// 	field: "anno",
		// 	headerName: "anno",
		// 	// width: 150,
		// },
		{
			field: "toro",
			headerName: "toro",
			width: 300,
		},

		{
			field: "lotto",
			headerName: "lotto",
			width: 300,
		},
		{
			field: "dosi",
			headerName: "dosi",
			width: 300,
			valueParser: (params) => Number(params.data.dosi),
			comparator: (valueA, valueB) => Number(valueA) - Number(valueB),
			filter: 'agNumberColumnFilter'
		},
		{
			field: "prov",
			headerName: "prov",
			width: 320,
		},

	];
	GridOptions: GridOptions<any> = {
		columnDefs: this.cqColDefs,
		defaultColDef: this.defaultColDef,
		rowSelection: "single",
		animateRows: true,
		pagination: true,
		paginationAutoPageSize: true,
	};

	tableName: string = 'lotti';
	itemPerPage: number = 14;
	pageNumber: number = 1;
	totalCount: number = 1;
	loader: boolean = false;
	cqForm: FormGroup;
	cqFormSubmitted: boolean = false;

	@ViewChild("PopupContainer", { read: TemplateRef })
	PopupContainer: TemplateRef<any>;
	view: any;
	currentYear: string = '';
	clickedData: any;
	centriCodiceAz: string | number;
	specie: string;
	anno: string | number;
	isAlive = true;
	subjectValue: boolean = false;
	modelType: string = '';

	constructor(public apiService: ApiService, private toasterService: ToastrService, private http: HttpClient,
		private viewContainerRef: ViewContainerRef, private formBuilder: FormBuilder, private modalService: ModalService, private renderer: Renderer2, private elementRef: ElementRef) {

		this.cqForm = this.formBuilder.group({
			data_prot: [null, Validators.required],
			cod_toro: [null],
			codice_az: [null],
			specie: [null],
			anno: [null, Validators.required],
			toro: [null],
			lotto: [null],
			dosi: [null],
			prov: [null]
		})
		this.GridOptions.onSortChanged = this.onSortChanged.bind(this);
	}

	ngOnInit(): void {
		this.apiService.current.pipe(takeWhile(() => this.isAlive)).subscribe((res) => {
			if (res) {
				this.subjectValue = true
				this.centriCodiceAz = res.codice_az ? res.codice_az : localStorage.getItem('centriCodiceAz')
				this.specie = res.specie ? res.specie : localStorage.getItem('specie');
				this.anno = res.anna ? res.anna : localStorage.getItem('anna')
				this.getLottiData();
			}
		})
		this.getLottiData();
	}
	ngOnDestroy() {
		this.isAlive = false;
		this.apiService.redirectButton.next(false)
	}
	dateComparator(date1, date2) {
		const dateParts1 = date1.split('/');
		const dateParts2 = date2.split('/');

		// Create date objects in the format YYYY-MM-DD
		const d1: any = new Date(dateParts1[2], dateParts1[1] - 1, dateParts1[0]);
		const d2: any = new Date(dateParts2[2], dateParts2[1] - 1, dateParts2[0]);

		return d1 - d2;
	}

	onSortChanged(event) {
		const sortedColumns: any = this.columnApi.getAllColumns().filter(column => column.getSort())[0];
		this.sortingOrder = sortedColumns?.getSort() ?? '';
		this.columnName = sortedColumns?.getColDef().field ?? '';
		this.getLottiData();
	}

	onGridReady(params) {
		this.columnApi = params.columnApi;
	}

	onSubmitCqForm() {
		this.modelType = 'submit';
		this.message = 'Sei sicuro di voler Aggiorna il seguente record?'
		this.modalService.openModal("lotti");
	}

	// onReceiveData(event: any) {
	// 	if (event?.table === this.tableName) {
	// 		this.cqTableData$ = event?.data?.result.rows;
	// 		this.totalCount = event?.data?.totalCount;
	// 		this.pageNumber = event?.page
	// 	}
	// }

	getLottiData() {
		this.loader = true;
		if (!this.subjectValue) {
			this.centriCodiceAz = localStorage.getItem('centriCodiceAz') ? localStorage.getItem('centriCodiceAz') : 1
			this.specie = localStorage.getItem('specie') ? localStorage.getItem('specie') : "BI"
			this.anno = localStorage.getItem('anna') ? localStorage.getItem('anna') : 2022
		}
		this.subjectValue = false
		this.apiService.getL30lotti(this.centriCodiceAz, this.anno, this.specie).subscribe({
			next: (data) => {
				if (data.status) {
					this.totalCount = data?.result?.count;
					this.cqTableData$ = data?.result?.rows ? data?.result?.rows : [];
				} else {
					this.toasterService.error(data.message);
				}
				this.loader = false
			},
			error: (err) => {
				this.cqTableData$ = [];
				this.loader = false
				this.toasterService.error(err.message)
			}
		});
	}

	get fc() { return this.cqForm.controls; }

	openCqFormPopup() {
		this.apiService.modaBackDrop.next(true);
		const bodyElement = this.elementRef.nativeElement.ownerDocument.body;
		this.renderer.setStyle(bodyElement, 'overflow', 'hidden');
		this.view = this.viewContainerRef.createEmbeddedView(this.PopupContainer);
		const domElem = this.view.rootNodes[0] as HTMLElement;
		domElem.classList.add("animate-in");
	}

	closePopup() {
		this.cqForm.reset();
		this.changeButtun = false
		const bodyElement = this.elementRef.nativeElement.ownerDocument.body;
		this.renderer.setStyle(bodyElement, 'overflow', 'auto');
		const domElem = this.view.rootNodes[0] as HTMLElement;
		domElem.classList.add("animate-out");
		domElem.addEventListener("click", (event: AnimationEvent) => {
			this.viewContainerRef.clear();
			this.apiService.modaBackDrop.next(false);
		});
	}
	formatDate1(inputDate): any {
		if (inputDate != null) {
			const [year, month, day] = inputDate.split('-');

			// Create the formatted date string in dd/mm/yyyy format
			const formattedDate = `${day}/${month}/${year}`;

			return formattedDate;
		}
	}


	formatDate(date): any {
		if (date !== 'NULL' && date !== null) {
			const dateComponents = date.split('/');
			const formattedDate = new Date(`${dateComponents[2]}-${dateComponents[1]}-${dateComponents[0]}`);

			if (!isNaN(formattedDate.getTime())) {
				// Check if the formattedDate is a valid Date object
				const timeZoneOffset = formattedDate.getTimezoneOffset() * 60000;
				const adjustedDate = new Date(formattedDate.getTime() - timeZoneOffset);
				const formattedDateString = adjustedDate.toISOString().split('T')[0];
				return formattedDateString;
			} else {
				// Handle invalid date
				return 'Invalid Date';
			}
		}
	}
	onCellClicked(event: any) {
		this.changeButtun = true;
		this.clickedData = event.data;
		let data = this.formatDate(this.clickedData.data_prot)
		this.openCqFormPopup();
		this.cqForm.patchValue({
			data_prot: data,
			cod_toro: this.clickedData.cod_toro,
			codice_az: this.clickedData.codice_az,
			specie: this.clickedData.specie,
			anno: this.clickedData.anno,
			toro: this.clickedData.toro,
			lotto: this.clickedData.lotto,
			dosi: this.clickedData.dosi,
			prov: this.clickedData.prov,
		})
	}

	openmodel() {
		this.modelType = 'delete';
		this.message = 'Sei sicuro di voler cancellare il seguente record?'
		this.modalService.openModal("lotti");

		// const fvalue = this.clickedData;
		// const paylod = { codice_az: fvalue.codice_az, specie: fvalue.specie, anno: fvalue.anno, lotto: fvalue.lotto, cod_toro: fvalue.cod_toro, }
		// this.apiService.lottiDelete(paylod).subscribe((res: any) => {
		// 	if (res?.status) {
		// 		this.toasterService.success('Dati eliminati con successo');
		// 		this.closePopup();
		// 		this.getLottiData();
		// 	} else {
		// 		this.message = res?.message;
		// 		this.modalService.openModal("lotti");
		// 	}
		// }, error => { this.toasterService.error('qualcosa è andato storto!')});

	}

	confirm(event) {

		if (this.modelType === 'delete') {
			const fvalue = this.clickedData;
			const paylod = { codice_az: fvalue.codice_az, specie: fvalue.specie, anno: fvalue.anno, lotto: fvalue.lotto, cod_toro: fvalue.cod_toro, }
			if (event?.modal == 'lotti') {
				this.apiService.lottiDelete(paylod).subscribe((res: any) => {
					if (res?.status) {
						this.toasterService.success('Dati eliminati con successo');
						this.closePopup();
						this.getLottiData();
					} else {
						this.message = res?.message;
						this.modalService.openModal("deletelotti");
					}
				}, error => { this.toasterService.error('qualcosa è andato storto!') });
			}

			if (event?.modal == 'deletelotti') {
				this.apiService.lottiDeleteLotti(paylod).subscribe((res: any) => {
					if (res?.status) {
						this.toasterService.success('Dati eliminati con successo');
						this.closePopup();
						this.getLottiData();
					}
				}, error => { this.toasterService.error('qualcosa è andato storto!') });
			}

			// this.apiService.lottiDelete(paylod).subscribe((res: any) => {
			// 	if (!res?.status) {
			// 		this.toasterService.success('Dati eliminati con successo');
			// 		this.closePopup();
			// 		this.getLottiData();
			// 	} else {
			// 		this.message = "res?.message";
			// 		this.modalService.openModal("deletelotti");
			// 		this.apiService.lottiDeleteLotti(paylod).subscribe((res: any) => {
			// 			if (res?.status) {
			// 				this.toasterService.success('Dati eliminati con successo');
			// 				this.closePopup();
			// 				this.getLottiData();
			// 			}
			// 		}, error => { this.toasterService.error('qualcosa è andato storto!') });

			// 	}
			// }, error => { this.toasterService.error('qualcosa è andato storto!') });

		}

		if (this.modelType === 'submit') {
			this.updateLotti();
		}

	}

	deleteLottiRow(id) {
		this.apiService.deleteCq(id).subscribe((res: any) => {
			if (res?.success) {
				this.toasterService.success(res?.description)
				this.closePopup()
				this.getLottiData()
			}
		})
	}

	updateLotti() {
		this.cqFormSubmitted = true;
		if (this.cqForm.invalid) {
			return;
		}


		let data = this.formatDate1(this.cqForm.value.data_prot)
		let new_lotto = this.cqForm.value.lotto
		let old_lotto = this.clickedData.lotto
		this.apiService.updateLottiData({ ...this.cqForm.value, data_prot: data, id: this.clickedData.id, new_lotto: new_lotto, old_lotto: old_lotto }).subscribe({
			next: (data: any) => {
				if (data.status) {
					this.toasterService.success(data.message);
					this.closePopup();
					this.getLottiData();
				} else {
					this.toasterService.error(data.message);
				}
				this.loader = false
			},
			error: (err) => {
				this.toasterService.error(err.message)
			}
		});
	}
}
