import { CustomButtonRendererComponent1 } from './../table-test/custom-button-renderer.component';
import { Component, ElementRef, Renderer2, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ApiService } from '../_services/api.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { GridOptions } from 'ag-grid-community';
import { CustomButtonRendererComponent } from '../table-test/custom-button-renderer.component';
import { ModalService } from '../_services/modal.service';

@Component({
	selector: 'app-courier-management',
	templateUrl: './courier-management.component.html',
	styleUrls: ['./courier-management.component.scss']
})
export class CourierManagementComponent {
	dnaTableData = [];
	populatedData:any[];
	paginationPageSize = 15;
	totalCount: any;
	changeButtun: boolean = false;
	rowData:any[];
	tableName: string = "courier-management";
	view;
	timeRange: string = "00.00/00.00"; // Default time range
	invalidTimeRange: boolean = false;
	p: number = 1;
	popup = false;
	form: FormGroup;
	loader: boolean = false;

	@ViewChild("popupContainer", { read: TemplateRef })
	popupContainer: TemplateRef<any>;
	rowId: any;
	codeic: string | number;
	code: string;
	columnDefs = [
		{
			field: "codice_cam",
			headerName: "CAMPIONAMENTO",
			width: 250,
			cellRenderer: (params: any) => {
				return `${params.data.codice_cam == '' || params.data.codice_cam == null ? 'N/A' : params.data.codice_cam}`;
			},
		},
		{
			field: "cest2mag",
			headerName: "MAGAZZINO CEST 2",
			width: 250,
			valueParser: (params) => Number(params?.data?.cest2mag),
			comparator: (valueA, valueB) => Number(valueA) - Number(valueB),
			filter: 'agNumberColumnFilter',
			cellRenderer: (params: any) => {
				return `${params.data.cest2mag == '' || params.data.cest2mag == null ? 'N/A' : params.data.cest2mag}`;
			},
		},
		{
			field: "cest_mag",
			headerName: "MAGAZZINO CEST",
			width: 250,
			cellRenderer: (params: any) => {
				return `${params.data.cest_mag == '' ||params.data.cest_mag == null ? 'N/A' : params.data.cest_mag}`;
			},
		},
		{
			field: "codice_cor",
			headerName: "CORRIERE",
			width: 160,
			valueParser: (params) => Number(params?.data?.codice_cor),
			comparator: (valueA, valueB) => Number(valueA) - Number(valueB),
			filter: 'agNumberColumnFilter',
			cellRenderer: (params: any) => {
				return `${params.data.codice_cor == '' ||params.data.codice_cor == null ? 'N/A' : params.data.codice_cor}`;
			},
		},
		{
			field: "cor_data",
			headerName: "DATA ARRIVO",
			comparator: this.dateComparator,
			cellRenderer: (params: any) => {
				return `${params.data.cor_data == 'undefined/undefined/' ||params.data.cor_data == null ? 'N/A' : params.data.cor_data}`;
			},
		},
		{
			field: "cor_tank",
			headerName: "TANK CONSEGNATO",
			width: 160,
			valueParser: (params) => Number(params?.data?.cor_tank),
			comparator: (valueA, valueB) => Number(valueA) - Number(valueB),
			filter: 'agNumberColumnFilter',
			cellRenderer: (params: any) => {
				return `${params.data.cor_tank == ''||params.data.cor_tank == null ? 'N/A' : params.data.cor_tank}`;
			},
		},
		// {
		// 	field: "fax_data",
		// 	headerName: "fax_data",
		// 	width: 160,
		// 	cellRenderer: (params: any) => {
		// 		return `${params.data.fax_data == 'undefined/undefined/' ||params.data.fax_data == null ? 'N/A' : params.data.fax_data}`;
		// 	},
		// },
		// {
		// 	field: "fax_oper",
		// 	headerName: "fax_oper",
		// 	width: 160,
		// 	cellRenderer: (params: any) => {
		// 		return `${params.data.fax_oper == '' ||params.data.fax_oper == null ? 'N/A' : params.data.fax_oper}`;
		// 	},
		// },
		{
			field: "ric_azoto",
			headerName: "TEMPERATURA",
			width: 160,
			valueParser: (params) => Number(params?.data?.ric_azoto),
			comparator: (valueA, valueB) => Number(valueA) - Number(valueB),
			filter: 'agNumberColumnFilter',
			cellRenderer: (params: any) => {
				return `${params.data.ric_azoto == '' ||params.data.ric_azoto == null ? 'N/A' : params.data.ric_azoto}`;
			},
		},
		{
			field: "ric_data",
			headerName: "DATA RICEVIMENTO TANK",
			width: 160,
			comparator: this.dateComparator,
			cellRenderer: (params: any) => {
				return `${params.data.ric_data == 'undefined/undefined/' ||params.data.ric_data == null ? 'N/A' : params.data.ric_data}`;
			},
		},
		{
			field: "ric_oper",
			headerName: "OP RIC TANK",
			valueParser: (params) => Number(params?.data?.ric_oper),
			comparator: (valueA, valueB) => Number(valueA) - Number(valueB),
			filter: 'agNumberColumnFilter',
			width: 210,
			
			cellRenderer: (params: any) => {
				return `${params.data.ric_oper == '' ||params.data.ric_oper == null? 'N/A' : params.data.ric_oper}`;
			},
		},
		{
			field: "ric_temp",
			headerName: "TEMPERATURA TANK (C°)",
			valueParser: (params) => Number(params?.data?.ric_temp),
			comparator: (valueA, valueB) => Number(valueA) - Number(valueB),
			filter: 'agNumberColumnFilter',
		},
		{
			field: "tank_mag",
			headerName: "MAGAZZINO TANK",
			valueParser: (params) => Number(params?.data?.tank_mag),
			comparator: (valueA, valueB) => Number(valueA) - Number(valueB),
			filter: 'agNumberColumnFilter',
		},
		{
			field: "piano_mag",
			headerName: "MAGAZZINO PIANO",
		
		},
		{
			field: "tank2mag",
			headerName: "MAGAZZINO TANK 2",
		},
		{
			field: "piano2mag",
			headerName: "MAGAZZINO PIANO 2",
		},
		{
			field: "note",
			headerName: "NOTE",
		},
	];
	columnDefs1 = [
		{
			field: "codice_cam",
			headerName: "CAMPIONAMENTO",
			cellRenderer: (params: any) => {
				return `${params.data.codice_cam == '' || params.data.codice_cam == null ? 'N/A' : params.data.codice_cam}`;
			},
		},
		{
			field: "cest2mag",
			headerName: "MAGAZZINO CEST 2",
			valueParser: (params) => Number(params?.data?.cest2mag),
			comparator: (valueA, valueB) => Number(valueA) - Number(valueB),
			filter: 'agNumberColumnFilter',
			cellRenderer: (params: any) => {
				return `${params.data.cest2mag == '' || params.data.cest2mag == null ? 'N/A' : params.data.cest2mag}`;
			},
		},
		{
			field: "cest_mag",
			headerName: "MAGAZZINO CEST",
			cellRenderer: (params: any) => {
				return `${params.data.cest_mag == '' ||params.data.cest_mag == null ? 'N/A' : params.data.cest_mag}`;
			},
		},
		{
			field: "codice_cor",
			headerName: "CORRIERE",
			width: 160,
			valueParser: (params) => Number(params?.data?.codice_cor),
			comparator: (valueA, valueB) => Number(valueA) - Number(valueB),
			filter: 'agNumberColumnFilter',
			cellRenderer: (params: any) => {
				return `${params.data.codice_cor == '' ||params.data.codice_cor == null ? 'N/A' : params.data.codice_cor}`;
			},
		},
		{
			field: "cor_data",
			headerName: "DATA ARRIVO",
			comparator: this.dateComparator,
			cellRenderer: (params: any) => {
				return `${params.data.cor_data == 'undefined/undefined/' ||params.data.cor_data == null ? 'N/A' : params.data.cor_data}`;
			},
		},
		{
			field: "cor_tank",
			headerName: "TANK CONSEGNATO",
			valueParser: (params) => Number(params?.data?.cor_tank),
			comparator: (valueA, valueB) => Number(valueA) - Number(valueB),
			filter: 'agNumberColumnFilter',
			cellRenderer: (params: any) => {
				return `${params.data.cor_tank == ''||params.data.cor_tank == null ? 'N/A' : params.data.cor_tank}`;
			},
		},
		// {
		// 	field: "fax_data",
		// 	headerName: "fax_data",
		// 	width: 160,
		// 	cellRenderer: (params: any) => {
		// 		return `${params.data.fax_data == 'undefined/undefined/' ||params.data.fax_data == null ? 'N/A' : params.data.fax_data}`;
		// 	},
		// },
		// {
		// 	field: "fax_oper",
		// 	headerName: "fax_oper",
		// 	width: 160,
		// 	cellRenderer: (params: any) => {
		// 		return `${params.data.fax_oper == '' ||params.data.fax_oper == null ? 'N/A' : params.data.fax_oper}`;
		// 	},
		// },
		{
			field: "ric_azoto",
			headerName: "TEMPERATURA",
			valueParser: (params) => Number(params?.data?.ric_azoto),
			comparator: (valueA, valueB) => Number(valueA) - Number(valueB),
			filter: 'agNumberColumnFilter',
			width: 160,
			cellRenderer: (params: any) => {
				return `${params.data.ric_azoto == '' ||params.data.ric_azoto == null ? 'N/A' : params.data.ric_azoto}`;
			},
		},
		{
			field: "ric_data",
			headerName: "DATA RICEVIMENTO TANK",
			width: 160,
			comparator: this.dateComparator,
			cellRenderer: (params: any) => {
				return `${params.data.ric_data == 'undefined/undefined/' ||params.data.ric_data == null ? 'N/A' : params.data.ric_data}`;
			},
		},
		{
			field: "ric_oper",
			headerName: "OP RIC TANK",
			valueParser: (params) => Number(params?.data?.ric_oper),
			comparator: (valueA, valueB) => Number(valueA) - Number(valueB),
			filter: 'agNumberColumnFilter',
			width: 210,
			cellRenderer: (params: any) => {
				return `${params.data.ric_oper == '' ||params.data.ric_oper == null? 'N/A' : params.data.ric_oper}`;
			},
		},
		{
			field: "ric_temp",
			headerName: "TEMPERATURA TANK (C°)",
			valueParser: (params) => Number(params?.data?.ric_temp),
			comparator: (valueA, valueB) => Number(valueA) - Number(valueB),
			filter: 'agNumberColumnFilter',
			
		},
		{
			field: "tank_mag",
			headerName: "MAGAZZINO TANK",
		},
		{
			field: "piano_mag",
			headerName: "MAGAZZINO PIANO",
		},
		{
			field: "tank2mag",
			headerName: "MAGAZZINO TANK 2",
		},
		{
			field: "piano2mag",
			headerName: "MAGAZZINO PIANO 2",
		},
		{
			field: "note",
			headerName: "NOTE",
		},
		// {
		// 	field: "Action",
		// 	headerName: "AZIONE",
		// 	width: 120,
		// 	// flex: 1,
		// 	cellRenderer: 'customButtonRenderer',
		// 	cellRendererParams: {
		// 		tableName: 'courier-management1',
		// 		// onClick: this.onButtonClicked.bind(this), // Define your custom click handler
		// 	},
		// },
	];
	clickedId: any;
	dateObject: any = {};
	deleteId: any;

	constructor(public apiService: ApiService, private router: Router, private viewContainerRef: ViewContainerRef, private formBuilder: FormBuilder, public toast: ToastrService, private modalService: ModalService,private renderer: Renderer2,
		private elementRef: ElementRef) {
		this.form = this.formBuilder.group({
			codice_cam: [null, Validators.required],
			codice_cor: [null],
			cor_data: [null],
			cor_tank: [null],
			ric_data: [null],
			ric_oper: [null],
			ric_temp: [null],
			tank_mag: [null],
			cest_mag: [null],
			piano_mag: [null],
			tank2mag: [null],
			cest2mag: [null],
			piano2mag: [null],
			note: [null],
			ric_azoto:[null]

		});
		this.apiService.delete.subscribe((res) => {
			this.deleteId = res?.data
			if ((res?.data?.id) && (res?.table === 'courier-management')) {
				this.modalService.openModal('courier-management');
				// this.modalService.isConfirmed$.pipe(take(1)).subscribe((con: any) => {
				// 	if (con?.modal === 'courier-management' && con?.value) {
				// 		// this.loader = false
				// 		this.onButtonClicked(res?.data);
				// 	}
				// });
			}else if ((res?.data?.id) && (res?.table === 'courier-management1')) {
				this.modalService.openModal('courier-management1');
				// this.modalService.isConfirmed$.pipe(take(1)).subscribe((con: any) => {
				// 	if (con?.modal === 'courier-management1' && con?.value) {
				// 		// this.loader = false
				// 		this.onButtonClicked(res?.data);
				// 	}
				// });
			}
		})
	}
	defaultColDef = {
		// flex: 1,
		resizable: true,
		sortable: true,
		filter: true,
	};
	gridOptions: GridOptions = {
		defaultColDef: this.defaultColDef,
		pagination:true,
		rowSelection: "single",
		animateRows: true,
		paginationAutoPageSize:true,
		frameworkComponents: {
			customButtonRenderer: CustomButtonRendererComponent,CustomButtonRendererComponent1 // Register the custom cell renderer
		},
	}
	gridOptions1: GridOptions = {
		defaultColDef: this.defaultColDef,
		pagination:true,
		rowSelection: "single",
		animateRows: true,
		paginationAutoPageSize:true,
		frameworkComponents: {
			customButtonRenderer: CustomButtonRendererComponent,CustomButtonRendererComponent1 // Register the custom cell renderer
		},
	}

	ngOnInit(): void {
		this.getCourierData();
	}

	openmodel(){
		this.modalService.openModal('courier-management');
	}
	confirm(event){
		// if (event?.modal == "courier-management" && event?.value) {
			this.onButtonClicked(this.clickedId);
		// }else if(event?.modal == "courier-management1" && event?.value){
		// 	this.onButtonClicked(this.deleteId);
		// }

	}
	onCellClicked(event: any) {
		// this.changeButtun = true;
		const data = event.data;
		this.clickedId = event.data
		this.openPopup()

		// const date1 = this.formatDate(data.data_campl)
		// const date2 = this.formatDate(data.data_camp2)
		const date1 = this.formatDate(data.cor_data)
		const date2 = this.formatDate(data.fax_data)
		const date3 = this.formatDate(data.ric_data)
		this.dateObject = {
			date: date1,
			date2: date2,
			// date3: date3
		}
		
		this.form.patchValue({
			codice_cam: data.codice_cam,
			codice_cor: data.codice_cor,
			cor_data: date1,
			cor_tank: data.cor_tank,
			ric_data: date3,
			ric_oper: data.ric_oper,
			ric_temp: data.ric_temp,
			tank_mag: data.tank_mag,
			cest_mag: data.cest_mag,
			piano_mag: data.piano_mag,
			tank2mag: data.tank2mag,
			cest2mag: data.cest2mag,
			piano2mag: data.piano2mag,
			note: data.note,
			ric_azoto:data.ric_azoto
		});
		// this.rowId = data.id
		// this.openPopup()
		// this.router.navigate(["/dna-table/micropipetta"], { queryParams: { id_lab: data.id_lab } });
	}
	getCourierData(page = 1, limit = this.paginationPageSize) {
		this.loader = true

		this.apiService.getCourierAllData(page, limit).subscribe({
			next: (data) => {
				if (data.success) {
					this.loader = false
					this.dnaTableData = data.result.notPopulatedData.result;
					this.populatedData = data.result.populatedData.result;
					this.totalCount = data.totalCount;
					this.rowData = this.dnaTableData;
				}else{
					this.loader = false;
				  }
			},
		});
	}

	receiveData(event: any) {
		if (event?.table === this.tableName) {
			this.rowData = event?.data?.result?.rows;
		}
	}
	openPopup() {
		// this.code = localStorage.getItem('centriCodiceAz')
		// this.form.controls['codice_az'].setValue(this.code)
		this.apiService.modaBackDrop.next(true);
		const bodyElement = this.elementRef.nativeElement.ownerDocument.body;
		this.renderer.setStyle(bodyElement, 'overflow', 'hidden');
		this.view = this.viewContainerRef.createEmbeddedView(this.popupContainer);
		const domElem = this.view.rootNodes[0] as HTMLElement;
		domElem.classList.add("animate-in");
	}
	closePopup() {
		this.form.reset()
		const bodyElement = this.elementRef.nativeElement.ownerDocument.body;
		this.renderer.setStyle(bodyElement, 'overflow', 'auto');
		this.changeButtun = false
		const domElem = this.view.rootNodes[0] as HTMLElement;
		domElem.classList.add("animate-out");

		// Ascoltare l'evento di fine animazione
		domElem.addEventListener("click", (event: AnimationEvent) => {
			// Rimuovere l'elemento dal DOM
			this.viewContainerRef.clear();
			// this.gridOptions.api.deselectAll();
			this.apiService.modaBackDrop.next(false);
		});

		// this.viewContainerRef.clear();
	}
 	onSubmit() {
		this.loader = true;
		const date1 = this.formatDateUpdate(this.form.value.cor_data)
		const date2 = this.formatDateUpdate(this.form.value.fax_data)
		const date3 = this.formatDateUpdate(this.form.value.ric_data)
		let payload = { ...this.form.value, id: this.clickedId.id, cor_data: date1, fax_data: date2,ric_data:date3 }
		this.apiService.updateCourierData(payload).subscribe((res: any) => {
			if (res.success == true) {
				this.loader = false
				this.toast.success(res.description)
				this.closePopup()
				this.getCourierData()
			}
		})
	}
	onButtonClicked(data) {
		this.loader = true;
		let payload = {
			id: data.id
		}
		this.apiService.deleteCourierData(payload).subscribe((res: any) => {
			if (res.success == true) {
				this.closePopup()
				this.loader = false;
				this.getCourierData()
				this.toast.success(res.description)
			}else{
				this.loader = false;
				this.getCourierData()
			}
		})
	}
	formatDate(date):any {
		if (date !== 'NULL' && date !== null && !date.includes('N/A')) {
			const dateComponents = date.split('/');
			const formattedDate = new Date(`${dateComponents[2]}-${dateComponents[1]}-${dateComponents[0]}`);
			
			if (!isNaN(formattedDate.getTime())) {
				// Check if the formattedDate is a valid Date object
				const timeZoneOffset = formattedDate.getTimezoneOffset() * 60000;
				const adjustedDate = new Date(formattedDate.getTime() - timeZoneOffset);
				const formattedDateString = adjustedDate.toISOString().split('T')[0];
				return formattedDateString;
			} else {
				// Handle invalid date
				return 'N/A';
			}
		}
	}

	formatDateUpdate(inputDate):any {
		if (inputDate != null) {
			const [year, month, day] = inputDate.split('-');
	
			// Create the formatted date string in dd/mm/yyyy format
			const formattedDate = `${day}/${month}/${year}`;
	
			return formattedDate;
		}
	}
	dateComparator(date1: string | null, date2: string | null): number {
		// Check if either date is null or undefined
		if (!date1 || !date2) {
		  // You can decide what to return in case of null values
		  // For example, if date1 is null, consider it as smaller than any non-null date2
		  return date1 ? 1 : -1;
		}
	  
		const dateParts1 = date1.split('/');
		const dateParts2 = date2.split('/');
		
		// Create date objects in the format YYYY-MM-DD
		const d1 = new Date(Number(dateParts1[2]), Number(dateParts1[1]) - 1, Number(dateParts1[0]));
		const d2 = new Date(Number(dateParts2[2]), Number(dateParts2[1]) - 1, Number(dateParts2[0]));
		
		return d1.getTime() - d2.getTime();
	  }
}
