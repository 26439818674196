import { Component, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ColDef, GridOptions } from 'ag-grid-community';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

import { ApiService } from '../_services/api.service';
import { environment } from 'src/environments/environment';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Params, Route, Router } from '@angular/router';

@Component({
	selector: 'app-statistica-descrittiva',
	templateUrl: './statistica-descrittiva.component.html',
	styleUrls: ['./statistica-descrittiva.component.scss']
})
export class StatisticaDescrittivaComponent implements OnInit {
	statisticaTableData$: any[];
	defaultColDef: ColDef = { resizable: true, sortable: true, filter: true };
	tableName = 'statistica-descrittiva';
	statisticaColDefs = [
		{
			field: "CAMPO",
			headerName: "CAMPO",
			width: 150,
		},

		{
			field: "CASI",
			headerName: "Number of occurrences",
			width: 270,
		},
		{
			field: "TOTALE",
			headerName: "Sum",
			width: 150,
		},
		{
			field: "MEDIA",
			headerName: "Average",
			width: 150,
		},
		{
			field: "DS",
			headerName: "Standard Deviation",
			width: 250,
		},
		{
			field: "ES",
			headerName: "Standard Error",
			width: 200,
		},
		{
			field: "CV",
			headerName: "Coefficient of variation",
			width: 260,
		},
		{
			field: "MIN",
			headerName: "Min",
			width: 210,
		},
		{
			field: "MAX",
			headerName: "Max",
			width: 200,
		},
		// {
		// 	field: "INT CONF 95",
		// 	headerName: "INT CONF 95",
		// 	width: 250,
		// },
		// {
		// 	field: "INT CONF 99",
		// 	headerName: "INT CONF 99",
		// 	width: 250,
		// },
	];
	statisticaGridOptions: GridOptions<any> = {
		columnDefs: this.statisticaColDefs,
		defaultColDef: this.defaultColDef,
		rowSelection: "multiple",
		animateRows: true,
		pagination: true,
		onSelectionChanged: this.onSelectionChanged.bind(this),
	};
	view: any;
	fagfilename: any;
	loader: boolean = false;
	currentYear: string = '';
	itemPerPage: number = 14;
	pageNumber: number = 1;
	totalCount: number = 1;
	selectedRows:any = [];
	@ViewChild("popupContainer", { read: TemplateRef })
	popupContainer: TemplateRef<any>;
	items = [
		{ id: 1, value: 'CASI', name: 'Number of occurrences' },
		{ id: 2, value: 'TOTALE', name: 'Sum' },
		{ id: 3, value: 'MEDIA', name: 'Average' },
		{ id: 4, value: 'DS', name: 'Standard Deviation' },
		{ id: 5, value: 'ES', name: 'Standard Error' },
		{ id: 6, value: 'CV', name: 'Coefficient of variation' },
		{ id: 7, value: 'MIN', name: 'Min' },
		{ id: 8, value: 'MAX', name: 'Max' },
	  ];
	  form: FormGroup;
	constructor(private apiService: ApiService, private toasterService: ToastrService,
		private http: HttpClient, private viewContainerRef: ViewContainerRef,
		private fb: FormBuilder, private route: ActivatedRoute,
		private router: Router) { }

	ngOnInit(): void {
		this.route.queryParams.subscribe((params) =>{
			this.currentYear = params['currentYear'];
		})
		this.getStatisticaData();
		this.initializeCheckboxes();
	}

	onSelectionChanged() {
		this.selectedRows = this.statisticaGridOptions.api.getSelectedRows();
		console.log(this.selectedRows, 'selectedRow is='+this.selectedRows.length);
	  }

	openPopup(type: string) {
		this.apiService.modaBackDrop.next(true);
		this.view = this.viewContainerRef.createEmbeddedView(this.popupContainer);
		const domElem = this.view.rootNodes[0] as HTMLElement;
		domElem.classList.add("animate-in");
	}

	closePopup() {
		this.form.reset();
		const domElem = this.view.rootNodes[0] as HTMLElement;
		domElem.classList.add("animate-out");

		// Ascoltare l'evento di fine animazione
		domElem.addEventListener("animationend", (event: AnimationEvent) => {
			// Rimuovere l'elemento dal DOM
			this.viewContainerRef.clear();
			// this.gridOptions.api.deselectAll();
			this.apiService.modaBackDrop.next(false);
		});

		// this.viewContainerRef.clear();
	}

	back() {
		this.router.navigate(['/controllo-interno']);
	  }

	private initializeCheckboxes(): void {
		this.form = this.fb.group({
		  checkboxItems: this.fb.array([]),
		});
		const checkboxItemsFormArray = this.form.get('checkboxItems') as FormArray;
	
		this.items.forEach((item) => {
		  const control = this.fb.control(false);
		  checkboxItemsFormArray.push(control);
		});
	  }

	getStatisticaData() {
		this.loader = true;
		this.apiService.getStatisticaData(this.currentYear).subscribe({
			next: (data) => {
				this.statisticaTableData$ = data?.result ? data?.result : [];
				this.loader = false
			},
			error: (err) => {
				this.loader = false
				this.toasterService.error(err.message)
			}
		});
	}

	onSubmit(): void {
		const selectedCols = this.form.value.checkboxItems
		  .map((checked, index) => (checked ? this.items[index].value : null))
		  .filter((item) => item !== null);

		  
		  selectedCols.unshift('CAMPO');
		  let reqPayload = {
			fieldsToConsider: selectedCols,
			selectedRows: this.selectedRows
		  }
		// let reqPayload: any = {};
		this.apiService.exportStatisticaDescrittiva(reqPayload).subscribe(
			(res: any) => {
			this.toasterService.success(res?.message);
			this.fagfilename = res?.filename;
			let url = environment.url + res?.filePath;
			this.downloadExcelFile(url)
			this.closePopup();
		  },
		  (error: any) => {
			this.toasterService.error(error?.message)
		  }
		  )
	  }



	downloadExcelFile(url: string): void {
		this.http.get(url, { responseType: 'arraybuffer' }).subscribe((data: ArrayBuffer) => {
			const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
			const workbook = XLSX.read(data, { type: 'array' });
			const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

			// Create a Blob and create a download link
			const excelBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
			saveAs(excelBlob, this.fagfilename);
		});
	}
}
