import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { User } from '../_models/user.model';
import {environment} from '../../environments/environment'

const AUTH_API = 'http://localhost:4200/';

// const AUTH_API = 'https://api.bitinformatica.net/auth/int/';

@Injectable({
  providedIn: 'root',
})

export class AuthenticationService {
 menuItem = new Subject<boolean>();
  private MODULE_API = {
    LOGIN:environment.url + 'user/login',
    CHNAGEPASSWORD:environment.url + 'change-password',
    NOTIFICATION:environment.url + 'notification/get-strumentazione-notification'
  }
  constructor(private http: HttpClient) {}

  login(username: string, password: string): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
    };
    let body = new URLSearchParams();
    body.set('username', username);
    body.set('password', password);
    body.set('service', 'spallanzani');
    let response = this.http.post('/auth/login', body, httpOptions);
    return response;
  }
  newLogIn(data){
    return this.http.post(this.MODULE_API.LOGIN,data)
  }
  changePassword(data){
    return this.http.post(this.MODULE_API.CHNAGEPASSWORD,data)
  }
  notification(){
    return this.http.get(this.MODULE_API.NOTIFICATION)
  }
}
