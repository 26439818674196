import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../_services/api.service';
import { ToastrService } from 'ngx-toastr';

// declare var $: any;
@Component({
  selector: 'app-setting-page',
  templateUrl: './setting-page.component.html',
  styleUrls: ['./setting-page.component.scss']
})
export class SettingPageComponent implements OnInit, AfterViewInit {
  years: number[] = [];
  selectYears = [];
  settingYear: string = '';
  constructor( private router: Router, private apiService: ApiService, private toasterService: ToastrService ) {
    this.apiService.modaBackDrop.next(false);
	}

  ngOnInit(): void {
    this.collectYears();
    this.yearselect();
    this.settingYear =localStorage.getItem('settingYear')
  }

  ngAfterViewInit(): void {
  //  this.initializeSelect2();
  }

  // initializeSelect2() {
  //   let thisObj =  this;
  //   $( document ).ready(function() {
  //     const settingYear = localStorage.getItem('settingYear') || new Date().getFullYear();
  //     thisObj.settingYear = settingYear.toString();
  //     $('#mySelect').val(settingYear).trigger('change');
  //     $('#mySelect').select2().on('change', (event) => {
  //       thisObj.settingYear = $(event.target).val();
        
  //     });
  //   })
  // }

  getYear(event){
    this.settingYear = event?.target?.value
  }

  collectYears() {
    const currentYear = new Date().getFullYear();
    for (let year = 1980; year <= currentYear; year++) {
      this.years.push(year);
    }
  }

changePassword(){
  this.router.navigate(['change-password'])
}

saveSettingYear() {
  localStorage.setItem('settingYear', this.settingYear);
  this.toasterService.success('Default Year Set Successfully');
}

yearselect() {
  const currentYear = new Date().getFullYear();
  const startYear = 2010;
  this.years = [];
  
  for (let year = currentYear; year >= startYear; year--) {
    this.years.push(year);
  }
  
}

selectYear(event){
  this.selectYears = event.target.value

}
}
