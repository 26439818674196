import { AfterViewInit, Component, TemplateRef, ViewChild, ViewContainerRef } from "@angular/core";
import { ColDef, ColumnApi, GridOptions } from "ag-grid-community";
import { ApiService } from "../_services/api.service";
import { ToastrService } from "ngx-toastr";
import { HttpClient } from "@angular/common/http";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { environment } from "src/environments/environment";
import { FormBuilder, FormGroup, NgForm } from "@angular/forms";

const dateFields = [
	"data_ver",
	"data_nasc",
	"data_nc",
	"rcamp_data",
	"data_campl",
	"data_camp2",
	"a_dna_data",
	"faxva_data",
	"faxva2data",
	"regva_data",
	"regi_data",
	"regi2data",
	"faxvi_data",
	"faxvi2data",
	"regvi_data",
	"faxie_data",
	"regie_data",
	"data_ac",
	"data_anal",
	"data_prod",
	"tipo_dose",
	"fax_data",
	"cor_data",
	"data_prot",
	"data_ricev",
	"data_estr",
	"data_dil",
	"data_pcr",
	"data_gel",
	"data_ris",
	"data",
	"DATA_RIF",
	"DATA_RPREV",
	"DATA_APREV",
	"DATA_BDO",
	"DATA_ORDIN",
	"DATA_RICEV",
];
@Component({
	selector: "app-archivio",
	templateUrl: "./archivio.component.html",
	styleUrls: ["./archivio.component.scss"],
})
export class ArchivioComponent {
	isCreatePopupOpen = false;
	rowData$: any[];
	defaultColDef: ColDef = { resizable: true, sortable: true, filter: true };
	colDefs: ColDef<any>[] = [];
	tableName: string = "archivio";
	itemPerPage: number = 14;
	pageNumber: number = 1;
	totalCount: number = 1;
	filterValue: string = "";
	columnName: string = "";
	fagfilename: any;
	loader: boolean = false;
	sortingOrder: string = "";
	columnApi: ColumnApi;
	form: FormGroup;
	gridOptions: GridOptions<any> = {
		onFilterChanged: this.onFilterChanged.bind(this),
		columnDefs: this.colDefs,
		defaultColDef: this.defaultColDef,
		rowSelection: "single",
		animateRows: true,
		// pagination: true,
		// paginationAutoPageSize: true,
	};
	selectedDbName: any = "";

	tableList: any[] = [];

	@ViewChild("popupContainer", { read: TemplateRef })
	popupContainer: TemplateRef<any>;
	view: any;
	selectedTable: any;
	isEdit: boolean;
  archivioId: any;
  showPopup: any;

	constructor(public apiService: ApiService, private toasterService: ToastrService, private http: HttpClient, public viewContainerRef: ViewContainerRef, private fb: FormBuilder) {
		this.gridOptions.onSortChanged = this.onSortChanged.bind(this);
	}

	ngOnInit() {
		this.form = this.fb.group({
			buttonType: ["excel"],
		});
		this.apiService.getDbTableNames().subscribe((data) => {
			data.tableNames.forEach((e) => {
				this.tableList.push({ name: this.capitalizeWords(e.tableName), value: e.tableName });
			});

			if (this.tableList.length > 0) {
				this.selectedDbName = this.tableList[0].value;
				this.getDbTables(this.selectedDbName);
			}
		});
	}
	newRow: any = {};
	openCreatePopup() {
		this.isEdit = false;
    this.editedRow = {};
		this.isEditPopupOpen = true;
	}

	// Close the create popup

	onSortChanged(event) {
		const sortedColumns: any = this.columnApi.getAllColumns().filter((column) => column.getSort())[0];
		this.sortingOrder = sortedColumns?.getSort() ?? "";
		this.columnName = sortedColumns?.getColDef().field ?? "";
		this.getDbTables(this.selectedDbName);
	}

	onGridReady(params) {
		this.columnApi = params.columnApi;
	}

	onReceiveData(event: any) {
		if (event?.table === this.tableName) {
			this.rowData$ = event?.data?.data?.rows;
			this.totalCount = event?.data?.data?.count;
		}
	}

	capitalizeWords(str: string): string {
		return str
			.split("_")
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(" ");
	}

	getDbTables(selectedDb: any, data?: string) {
		if (data == "change") {
			this.sortingOrder = "";
			this.columnName = "";
		}

		this.apiService.getDbTables({ db_name: selectedDb, page_number: this.pageNumber, per_page: this.itemPerPage }, this.columnName, "", this.sortingOrder).subscribe((data) => {
      this.isEditPopupOpen = false;
			if (data.error) {
				this.toasterService.error(data.error);
				return;
			}
			if (!data.data.rows || data.data.rows.length === 0) {
				this.toasterService.error("Data is empty.");
				return;
			}
      this.showPopup = data.data.isEditableTable
			// Extract all keys but exclude 'id' from column definitions
			const excludedFields = ["id"];
			const keys = Object.keys(data.data.rows[0]);
			const displayKeys = keys.filter((key) => !excludedFields.includes(key));

			// Set column definitions dynamically (excluding 'id')
			this.colDefs = displayKeys.map((key) => ({
				field: key,
				headerName: key,
				minWidth: displayKeys.length < 8 ? 200 : 150,
				comparator: dateFields.includes(key) ? this.dateComparator : undefined,
			}));

			this.gridOptions.api.setColumnDefs(this.colDefs);

			// Keep 'id' in the rowData but exclude it from display
			this.rowData$ = data.data.rows.map((item) => ({
				...item, // Keep all fields including 'id'
			}));

			this.totalCount = data?.data?.count;
			this.apiService.selectedPage.next(1);
		});
	}

	// onFilterChanged(params: any): void {
	//   this.filterValue = '';
	//   this.columnName = params.columns[0].colId;
	//   this.colDefs.forEach((column) => {
	//     const specificColumnFilterModel = params.api.getFilterModel()[this.columnName];
	//     if (specificColumnFilterModel) {
	//        this.filterValue = specificColumnFilterModel?.filter;
	//     }
	//   });
	//   let data = {
	//     db_name: this.selectedDbName,
	//     page_number:this.pageNumber,
	//     per_page: this.itemPerPage
	//   }
	//   this.apiService.getDbTables(data,this.columnName,this.filterValue,this.sortingOrder).subscribe({
	//     next: (res) => {
	//       this.rowData$ = res?.data.rows;
	//       this.totalCount = res?.data?.count;
	//       this.apiService.selectedPage.next(1);

	//       // this.codeDna = res.maxValCod_dna +1
	//     }, error: (error) => { },
	//     complete: () => { }
	//   });
	//   console.log(`Filter value for column ${this.columnName}:`, this.filterValue);

	// }

	onFilterChanged(params: any): void {
		this.filterValue = "";
		this.columnName = "";

		// Get the filter model
		const filterModel = params.api.getFilterModel();

		// Check if any column has a filter applied
		for (const columnId in filterModel) {
			if (filterModel.hasOwnProperty(columnId)) {
				const specificColumnFilterModel = filterModel[columnId];
				if (specificColumnFilterModel) {
					this.columnName = columnId;
					this.filterValue = specificColumnFilterModel.filter;
					break; // Exit loop after finding the first column with a filter
				}
			}
		}

		let data = {
			db_name: this.selectedDbName,
			page_number: this.pageNumber,
			per_page: this.itemPerPage,
		};

		this.apiService.getDbTables(data, this.columnName, this.filterValue, this.sortingOrder).subscribe({
			next: (res) => {
				this.rowData$ = res?.data?.rows;
				this.totalCount = res?.data?.count;
				this.apiService.selectedPage.next(1);
			},
			error: (error) => {
				// Handle error
			},
			complete: () => {
				// Handle completion
			},
		});

	
	}

	export(data) {
		this.openPopup();
		this.selectedTable = data;
		// this.loader = true
		// this.apiService.exportArchivo(data).subscribe((res)=>{
		//   if(res?.data?.status){
		//     let url = environment.url + res?.data?.path;
		//     this.fagfilename = res?.data?.filename
		//     this.downloadExcelFile(url)
		//     this.loader = false
		//   }
		// })
	}

	downloadExcelFile(url: string): void {
		this.http.get(url, { responseType: "arraybuffer" }).subscribe((data: ArrayBuffer) => {
			const blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
			const workbook = XLSX.read(data, { type: "array" });
			const excelBuffer: any = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

			const excelBlob = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
			saveAs(excelBlob, this.fagfilename);
		});
	}

	downloadCsvFile(url: string): void {
		this.http.get(url, { responseType: "text" }).subscribe((data: string) => {
			const blob = new Blob([data], { type: "text/csv" }); // Set MIME type to text/csv
			const anchor = document.createElement("a");
			anchor.href = window.URL.createObjectURL(blob);
			anchor.download = this.fagfilename + ".csv"; // Set file name with .csv extension
			document.body.appendChild(anchor);
			anchor.click();
			window.URL.revokeObjectURL(anchor.href);
			document.body.removeChild(anchor);
		});
	}

	onSubmitDownload() {
		this.loader = true;

		this.apiService.exportArchivo(this.selectedTable, this.form.value.buttonType).subscribe((res) => {
			if (res?.data?.status) {
				let url = environment.url + res?.data?.path;
				this.fagfilename = res?.data?.filename;
				this.closePopup();
				if (this.form.value.buttonType == "excel") {
					this.downloadExcelFile(url);
				} else {
					this.downloadCsvFile(url);
				}
				this.loader = false;
			}
		});
	}

	openPopup() {
		this.apiService.modaBackDrop.next(true);
		setTimeout(() => {
			this.view = this.viewContainerRef?.createEmbeddedView(this.popupContainer);
			const domElem = this.view.rootNodes[0] as HTMLElement;
			domElem.classList.add("animate-in");
		});
	}
	closePopup() {
		// this.saveanimal = true
		const domElem = this.view.rootNodes[0] as HTMLElement;
		domElem.classList.add("animate-out");
		domElem.addEventListener("animationend", (event: AnimationEvent) => {
			this.viewContainerRef.clear();
			this.apiService.modaBackDrop.next(false);
		});
	}

	dateComparator(date1: string | null, date2: string | null): number {
		// Check if either date is null or undefined
		if (!date1 || !date2) {
			// You can decide what to return in case of null values
			// For example, if date1 is null, consider it as smaller than any non-null date2
			return date1 ? 1 : -1;
		}

		const dateParts1 = date1.split("/");
		const dateParts2 = date2.split("/");

		// Create date objects in the format YYYY-MM-DD
		const d1 = new Date(Number(dateParts1[2]), Number(dateParts1[1]) - 1, Number(dateParts1[0]));
		const d2 = new Date(Number(dateParts2[2]), Number(dateParts2[1]) - 1, Number(dateParts2[0]));

		return d1.getTime() - d2.getTime();
	}
	isValidDate(dateString: string): boolean {
		// Check if the dateString matches the DD/MM/YYYY format
		const datePattern = /^(\d{2})\/(\d{2})\/(\d{4})$/;
		return datePattern.test(dateString);
	}
	isEditPopupOpen = false;
	editedRow: any = {};
	onRowClicked(event?: any) {
  
		this.isEdit = true;
		if (event) {
			this.editedRow = { ...event.data }; // Copy row data
		}
    this.archivioId = event.data.id;
		this.isEditPopupOpen = true;
	}

	closePopup1() {
		this.isEditPopupOpen = false;
	}
  deleteArchivo(data:any){
    this.apiService.deleteArchivo(this.archivioId, this.selectedDbName).subscribe(
      (res: any) => {
        if (res) {
          if(res.status == "error"){
            this.toasterService.error(res.message);
          }
          else{
          this.toasterService.success("Deleted successfully!");
          }
          this.isEditPopupOpen = false;
          this.getDbTables(this.selectedDbName);
        } else {
          this.toasterService.error(res.message || "Failed to add data.");
        }
      },
      (error) => {
        console.error("Error:", error);
        this.toasterService.error("An error occurred while adding data.");
      }
    );
  }
  saveChanges(form: NgForm) {
    if (form.invalid) {
      console.error("Validation failed: Missing or invalid fields");
      this.toasterService.error("Please fill in all required fields correctly.");
      return;
    }

    if (!this.isEdit) {
      this.apiService.createArchivo(this.editedRow, this.selectedDbName).subscribe(
        (res: any) => {
          if (res) {
            this.toasterService.success("Data added successfully.");
            this.isEditPopupOpen = false;
            this.getDbTables(this.selectedDbName);
          } else {
            this.toasterService.error(res.message || "Failed to add data.");
          }
        },
        (error) => {
          console.error("Error:", error);
          this.toasterService.error("An error occurred while adding data.");
        }
      );
    } else {
      this.apiService.updateArchivo(this.editedRow, this.selectedDbName).subscribe(
        (res: any) => {
          if (res) {
            this.toasterService.success("Data updated successfully.");
            this.isEditPopupOpen = false;
            this.getDbTables(this.selectedDbName);
          } else {
            this.toasterService.error(res.message || "Failed to update data.");
          }
        },
        (error) => {
          console.error("Error:", error);
          this.toasterService.error("An error occurred while updating data.");
        }
      );
    }
  }
  
  
}
