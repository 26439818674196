import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { httpInterceptorProviders } from './_helpers/http.interceptor';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { AnagraficheComponent } from './anagrafiche/anagrafiche.component';
import { NgxtForDirective } from './_directives/ngxt-for.directive';
import { ImportaListatiComponent } from './importa-listati/importa-listati.component';
import { DragDropFileUploadDirective } from './_directives/drag-drop-file-upload.directive';
import { NgChartsModule } from 'ng2-charts';
import { AgGridModule } from 'ag-grid-angular';
import { TestComponentsComponent } from './test-components/test-components.component';
import { AggiungiAnagraficaComponent, ConfirmationDialog } from './aggiungi-anagrafica/aggiungi-anagrafica.component';
import { CampionamentiComponent } from './campionamenti/campionamenti.component';
import { StatisticaTestualeComponent } from './statistica-testuale/statistica-testuale.component';
import { TabSelectorComponent } from './tab-selector/tab-selector.component';
import { StatisticaGraphComponent } from './statistica-graph/statistica-graph.component';
import { StatisticheComponent } from './statistiche/statistiche.component';
import { TableComponent } from './components/table/table.component';
import { InstrumentDetailsComponent } from './pages/instrument-details/instrument-details.component';
import { RiproduttoriComponent } from './riproduttori/riproduttori.component';
import { TableTestComponent } from './table-test/table-test.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { StatisticsOverviewComponent } from './statistics-overview/statistics-overview.component';
import { ToastrModule } from 'ngx-toastr';
import { AuthInterceptor } from './interceptor/auth.interceptor';
import {NgxPaginationModule} from 'ngx-pagination';
import { SharedModule } from './_shared/shared.module';
import { AnimalsManagementComponent } from './animals-management/animals-management.component';
import { CustomButtonRendererComponent } from '../app/table-test/custom-button-renderer.component';
import { NewDnaRefComponent } from './new-dna-ref/new-dna-ref.component';
import { CourierManagementComponent } from './courier-management/courier-management.component';
import { ConformComponent } from './_shared/modal/conform.component';
import { DashModule } from './dash/dash.module';
import { AlertComponent } from './_shared/modal/alert.component';
import { WorksheetComponent } from './worksheetnsamedna/worksheetnsamedna.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { RicarTableComponent } from './ricar-table/ricar-table.component';
import { FoglioTableComponent } from './foglio-table/foglio-table.component';
import { BollaTableComponent } from './bolla-table/bolla-table.component';
import { L30qsTableComponent } from './l30qs-table/l30qs-table.component';
import { L30operTableComponent } from './l30oper-table/l30oper-table.component';
import { L30dnaTableComponent } from './l30dna-table/l30dna-table.component';
import { AutocertificazioniComponent } from './autocertificazioni/autocertificazioni.component';
import { DetailPageComponent } from './detail-page/detail-page.component';
import { CreatesampleComponent } from './createsample/createsample.component';
import { MagazzinoComponent } from './magazzino/magazzino.component';
import { RegistrosemednaComponent } from './registrosemedna/registrosemedna.component';
import { SettingPageComponent } from './setting-page/setting-page.component';
import { ArchivioComponent } from './archivio/archivio.component';
import { RisultatiComponent } from './risultati/risultati.component';
import { RisultatidnaComponent } from './risultatidna/risultatidna.component';
import { DashboardChartComponent } from './dash/dashboard-chart/dashboard-chart.component';
import { ControlloInternoComponent } from './controllo-interno/controllo-interno.component';
import { StatisticaDescrittivaComponent } from './statistica-descrittiva/statistica-descrittiva.component';
import { LottiSectionComponent } from './lotti-section/lotti-section.component';
import { CentriSectionComponent } from './centri-section/centri-section.component';
import { FornitoriComponent } from './fornitori/fornitori.component';
import { FurnitureComponent } from './furniture/furniture.component';

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        HomeComponent,
        DashboardComponent,
        PagenotfoundComponent,
        AnagraficheComponent,
        NgxtForDirective,
        ImportaListatiComponent,
        DragDropFileUploadDirective,
        TestComponentsComponent,
        AggiungiAnagraficaComponent,
        CampionamentiComponent,
        StatisticaTestualeComponent,
        TabSelectorComponent,
        StatisticaGraphComponent,
        StatisticheComponent,
        TableComponent,
        InstrumentDetailsComponent,
        RiproduttoriComponent,
        TableTestComponent,
        ChangePasswordComponent,
        StatisticsOverviewComponent,
        AnimalsManagementComponent,
        CustomButtonRendererComponent,
        NewDnaRefComponent,
        CourierManagementComponent,
        ConformComponent,
        AlertComponent,
        WorksheetComponent,
        RicarTableComponent,
        FoglioTableComponent,
        BollaTableComponent,
        L30qsTableComponent,
        L30operTableComponent,
        L30dnaTableComponent,
        AutocertificazioniComponent,
        DetailPageComponent,
        CreatesampleComponent,
        MagazzinoComponent,
        ConfirmationDialog,
        RegistrosemednaComponent,
        SettingPageComponent,
        ArchivioComponent,
        RisultatiComponent,
        RisultatidnaComponent,
        DashboardChartComponent,
        ControlloInternoComponent,
        StatisticaDescrittivaComponent,
        LottiSectionComponent,
        CentriSectionComponent,
        FornitoriComponent,
        FurnitureComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        NgChartsModule,
        AgGridModule,
        NgxPaginationModule,
        ToastrModule.forRoot(),
        SharedModule,
        DashModule,
        FormsModule,
        HighchartsChartModule
    ],
    providers: [httpInterceptorProviders,{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }],
    bootstrap: [AppComponent],
})
export class AppModule { }
