import { Observable, EMPTY, Subscription } from 'rxjs';
import { Component, DoCheck, OnDestroy, OnInit } from '@angular/core';
import { ApiService } from '../_services/api.service';
import { Toro } from '../_models';

@Component({
  selector: 'app-anagrafiche',
  templateUrl: './anagrafiche.component.html',
  styleUrls: ['./anagrafiche.component.css'],
})
export class AnagraficheComponent implements OnInit, OnDestroy, DoCheck {
  tori: Toro[] = [];
  custMarging = '0%';
  currentTableSize = 0;
  //subscription: Subscription = Subscription.EMPTY;
  constructor(private apiService: ApiService) {}

  ngOnInit(): void {
    var subscription1$ = this.retrieveTori();
    // this.subscription.add(subscription1$);
  }

  ngOnDestroy(): void {
    // this.subscription.unsubscribe(); // TODO FIX
  }

  ngDoCheck() {
    this.adaptMargin();
  }

  adaptMargin() {
    let tt = document.getElementById('tori-table');
    if (tt != null && tt.clientWidth == this.currentTableSize) {
      return;
    }
    let availableSpace = document.getElementById('main-section');
    if (tt != null && availableSpace != null) {
      this.custMarging =
        '0px ' +
        (availableSpace.clientWidth -
          (availableSpace.clientWidth * 10) / 100 -
          tt.clientWidth) /
          2 +
        'px';
    }
  }

  retrieveTori() {
    // retrieveTori returns an Observable. subscribe() returns a Subscription. May need to handle these correctly in order to clean up memory onDestroy.
    return this.apiService.retrieveTori().subscribe({
      next: (data) => {
        this.tori = data.result;
      },
      error: (err) => {
      },
    });
  }

  // TODO possibilità di selezionare elemento nella tabella per aprire pagine dedicate????
}
