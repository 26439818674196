import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../_services/api.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

// declare var $: any;
@Component({
  selector: 'app-setting-page',
  templateUrl: './setting-page.component.html',
  styleUrls: ['./setting-page.component.scss']
})
export class SettingPageComponent implements OnInit, AfterViewInit {
  years: number[] = [];
  selectYears = [];
  settingYear: string = '';
  signatureVal: boolean = false;
  settingForm: FormGroup;
  percentualeForm: any;
  constructor( private router: Router, private apiService: ApiService, private toasterService: ToastrService, private fb:FormBuilder ) {
    this.apiService.modaBackDrop.next(false);
	}

  ngOnInit(): void {
    this.settingForm = this.fb.group({
      qs_giorno_conc: [0.5, [Validators.required]],
      qs_giorno_mp: [1, [Validators.required]],
      qs_giorno_nspm: [0.5, [Validators.required]],
      qs_settimana_conc: [0.5, [Validators.required]], 
      qs_settimana_mp: [1, [Validators.required]],  
      qs_settimana_nspm: [0.5, [Validators.required]] 
    });
    this.percentualeForm = this.fb.group({
      percentuale: [0.5, [Validators.required]],
     
    });
    this.collectYears();
    this.yearselect();
    this.settingYear =localStorage.getItem('settingYear');
    this.getQsGiornoSettimana();
    this.getpercentualData();
  }

  ngAfterViewInit(): void {
  //  this.initializeSelect2();
  }

  // initializeSelect2() {
  //   let thisObj =  this;
  //   $( document ).ready(function() {
  //     const settingYear = localStorage.getItem('settingYear') || new Date().getFullYear();
  //     thisObj.settingYear = settingYear.toString();
  //     $('#mySelect').val(settingYear).trigger('change');
  //     $('#mySelect').select2().on('change', (event) => {
  //       thisObj.settingYear = $(event.target).val();
        
  //     });
  //   })
  // }

  getYear(event){
    this.settingYear = event?.target?.value
  }

  collectYears() {
    const currentYear = new Date().getFullYear();
    for (let year = 1980; year <= currentYear; year++) {
      this.years.push(year);
    }
  }

changePassword(){
  this.router.navigate(['change-password'])
}

saveSettingYear() {
  localStorage.setItem('settingYear', this.settingYear);
  this.toasterService.success('Default Year Set Successfully');
}

yearselect() {
  const currentYear = new Date().getFullYear();
  const startYear = 2010;
  this.years = [];
  
  for (let year = currentYear; year >= startYear; year--) {
    this.years.push(year);
  }
  
}

selectYear(event){
  this.selectYears = event.target.value

}
saveQsGiornoSettimana() {
  if (this.settingForm.invalid) {
    this.toasterService.error('Please fill in all required fields.');
    return;
  }

  const data = this.settingForm.value;  // Get all values from the form

  this.apiService.setQsGiornoSettimana(data).subscribe({
    next: (response) => {
      this.toasterService.success(response.message);
      this.getQsGiornoSettimana();
    },
    error: (error) => {
      // this.toasterService.error('Failed to save settings');
    }
  });
}
savePercentual() {
  if (this.percentualeForm.invalid) {
    this.toasterService.error('Please fill in all required fields.');
    return;
  }

  const data = this.percentualeForm.value;  // Get all values from the form

  this.apiService.setPercentualData(data).subscribe({
    next: (response) => {
      this.toasterService.success(response.message);
      this.getpercentualData();
    },
    error: (error) => {
      // this.toasterService.error('Failed to save settings');
    }
  });
}
getQsGiornoSettimana(){
  this.apiService.getQsGiornoSettimana().subscribe(
    (response) => {
      // Check for the 'giorno' and 'settimana' data in the response and populate the form
      const giorno = response.data.giorno[0]; // Assuming there is only one object in the array
      const settimana = response.data.settimana[0]; // Assuming there is only one object in the array

      // Check if the data exists and patch values to the form
      if (giorno) {
        this.settingForm.patchValue({
          qs_giorno_conc: giorno.conc,
          qs_giorno_mp: giorno.mp,
          qs_giorno_nspm: giorno.nspm
        });
      }

      if (settimana) {
        this.settingForm.patchValue({
          qs_settimana_conc: settimana.conc,
          qs_settimana_mp: settimana.mp,
          qs_settimana_nspm: settimana.nspm
        });
      }
    },
    (error) => {
      this.toasterService.error('Failed to fetch settings data');
    }
  );
}
getpercentualData(){
  this.apiService.getPercentual().subscribe(
    (response) => {
      console.log(response);
      this.percentualeForm.patchValue({
        percentuale: response.data.percentuale,
       
      });
      // Check for the 'giorno' and 'settimana' data in the response and populate the form
    
    },
    (error) => {
      this.toasterService.error('Failed to fetch settings data');
    }
  );
}



onCheckboxChange(value: boolean): void {
  const body={
    "isCheckedCheckbox":value
  }
  this.apiService.updateSignature(body).subscribe({
    next: (response) => {
      this.toasterService.success(response.message);
      // this.getQsGiornoSettimana();
    },
    error: (error) => {
      // this.toasterService.error('Failed to save settings');
    }
  });
}
}
