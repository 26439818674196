<!-- <div class="col-md-6 mx-auto">
  <div class="card card-container">
    <form
      *ngIf="!isLoggedIn"
      name="form"
      (ngSubmit)="f.form.valid && onSubmit()"
      #f="ngForm"
      novalidate
    >
      <div class="form-group">
        <label for="username">Username</label>
        <input
          type="text"
          class="form-control"
          name="username"
          [(ngModel)]="form.username"
          required
          #username="ngModel"
          [ngClass]="{ 'is-invalid': f.submitted && username.errors }"
        />
        <div *ngIf="username.errors && f.submitted" class="invalid-feedback">
          Username is required!
        </div>
      </div>
      <div class="form-group">
        <label for="password">Password</label>
        <input
          type="password"
          class="form-control"
          name="password"
          [(ngModel)]="form.password"
          required
          minlength="6"
          #password="ngModel"
          [ngClass]="{ 'is-invalid': f.submitted && password.errors }"
        />
        <div *ngIf="password.errors && f.submitted" class="invalid-feedback">
          <div *ngIf="password.errors['required']">Password is required</div>
          <div *ngIf="password.errors['minlength']">
            Password must be at least 6 characters
          </div>
        </div>
      </div>
      <div class="form-group">
        <button class="btn btn-primary btn-block">
          Login
        </button>
      </div>
      <div class="form-group">
        <div *ngIf="f.submitted && isLoginFailed" class="alert alert-danger" role="alert">
          Login failed: {{ errorMessage }}
        </div>
      </div>
    </form>
    <div class="alert alert-success" *ngIf="isLoggedIn">
      Logged in as {{ roles }}.
    </div>
  </div>
</div>

<button type="button" class="btn" routerLink="/">BACK HOME</button> -->
<!-- <app-loader *ngIf="loader"></app-loader> -->
 <!-- <ng-container *ngIf="isLoggedIn; then logged; else anonymous">
</ng-container>

<ng-template #anonymous>  -->
  <!-- <div class="loader-container" *ngIf="loader">
    <div class="loader"></div>
  </div> -->
  <div class="page">
    <div class="container login-container">
      <form class="login-form styling" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <label for="email" class="form-label">Email</label>
          <input type="email" class="form-control" id="email" [(ngModel)]="form.username" name="email" required>
        </div>
        <div class="form-group">
          <label for="password" class="form-label">Password</label>
          <input type="password" class="form-control" id="password" [(ngModel)]="form.password" name="password"
            required>
        </div>
        <button type="submit" class="btn btn-primary login-button">Login</button>
      </form>
    </div>
  </div>
<!--   
 </ng-template>

<ng-template #logged>Already logged in.</ng-template>  -->