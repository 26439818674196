import { HttpClient } from '@angular/common/http';
import { Component, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ColDef, GridOptions } from 'ag-grid-community';
import { ModalService } from '../_services/modal.service';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../_services/api.service';
import { saveAs } from 'file-saver';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-risultatidna',
  templateUrl: './risultatidna.component.html',
  styleUrls: ['./risultatidna.component.scss']
})
export class RisultatidnaComponent {
  rowData$:any[]=[];
  rowData2:any[]=[];
  rowData3:any[]=[];
  loader:boolean = false;
  colDefs: ColDef<any>[] = [
    { field: "Camp", headerName: "COD_CAM", width: 250 },
    { field: "toro", headerName: "Nome", width: 250 },
    { field: "matricola", headerName: "Matr", width: 250 },
    { field: "lotto", headerName: "Lotto", width: 250 },
    { field: "microstat", headerName: "MICROSAT", width: 250},
    { 
      field: "al1_seme", 
      headerName: "AL1_SEME", 
      width: 300,
      valueParser: (params) => Number(params.data.al1_seme),
      comparator: (valueA, valueB) => Number(valueA) - Number(valueB),
      filter: 'agNumberColumnFilter' 
    },
    { 
      field: "al2_seme", 
      headerName: "AL2_SEME", 
      width: 300,
      valueParser: (params) => Number(params?.data?.al2_seme),
      comparator: (valueA, valueB) => Number(valueA) - Number(valueB),
      filter: 'agNumberColumnFilter' 
    },
  ];
  defaultColDef: ColDef = { resizable: true, sortable:  true, filter: true };
  gridOptions: GridOptions<any> = {
    columnDefs: this.colDefs,
    defaultColDef: this.defaultColDef,
    rowSelection: "single",
    animateRows: true,
    pagination: true,
    paginationAutoPageSize: true,
  };
  codice_cam: any;
  fileName: any;
  constructor(
    public apiService: ApiService,
    public toastr: ToastrService,
    private modalService: ModalService,
    public activate: ActivatedRoute,
    private viewContainerRef: ViewContainerRef,
    private http: HttpClient,
  ) {}

  ngOnInit() {
    this.activate.queryParams.subscribe((res: any) => {
      this.codice_cam = res.codice_cam;
      this.getRisultati(this.codice_cam)
    })
  }
  

  getRisultati(data){
    this.loader = true
    this.apiService.semeRIsultati(data).subscribe((res)=>{
      if(res.status){
        this.rowData$ = res?.table1Data
        this.rowData2 = res?.table2data
        this.rowData3 = res?.table3data
        this.loader = false
      }
    })
  }
  getButtonFile(data){
    this.loader = true;
    let codiceAz = localStorage.getItem('centriCodiceAz')
    if(data == 'dna1'){
      let buttontype  = 'prima'
      this.apiService.getResultatiDna1(this.codice_cam,codiceAz,buttontype).subscribe((res:any)=>{
        if(res.status){
          let url = environment.url + res?.filePath;
          this.fileName = res?.filename
          this.downloadPdfFile(url)
          this.loader = false;
        }else{
          this.toastr.error(res?.message)
          this.loader = false;

        }
      })
    }else{
      let buttontype  = ''
      this.apiService.getResultatiDna1(this.codice_cam,codiceAz,buttontype).subscribe((res:any)=>{
        if(res.status){
          let url = environment.url + res?.filePath;
          this.fileName = res?.filename
          this.downloadPdfFile(url)
          this.loader = false;
        }else{
          this.toastr.error(res?.message)
          this.loader = false;
        }
      })
    }
  }

  
  downloadPdfFile(url: string): void {
    this.http.get(url, { responseType: 'blob' }).subscribe((data: Blob) => {
      const blob = new Blob([data], { type: 'application/pdf' });
      saveAs(blob, this.fileName);
    });
  }
}
