import { Component, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { NewDNA, Razza1 } from '../_models';
import { CellClickedEvent, ColDef, GridOptions } from "ag-grid-community";
import { CustomButtonRendererComponent } from '../table-test/custom-button-renderer.component';
import { ApiService } from '../_services/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ModalService } from '../_services/modal.service';
import { take, takeWhile } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-l30oper-table',
  templateUrl: './l30oper-table.component.html',
  styleUrls: ['./l30oper-table.component.scss']
})
export class L30operTableComponent {
  centriCodiceAz: string | number;
  specie: string;
  anno: string | number;
  loader: boolean = false;
  rowData$: NewDNA[];
  clickedRowData: any;
  subjectValue: boolean = false;
  years: number[] = [];

  addL30Qs: FormGroup;
  view: any;
  isAlive = true;
  @ViewChild("popupContainer", { read: TemplateRef })
  popupContainer: TemplateRef<any>;
  tableName: string = 'newdnadata';
  formSubmitted: boolean = false;
  defaultColDef: ColDef = {
    // flex: 1,
    resizable: true,
    sortable: true,
    filter: true,
  };


  colDefs: ColDef<NewDNA>[] = [
    { field: "codice_cam", headerName: "Cod Cam", width: 150 },
    { field: "cod_seme", headerName: "cod_seme", width: 150 },
    { field: "ripetizion", headerName: "ripetizion", width: 150 },
    { field: "op_ricev", headerName: "op_ricev", width: 150 },
    { field: "data_ricev", headerName: "data_ricev", width: 150, cellRenderer: (params: any) => { return `${params?.data?.data_ricev == null ? 'N/A' : params?.data?.data_ricev}` } },
    // { field: "autocertif", headerName: "autocertif", width:150, cellRenderer: (params: any) => { return `${params?.data?.autocertif == null||params?.data?.autocertif == null?'N/A':params?.data?.autocertif}`} },
    // { field: "cod_dna", headerName: "cod_dna" , width:150 },
    { field: "data_estr", headerName: "data_estr", width: 150 },
    { field: "diluizione", headerName: "diluizione", width: 150 },
    { field: "data_dil", headerName: "data_dil", width: 150 },
    { field: "id_pcr", headerName: "id_pcr", width: 150 },
    { field: "pcr", headerName: "pcr", width: 150 },
    { field: "data_pcr", headerName: "data_pcr", width: 150 },
    { field: "mix_car", headerName: "mix_car", width: 150 },
    { field: "num_gel", headerName: "num_gel", width: 150 },
    { field: "data_gel", headerName: "data_gel", width: 150 },
    { field: "sequenziat", headerName: "sequenziat", width: 150 },
    { field: "risultati", headerName: "risultati", width: 150 },
    { field: "data_ris", headerName: "data_ris", width: 150 },
    // { field: "mestruo", headerName: "mestruo" , width:150},
    { field: "op_mac", headerName: "op_mac", width: 150 },
    // { field: "vodice_vhs", headerName: "vodice_vhs" , width:150},
    // { field: "vhs_ora", headerName: "vhs_ora" , width:150},
    // { field: "vhs_oper", headerName: "vhs_oper" , width:150},
    { field: "op_dos", headerName: "op_dos", width: 150 },
    // { field: "conc_um", headerName: "conc_um" , width:150},
    { field: "analisi_ok", headerName: "analisi_ok", width: 150 },
    { field: "faxvi_data", headerName: "faxvi_data", width: 150 },
    { field: "faxvi_oper", headerName: "faxvi_oper", width: 150 },
    { field: "regvi_data", headerName: "regvi_data", width: 150 },
    { field: "regvi_oper", headerName: "regvi_oper", width: 150 },
    { field: "stato", headerName: "stato", width: 150 },
    { field: "id_piastra", headerName: "id_piastra", width: 150 },
    { field: "tank_rif", headerName: "tank_rif", width: 150, cellRenderer: (params: any) => { return `${params?.data?.tank_rif == null ? 'N/A' : params?.data?.tank_rif}` } },
    { field: "non_conf", headerName: "non_conf", width: 150 },
    { field: "faxvi2data", headerName: "faxvi2data", width: 150 },
    { field: "faxvi2oper", headerName: "faxvi2oper", width: 150 },
    // {
    //   field: "Action",
    //   headerName: "AZIONE",
    //   // flex: 1,
    //   width: 150,
    //   cellRenderer: 'customButtonRenderer',
    //   cellRendererParams: {
    //     onClick: this.onButtonClicked.bind(this),
    //     tableName: 'newdnadata',
    //   },
    // },
  ];
  // cellRenderer: (params: any) => {
  //   return `${params.data.codice_cam == 'NULL'?'N/A':params.data.codice_cam}`;
  // },

  gridOptions: GridOptions<NewDNA> = {
    columnDefs: this.colDefs,
    defaultColDef: this.defaultColDef,
    rowSelection: "single",
    animateRows: true,
    pagination: true,
    paginationAutoPageSize: true,
    frameworkComponents: { customButtonRenderer: CustomButtonRendererComponent },
    onCellClicked: (event: CellClickedEvent) => {
      this.clickedRowData = event?.data
      this.patchDNAForm();
      this.openPopup();
      this.changeButtun = true;
    },
  };

  razze: Razza1[] = [];
  changeButtun: boolean = false;
  itemPerPage: number = 50;
  pageNumber: number = 1;
  totalCount: number = 1;
  deleteId: any;
  spaciesData: any[] ;
  selectYears: any;
  newCodiceLab1: string;
  calc: any;
  lastcodiccam: any;
  constructor(
    public apiService: ApiService,
    public viewContainerRef: ViewContainerRef,
    public formBuilder: FormBuilder,
    public toastr: ToastrService,
    public modalService: ModalService
  ) {
    this.newDNAForm();
    this.deleteRow();
    this.apiService.current.pipe(takeWhile(() => this.isAlive)).subscribe((res) => {
      if (res) {
        this.subjectValue = true
        this.centriCodiceAz = res.codice_az ? res.codice_az : localStorage.getItem('centriCodiceAz')
        this.specie = res.specie ? res.specie : localStorage.getItem('specie');
        this.anno = res.anna ? res.anna : localStorage.getItem('anna')
        this.tableData();
      }
    })
  }

  ngOnInit() {
    this.getSpeciesList();
    this.yearselect()
    // this.apiService.current.pipe(takeWhile(() => this.isAlive)).subscribe((res) => {
    // 	if (res) {
    // 		this.subjectValue = true
    // 		this.centriCodiceAz = res.codice_az ? res.codice_az : localStorage.getItem('centriCodiceAz')
    // 		this.specie = res.specie ? res.specie : localStorage.getItem('specie');
    // 		this.anno = res.anna ? res.anna : localStorage.getItem('anna')
    // 		this.tableData();
    // 	}
    // })
    this.tableData();
  }
  selectSpecie(event) {
    this.specie = event?.target?.value;
    // localStorage.setItem('specie',this.specie)
    // this.apiService.current.next({specie:this.specie, anna:this.selectYears });
    // this.dataToParent.emit(this.specie)
    // this.razzeData = this.razze.filter((sp: any) => sp?.specie === this.specie);
  }
  getSpeciesList(){
  
  this.apiService?.getSpecies().subscribe({
    next: (res: any) => {
    if (res) {
     this.spaciesData= res.data;

    }
    },
    error: (err:HttpErrorResponse) => {
  
    
    if(err?.error.errorMessage){
      this.toastr.error(err?.error.errorMessage);
    }
    else{
    this.toastr.error("An error occurred");
    }
    },
  });
  }
  selectYear(event) {
    this.selectYears = event.target.value

  }
  yearselect() {
    const currentYear = new Date().getFullYear();
    for (let i = 0; i < 4; i++) {
      this.years.push(currentYear - i);
    }
  }

  onButtonClicked(data: any) {
    this.loader = true;
    this.apiService.deleteDna(data?.id).subscribe((res: any) => {
      if(res){
        this.closePopup()
        this.tableData();
        this.loader = false;
        this.toastr.success(res.description);
      }
    });
  }

  tableData() {
    // if(!this.subjectValue){
    //   this.centriCodiceAz = localStorage.getItem('centriCodiceAz') ? localStorage.getItem('centriCodiceAz') : 1
    //   this.specie = localStorage.getItem('specie') ? localStorage.getItem('specie') : "BI"
    //   this.anno = localStorage.getItem('anna') ? localStorage.getItem('anna') : 2022
    // }
    // this.subjectValue = false
    this.apiService.getdnaTable().subscribe({
      next: (res) => {
        this.rowData$ = res?.result?.rows;
        this.totalCount = res?.totalCount;
        // let lastValue = res?.codice_labLast
        // const [value, year] = lastValue.split('.');
        // // const currentYear = new Date().getFullYear();
        // const currentYear = new Date().getFullYear().toString().slice(-2);
        // let newCodiceLab: string;
        // const incrementedValue = parseInt(value, 10) + 1;
        // newCodiceLab = `${incrementedValue}.${currentYear}`;
        // this.newCodiceLab1 = newCodiceLab
        // this.lastcodiccam = this.incrementString(res.getLastCODICECAM.codice_cam)
      },
      error: (error) => {
      },
      complete: () => { }
    });
  }

  openPopup() {
    this.apiService.modaBackDrop.next(true);
    this.view = this.viewContainerRef.createEmbeddedView(this.popupContainer);
    const domElem = this.view.rootNodes[0] as HTMLElement;
    domElem.classList.add("animate-in");
  }
  openPopup1() {
    this.addL30Qs.get('codice_lab').setValue(this.newCodiceLab1)
    this.addL30Qs.controls['codice_cam'].setValue(this.lastcodiccam)
  }

  patchDNAForm() {

    let date = this.formatDate(this.clickedRowData.data_ris)
    let date1 = this.formatDate(this.clickedRowData.data_gel)

    // let nspm = this.clickedRowData.conc * this.clickedRowData.mp / 100
    this.addL30Qs.patchValue({
      // id: this.clickedRowData.id,
      codice_cam: this.clickedRowData.codice_cam,
      cod_seme: this.clickedRowData.cod_seme,
      ripetizion: this.clickedRowData.ripetizion,
      op_ricev: this.clickedRowData.op_ricev,
      data_ricev: this.clickedRowData.data_ricev,
      estrazione: this.clickedRowData.estrazione,
      data_estr: this.clickedRowData.data_estr,
      diluizione: this.clickedRowData.diluizione,
      data_dil: this.clickedRowData.data_dil,
      id_pcr: this.clickedRowData.id_pcr,
      pcr: this.clickedRowData.pcr,
      data_pcr: this.clickedRowData.data_pcr,
      mix_car: this.clickedRowData.mix_car,
      num_gel: this.clickedRowData.num_gel,
      data_gel: date1,
      sequenziat: this.clickedRowData.sequenziat,
      risultati: this.clickedRowData.risultati,
      data_ris: date,
      op_mac: this.clickedRowData.op_mac,
      op_dos: this.clickedRowData.op_dos,
      analisi_ok: this.clickedRowData.analisi_ok,
      faxvi_data: this.clickedRowData.faxvi_data,
      faxvi_oper: this.clickedRowData.faxvi_oper,
      regvi_data: this.clickedRowData.regvi_data,
      regvi_oper: this.clickedRowData.regvi_oper,
      stato: this.clickedRowData.stato,
      tank_rif: this.clickedRowData.tank_rif,
      id_piastra: this.clickedRowData.id_piastra,
      non_conf: this.clickedRowData.non_conf,
      faxvi2data: this.clickedRowData.faxvi2data,
      faxvi2oper: this.clickedRowData.faxvi2oper,
    });

  }

  newDNAForm() {
    this.addL30Qs = this.formBuilder.group({
      codice_cam: [null, Validators.required],
      cod_seme: [null, Validators.required],
      ripetizion: [null],
      op_ricev: [null],
      data_ricev: [null],
      estrazione: [null],
      data_estr: [null],
      diluizione: [null],
      data_dil: [null],
      id_pcr: [null],
      pcr: [null],
      data_pcr: [null],
      mix_car: [null],
      num_gel: [null],
      data_gel: [null],
      sequenziat: [null],
      risultati: [null],
      data_ris: [null],
      op_mac: [null],
      op_dos: [null],
      analisi_ok: [null],
      faxvi_data: [null],
      faxvi_oper: [null],
      regvi_data: [null],
      regvi_oper: [null],
      stato: [null],
      id_piastra: [null],
      tank_rif: [null],
      non_conf: [null],
      faxvi2data: [null],
      faxvi2oper: [null]
    });

  }

  deleteRow() {
    this.apiService.delete.subscribe((res) => {
      this.deleteId = res.data
      if ((res?.data?.id) && (res?.table === 'newdnadata')) {
        this.modalService.openModal('newdnadata');
      }
    })
  }
	openmodel(){
		this.modalService.openModal('newdnadata');
	}
  confirm(event) {
    // if (event?.modal == "newdnadata" && event?.value) {
      this.onButtonClicked(this.clickedRowData);
    // }

  }

  receiveData(event: any) {
    if (event?.table === this.tableName) {
      this.rowData$ = event?.data?.result;
      this.totalCount = event?.data?.totalCount;
    }
  }
  get fc() { return this.addL30Qs.controls; }

  onSubmit() {
    this.loader = true;
    this.formSubmitted = true;
    if (this.addL30Qs.invalid) {
      this.loader = false;
      return
    } else {
      const payload = this.addL30Qs.value;
      if (this.changeButtun) {
        let data = this.addL30Qs.value.data_ris == '' ? null : this.addL30Qs.value.data_ris
        let data1 = this.addL30Qs.value.data_gel == '' ? null : this.addL30Qs.value.data_gel

        let date = this.formatDate1(data)
        let dategel = this.formatDate1(data1)

        this.updateRecord({ id: this.clickedRowData.id, ...payload, data_ris: date, data_gel: dategel });
        this.formSubmitted = false;
      } else {
        let data = this.addL30Qs.value.data_ris == '' ? null : this.addL30Qs.value.data_ris
        let data1 = this.addL30Qs.value.data_gel == '' ? null : this.addL30Qs.value.data_gel

        let date = this.formatDate1(data)
        let dategel = this.formatDate1(data1)

        this.createRecord({ ...payload, data_ris: date, data_gel: dategel });
        this.formSubmitted = false;
      }
    }
  }

  createRecord(payload: any) {
    this.loader = true
    this.apiService?.CreateNewDna(payload).subscribe({
      next: (res: any) => {
        if (res) {
          this.loader = false;
          this.toastr.success(res.description);
          this.closePopup();
          this.tableData();
        }
      },
      error: (err) => {
        this.loader = false;
        this.toastr.error('An error occurred');
      }
    });
  }

  updateRecord(payload: any) {
    this.loader = true;
    this.apiService?.updateDnaRecord(payload).subscribe({
      next: (res: any) => {
        if (res) {
          this.loader = false;
          this.toastr.success(res.description);
          this.closePopup();
          this.tableData();
        }
      },
      error: (err) => {
        this.loader = false;
        this.toastr.error('An error occurred');
      }
    });
  }

  // retrieveRazze() {
  //   this.apiService.retrieveRazze().subscribe({
  //     next: (data) => {
  //       this.razze = data.result as Razza1[];
  //     },
  //     error: (err) => {
  //     },
  //   });
  // }

  closePopup() {
    this.addL30Qs.reset();
    this.changeButtun = false;
    const domElem = this.view.rootNodes[0] as HTMLElement;
    domElem.classList.add("animate-out");
    domElem.addEventListener("animationend", (event: AnimationEvent) => {
      this.viewContainerRef.clear();
      this.apiService.modaBackDrop.next(false);
    });
  }

  formatDate1(inputDate): any {
    if (inputDate != null) {
      const [year, month, day] = inputDate.split('-');

      // Create the formatted date string in dd/mm/yyyy format
      const formattedDate = `${day}/${month}/${year}`;

      return formattedDate;
    }
  }
  formatDate(date): any {
    if (date !== 'NULL' && date !== null) {
      const dateComponents = date.split('/');
      const formattedDate = new Date(`${dateComponents[2]}-${dateComponents[1]}-${dateComponents[0]}`);

      if (!isNaN(formattedDate.getTime())) {
        // Check if the formattedDate is a valid Date object
        const timeZoneOffset = formattedDate.getTimezoneOffset() * 60000;
        const adjustedDate = new Date(formattedDate.getTime() - timeZoneOffset);
        const formattedDateString = adjustedDate.toISOString().split('T')[0];
        return formattedDateString;
      } else {
        // Handle invalid date
        return 'Invalid Date';
      }
    }
  }
  // incrementString(inputString: any) {
  //   if (inputString !== null) {
  //     const numericPartMatch = inputString.match(/\d+/);
  //     if (numericPartMatch) {
  //       const numericPart = parseInt(numericPartMatch[0], 10);
  //       const incrementedNumericPart = numericPart + 1;
  //       const outputString = inputString.replace(/\d+/, incrementedNumericPart);
  //       return outputString;
  //     } else {
  //       return inputString;
  //     }
  //   }
  // }
}
