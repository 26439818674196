import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ApiService } from 'src/app/_services/api.service';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})

export class PaginationComponent {

  @Input() dataLength: number;
  @Input() itemPerPage: number;
  @Input() tableName: string;
  @Input() specie:string;
  @Input() selectedDbName: string;
  @Input() currentYear: string;
  @Input() columnName: string;
  @Input() filterValue: string;
  @Input() sortingOrder: string;
  @Output() dataOnPage = new EventEmitter<any>();
  currentPage: number = 1;
  totalPages: number = 0;
  constructor(private apiService: ApiService) { this.calculateTotalPages(); }
  ngOnInit(): void {
    this.apiService.selectedPage.subscribe(val => {
      this.currentPage = val;
    })
   }
  ngOnChanges() { this.calculateTotalPages(); }
  private calculateTotalPages() {
    const dataL = +this.dataLength;
    const item = +this.itemPerPage;
    if (!isNaN(dataL) && !isNaN(item) && item > 0) {
      const pages = Math.ceil(dataL / item);
      this.totalPages = pages > 0 ? pages : 1;
    } else {
      this.totalPages = 1;
    }
  }

  get visiblePages(): number[] {
    const range = 1;
    let start = Math.max(1, this.currentPage - range);
    let end = Math.min(this.totalPages, this.currentPage + range);
    if (end - start < (range * 2)) {
      if (start === 1) {
        end = Math.min(end + (range * 2) - (end - start), this.totalPages);
      } else if (end === this.totalPages) { start = Math.max(start - (range * 2) + (end - start), 1); }
    }
    return Array.from({ length: end - start + 1 }, (_, i) => i + start);
  }

  previousPage() { if (this.currentPage > 1) { this.currentPage--; this.fetchData(this.currentPage, this.itemPerPage); } }
  nextPage() {
     if (this.currentPage < this.totalPages) {
    this.currentPage++; this.fetchData(this.currentPage, this.itemPerPage); } }
  goToPage(page: number) {  this.currentPage = page; this.fetchData(this.currentPage, this.itemPerPage); }
  fistPage(page: number) { this.currentPage = page; this.fetchData(this.currentPage, this.itemPerPage); }
  lastPage(page: number) { this.currentPage = page; this.fetchData(this.currentPage, this.itemPerPage); }

  fetchData(page: number, limit: number) {

    let centriCodiceAz = localStorage.getItem('centriCodiceAz') ? localStorage.getItem('centriCodiceAz') : 1;

    if (this.tableName === 'reproduttori') {
      this.apiService.reproduttoriNotFilter(page, limit,this.columnName,this.filterValue,this.sortingOrder).subscribe(
        (response) => {
          this.dataOnPage.emit({ data: response, table: this.tableName,page:page });
        }, (error) => { this.dataOnPage.emit(error); }
      );
    };

    if (this.tableName === 'dnaTable') {
      this.apiService.getDnaTable(page, limit).subscribe(
        (response) => {
          this.dataOnPage.emit({ data: response, table: this.tableName });
        }, (error) => { this.dataOnPage.emit(error); }
      );
    };

    if (this.tableName === 'sample_management') {
      this.apiService.getSampleData(page, limit, centriCodiceAz,'','').subscribe(
        (response) => {
          this.dataOnPage.emit({ data: response, table: this.tableName });
        }, (error) => { this.dataOnPage.emit(error); }
      );
    }
    if (this.tableName === 'courier-management') {
      this.apiService.getCourierAllData(page, limit).subscribe(
        (response) => {
          this.dataOnPage.emit({ data: response, table: this.tableName });
        }, (error) => { this.dataOnPage.emit(error); }
      );
    }
    if (this.tableName === 'l30_vci') {
      this.apiService.getl30dnaData(page, limit,this.columnName,this.filterValue,this.sortingOrder).subscribe(
        (response) => {
          console.log(response)
          this.dataOnPage.emit({ data: response, table: this.tableName ,page:page });
        }, (error) => { this.dataOnPage.emit(error); }
      );
    }
    if (this.tableName === 'newdnadata') {
      this.apiService.getNewDNAData(page, limit,this.columnName,this.filterValue,this.sortingOrder).subscribe({
        next: (response) => {
          this.dataOnPage.emit({ data: response, table: this.tableName,page:page });
        }, error: (error) => { this.dataOnPage.emit(error); }
      });
    };
    if(this.tableName === 'magzino'){
      this.apiService.getmagData(page,limit,this.columnName,this.filterValue,this.sortingOrder).subscribe({
        next: (response) => {
          this.dataOnPage.emit({ data: response, table: this.tableName,page:page });
        }, error: (error) => { this.dataOnPage.emit(error); }
      });
    }

    if(this.tableName === 'archivio'){
      this.apiService.getDbTables({db_name: this.selectedDbName, page_number: page, per_page: this.itemPerPage },this.columnName,this.filterValue,this.sortingOrder).subscribe({
        next: (response) => {
          this.dataOnPage.emit({ data: response, table: this.tableName });
        }, error: (error) => { this.dataOnPage.emit(error); }
      });
    }
    if(this.tableName === 'cq'){
      this.apiService.getCqData(this.currentYear, page,limit,this.columnName,this.sortingOrder).subscribe({
        next: (response) => {
          this.dataOnPage.emit({ data: response, table: this.tableName,page:page });
        }, error: (error) => { this.dataOnPage.emit(error); }
      });
    }

  }

}
