<div class="tabs_wrap">
        <ul>
            <li *ngFor="let entry of entries; let i=index" [attr.data-index]="i" id="li-{{entry}}" (click)="tabSelect($event)" [ngClass]="i==0 ? ' active' : ''">
                {{entry}}            
            </li>
        </ul>
        <select *ngIf="selects">
            <option *ngFor="let sel of selects;" value='{{sel}}'>{{sel}}</option>
        </select>
</div>

