<!-- <app-loader *ngIf="loader"></app-loader> -->
<app-conform [message]="'Are you sure you want to delete?'" (confirm)="confirm($event)"></app-conform>
<div class="pl-2 pr-2 pb-2 pt-1">
	<h2>CAMPIONAMENTI</h2>
	<div class="header d-flex mt-2">
		
		<h2>IN CORSO</h2>
		<button class="button" (click)="openPopup('crea')">{{'Crea Campionamento'|uppercase}}</button>
	</div>
	<!-- <div>
		<h3>Percentuale di lotti campionati : {{numberRowCount}}({{percentage==undefined?'0%':percentage+'%'}})</h3>
	</div> -->
	<div class="mt-1">
		<ag-grid-angular style="height: 600px" class="ag-theme-alpine-dark" [columnDefs]="columnDefs"
			[rowSelection]="'single'" [rowData]="rowData" [animateRows]="true" (cellClicked)="onCellClicked($event)"
			(gridReady)="onGridReady($event)" [gridOptions]="gridOptions"> </ag-grid-angular>
		<div class="mt-1 text-end">
			<!-- <app-pagination [dataLength]="totalCount" [tableName]="tableName" [itemPerPage]="20"
				(dataOnPage)="receiveData($event)"></app-pagination> -->
		</div>
	</div>
	<h2>COMPLETATI</h2>
	<div class="mt-3">
		<ag-grid-angular style="height: 600px" class="ag-theme-alpine-dark" [columnDefs]="columnDefs1"
			[rowSelection]="'single'" [rowData]="pupulatedData" [animateRows]="true" (cellClicked)="onCellClicked($event)"
			(gridReady)="onGridReady($event)" [gridOptions]="gridOptions2"> </ag-grid-angular>
		<div class="mt-1 text-end">
		</div>
	</div>
</div>

<ng-template #popupContainer let-options>
	<div class="popup-window animate-in popup-form">
			<form [formGroup]="form" (ngSubmit)="onSubmit()">
			<div class="d-flex gap-16">
				<div class="field w-100">
					<label class="d-block">*Cod CAMP:</label>
					<input type="text" class="w-100" formControlName="codice_cam" required />
					<div *ngIf="(formSubmitted || fc['codice_cam'].touched) && fc['codice_cam'].errors" class="alert">
					    <div *ngIf="(formSubmitted || fc['codice_cam'].touched)"> Please enter CAMPIONAMENTO</div>
				    </div>
				</div>
				<div class="field w-100">
					<label  class="d-block">*Cod AZIENDA:</label>
					<input type="text" class="w-100" [value]="codeic" formControlName="codice_az" required readonly />
					<div *ngIf="(formSubmitted || fc['codice_az'].touched) && fc['codice_az'].errors" class="alert">
					    <div *ngIf="(formSubmitted || fc['codice_az'].touched)"> Please enter CENTRO</div>
				    </div>
				</div>
			</div>
			<!-- <div class="d-flex gap-16">
				<div class="field w-100">
					<label class="d-block">ANNO RIFERIMENTO:</label>
					<select class="w-100 custom" formControlName="anno_riferimento">
						<option *ngFor="let year of years">{{ year }}</option>
					</select>
				</div>
				<div class="field w-100">
					<label class="d-block">% LOTTI CAMPIONATI:</label>
					<input type="number" [(ngModel)]="lotti" class="w-100" formControlName="lotti_cam"/>
				</div>
			</div> -->
			<div class="d-flex gap-16">
				<div class="field w-100">
					<label class="d-block">Data RiCAR:</label>
					<input type="date" class="w-100" formControlName="rcamp_data"/>
				</div>
				<div class="field w-100">
					<label  class="d-block">Op RiCAR:</label>
					<input type="text" class="w-100" formControlName="rcamo_oper"  />
				</div>
			</div>
			<div class="d-flex gap-16">
				<div class="field w-100">
					<label class="d-block">Data CAMP:</label>
					<input type="date" class="w-100" formControlName="data_campl"  />
				</div>
				<div class="field w-100">
					<label  class="d-block">Data CAMP.2:</label>
					<input type="date" class="w-100"  formControlName="data_camp2" />
				</div>
			</div>
			<div class="d-flex gap-16" >
				<div class="field w-100" >
					<label class="d-block">ORA CAMPIONAMENTO:</label>
					<input type="text" class="w-100"  formControlName="ora_camp" />
				</div>
				<div class="field w-100">
					<label  class="d-block"> Data BOLLA/DNA:</label>
					<input type="date" class="w-100" formControlName="a_dna_data" />
				</div>
			</div>
			<div class="d-flex gap-16" >
				<div class="field w-100">
					<label  class="d-block"> Data VCA/CPS:</label>
					<input type="date" class="w-100" formControlName="faxva_data"  />
				</div>
				<div class="field w-100">
					<label class="d-block">Op VCA/CPS:</label>
					<input type="text" class="w-100" formControlName="faxva_oper" />
				</div>
			</div>
			 <!-- <div class="d-flex gap-16">
				<div class="field w-100">
					<label  class="d-block">NUMERO MINIMO DOSI / LOTTO:</label>
					<input type="number" class="w-100" formControlName="minumo_dosi"/>
				</div>
				<div class="field w-100">
					<label class="d-block">NUMERO MASSIMO TENTATIVI:</label>
					<input type="number" class="w-100" formControlName="massimo_tentivi"/>
				</div>
			</div> -->
			<!-- <div class="d-flex gap-16" *ngIf="!changeButtun">
				<div class="field w-100">
					<label  class="d-block">DA RECORD:</label>
					<input type="number" class="w-100" formControlName="darecord"/>
				</div>
				<div class="field w-100">
					<label class="d-block">A RECORD:</label>
					<input type="number" class="w-100" formControlName="arecord"/>
				</div>
			</div>  -->
			<div class="d-flex gap-16">
				<div class="field w-100">
					<label  class="d-block">Data VCA.2/CPS:</label>
					<input type="date" class="w-100" formControlName="faxva2data"/>
				</div>
				<div class="field w-100">
					<label class="d-block">Op VCA.2/CPS:</label>
					<input type="number" class="w-100" formControlName="faxva2oper"/>
				</div>
			</div> 
			<div class="d-flex gap-16">
				<div class="field w-100">
					<label  class="d-block">Data VCA.2/REG:</label>
					<input type="date" class="w-100" formControlName="regva_data"/>
				</div>
				<div class="field w-100">
					<label class="d-block">Op VCA.2/REG:</label>
					<input type="number" class="w-100" formControlName="regva_oper"/>
				</div>
			</div> 
			<div class="d-flex gap-16">
				<div class="field w-100">
					<label  class="d-block">Data INAD/REG:</label>
					<input type="date" class="w-100" formControlName="regi_data"/>
				</div>
				<div class="field w-100">
					<label class="d-block"> Op INAD/REG:</label>
					<input type="number" class="w-100" formControlName="regi_oper"/>
				</div>
			</div> 
			<div class="d-flex gap-16">
				<div class="field w-100">
					<label  class="d-block">Data INAD.2/REG:</label>
					<input type="date" class="w-100" formControlName="regi2data"/>
				</div>
				<div class="field w-100">
					<label class="d-block">Op INAD.2/REG:</label>
					<input type="number" class="w-100" formControlName="regi2oper"/>
				</div>
			</div> 
			<div class="d-flex gap-16">
				<!-- <div class="field w-100">
					<label  class="d-block">DATA FAXVI:</label>
					<input type="date" class="w-100" formControlName="faxvi_data"/>
				</div> -->
				<!-- <div class="field w-100">
					<label class="d-block">OPERATORE FAXVI:</label>
					<input type="number" class="w-100" formControlName="faxvi_oper"/>
				</div> -->
			</div>
			<div class="d-flex gap-16">
				<!-- <div class="field w-100">
					<label  class="d-block">DATA FAXVI2:</label>
					<input type="date" class="w-100" formControlName="faxvi2data"/>
				</div> -->
				<!-- <div class="field w-100">
					<label class="d-block">OPERATORE FAXVI2:</label>
					<input type="number" class="w-100" formControlName="faxvi2oper"/>
				</div> -->
			</div>
			<div class="d-flex gap-16">
				<!-- <div class="field w-100">
					<label  class="d-block">DATA REGVI:</label>
					<input type="date" class="w-100" formControlName="regvi_data"/>
				</div> -->
				<!-- <div class="field w-100">
					<label class="d-block">OPERATORE REGVI:</label>
					<input type="number" class="w-100" formControlName="regvi_oper"/>
				</div> -->
			</div>
			<div class="d-flex gap-16">
				<!-- <div class="field w-100">
					<label  class="d-block">DATA FAXIE:</label>
					<input type="date" class="w-100" formControlName="faxie_data"/>
				</div> -->
				<!-- <div class="field w-100">
					<label class="d-block">OPERATORE FAXIE:</label>
					<input type="number" class="w-100" formControlName="faxie_oper"/>
				</div> -->
			</div>
			<div class="d-flex gap-16">
				<!-- <div class="field w-100">
					<label  class="d-block">DATA REGIE:</label>
					<input type="date" class="w-100" formControlName="regie_data"/>
				</div> -->
				<!-- <div class="field w-100">
					<label class="d-block">OPERATORE REGIE:</label>
					<input type="number" class="w-100" formControlName="regie_oper"/>
				</div> -->
			</div>
			<div class="main-footer">
				<div class="delete">
					<i class="fa fa-trash customtrash" aria-hidden="true" (click)="openmodel()"></i>
				</div>
				<div class="popup-footer">
					<div><button class="button danger" type="submit">{{changeButtun?'Aggiorna':'Salva'}}</button></div>
					<div><button class="button" type="button" (click)="closePopup()">Chiudi</button></div>
				</div>
			</div>
		</form>
		</div>
</ng-template>
