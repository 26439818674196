import { Component } from "@angular/core";
import { StorageService } from "src/app/_services/storage.service";

@Component({
  selector: "app-instrument-details",
  templateUrl: "./instrument-details.component.html",
  styleUrls: ["./instrument-details.component.scss"],
})
export class InstrumentDetailsComponent {
  record: any;
  
  constructor(private storageService: StorageService) {}

  ngOnInit(): void {
    this.record = this.storageService.insturmentRecord?.data;
  }
}
