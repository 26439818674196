import { Component, ElementRef, OnInit, Renderer2, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ApiService } from '../_services/api.service';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { ColDef, ColumnApi, GridOptions } from 'ag-grid-community';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalService } from '../_services/modal.service';

@Component({
	selector: 'app-centri-section',
	templateUrl: './centri-section.component.html',
	styleUrls: ['./centri-section.component.scss']
})
export class CentriSectionComponent {
	rowdata$: any[];
	rowdata1$: any[];
	defaultColDef: ColDef = { resizable: true, sortable: true, filter: true };
	sortingOrder: string = '';
	columnApi: ColumnApi;
	columnName: string = '';
	changeButtun: boolean = false;
	message: string = ''
	cqColDefs = [
		{
			field: "codifica",
			headerName: "Codifica",
		},
		{
			field: "codice_az",
			headerName: "Codice az",
			valueParser: (params) => Number(params?.data?.codice_az),
      		comparator: (valueA, valueB) => Number(valueA) - Number(valueB),
      		filter: 'agNumberColumnFilter'
		},
		{
			field: "cod_reg",
			headerName: "Cod reg",
			valueParser: (params) => Number(params?.data?.cod_reg),
      		comparator: (valueA, valueB) => Number(valueA) - Number(valueB),
      		filter: 'agNumberColumnFilter'
		},
		{
			field: "appoggio",
			headerName: "Appoggio",
		},
		{
			field: "attivo",
			headerName: "Attivo",
		},
		{
			field: "denominaz",
			headerName: "Denominaz",
		},
		{
			field: "indirizzo",
			headerName: "Indirizzo",
		},

		{
			field: "comune",
			headerName: "Comune",
		},
		{
			field: "tel",
			headerName: "Tel",
		},
		{
			field: "fax",
			headerName: "Fax",
		},
		{
			field: "ora_camp",
			headerName: "Ora camp",
		},
		{
			field: "stampa",
			headerName: "Stampa",
		},
		{
			field: "form_data",
			headerName: "Form data",
			valueParser: (params) => Number(params?.data?.form_data),
			comparator: (valueA, valueB) => Number(valueA) - Number(valueB),
			filter: 'agNumberColumnFilter'
			
		},
		{
			field: "note",
			headerName: "Note",
		},

	];
	GridOptions: GridOptions<any> = {
		columnDefs: this.cqColDefs,
		defaultColDef: this.defaultColDef,
		rowSelection: "single",
		animateRows: true,
		pagination: true,
		paginationAutoPageSize: true,
	};

	tableName: string = 'lotti';
	itemPerPage: number = 14;
	pageNumber: number = 1;
	totalCount: number = 1;
	loader: boolean = false;
	Form: FormGroup;
	cqFormSubmitted: boolean = false;

	@ViewChild("PopupContainer", { read: TemplateRef })
	PopupContainer: TemplateRef<any>;
	view: any;
	currentYear: string = '';
	clickedData: any;
	centriCodiceAz: string | number;
	specie: string;
	anno: string | number;
	isAlive = true;
	subjectValue: boolean = false;
	lastCodiceAz: any;


	constructor(public apiService: ApiService, public toasterService: ToastrService, private http: HttpClient,
		private viewContainerRef: ViewContainerRef, private formBuilder: FormBuilder, private modalService: ModalService, private renderer: Renderer2, private elementRef: ElementRef) {

		this.Form = this.formBuilder.group({
			codifica: [null, Validators.required],
			codice_az: [null],
			cod_reg: [null],
			appoggio: [null],
			attivo: [null, Validators.required],
			denominaz: [null],
			indirizzo: [null],
			comune: [null],
			tel: [null],
			fax: [null],
			ora_camp: [null],
			stampa: [null],
			form_data: [null],
			note: [null]
		})
		this.GridOptions.onSortChanged = this.onSortChanged.bind(this);
	}

	ngOnInit(): void {
		this.getLottiData();
	}
	ngOnDestroy() {
		this.isAlive = false;
		this.apiService.redirectButton.next(false)
	}

	onSortChanged(event) {
		const sortedColumns: any = this.columnApi.getAllColumns().filter(column => column.getSort())[0];
		this.sortingOrder = sortedColumns?.getSort() ?? '';
		this.columnName = sortedColumns?.getColDef().field ?? '';
		this.getLottiData();
	}

	onGridReady(params) {
		this.columnApi = params.columnApi;
	}

	onSubmitCqForm() {
		if(!this.changeButtun){
			this.cqFormSubmitted = true;
			if (this.Form.invalid) {
				return;
			}
			this.apiService.createCentri({...this.Form.value }).subscribe({
				next: (data: any) => {
					if (data.status) {
						this.toasterService.success(data.message);
						this.closePopup();
						this.getLottiData();
					} else {
						this.toasterService.error(data.message);
					}
					this.loader = false
				},
				error: (err) => {
					this.toasterService.error(err.message)
				}
			});
		}else{
			this.cqFormSubmitted = true;
			if (this.Form.invalid) {
				return;
			}
			this.apiService.updateCentri({...this.Form.value,id:this.clickedData.id }).subscribe({
				next: (data: any) => {
					if (data.status) {
						this.toasterService.success(data.message);
						this.closePopup();
						this.getLottiData();
					} else {
						this.toasterService.error(data.message);
					}
					this.loader = false
				},
				error: (err) => {
					this.toasterService.error(err.message)
				}
			});
		}

	}

	getLottiData() {
		this.loader = true;
		this.apiService.getCentriData().subscribe({
			next: (data) => {
				if (data.status) {
					this.totalCount = data?.attivoHavingS?.count;
					this.lastCodiceAz = +data?.LastCodiceAz?.max_codice_az + 1;
					this.rowdata$ = data?.result.attivoHavingS?.rows;
					this.rowdata1$ = data?.result.attivoHavingN?.rows
				} else {
					this.toasterService.error(data.message);
				}
				this.loader = false
			},
			error: (err) => {
				this.rowdata$ = [];
				this.loader = false
				this.toasterService.error(err.message)
			}
		});
	}

	get fc() { return this.Form.controls; }

	openCqFormPopup() {
		this.apiService.modaBackDrop.next(true);
		const bodyElement = this.elementRef.nativeElement.ownerDocument.body;
		this.renderer.setStyle(bodyElement, 'overflow', 'hidden');
		this.view = this.viewContainerRef.createEmbeddedView(this.PopupContainer);
		const domElem = this.view.rootNodes[0] as HTMLElement;
		domElem.classList.add("animate-in");
	}
	openPopup(){
		this.Form.get('codice_az').setValue(this.lastCodiceAz)
	}

	closePopup() {
		this.Form.reset();
		this.changeButtun = false
		const bodyElement = this.elementRef.nativeElement.ownerDocument.body;
		this.renderer.setStyle(bodyElement, 'overflow', 'auto');
		const domElem = this.view.rootNodes[0] as HTMLElement;
		domElem.classList.add("animate-out");
		domElem.addEventListener("click", (event: AnimationEvent) => {
			this.viewContainerRef.clear();
			this.apiService.modaBackDrop.next(false);
		});
	}




	onCellClicked(event: any) {
		this.changeButtun = true;
		this.clickedData = event.data;
		// let data = this.formatDate(this.clickedData.data_prot)
		this.openCqFormPopup();
		this.Form.patchValue({
			codifica: this.clickedData.codifica,
			codice_az: this.clickedData.codice_az,
			cod_reg: this.clickedData.cod_reg,
			appoggio: this.clickedData.appoggio,
			attivo: this.clickedData.attivo,
			denominaz: this.clickedData.denominaz,
			indirizzo: this.clickedData.indirizzo,
			comune: this.clickedData.comune,
			tel: this.clickedData.tel,
			fax: this.clickedData.fax,
			ora_camp: this.clickedData.ora_camp,
			stampa: this.clickedData.stampa,
			form_data: this.clickedData.form_data,
			note: this.clickedData.note
		})
	}

	updateLotti() {
		this.cqFormSubmitted = true;
		if (this.Form.invalid) {
			return;
		}
		this.apiService.updateLottiData({ ...this.Form.value }).subscribe({
			next: (data: any) => {
				if (data.status) {
					this.toasterService.success(data.message);
					this.closePopup();
					this.getLottiData();
				} else {
					this.toasterService.error(data.message);
				}
				this.loader = false
			},
			error: (err) => {
				this.toasterService.error(err.message)
			}
		});
	}
}
