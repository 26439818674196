import { Component, ElementRef, OnInit, Renderer2, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ApiService } from '../_services/api.service';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { ColDef, ColumnApi, GridOptions } from 'ag-grid-community';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalService } from '../_services/modal.service';

@Component({
	selector: 'app-controllo-interno',
	templateUrl: './controllo-interno.component.html',
	styleUrls: ['./controllo-interno.component.scss']
})
export class ControlloInternoComponent implements OnInit {
	// cq Table Data
	cqTableData$: any[];
	defaultColDef: ColDef = { resizable: true, sortable: true, filter: true };
	sortingOrder: string= 'DESC';
  	columnApi: ColumnApi;
	columnName: string = 'data';
	changeButtun:boolean = false;
	cqColDefs = [
		{
			field: "data",
			headerName: "Data",
			width: 150,
			comparator: this.dateComparator,
		},
		{
			field: "",
			headerName: "STATO",
			width: 150,
				cellRenderer: function (params) {
					var color = "Green";

					if(params?.data?.sp100_ok == "S" && params?.data?.sp1002_ok == "S" && params?.data?.ivos12_ok == "S"&&params?.data?.ivos14_ok == "S" && params?.data?.z2_ok == "S" ){
						color = "Green"
					}else{
						color = "Red"
					}
					// if (params.data.Stato == "OK") {
					// 	color = "#a6e3a1";
					// }
					// if (params.data.Stato == "NOT_FOUND") {
					// 	color = "#9399b2";
					// }
					// if (params.data.Stato == "WARNING") {
					// 	color = "#f9e2af";
					// }
					// if (params.data.Stato == "DUPLICATE") {
					// 	color = "#89b4fa";
					// }
					let html = `<div style="display:flex;height: 100%;aspect-ratio: 1 / 1;align-items: center;">\
			  <div style="height: 50%;aspect-ratio: 1 / 1;background-color: ${color};border-radius: 50%;text-align: center;"></div>\
			</div>`;
					return html;
				},
		},
		{
			field: "settimana",
			headerName: "Settimana",
			width: 150,
			valueParser: (params) => Number(params?.data?.settimana),
			comparator: (valueA, valueB) => Number(valueA) - Number(valueB),
			filter: 'agNumberColumnFilter'
			
		},
		{
			field: "id_rif",
			headerName: "id_rif",
			width: 150,
		},
		{
			field: "op_sp100",
			headerName: "op_sp100",
			width: 150,
			valueParser: (params) => Number(params?.data?.op_sp100),
      		comparator: (valueA, valueB) => Number(valueA) - Number(valueB),
      		filter: 'agNumberColumnFilter'
		},
		{
			field: "conc_sp100",
			headerName: "conc_sp100",
			width: 150,
			valueParser: (params) => Number(params?.data?.conc_sp100),
			comparator: (valueA, valueB) => Number(valueA) - Number(valueB),
			filter: 'agNumberColumnFilter'
		},
		{
			field: "op2sp100",
			headerName: "op2sp100",
			width: 150,
			valueParser: (params) => Number(params?.data?.op2sp100),
			comparator: (valueA, valueB) => Number(valueA) - Number(valueB),
			filter: 'agNumberColumnFilter'
		},
		{
			field: "conc2sp100",
			headerName: "conc2sp100",
			width: 150,
			valueParser: (params) => Number(params?.data?.conc2sp100),
			comparator: (valueA, valueB) => Number(valueA) - Number(valueB),
			filter: 'agNumberColumnFilter'
		},
		{
			field: "op_z2",
			headerName: "op_z2",
			width: 150,
			valueParser: (params) => Number(params?.data?.op_z2),
			comparator: (valueA, valueB) => Number(valueA) - Number(valueB),
			filter: 'agNumberColumnFilter'
		},
		{
			field: "conc_z2",
			headerName: "conc_z2",
			width: 150,
			valueParser: (params) => Number(params?.data?.conc_z2),
			comparator: (valueA, valueB) => Number(valueA) - Number(valueB),
			filter: 'agNumberColumnFilter'
		},
		{
			field: "op_ivos12",
			headerName: "op_ivos12",
			width: 150,
			valueParser: (params) => Number(params?.data?.op_ivos12),
			comparator: (valueA, valueB) => Number(valueA) - Number(valueB),
			filter: 'agNumberColumnFilter'
		},
		{
			field: "mt_ivos12",
			headerName: "mt_ivos12",
			width: 150,
			valueParser: (params) => Number(params?.data?.mt_ivos12),
			comparator: (valueA, valueB) => Number(valueA) - Number(valueB),
			filter: 'agNumberColumnFilter'
		},
		{
			field: "mp_ivos12",
			headerName: "mp_ivos12",
			width: 150,
			valueParser: (params) => Number(params?.data?.mp_ivos12),
			comparator: (valueA, valueB) => Number(valueA) - Number(valueB),
			filter: 'agNumberColumnFilter'
		},
		{
			field: "vm_ivos12",
			headerName: "vm_ivos12",
			width: 150,
			valueParser: (params) => Number(params?.data?.vm_ivos12),
			comparator: (valueA, valueB) => Number(valueA) - Number(valueB),
			filter: 'agNumberColumnFilter'
		},
		{
			field: "op_ivos14",
			headerName: "op_ivos14",
			width: 150,
			valueParser: (params) => Number(params?.data?.op_ivos14),
			comparator: (valueA, valueB) => Number(valueA) - Number(valueB),
			filter: 'agNumberColumnFilter'
		},
		{
			field: "mt_ivos14",
			headerName: "mt_ivos14",
			width: 150,
			valueParser: (params) => Number(params?.data?.mt_ivos14),
			comparator: (valueA, valueB) => Number(valueA) - Number(valueB),
			filter: 'agNumberColumnFilter'
		},
		{
			field: "mp_ivos14",
			headerName: "mp_ivos14",
			width: 150,
			valueParser: (params) => Number(params?.data?.mp_ivos14),
			comparator: (valueA, valueB) => Number(valueA) - Number(valueB),
			filter: 'agNumberColumnFilter'
		},
		{
			field: "vm_ivos14",
			headerName: "vm_ivos14",
			width: 150,
			valueParser: (params) => Number(params?.data?.vm_ivos14),
			comparator: (valueA, valueB) => Number(valueA) - Number(valueB),
			filter: 'agNumberColumnFilter'
		},
		{
			field: "sp100_ok",
			headerName: "sp100_ok",
			width: 150,
		},
		{
			field: "sp1002_ok",
			headerName: "sp1002_ok",
			width: 150,
		},
		{
			field: "ivos12_ok",
			headerName: "ivos12_ok",
			width: 150,
		},
		{
			field: "ivos14_ok",
			headerName: "ivos14_ok",
			width: 150,
		},
		{
			field: "z2_ok",
			headerName: "z2_ok",
			width: 150,
		}
	];
	cqGridOptions: GridOptions<any> = {
		columnDefs: this.cqColDefs,
		defaultColDef: this.defaultColDef,
		rowSelection: "single",
		animateRows: true,
	};

	tableName: string = 'cq';
	itemPerPage: number = 14;
	pageNumber: number = 1;
	totalCount: number = 1;
	loader: boolean = false;
	cqForm: FormGroup;
	cqFormSubmitted: boolean = false;
	isToggleCq: boolean = true;


	@ViewChild("cqPopupContainer", { read: TemplateRef })
	cqPopupContainer: TemplateRef<any>;
	view: any;
	currentYear: string = '';
	clickedData: any;


	constructor(public apiService: ApiService, private toasterService: ToastrService, private http: HttpClient,
		private viewContainerRef: ViewContainerRef, private formBuilder: FormBuilder,private modalService: ModalService ,
		private renderer: Renderer2,
		private elementRef: ElementRef) {

		this.cqForm = this.formBuilder.group({
			data: [null, Validators.required],
			settimana: [null],
			id_rif: [null],
			op_sp100: [null],
			conc_sp100: [null, Validators.required],
			op2sp100: [null],
			conc2sp100: [null, Validators.required],
			op_z2: [null],
			conc_z2: [null],
			op_ivos12: [null],
			mt_ivos12: [null, Validators.required],
			mp_ivos12: [null, Validators.required],
			vm_ivos12: [null, Validators.required],
			op_ivos14: [null],
			mt_ivos14: [null, Validators.required],
			mp_ivos14: [null, Validators.required],
			vm_ivos14: [null, Validators.required],
			sp100_ok: [null],
			sp1002_ok: [null],
			z2_ok: [null],
			ivos12_ok: [null],
			ivos14_ok: [null],
		})
		this.cqGridOptions.onSortChanged = this.onSortChanged.bind(this);

	}

	ngOnInit(): void {
		this.getCqData();
	}

	onSortChanged(event) {
		const sortedColumns:any = this.columnApi.getAllColumns().filter(column => column.getSort())[0];
		this.sortingOrder = sortedColumns?.getSort() ?? '';
		this.columnName = sortedColumns?.getColDef().field ?? '';
		this.getCqData();
	  }
	
	  onGridReady(params) {
		this.columnApi = params.columnApi;
	  }

	onSubmitCqForm() {
		if(!this.changeButtun){
			this.cqFormSubmitted = true;
			if (this.cqForm.invalid) {
				return;
			}
	
			let data  = this.formatDate1(this.cqForm.value.data)
			this.apiService.submitCqData({...this.cqForm.value,data:data}).subscribe({
				next: (data:any) => {
					if (data.success) {
						this.toasterService.success(data.message);
						this.closePopup();
						this.getCqData();
					} else {
						this.toasterService.error(data.message);
					}
					this.loader = false
				},
				error: (err) => {
					this.toasterService.error(err.message)
				}
			});
		}else{
			this.cqFormSubmitted = true;
			if (this.cqForm.invalid) {
				return;
			}
	
			let data  = this.formatDate1(this.cqForm.value.data)
			this.apiService.updateCqData({...this.cqForm.value,data:data,id:this.clickedData.id}).subscribe({
				next: (data:any) => {
					if (data.success) {
						if(data?.color == 'red'){
							this.toasterService.error(data.description);
						}else{
							this.toasterService.success(data.description);
						}
						this.closePopup();
						this.getCqData();
					} else {
						this.toasterService.error(data.description);
					}
					this.loader = false
				},
				error: (err) => {
					this.toasterService.error(err.description)
				}
			});

		}
	}

	onReceiveData(event: any) {
		if (event?.table === this.tableName) {
			this.cqTableData$ = event?.data?.result.rows;
			this.totalCount = event?.data?.totalCount;
			this.pageNumber = event?.page
		}
	}


	onChangeToggle() {
		this.getCqData();
		this.apiService.selectedPage.next(1);
	}
	getCqData() {
		this.loader = true;
		this.currentYear = this.isToggleCq ? (new Date().getFullYear()).toString() : '';
		this.apiService.getCqData(this.currentYear, this.pageNumber, this.itemPerPage,this.columnName ,this.sortingOrder).subscribe({
			next: (data) => {
				if (data.success) {
					this.totalCount = data.totalCount;
					this.cqTableData$ = data?.result?.rows ? data?.result?.rows : [];
				} else {
					this.toasterService.error(data.message);
				}
				this.loader = false
			},
			error: (err) => {
				this.cqTableData$ = [];
				this.loader = false
				this.toasterService.error(err.message)
			}
		});
	}







	get fc() { return this.cqForm.controls; }

	// openStatisticaPopup() {
	// 	console.log('statistica clicked!!!!!');
	// 	this.apiService.modaBackDrop.next(true);
	// 	this.view = this.viewContainerRef.createEmbeddedView(this.statisticaPopupContainer);
	// 	const domElem = this.view.rootNodes[0] as HTMLElement;
	// 	domElem.classList.add("animate-in");
	// }

	openCqFormPopup() {
		this.apiService.modaBackDrop.next(true);
		const bodyElement = this.elementRef.nativeElement.ownerDocument.body;
		this.renderer.setStyle(bodyElement, 'overflow', 'hidden');
		this.view = this.viewContainerRef.createEmbeddedView(this.cqPopupContainer);
		const domElem = this.view.rootNodes[0] as HTMLElement;
		domElem.classList.add("animate-in");
	}

	closePopup() {
		this.cqForm.reset();
		this.changeButtun = false
		const bodyElement = this.elementRef.nativeElement.ownerDocument.body;
		this.renderer.setStyle(bodyElement, 'overflow', 'auto');
		const domElem = this.view.rootNodes[0] as HTMLElement;
		domElem.classList.add("animate-out");
		domElem.addEventListener("click", (event: AnimationEvent) => {
			this.viewContainerRef.clear();
			this.apiService.modaBackDrop.next(false);
		});
	}

	openCarta() {
		console.log('openCarta clicked!!!!!');
	}

	openAnalisi() {
		console.log('openAnalisi clicked!!!!!');
	}
	formatDate1(inputDate):any {
		if (inputDate != null) {
			const [year, month, day] = inputDate.split('-');
	
			// Create the formatted date string in dd/mm/yyyy format
			const formattedDate = `${day}/${month}/${year}`;
	
			return formattedDate;
		}
	}

	
	formatDate(date): any {
		if (date !== 'NULL' && date !== null) {
			const dateComponents = date.split('/');
			const formattedDate = new Date(`${dateComponents[2]}-${dateComponents[1]}-${dateComponents[0]}`);

			if (!isNaN(formattedDate.getTime())) {
				// Check if the formattedDate is a valid Date object
				const timeZoneOffset = formattedDate.getTimezoneOffset() * 60000;
				const adjustedDate = new Date(formattedDate.getTime() - timeZoneOffset);
				const formattedDateString = adjustedDate.toISOString().split('T')[0];
				return formattedDateString;
			} else {
				// Handle invalid date
				return 'Invalid Date';
			}
		}
	}
	onCellClicked(event: any) {
		this.changeButtun = true;
		this.clickedData= event.data;
		let data  = this.formatDate(this.clickedData.data)
		this.openCqFormPopup();
		this.cqForm.patchValue({
			data: data,
			settimana: this.clickedData.settimana,
			id_rif: this.clickedData.id_rif,
			op_sp100: this.clickedData.op_sp100,
			conc_sp100:this.clickedData.conc_sp100,
			op2sp100: this.clickedData.op2sp100,
			conc2sp100:this.clickedData.conc2sp100,
			op_z2: this.clickedData.op_z2,
			conc_z2: this.clickedData.conc_z2,
			op_ivos12:this.clickedData.op_ivos12,
			mt_ivos12: this.clickedData.mt_ivos12,
			mp_ivos12: this.clickedData.mp_ivos12,
			vm_ivos12: this.clickedData.vm_ivos12,
			op_ivos14: this.clickedData.op_ivos14,
			mt_ivos14: this.clickedData.mt_ivos14,
			mp_ivos14: this.clickedData.mp_ivos14,
			vm_ivos14: this.clickedData.vm_ivos14,
			z2_ok:this.clickedData.z2_ok,
			sp100_ok:this.clickedData.sp100_ok,
			sp1002_ok:this.clickedData.sp1002_ok,
			ivos12_ok:this.clickedData.ivos12_ok,
			ivos14_ok:this.clickedData.ivos14_ok
		})
	}

	openmodel() {
		this.modalService.openModal("cq");
	}
	confirm(event) {
		this.deleteCqRow(this.clickedData.id)
	}
	deleteCqRow(id){
		this.apiService.deleteCq(id).subscribe((res:any)=>{
			if(res?.success){
				this.toasterService.success(res?.description)
				this.closePopup()
				this.getCqData()
			}
		})
	}

	dateComparator(date1: string | null, date2: string | null): number {
		// Check if either date is null or undefined
		if (!date1 || !date2) {
			// You can decide what to return in case of null values
			// For example, if date1 is null, consider it as smaller than any non-null date2
			return date1 ? 1 : -1;
		}

		const dateParts1 = date1.split('/');
		const dateParts2 = date2.split('/');

		// Create date objects in the format YYYY-MM-DD
		const d1 = new Date(Number(dateParts1[2]), Number(dateParts1[1]) - 1, Number(dateParts1[0]));
		const d2 = new Date(Number(dateParts2[2]), Number(dateParts2[1]) - 1, Number(dateParts2[0]));

		return d1.getTime() - d2.getTime();
	}
}
