<app-loader *ngIf="loader"></app-loader>
<div class="specie-main">
    <h1 class="semi-bold mb-1">REGISTRO SEME DNA</h1>
    <div class="head-btn">
        <button class="button1" (click)="openPopup('Replica')">Replica</button>
        <button class="button1" (click)="openPopup('RPlus')">R+</button>
        <button class="button1" (click)="openPopup('R2')">R2</button>
        <button class="button1" (click)="downlaodEstratto()">ESTRATTO</button>
        <button class="button1" (click)="openPopup('Rifer')">Rifer</button>
        <button class="button1" (click)="openPopup('Piastra')">Piastra</button>
        <!-- <button class="button1" (click)="openPopupPiastra('PIASTRA_MANUALE')">PIASTRA MANUALE</button> -->
        <button class="button1" (click)="openPopup('fillDown')">fill down</button>
        <button class="button1" (click)="onClickStatoRif()">Stato Rif</button>
    </div>
</div>
<ag-grid-angular style="height: 750px;" class="ag-theme-alpine-dark basic-table" [rowData]="rowData$"
    [gridOptions]="gridOptions" [rowSelection]="'multiple'" (gridReady)="onGridReady($event)"
    (rowClicked)="onRowClicked($event)">
</ag-grid-angular>

<ng-template #popupContainer let-options>
    <div class="popup-window animate-in" [ngStyle]="{
        'min-width': btnPopup ? '15%' : '60%', 
        'height': btnPopup||piastrabutton? '600px' : 'calc(100vh - 120px)',
        'overflow': btnPopup ? 'hidden' : 'auto'
    }">
        <div *ngIf="btnPopup">
            <form [formGroup]="form" (ngSubmit)="onSubmit()">
                <div formArrayName="checkboxItems">
                    <label *ngFor="let item of items; let i = index" class="container-checkbox">{{ item.name }}
                        <input type="checkbox" [formControlName]="i" />
                        <span class="checkmark"></span>
                    </label>
                </div>
                <div class="popup-footer">
                    <button type="submit" class="button">Submit</button>
                    <button type="button" class="button" (click)="closePopup('Replica')">Chiudi</button>
                </div>
            </form>
        </div>
        <div *ngIf="riferBtn">
            <form [formGroup]="formIndietro" (ngSubmit)="onSubmitIndietro()">
                <div class="row">
                    <div class="column">
                        <label>*Nome del Toro:</label>
                        <input type="text" formControlName="toro" required />
                        <div *ngIf="(formIndietro?.controls['toro']?.invalid) && (formIndietro?.controls['toro']?.dirty || formIndietro?.controls['toro']?.touched)"
                            class="alert">
                            <div *ngIf="formIndietro?.controls['toro']?.hasError('required')">
                                Please enter toro
                            </div>
                        </div>
                    </div>
                    <div class="column">
                        <label>*Matricola:</label>
                        <input type="text" formControlName="matricola" required />
                        <div *ngIf="(formIndietro?.controls['matricola'].invalid) && (formIndietro?.controls['matricola'].dirty || formIndietro?.controls['matricola'].touched)"
                            class="alert">
                            <div *ngIf="formIndietro?.controls['matricola']?.hasError('required')">
                                Please enter matricola
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="column">
                        <label>Matricola2:</label>
                        <input type="text" formControlName="matricola2" />
                    </div>
                    <div class="column">
                        <label>MatricolaANA:</label>
                        <input type="text" formControlName="matric_ana" />
                    </div>
                </div>

                <div class="row">
                    <div class="column">
                        <label>DNA:</label>
                        <input type="text" formControlName="cod_dna" readonly />
                    </div>
                    <div class="column">
                        <label>Provato:</label>
                        <input type="text" formControlName="provato" />
                    </div>
                </div>
                <div class="row">
                    <div class="column">
                        <label>Top100:</label>
                        <input type="text" formControlName="top100" />
                    </div>
                    <div class="column">
                        <label>Nascita:</label>
                        <input type="date" formControlName="data_nasc" />
                    </div>
                </div>
                <div class="row">
                    <div class="column">
                        <label>*Specie:</label>
                        <select formControlName="specie" required (change)="selectSpecie($event)">
                            <option [value]="specie?.value" *ngFor="let specie of spaciesData;">{{specie?.name}}
                            </option>
                        </select>
                        <div *ngIf="(formIndietro?.controls['specie']?.invalid) && (formIndietro?.controls['specie']?.dirty || formIndietro?.controls['specie']?.touched)"
                            class="alert">
                            <div *ngIf="formIndietro?.controls['specie']?.hasError('required')">
                                Please enter specie
                            </div>
                        </div>
                    </div>
                    <div class="column">
                        <label>Razza:</label>
                        <select formControlName="razza">
                            <option [ngValue]="null">Scegli la Razza</option>
                            <option *ngFor="let razza of razzeData" [ngValue]="razza.razza">
                                {{ razza.razza_nome }}
                            </option>
                        </select>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="column">
                        <label>Cilindro:</label>
                        <input type="text" formControlName="cilindro" readonly />
                    </div>
                    <div class="column">
                        <label>Contenit:</label>
                        <input type="text" formControlName="contenit" readonly />
                    </div>
                </div>
                <div class="row">
                    <div class="column">
                        <label>Data:</label>
                        <input type="text" formControlName="data" readonly />
                    </div>
                    <div class="column">
                        <label>Diluizione:</label>
                        <input type="text" formControlName="diluizione" readonly />
                    </div>
                </div>
                <div class="row">
                    <div class="column">
                        <label>Dna_seme:</label>
                        <input type="text" formControlName="dna_seme" readonly />
                    </div>
                    <div class="column">
                        <label>Estrazione:</label>
                        <input type="text" formControlName="estrazione" readonly />
                    </div>
                </div>
                <div class="row">

                    <div class="column">
                        <label>Note:</label>
                        <input type="text" formControlName="note" readonly />
                    </div>
                    <div class="column">
                        <label>Piano:</label>
                        <input type="text" formControlName="piano" readonly />
                    </div>
                </div>
                <div class="row">
                    <div class="column">
                        <label>Riceviment:</label>
                        <input type="text" formControlName="riceviment" readonly />
                    </div>
                    <div class="column">
                        <label>Settore:</label>
                        <input type="text" formControlName="settore" readonly />
                    </div>
                </div>
                <div class="row">
                    <div class="column">
                        <label>Tipo_seq:</label>
                        <input type="text" formControlName="tipo_seq" readonly />
                    </div>
                </div>
                <div class="popup-footer">
                    <button type="submit" class="button" [disabled]="!formIndietro.valid">Aggiorna</button>
                    <button type="button" class="button" (click)="closePopup('Rifer')">Chiudi</button>
                </div>
            </form>
        </div>

        <div *ngIf="piastrabutton">
            <form [formGroup]="piastraFrom" (ngSubmit)="onSubmitPiastra()" class="form-container">
                <div class="row">
                    <div class="column">
                        <label>ID_PIASTRA:</label>
                        <select formControlName="ID_PIASTRA" (change)="onSelectionChange($event)">
                            <option *ngFor="let item of piastraData" [value]="item?.ID_PIASTRA">
                                {{ item?.ID_PIASTRA + ' - ' + 'Posti Disponibili: ' + item?.Avail_Slots }}
                            </option>
                        </select>
                    </div>
                    <div class="column">
                        <label>Tipo Estrattore:</label>
                        <input type="text" formControlName="tipo_seq" />
                    </div>
                </div>
                <div class="row">
                    <div class="column">
                        <label>DATA_RIF:</label>
                        <input type="date" formControlName="DATA_RIF" [(ngModel)]="currentDate" />
                    </div>
                    <div class="column radio">
                        <label>File Type:</label>
                        <input type="hidden" formControlName="CELLEPIENE" />
                        <label class="container">xlsx
                            <input type="radio" value="xlsx" formControlName="buttonType" />
                            <span class="check"></span>
                        </label>
                        <label class="container">Txt
                            <input type="radio" value="text" formControlName="buttonType" checked />
                            <span class="check"></span>
                        </label>
                    </div>
                </div>
                <div class="custombutton">
                    <button type="button" class="button1" (click)="addItem()">Add Item</button>
                </div>
                <div class="row">
                    <div formArrayName="id_seme" class="column">
                        <label>ID Seme:</label>
                        <div *ngFor="let item of id_seme.controls; let i = index" [formGroupName]="i" class="mainclass">
                            <div class="columns">
                                <input type="text" formControlName="value" />
                            </div>
                            <div class="removebutton" *ngIf="i == id_seme.controls.length - 1 && i != 0">
                                <button type="button" class="button2" (click)="removeItem(i)">Remove</button>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="popup-footer">
                    <button type="button" class="button" (click)="previewPisatraButton()">Preview</button>
                    <button type="submit" class="button" [disabled]="!piastraFrom.valid">Aggiorna</button>
                    <button type="button" class="button" (click)="closePopup('Piastra')">Chiudi</button>
                </div>
            </form>
        </div>
    </div>
</ng-template>
<ng-template #popupContainer1 let-options>
    <div class="popup-window animate-in updateform">
        <div *ngIf="doubleClick">
            <form [formGroup]="formupdate" (ngSubmit)="updateDnaRow()">
                <div class="row">
                    <div class="column">
                        <label>*Codice Camp:</label>
                        <input type="text" formControlName="codice_cam" required />
                        <div *ngIf="(formupdate?.controls['codice_cam']?.invalid) && (formupdate?.controls['codice_cam']?.dirty || formupdate?.controls['toro']?.touched)"
                            class="alert">
                            <div *ngIf="formupdate?.controls['codice_cam']?.hasError('required')">
                                Please enter codice_cam
                            </div>
                        </div>
                    </div>
                    <div class="column">
                        <label>Stato:</label>
                        <!-- <input type="text" formControlName="stato" /> -->
                        <select formControlName="stato" [(ngModel)]="selectedStatus">
                            <option *ngFor="let option of statusOptions" [value]="option.id">
                                {{ option.label }}
                            </option>
                        </select>
                    </div>

                </div>
                <div class="row">
                    <div class="column">
                        <label>*ID Seme:</label>
                        <input type="text" formControlName="cod_seme" required />
                        <div *ngIf="(formupdate?.controls['cod_seme'].invalid) && (formupdate?.controls['cod_seme'].dirty || formupdate?.controls['cod_seme'].touched)"
                            class="alert">
                            <div *ngIf="formupdate?.controls['cod_seme']?.hasError('required')">
                                Please enter cod_seme
                            </div>
                        </div>
                    </div>
                    <div class="column">
                        <label>Ripetizione:</label>
                        <input type="number" formControlName="ripetizion" />
                    </div>
                </div>

                <div class="row">
                    <div class="column">
                        <label>Data Ricevimento:</label>
                        <input type="date" formControlName="data_ricev" />
                    </div>
                    <div class="column">
                        <label>Operatore Ricevimento:</label>
                        <input type="text" formControlName="op_ricev" />
                    </div>

                </div>
                <div class="row">
                    <div class="column">
                        <label>Data Estrazione:</label>
                        <input type="date" formControlName="data_estr" />
                    </div>

                    <div class="column">
                        <label>Operatore Estrazione:</label>
                        <input type="text" formControlName="estrazione" />
                    </div>

                </div>
                <div class="row">

                    <div class="column">
                        <label>ID PCR:</label>
                        <input type="text" formControlName="id_pcr" />
                    </div>
                    <div class="column">
                        <label>Data PCR:</label>
                        <input type="date" formControlName="data_pcr" />
                    </div>
                </div>

                <div class="row">

                    <div class="column">
                        <label>Operatore PCR :</label>
                        <input type="text" formControlName="pcr" />
                    </div>
                    <div class="column">
                        <label>Operatore Caricamento:</label>
                        <input type="number" formControlName="mix_car" />
                    </div>
                </div>
                <div class="row">

                    <div class="column">
                        <label>N° Run:</label>
                        <input type="text" formControlName="num_gel" />
                    </div>
                    <div class="column">
                        <label>Data Run :</label>
                        <input type="date" formControlName="data_gel" />
                    </div>
                </div>
                <div class="row">
                    <!-- <div class="column">
                        <label>op_mac:</label>
                        <input type="text" formControlName="op_mac" />
                    </div> -->
                    <div class="column">
                        <label>Tipo Sequenziatore:</label>
                        <input type="text" formControlName="sequenziat" />
                    </div>

                    <div class="column">
                        <label>Operatore Sequenziatore:</label>
                        <input type="text" formControlName="risultati" />
                    </div>

                </div>
                <div class="row">
                    <div class="column">
                        <label>OP L30:</label>
                        <input type="text" formControlName="op_dos" />
                    </div>
                    <!-- <div class="column">
                        <label>analisi_ok:</label>
                        <input type="text" formControlName="analisi_ok" />
                    </div> -->
                    <div class="column">
                        <label>Data VCI:</label>
                        <input type="date" formControlName="faxvi_data" />
                    </div>
                </div>
                <div class="row">
                    <div class="column">
                        <label>Operatore VCI:</label>
                        <input type="text" formControlName="faxvi_oper" />
                    </div>
                    <div class="column">
                        <label>Data VCI2:</label>
                        <input type="text" formControlName="faxvi2data" />
                    </div>

                </div>
                <div class="row">
                    <div class="column">
                        <label>Operatore VCI2:</label>
                        <input type="text" formControlName="faxvi2oper" />
                    </div>
                    <div class="column">
                        <label>Data invio Reg:</label>
                        <input type="text" formControlName="regvi_data" />
                    </div>


                </div>
                <div class="row">
                    <div class="column">
                        <label>Operatore invio Reg :</label>
                        <input type="text" formControlName="regvi_oper" />
                    </div>
                    <div class="column">
                        <label>ID piastra:</label>
                        <input type="text" formControlName="id_piastra" />
                    </div>
                </div>
                <div class="row">
                    <!-- <div class="column">
                        <label>tank_rif:</label>
                        <input type="text" formControlName="tank_rif" />
                    </div> -->
                    <div class="column">
                        <label>N.C.:</label>
                        <input type="text" formControlName="non_conf" />
                    </div>
                </div>

                <div class="popup-footer">
                    <button type="submit" class="button" [disabled]="!formupdate.valid">Salva</button>
                    <button type="button" class="button" (click)="closePopup('Rifer')">Chiudi</button>
                </div>
            </form>
        </div>
    </div>
</ng-template>