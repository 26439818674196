import { Component, OnInit, HostListener, ViewChild, OnDestroy, TemplateRef, ViewContainerRef, Renderer2, ElementRef } from "@angular/core";
import { AgGridAngular } from "ag-grid-angular";
import { CellClickedEvent, ColDef, GridOptions, GridReadyEvent, RowSelectedEvent } from "ag-grid-community";
import { ApiService } from "../_services/api.service";
import { Centro, GenericResponse, Toro } from "../_models/index";
import FuzzySearch from "fuzzy-search";
import { Router } from "@angular/router";
import { StorageService } from "../_services/storage.service";
import { dummyData } from "../../assets/data/data";
import { takeWhile } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";

export interface Campionati {
	message: string;
	details: Map<string, ArrayBuffer>;
}
@Component({
	selector: "app-autocertificazioni",
	templateUrl: "./autocertificazioni.component.html",
	styleUrls: ["./autocertificazioni.component.scss"],
})
export class AutocertificazioniComponent {
	files: File[] = [];
	showFileNotUploadedTemplate: boolean = false;
	uploadedFile: boolean;
	dragOver = false;
	review = false;
	importWarnings = true;
	rowData: any = [];
	rowData$: any[] = [];
	test = "abc";
	view: any;
	Form: FormGroup;
	form = new FormGroup({
		dataProt: new FormControl(new Date().toISOString().substring(0, 10)),
		operator: new FormControl(null),
	});
	// Each Column Definition results in one Column.
	colDefs: ColDef<any>[] = [
		{ field: "codice_cam", headerName: "COD_CAM", width: 250 },
		{ field: "toro", headerName: "Toro", width: 250 },
		{ field: "cod_toro", headerName: "Cod toro", width: 250 },
		{ field: "lotto", headerName: "Lotto", width: 250 },
		{
			field: "data_ac", headerName: "Data ac",
			width: 250,

			comparator: this.dateComparator,

		},
		{ field: "conc_c", headerName: "Conc c", width: 250 },
		{ field: "mp_c", headerName: "Mp c", width: 250 },
		{ field: "nspm_c", headerName: "Nspm c", width: 250 },
		{ field: "num_anal", headerName: "Num anal", width: 250 },
		{ field: "non_conf", headerName: "Non conf", width: 250 },
	];
	defaultColDef1: ColDef = { resizable: true, sortable: true, filter: true };

	public columnDefs: ColDef[] = [
		{
			field: "LOTTO",
			headerClass: "custom-header-class",
		},
		{ field: "NOME" },
		{ field: "CONC_C" },
		{
			field: "MP_C",
		},
		{
			// cellRenderer: (params) => (params.value == undefined ? params.data.Nome || params.value : params.value),
			field: "NSPM_C",
		},
		{
			// 	cellRenderer: function (params) {
			// 		var color = "#f38ba8";
			// 		if (params.data.Stato == "OK") {
			// 			color = "#a6e3a1";
			// 		}
			// 		if (params.data.Stato == "NOT_FOUND") {
			// 			color = "#9399b2";
			// 		}
			// 		if (params.data.Stato == "WARNING") {
			// 			color = "#f9e2af";
			// 		}
			// 		if (params.data.Stato == "DUPLICATE") {
			// 			color = "#89b4fa";
			// 		}
			// 		let html = `<div style="display:flex;height: 100%;aspect-ratio: 1 / 1;align-items: center;">\
			//   <div style="height: 50%;aspect-ratio: 1 / 1;background-color: ${color};border-radius: 50%;text-align: center;"></div>\
			// </div>`;
			// 		return html;
			// 	},
			field: "NOME_DB",
		},
		{
			field: "MATRICOLA",
		},
		{
			field: "MATRICOLA2",
		},
		{
			field: "CODICE_TORO",
		},
		// {
		//   field: 'Nome DB',
		//   cellStyle: function (params) {
		//     console.log({
		//       params: params,f
		//       data: params.data.Matricola,
		//       prova: params.value,
		//     });
		//     return {
		//       background: '#05CC00',
		//     };
		//   },
		// },
	];
	public warningColumnDefs: ColDef[] = [{ field: "Matricola" }, { field: "Nome" }, { field: "Nome DB" }];

	// DefaultColDef sets props common to all Columns
	public defaultColDef: ColDef = {
		flex: 1,
		resizable: true,
		sortable: true,
		filter: true
	};
	gridOptions: GridOptions<any> = {
		columnDefs: this.colDefs,
		defaultColDef: this.defaultColDef,
		rowSelection: "single",
		animateRows: true,
		pagination: true,
		paginationAutoPageSize: true,
	};
	subjectValue: boolean = false;
	@ViewChild("agGrid1") agGrid1!: AgGridAngular;
	@ViewChild("agGrid7") agGrid7!: AgGridAngular;
	@ViewChild("popupContainer", { read: TemplateRef })
	popupContainer: TemplateRef<any>;
	@ViewChild("PopupContainer", { read: TemplateRef })
	PopupContainer: TemplateRef<any>;
	centriCodiceAz: any;
	isAlive = true;
	listatoSummary: string;
	okRowData: any;
	clickedData: any;
	fileName: string;
	specie: any;
	anno: any;
	constructor(private http: ApiService, private https: HttpClient, private router: Router, private viewContainerRef: ViewContainerRef, private storageService: StorageService, public toastr: ToastrService, private renderer: Renderer2, private elementRef: ElementRef, private formBuilder: FormBuilder) {

		this.Form = this.formBuilder.group({
			codice_cam: [null, Validators.required],
			toro: [null],
			cod_toro: [null],
			lotto: [null],
			data_ac: [null, Validators.required],
			conc_c: [null],
			mp_c: [null],
			nspm_c: [null],
			num_anal: [null],
			non_conf: [null],
		})
	}

	ngOnInit(): void {
		this.http.current.pipe(takeWhile(() => this.isAlive)).subscribe((res) => {
			if (res) {
				this.subjectValue = true
				this.centriCodiceAz = res.codice_az ? res.codice_az : localStorage.getItem('centriCodiceAz')
				this.specie = res.specie ? res.specie : localStorage.getItem('specie');
				this.anno = res.anna ? res.anna : localStorage.getItem('anna')
				this.getL30AcTable()
			}
		})
		this.uploadedFile = false;
		this.getL30AcTable()
		//this.rowData = JSON.parse(JSON.stringify(dummyData.records));
	}

	ngOnDestroy() {
		this.isAlive = false;
	}
	onCellClicked(event: CellClickedEvent) {
		this.clickedData = event.data;
		let data = this.formatDate(this.clickedData.data_ac)
		this.openCqFormPopup()
		this.Form.patchValue({
			codice_cam: this.clickedData.codice_cam,
			toro: this.clickedData.toro,
			cod_toro: this.clickedData.cod_toro,
			lotto: this.clickedData.lotto,
			data_ac: data,
			conc_c: this.clickedData.conc_c,
			mp_c: this.clickedData.mp_c,
			nspm_c: this.clickedData.nspm_c,
			num_anal: this.clickedData.num_anal,
			non_conf: this.clickedData.non_conf,
		})
	}

	@HostListener("dragover", ["$event"]) onDragOver(evt) {
		evt.preventDefault();
		evt.stopPropagation();
		evt.dataTransfer.dropEffect = "copy";
		this.dragOver = true;
	}

	loadFile(evt) {
		this.files = evt.target.files;
		this.sendFiles();
	}

	@HostListener("dragleave", ["$event"]) onDragLeave(evt) {
		evt.preventDefault();
		evt.stopPropagation();
		this.dragOver = false;
	}

	@HostListener("drop", ["$event"]) onDrop(evt) {
		evt.preventDefault();
		evt.stopPropagation();
		this.dragOver = false;
		this.files = evt.dataTransfer.files;
		this.sendFiles();
	}

	// Example of consuming Grid Event


	// Example using Grid's API
	clearSelection(): void {
		this.agGrid1.api.deselectAll();
	}

	onRowSelected(event: RowSelectedEvent) {
		if (event.source == "rowClicked") {
			this.openPopup(event.data.Nome, event.data.Matricola);
		}
	}

	setGridData(grid: AgGridAngular, data: any[]) {
		grid.api.setRowData(data);
		grid.api.sizeColumnsToFit();
	}
	concValueFnc(event: any) {
		const mp_cValue = this.Form.get('mp_c').value;
		this.Form.patchValue({
			nspm_c: (event.target.value * mp_cValue)/100
		})
	}

	mpValueFnc(event: any) {
		const conc_cValue = this.Form.get('conc_c').value;
		this.Form.patchValue({
			nspm_c: (event.target.value * conc_cValue)/100
		})
	}
	convertDetails(details: Map<string, ArrayBuffer>): Array<Map<string, string>> {
		var arr: Array<Map<string, string>> = [];
		for (const [key, value] of Object.entries(details)) {
			value.forEach((element) => {
				element["Stato"] = key;
				arr.push(element);
			});
		}
		return arr;
	}

	sendFiles() {
		let formData = new FormData();
		formData.append("file", this.files[0]);
		this.uploadedFile = true;
		this.http.controlloAutoceritizio(this.files[0]).subscribe({
			next: (data: any) => {
				this.okRowData = data?.details?.OK
				console.log(data);

				var d = this.okRowData;
				let table = this.okRowData;
				console.log(table);

				localStorage.setItem("autocertificazione", JSON.stringify(table));
				this.setGridData(this.agGrid1, table);
			},
			error: (err) => {
				console.log({ err });
			},
		});
		return 1;
	}



	import() {

		this.http.modaBackDrop.next(true);
		this.viewContainerRef.createEmbeddedView(this.popupContainer);
		this.review = true;
		// this.http.importaAutocertizion(this.okRowData).subscribe({
		// 	next: (data) => {
		// 		console.log(data)
		// 		this.toastr.success('row updated')
		// 	},
		// 	error: (err) => {
		// 		console.log({ err });
		// 	},
		// });
	}

	downLoadFile(data: any, type: string) {
		let blob = new Blob([data], { type: type });
		let url = window.URL.createObjectURL(blob);
		let pwa = window.open(url);
		if (!pwa || pwa.closed || typeof pwa.closed == "undefined") {
			alert("Sembrerebbe che tu abbia disabilitato i popup. Abilitali per visualizzare il PDF.");
			this.router.navigate(["/dashboard"]);
		} else {
			this.router.navigate(["/dashboard"]);
		}
	}

	openPopup(nome, matricola) {
		this.http.modaBackDrop.next(true);
		const context = {
			$implicit: {
				nome: nome,
				matricola: matricola,
			},
		};
		this.viewContainerRef.createEmbeddedView(this.popupContainer, context);
	}
	openCqFormPopup() {
		this.http.modaBackDrop.next(true);
		const bodyElement = this.elementRef.nativeElement.ownerDocument.body;
		this.renderer.setStyle(bodyElement, 'overflow', 'hidden');
		this.view = this.viewContainerRef.createEmbeddedView(this.PopupContainer);
		const domElem = this.view.rootNodes[0] as HTMLElement;
		domElem.classList.add("animate-in");
	}

	closePopup2() {
		const bodyElement = this.elementRef.nativeElement.ownerDocument.body;
		this.renderer.setStyle(bodyElement, 'overflow', 'auto');
		const domElem = this.view.rootNodes[0] as HTMLElement;
		domElem.classList.add("animate-out");
		domElem.addEventListener("click", (event: AnimationEvent) => {
			this.viewContainerRef.clear();
			this.http.modaBackDrop.next(false);
		});
	}
	formatDate(date): any {
		if (date !== 'NULL' && date !== null) {
			const dateComponents = date.split('/');
			const formattedDate = new Date(`${dateComponents[2]}-${dateComponents[1]}-${dateComponents[0]}`);

			if (!isNaN(formattedDate.getTime())) {
				// Check if the formattedDate is a valid Date object
				const timeZoneOffset = formattedDate.getTimezoneOffset() * 60000;
				const adjustedDate = new Date(formattedDate.getTime() - timeZoneOffset);
				const formattedDateString = adjustedDate.toISOString().split('T')[0];
				return formattedDateString;
			} else {
				// Handle invalid date
				return 'Invalid Date';
			}
		}
	}
	closePopup() {
		this.http.modaBackDrop.next(false);
		// if (this.agGrid5) {
		//   this.agGrid5.api.deselectAll();
		// }
		this.viewContainerRef.clear();
	}
	formatDatedata(dateStr) {
		// Split the input date string into parts
		const [year, month, day] = dateStr.split('-');

		// Return the formatted date string in "dd/MM/yyyy" format
		return `${day}/${month}/${year}`;
	}

	saveData() {
		let dataprot = this.formatDatedata(this.form.value.dataProt)
		let payload = {
			...this.form.value,
			dataProt: dataprot,
			autocertificazioni: this.okRowData,


		}

		// let payload = {
		// 	...this.form.value,
		// 	autocertificazioni:

		// 		[
		// 			{
		// 				"LOTTO": "60322",
		// 				"NOME": "SILK",
		// 				"CONC_C": 11,
		// 				"MP_C": 22,
		// 				"NSPM_C": 2.42,
		// 				"NOME_DB": "SILK",
		// 				"MATRICOLA": "IT014990150479",
		// 				"MATRICOLA2": null,
		// 				"CODICE_TORO": "27711"
		// 			},
		// 			{
		// 				"LOTTO": "60322",
		// 				"NOME": "SILK",
		// 				"CONC_C": 11,
		// 				"MP_C": 22,
		// 				"NSPM_C": 2.42,
		// 				"NOME_DB": "SILK",
		// 				"MATRICOLA": "IT014990150479",
		// 				"MATRICOLA2": null,
		// 				"CODICE_TORO": "21211"
		// 			}
		// 		]

		// }

		this.http.importaAutocertizion(payload).subscribe({
			next: (data: any) => {
				if (data.status) {
					console.log(data)
					this.toastr.success(data?.message)
					this.closePopup();
				} else {
					this.toastr.error(data?.message)
					this.closePopup();
				}
			},
			error: (err) => {
				console.log({ err });
			},
		});
		// this.agGrid5.api.deselectAll();
		this.closePopup();
	}

	provaGenerics() {
		this.http.genericTableRetrieve("Centro", { select: ["Id", "Nascita"] }).subscribe((data: GenericResponse<Centro>) => {
			if (data.success) {
				console.log(data.result);
			} else {
				console.log(data.type);
			}
		});
	}

	clear() {
		this.files = [];
		this.uploadedFile = false;
		this.showFileNotUploadedTemplate = false
	}
	showNotUploadedTemplate() {
		this.showFileNotUploadedTemplate = true;
	}
	importFile() {
		this.showFileNotUploadedTemplate = true
	}
	getL30AcTable() {
		if (!this.subjectValue) {
			this.centriCodiceAz = localStorage.getItem('centriCodiceAz') ? localStorage.getItem('centriCodiceAz') : 1
			this.specie = localStorage.getItem('specie') ? localStorage.getItem('specie') : "BI"
			this.anno = localStorage.getItem('anna') ? localStorage.getItem('anna') : 2022
		}
		this.subjectValue = false
		this.http.getL30Ac(this.centriCodiceAz, this.specie, this.anno).subscribe((res) => {
			if (res.success) {
				this.rowData$ = res?.result?.rows
			} else {
				this.rowData$ = res?.result?.rows
				// this.toastr.error(res?.description)
			}
		})
	}


	formatDate1(inputDate): any {
		if (inputDate != null) {
			const [year, month, day] = inputDate.split('-');

			// Create the formatted date string in dd/mm/yyyy format
			const formattedDate = `${day}/${month}/${year}`;

			return formattedDate;
		}
	}

	onSubmitCqForm() {
		let data = this.formatDate1(this.Form.value.data_ac)
		this.http.updateAutocerti({ ...this.Form.value, id: this.clickedData.id, data_ac: data }).subscribe((res: any) => {
			console.log(res)
			if (res.status) {
				this.toastr.success(res?.description)
				this.closePopup2()
				this.getL30AcTable()
			} else {
				this.toastr.success(res?.description)

			}
		})
	}

	downloadXlsFile(url: string): void {
		this.https.get(url, { responseType: 'blob' }).subscribe((data: Blob) => {
			const blob = new Blob([data], { type: 'application/vnd.ms-excel' });
			const anchor = document.createElement('a');
			anchor.href = window.URL.createObjectURL(blob);
			anchor.download = this.fileName;
			document.body.appendChild(anchor);
			anchor.click();
			window.URL.revokeObjectURL(anchor.href);
			document.body.removeChild(anchor);
		});
	}

	downloadTemplete() {
		let url = environment.url + 'public/files/autocertificazioni_template.xls'
		this.fileName = 'autocertificazioni_template.xls'
		this.downloadXlsFile(url)
	}
	dateComparator(date1: string | null, date2: string | null): number {
		// Check if either date is null or undefined
		if (!date1 || !date2) {
			// You can decide what to return in case of null values
			// For example, if date1 is null, consider it as smaller than any non-null date2
			return date1 ? 1 : -1;
		}

		const dateParts1 = date1.split('/');
		const dateParts2 = date2.split('/');

		// Create date objects in the format YYYY-MM-DD
		const d1 = new Date(Number(dateParts1[2]), Number(dateParts1[1]) - 1, Number(dateParts1[0]));
		const d2 = new Date(Number(dateParts2[2]), Number(dateParts2[1]) - 1, Number(dateParts2[0]));

		return d1.getTime() - d2.getTime();
	}

}
