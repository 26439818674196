
<div *ngIf="!showFileNotUploadedTemplate">
    <div class="section">
        <h2>AUTOCERTIFICAZIONI</h2>
        <button class="button" (click)="importFile()">IMPORTA</button>
    </div>
    <ag-grid-angular style="height: 700px;" class="ag-theme-alpine-dark"
    [columnDefs]="colDefs" [defaultColDef]="defaultColDef" [rowData]="rowData$" [gridOptions]="gridOptions" (cellClicked)="onCellClicked($event)">
   </ag-grid-angular>
    <!-- <button (click)="importFile()">import</button> -->

</div>
<ng-container *ngIf="showFileNotUploadedTemplate" >
    <ng-container *ngIf="uploadedFile; then fileUploadedTemplate; else fileNotUploadedTemplate">
    </ng-container>
</ng-container>
   

<ng-template #fileUploadedTemplate>
    <div class="container">

        <div class="row" style="border-bottom: 1px dashed;">
            <div class="row-table" id="file-description">
                <h3 *ngIf="files.length > 0">Il file {{files[0].name}} è stato correttamente
                    caricato.</h3>
            </div>

            <div class="row-section" id="row-file-buttons">
                <div class="button-container"><button class="button danger" (click)="clear()">PULISCI</button></div>
                <div class="button-container"><button class="button" (click)="import()">IMPORTA</button></div>


            </div>
        </div>


        <div class="row" style="height: 9%; min-height: 80px;align-items: center;">
            <div
                style="height: 100%;aspect-ratio: 1 / 1;background-color: #a6e3a1;border-radius: 50%;text-align: center;">
            </div>
            <p style="border-right: 1px solid;padding-right: 1rem;">PRONTO ALL' IMPORTAZIONE</p>
            <div
                style="height: 100%;aspect-ratio: 1 / 1;background-color: #f9e2af;border-radius: 50%;text-align: center;">
            </div>
            <p style="border-right: 1px solid;padding-right: 1rem;">NOME DIVERSO NEL DATABASE</p>
            <div
                style="height: 100%;aspect-ratio: 1 / 1;background-color: #89b4fa;border-radius: 50%;text-align: center;">
            </div>
            <p style="border-right: 1px solid;padding-right: 1rem;">DUPLICATO (AGGIUNTA DOSI)</p>
            <div
                style="height: 100%;aspect-ratio: 1 / 1;background-color: #9399b2;border-radius: 50%;text-align: center;">
            </div>
            <p style="border-right: 1px solid;padding-right: 1rem;">NON TROVATO A DATABASE</p>
            <div
                style="height: 100%;aspect-ratio: 1 / 1;background-color: #f38ba8;border-radius: 50%;text-align: center;">
            </div>
            <p>ERRORE DI CONFORMITA'</p>
        </div>


        <div class="container-table">
            <div style=" overflow: hidden; flex: 1">
                <ag-grid-angular #agGrid1 style="width: 100%; height: 100%;" class="ag-theme-alpine-dark"
                    [columnDefs]="columnDefs" [defaultColDef]="defaultColDef" [rowSelection]="'single'" 
                    [animateRows]="true" (cellClicked)="onCellClicked($event)" >
                </ag-grid-angular>
            </div>

        </div>
    </div>


</ng-template>

<ng-template #fileNotUploadedTemplate>
    <div class="container">
        <div class="download">
            <button class="button" (click)="downloadTemplete()">Scarica Template</button>
        </div>

        <div id="drop-zone" class="drop-zone " [ngClass]="{'drag-over' : dragOver}" (dragover)="onDragOver($event)"
            (drop)="onDrop($event)" (dragleave)="onDragLeave($event)" (click)="fileField.click()">
            <h1>Trascina il file da importare qui dentro, oppure clicca per sceglierlo.</h1>
        </div>
        <input type="file"
            accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            (change)="loadFile($event)" #fileField hidden>

    </div>

</ng-template>


<ng-template #PopupContainer let-options>
    <div class="popup-window animate-in popup-form p-1">
        <form [formGroup]="Form" (ngSubmit)="onSubmitCqForm()">
            <div class="d-flex gap-16">
                <div class="field w-100">
                    <label class="d-block">Codice cam:</label>
                    <input type="text" class="w-100" formControlName="codice_cam" required />
                   
                </div>
                <div class="field w-100">
                    <label class="d-block">Toro:</label>
                    <input type="text" class="w-100" formControlName="toro" />
                 
                </div>
            </div>
            <div class="d-flex gap-16">
                <div class="field w-100">
                    <label class="d-block">Cod toro:</label>
                    <input type="number" class="w-100" formControlName="cod_toro" />
                   
                </div>
                <div class="field w-100">
                    <label class="d-block">Lotto:</label>
                    <input type="text" class="w-100" formControlName="lotto" />
                </div>
            </div>
            <div class="d-flex gap-16">
                <div class="field w-100">
                    <label class="d-block">Data ac:</label>
                    <input type="date" class="w-100" formControlName="data_ac" />
                </div>
                <div class="field w-100">
                    <label class="d-block">Conc c:</label>
                    <input type="text" class="w-100" formControlName="conc_c" (input)="concValueFnc($event)"/>
                </div>
            </div>
            <div class="d-flex gap-16">
                <div class="field w-100">
                    <label class="d-block">*Mp c:</label>
                    <input type="text" class="w-100" formControlName="mp_c" (input)="mpValueFnc($event)" />
                </div>
                <div class="field w-100">
                    <label class="d-block">Nspm c:</label>
                    <input type="text" class="w-100" formControlName="nspm_c" />
                </div>
            </div>
            <div class="d-flex gap-16">
                <div class="field w-100">
                    <label class="d-block">Num anal:</label>
                    <input type="tel" class="w-100" formControlName="num_anal" />
                </div>
                <div class="field w-100">
                    <label class="d-block">Non conf:</label>
                    <input type="tel" class="w-100" formControlName="non_conf" />
                </div>
            </div>
            <div class="main-footer">
                <div class="delete">
                    <button
                   
                    type="button"
                    class="delete-button"
                    title="Delete this item"
                    (click)="deleteautocert()"
                  >
                    <i class="fas fa-trash-alt"></i>
                  </button>
                    <!-- <i class="fa fa-trash customtrash" aria-hidden="true" (click)="openmodel()"></i> -->
                </div>
                <div class="popup-footer">
                    <button class="button1 mr-1" type="submit">Aggiorna</button>
                    <button class="button1" (click)="closePopup2()" type="button">ANNULLA</button>
                </div>
            </div>
        </form>
    </div>
</ng-template>
<ng-template #popupContainer let-options>
    <ng-container *ngIf="review; then reviewContainer">
    </ng-container>
</ng-template>

<ng-template #reviewContainer>
    <div class="popup-window">
        <div class="d-flex gap-16">
            <div class="field w-100">
                <label class="d-block">DATA PROTOCOLLO:</label>
                <form [formGroup]="form">
                    <input type="date" class="w-100" formControlName="dataProt">
                    <label for="">Codice OPERATORE:</label>
                    <input type="text" class="w-100" formControlName="operator">
                </form>
            </div>
        </div>
        <div [innerHTML]="listatoSummary">
        </div>
        <div style="display: flex; justify-content: flex-end; justify-self: flex-end; margin-top: 10px;">
            <div class="column popup-button">
                <button class="button1 mr-1" (click)="closePopup()">Chiudi</button>
            </div>
            <div class="column popup-button">
                <button class="button1" (click)="saveData()">Importa</button>
            </div>
        </div>
    </div>
</ng-template>