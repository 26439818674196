<div class="column">
    <div class="main">
        <div class="anna">
            <label class="semi-bold mr-2">SPECIE</label>
            <select  required [(ngModel)]="defaultValue" (change)="selectSpecie($event)" class="specieSelect">
                <option [value]="specie?.value" *ngFor="let specie of spaciesData;">{{specie?.name}}</option>
            </select>
        </div>
        <div *ngIf="year" class="anna">
        <label class="semi-bold mr-2">ANNO</label>
        <select id="yearSelect" [(ngModel)]="defaultvalYear" name="year" class="specieSelect2" (change)="selectYear($event)">
          <option *ngFor="let year of years" [value]="year">{{ year }}</option>
        </select>
        </div>
    </div>
</div>
