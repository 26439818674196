import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { ConfirmValue } from "../_shared/modal/conform.component";


@Injectable({
    providedIn: "root",
})
export class ModalService {
    private showModalSubject = new BehaviorSubject<ConfirmValue>({ modal: '', value: false });
    public isConfirmed$ = new Subject<ConfirmValue>();
    public showModal$ = this.showModalSubject.asObservable();
    constructor() { }
    openModal(name: string) { this.showModalSubject.next({ modal: name, value: true }); }

}