import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashComponent } from './dash/dash.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgChartsModule } from 'ng2-charts';
import { AgGridModule } from 'ag-grid-angular';
import { ToastrModule } from 'ngx-toastr';
import { NgxPaginationModule } from 'ngx-pagination';
import { SharedModule } from '../_shared/shared.module';
import { DashRoutingModule } from './dash-routing.modules';
import { MobileSidebarComponent } from './mobile-sidebar/mobile-sidebar.component';


@NgModule({
  declarations: [
    DashComponent,
    MobileSidebarComponent
  ],
  imports: [
    CommonModule,
    DashRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgChartsModule,
    AgGridModule,
    NgxPaginationModule,
    ToastrModule.forRoot(),
    SharedModule,
  ]
})
export class DashModule { }
