<div>
    <app-loader *ngIf="loader"></app-loader>
    <app-conform [message]="'Are you sure you want to delete?'"(confirm)="confirm($event)"></app-conform>
    
    <div class="header d-flex mt-2">
        <h1 class="title">L30OPER Table</h1>
        <button class="button mr-1" (click)="openPopup();openPopup1()">Crea</button>
    </div>
    
    <ag-grid-angular style="height: 750px;"
        class="ag-theme-alpine-dark basic-table" [rowData]="rowData$" [gridOptions]="gridOptions">
    </ag-grid-angular>
    
    <div class="pagination">
        <!-- <app-pagination [dataLength]="totalCount" [tableName]="tableName" [itemPerPage]="itemPerPage"
            (dataOnPage)="receiveData($event)"></app-pagination> -->
    </div>
    
    <ng-template #popupContainer let-options>
        <div class="popup-window animate-in">
            <!-- <div class="popup-footer">
                <div><button class="button danger" type="submit">{{changeButtun?'Update':'Save'}}</button></div>
                <div><button class="button" type="button" (click)="closePopup()">Chiudi</button></div>
            </div> -->
            <div class="form-container">
                <form [formGroup]="addL30Qs" (ngSubmit)="onSubmit()">
                    <div class="row">
                        <div class="column field">
                            <label>*Cod Cam:</label>
                            <input type="text" formControlName="codice_cam" required />
                            <div *ngIf="(formSubmitted || fc['codice_cam'].touched) && fc['codice_cam'].errors" class="alert">
                                <div *ngIf="(formSubmitted || fc['codice_cam'].touched)"> Please enter Cod Cam </div>
                            </div>
                        </div>
                        <div class="column field">
                            <label>*cod_seme:</label>
                            <input type="number" formControlName="cod_seme" required />
                            <!-- <div *ngIf="(formSubmitted || fc['codice_az'].touched) && fc['codice_az'].errors" class="alert">
                                <div *ngIf="(formSubmitted || fc['codice_az'].touched)"> Please enter seme code name</div>
                            </div> -->
                        </div>
                    </div>
                    <div class="row">
                        <div class="column field">
                            <label>ripetizion:</label>
                            <!-- <select  (change)="selectSpecie($event)" class="specieSelect" formControlName="specie">
                                <option [value]="specie?.value" *ngFor="let specie of spaciesData;">{{specie?.name}}</option>
                            </select> -->
                            <input type="number" formControlName="ripetizion" />
                        </div>
                        <div class="column field">
                            <label>op_ricev:</label>
                            <input type="number" formControlName="op_ricev" />
                        </div>
                    </div>
    
                    <div class="row">
                        <div class="column field">
                            <label>data_ricev:</label>
                            <!-- <select id="yearSelect"  name="year" class="specieSelect2" (change)="selectYear($event)" formControlName="anno_rif">
                                <option *ngFor="let year of years" [value]="year">{{ year }}</option>
                            </select> -->
                            <input type="number" formControlName="data_ricev" />
                        </div>
                        <div class="column field">
                            <label>estrazione:</label>
                            <input type="number" formControlName="estrazione" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="column field">
                            <label>data_estr:</label>
                            <input type="number" formControlName="data_estr"/>
                        </div>
                        <div class="column field">
                            <label>diluizione:</label>
                            <input type="number" formControlName="diluizione" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="column field">
                            <label>data_dil:</label>
                            <input type="number" formControlName="data_dil" />
                        </div>
                        <div class="column field">
                            <label>id_pcr:</label>
                            <input type="number" formControlName="id_pcr" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="column field">
                            <label>pcr:</label>
                            <input type="number" formControlName="pcr" />
                        </div>
                        <div class="column field">
                            <label>data_pcr:</label>
                            <input type="text" formControlName="data_pcr" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="column field">
                            <label>mix_car:</label>
                            <input type="number" formControlName="mix_car" />
                        </div>
                        <div class="column field">
                            <label>num_gel:</label>
                            <input type="number" formControlName="num_gel" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="column field">
                            <label>data_gel:</label>
                            <input type="date" formControlName="data_gel" />
                        </div>
                        <div class="column field">
                            <label>sequenziat:</label>
                            <input type="number" formControlName="sequenziat" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="column field">
                            <label>risultati:</label>
                            <input type="number" formControlName="risultati" />
                        </div>
                        <div class="column field">
                            <label>data_ris:</label>
                            <input type="date" formControlName="data_ris" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="column field">
                            <label>op_mac:</label>
                            <input type="number" formControlName="op_mac" />
                        </div>
                        <div class="column field">
                            <label>op_dos:</label>
                            <input type="number" formControlName="op_dos" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="column field">
                            <label>analisi_ok:</label>
                            <input type="number" formControlName="analisi_ok" />
                        </div>
                        <div class="column field">
                            <label>faxvi_data:</label>
                            <input type="number" formControlName="faxvi_data" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="column field">
                            <label>faxvi_oper:</label>
                            <input type="number" formControlName="faxvi_oper" />
                        </div>
                        <div class="column field">
                            <label>regvi_data:</label>
                            <input type="number" formControlName="regvi_data" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="column field">
                            <label>regvi_oper:</label>
                            <input type="number" formControlName="regvi_oper" />
                        </div>
                        <div class="column field">
                            <label>stato:</label>
                            <input type="number" formControlName="stato" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="column field">
                            <label>id_piastra:</label>
                            <input type="number" formControlName="id_piastra" />
                        </div>
                        <div class="column field">
                            <label>tank_rif:</label>
                            <input type="text" formControlName="tank_rif" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="column field">
                            <label>non_conf:</label>
                            <input type="number" formControlName="non_conf"/>
                        </div>
                        <div class="column field">
                            <label>faxvi2data:</label>
                            <input type="number" formControlName="faxvi2data"  />
                        </div>
                    </div>
                    <div class="row" *ngIf="changeButtun">
                        <div class="column field">
                            <label>faxvi2oper</label>
                            <input type="number" formControlName="faxvi2oper" />
                        </div>
                        <!-- <div class="column field">
                            <label>MP(c):</label>
                            <input type="text" formControlName="total_mp_c" />
                        </div> -->
                    </div>
                    <div class="main-footer" [ngClass]="changeButtun?'main-footer':'main-footer1'">
                        <div class="delete" *ngIf="changeButtun">
                            <i class="fa fa-trash customtrash" aria-hidden="true" (click)="openmodel()"></i>
                        </div>
                        <div class="popup-footer" >
                            <div><button class="button danger" type="submit">{{changeButtun?'Aggiorna':'Salva'}}</button></div>
                            <div><button class="button" type="button" (click)="closePopup()">Chiudi</button></div>
                        </div>
                    </div>
                    
                </form>
            </div>
    
        </div>
    </ng-template>
    </div>
