<app-loader *ngIf="loader"></app-loader>
<app-conform [message]="'Are you sure you want to delete?'" (confirm)="confirm($event)"></app-conform>

<div class="header d-flex">
    <h1 class="semi-bold mb-1">GENOTIPO DI RIFERIMENTO</h1>
    <div><button class="button mr-1" (click)="getUnique()">DNA UGUALI</button>
        <button class="button mr-1" (click)="openPopup();openPopup1()">IMPORT</button>
    </div>
</div>

<ag-grid-angular style="height: 750px;"
    class="ag-theme-alpine-dark basic-table" [rowData]="rowData$" [gridOptions]="gridOptions"(gridReady)="onGridReady($event)">
</ag-grid-angular>

<div class="pagination">
    <app-pagination [dataLength]="totalCount" [tableName]="tableName" [itemPerPage]="itemPerPage"
    [columnName]="columnName" [filterValue]="filterValue" [sortingOrder]="sortingOrder"
        (dataOnPage)="receiveData($event)"></app-pagination>
</div>

<ng-template #popupContainer let-options>
    <div class="popup-window animate-in">
        <div class="form-container">
            <form [formGroup]="addDNAForm" (ngSubmit)="onSubmit()">
                <div class="row">
                    <div class="column field" *ngIf="changeButtun">
                        <label>*CODICE DNA:</label>
                        <input type="text" formControlName="cod_dna" required />
                        <div *ngIf="(formSubmitted || fc['cod_dna'].touched) && fc['cod_dna'].errors" class="alert">
                            <div *ngIf="(formSubmitted || fc['cod_dna'].touched)"> Please enter DNA code name</div>
                        </div>
                    </div>
                    <!-- <div class="column field">
                        <label>*CODICE SEME:</label>
                        <input type="text" formControlName="cod_seme" required />
                        <div *ngIf="(formSubmitted || fc['cod_seme'].touched) && fc['cod_seme'].errors" class="alert">
                            <div *ngIf="(formSubmitted || fc['cod_seme'].touched)"> Please enter seme code name</div>
                        </div>
                    </div> -->
                    <div class="column field">
                        <label>Tipo Sequenziatore:</label>
                        <input type="text" formControlName="tipo_seq" />
                    </div>
                    <div class="column field" *ngIf="!changeButtun">
                        <label for="myfile">Select a file:</label>
                        <input type="file" id="myfile" formControlName="myfile" (change)="onFileSelected($event)"
                            accept=".txt">
                    </div>
                </div>
                <div class="row">
                    <div class="column field" *ngIf="changeButtun">
                        <label>MICROSATELLITE:</label>
                        <input type="text" formControlName="microsat" />
                    </div>
                    <div class="column field" *ngIf="changeButtun">
                        <label>RIF AL1:</label>
                        <input type="text" formControlName="al1_rif" />
                    </div>
                </div>

                <div class="row">
                    <div class="column field" *ngIf="changeButtun">
                        <label>RIF AL2:</label>
                        <input type="text" formControlName="al2_rif" />
                    </div>
                </div>
                <!-- <div class="popup-footer">
                    <div><button class="button danger" type="submit">{{changeButtun?'Update':'Save'}}</button></div>
                    <div><button class="button" type="button" (click)="closePopup()">Chiudi</button></div>
                </div> -->


                <div class="main-footer" [ngClass]="changeButtun?'main-footer':'main-footer1'">
                    <div class="delete" *ngIf="changeButtun">
                        <i class="fa fa-trash customtrash" aria-hidden="true" (click)="openmodel()"></i>
                    </div>
                    <div class="popup-footer">
                        <div><button class="button danger" type="submit">{{changeButtun?'Aggiorna':'Salva'}}</button></div>
                        <div><button class="button" type="button" (click)="closePopup()">Chiudi</button></div>
                    </div>
                </div>

            </form>
        </div>

    </div>
</ng-template>
<ng-template #popupContainer1>
    <div class="popup-window animate-in">
        <div class="form-container">
            <form [formGroup]="dnarifForm" (ngSubmit)="submitDnaRefForm()">
                <div class="row">
                    <div class="column field" >
                        <label>*Operatore RUN:</label>
                        <input type="text" formControlName="op_run" required />
                        <div *ngIf="dnarifForm.get('op_run')?.errors?.['required'] && submitted" class="errorCls">
                            <span >Operatore RUN is required</span>
                          </div>
                    </div>
                    <div class="column field">
                        <label>*Data RUN:</label>
                        <input type="date" formControlName="data_run" />
                        <div *ngIf="dnarifForm.get('data_run')?.errors?.['required'] && submitted" class="errorCls">
                            <span >Data RUN is required</span>
                          </div>
                    </div>
                    <div class="column field" >
                        <label for="myfile">*N. RUN :</label>
                        <input type="text" formControlName="num_run" required>
                        <div *ngIf="dnarifForm.get('num_run')?.errors?.['required'] && submitted" class="errorCls">
                            <span >N. RUN is required</span>
                          </div>
                    </div>
                </div>
                <!-- other form fields -->
                <div class="main-footer1">
                    <button class="button danger" type="submit">{{changeButtun ? 'Aggiorna' : 'Salva'}}</button>
                    <button class="button" type="button" (click)="closePopup()">Chiudi</button>
                </div>
            </form>
        </div>
    </div>
</ng-template>

<div *ngIf="showModal" class="modal-overlay" (click)="onCancelClick()">
    <div class="modal-content" (click)="onModalClick($event)">
      <p class="confirmationmsg">Sei sicuro di voler aggiornare la registrazione?</p>
      <button class="confirm-button" (click)="onOkClick()">Conferma</button>
      <button class="cancel-button" (click)="onCancelClick()">Annulla</button>
    </div>
</div>
  
  