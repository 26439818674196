<app-loader *ngIf="loader"></app-loader>
<div class="go-back">
    <button class="btn backbtn fs-18 fw-600" (click)="back()"><i
            class="fa-solid fa-arrow-left pr-1"></i>INDIETRO</button>
</div>
<div class="sample-create">
    <div class="create-form">
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="popup-window animate-in">
                <div class="d-flex gap-16">
                    <div class="field w-100">
                        <label class="d-block">*CODICE CAMPIONAMENTO:</label>
                        <input type="text" class="w-100" formControlName="codice_cam" required readonly />
                        <div *ngIf="(formSubmitted || fc['codice_cam'].touched) && fc['codice_cam'].errors"
                            class="alert">
                            <div *ngIf="(formSubmitted || fc['codice_cam'].touched)"> Please enter CAMPIONAMENTO</div>
                        </div>
                    </div>
                </div>
                <div class="d-flex gap-16">
                    <div class="field w-100">
                        <label class="d-block">*CODICE CENTRO:</label>
                        <input type="text" class="w-100" formControlName="codice_az" required readonly />
                        <div *ngIf="(formSubmitted || fc['codice_az'].touched) && fc['codice_az'].errors" class="alert">
                            <div *ngIf="(formSubmitted || fc['codice_az'].touched)"> Please enter CENTRO</div>
                        </div>
                    </div>
                </div>
                <div class="d-flex gap-16">
                    <div class="field w-100">
                        <label class="d-block">ANNO RIFERIMENTO:</label>
                        <select class="w-100 custom" formControlName="anno_riferimento" (change)="annoChange($event)">
                            <option *ngFor="let year of years">{{ year }}</option>
                        </select>
                    </div>
                </div>
                <div class="d-flex gap-16">
                    <div class="field w-100">
                        <label class="d-block">% LOTTI CAMPIONATI:</label>
                        <input type="number" class="w-100" formControlName="lotti_cam" />
                    </div>
                </div>
                <div class="d-flex gap-16">
                    <div class="field w-100">
                        <label class="d-block">DATA CAMPIONAMENTO:</label>
                        <input type="date" [(ngModel)]="currentDate" class="w-100" formControlName="data_campl" />
                    </div>
                </div>
                <div class="d-flex gap-16">
                    <div class="field w-100">
                        <label class="d-block">NUMERO MINIMO DOSI / LOTTO:</label>
                        <input type="number" class="w-100" formControlName="minumo_dosi"
                            (input)="getMinumoDosiValue($event)" />
                    </div>
                </div>
                <!-- <div class="d-flex gap-16">
                    <div class="field w-100">
                        <label class="d-block">NUMERO MASSIMO TENTATIVI:</label>
                        <input type="number" class="w-100" formControlName="massimo_tentivi" />
                    </div>
                </div> -->
                <div class="d-flex gap-16">
                    <div class="field w-100">
                        <label class="d-block">DA RECORD:</label>
                        <input type="number" class="w-100" formControlName="darecord"
                            (input)="inputValueChangeDa($event)" />
                    </div>
                </div>
                <div class="d-flex gap-16">
                    <div class="field w-100">
                        <label class="d-block">A RECORD:</label>
                        <input type="number" class="w-100" formControlName="arecord"
                            (input)="inputValueChangeA($event)" />
                    </div>
                </div>
                <div class="main-footer">
                    <div class="delete"></div>
                    <div class="popup-footer">
                        <div><button class="button danger" type="submit">CREA CAMPIONAMENTO</button></div>
                    </div>
                </div>
            </div>
        </form>
    </div>

    <div class="sample-list">
        <div class="mt-1">
            <ag-grid-angular style="height: 600px" class="ag-theme-alpine-dark" [columnDefs]="columnDefs"
                [rowSelection]="'single'" [rowData]="rowData" [animateRows]="true" (gridReady)="onGridReady($event)"
                [gridOptions]="gridOptions"> </ag-grid-angular>
            <div class="mt-1 text-end">
            </div>
        </div>
    </div>
</div>