import { Component, Input } from '@angular/core';
declare function setActive(event): void;

@Component({
    selector: 'app-tab-selector',
    templateUrl: './tab-selector.component.html',
    styleUrls: ['./tab-selector.component.scss']
})
export class TabSelectorComponent {
    @Input() entries: Array<string>;
    @Input() selects: Array<string>;

    tabSelect(evt) {
        setActive(evt)
    }

}
