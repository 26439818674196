<app-loader *ngIf="loader"></app-loader>
<div class="header d-flex mt-2">
	<h1 class="title">ARCHIVIO</h1>

	<!-- <div class="column radio">
        <label>File Type:</label>
        <input type="hidden" formControlName="CELLEPIENE" />

        <label class="container">xlsx
            <input type="radio"  value="xlsx" formControlName="buttonType" />
            <span class="check"></span>
          </label>
        <label class="container">Txt
            <input type="radio"  value="text" formControlName="buttonType" checked />
            <span class="check"></span>
          </label>
   
    </div> -->
	<div class="export">
		<button class="button1" (click)="openCreatePopup()" *ngIf="showPopup">Crea</button>
		<button class="button1" (click)="export(selectedDbName)">Esporta</button>
	
		<select class="button mr-1" (change)="getDbTables(selectedDbName, 'change')" [(ngModel)]="selectedDbName">
			<option [value]="tb?.value" *ngFor="let tb of tableList">{{ tb?.name }}</option>
		</select>
	</div>
</div>

<ag-grid-angular style="height: 700px" class="ag-theme-alpine-dark basic-table" [rowData]="rowData$" [gridOptions]="gridOptions" (gridReady)="onGridReady($event)" (rowClicked)="onRowClicked($event)">
</ag-grid-angular>

<div class="pagination">
	<app-pagination
		[dataLength]="totalCount"
		[tableName]="tableName"
		[itemPerPage]="20"
		[selectedDbName]="selectedDbName"
		[filterValue]="filterValue"
		[columnName]="columnName"
		[sortingOrder]="sortingOrder"
		(dataOnPage)="onReceiveData($event)"
	></app-pagination>
</div>

<ng-template #popupContainer let-options>
	<div class="popup-window animate-in">
		<div class="form-container">
			<form class="popForm" [formGroup]="form" (ngSubmit)="onSubmitDownload()">
				<div class="column radio">
					<label>File Type:</label>

					<label class="container"
						>XLSX
						<input type="radio" value="excel" formControlName="buttonType" name="buttonType" checked />
						<span class="check"></span>
					</label>
					<label class="container"
						>CSV
						<input type="radio" value="csv" formControlName="buttonType" name="buttonType" />
						<span class="check"></span>
					</label>
				</div>
				<div class="main-footer">
					<div class="popup-footer">
						<div><button class="button customSave" type="submit">Export</button></div>
						<div><button class="button customtrash" type="button" (click)="closePopup()">Chiudi</button></div>
					</div>
				</div>
			</form>
		</div>
	</div>
</ng-template>
<div *ngIf="isEditPopupOpen && showPopup" class="modal-overlay">
	<div class="modal">
		<form #itemForm="ngForm" (ngSubmit)="saveChanges(itemForm)">
		<!-- <h2 class="text-xl font-bold mb-4">{{ isEdit ? "Edit Item" : "Add Item" }}</h2> -->
  
		<!-- Grid Layout for Fields -->
		<div class="form-grid">
		  <div class="input-group" *ngFor="let col of colDefs">
			<!-- Exclude unwanted fields -->
			<ng-container *ngIf="!['created_at', 'updated_at', 'deleted_at'].includes(col.field)">
			  <label>{{ col.headerName }}</label>
  
			  <input
				type="text"
				[(ngModel)]="editedRow[col.field]"
				name="{{ col.field }}"
				[required]="!isEdit"
				[pattern]="col.field === 'email' ? '.+@.+\\..+' : null"
				#inputField="ngModel"
			  />
  
			  <!-- Show validation on submit attempt -->
			  <div
			  *ngIf="inputField.invalid && (inputField.touched || itemForm.submitted)"
			  style="color: #dc2626; font-weight: bold; font-size: 0.875rem;"
			>
			  <span *ngIf="inputField.errors?.['required']">{{ col.headerName }} field is required.</span>
			  <span *ngIf="inputField.errors?.['pattern'] && col.field === 'email'">Please enter a valid email.</span>
			</div>
			
			</ng-container>
		  </div>
		</div>
		<button
		*ngIf="isEdit"
		type="button"
		class="delete-button"
		title="Delete this item"
		(click)="deleteArchivo(colDefs)"
	  >
		<i class="fas fa-trash-alt"></i>
	  </button>
	  
		<!-- Button Section -->
		<div class="flex justify-end mt-2">
		  <button
			type="submit"
			class="px-6 py-2 rounded-lg bg-green-200 hover:bg-green-300 text-black font-semibold shadow-md"
		  >
			{{ isEdit ? "Aggiorna" : "Crea" }}
		  </button>
  
		  <button
			type="button"
			(click)="closePopup1()"
			class="ml-4 px-6 py-2 rounded-lg bg-gray-300 hover:bg-gray-400 text-black font-semibold shadow-md"
		  >
			Annulla
		  </button>
		</div>
	  </form>
	</div>
  </div>
  
  
