import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ColDef } from 'ag-grid-community';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent {
  @Input() columnDefs: ColDef[] = [];
  @Input() defaultColDef: ColDef[] | any = [];
  @Input() rowData: any[] = [];
  @Output() cellClicked: EventEmitter<any> = new EventEmitter();

  onCellClicked(event){
    this.cellClicked.emit(event);
  }

}
