import { Component, Input } from "@angular/core";
import { AlertService } from "src/app/_services/alert.service";

@Component({
    selector: "app-alert",
    template: `
  <div class="ismodal" [ngClass]="{'active': showModal }">
  <div class="modal-overlay">
    <div class="modal">
      <div class="modal-content">
      <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
        <h1>Warning</h1>
        <p class="text">{{ message }}</p>
        <div class="modal-buttons">
          <button class="cancel-button mbutton" (click)="Cancel()"><i class="fa fa-times" aria-hidden="true"></i></button>
        </div>
      </div>
    </div>
  </div>
  </div>
    `,
    styles: [`
      .custom{
        color: red;
        font-size: 32px;
      }
      .modal-overlay{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 999;
      }
      .modal {
        background-color: #858282;
        padding: 20px;
        border-radius: 5px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
        max-width: 400px;
        text-align: center;
        position: relative;
      }
      .modal-buttons{
        position: absolute;
        top: 2px;
        right: 3px;
      }
      .ismodal {
        display: none;
      }
      .ismodal.active {
        display: block;
      }
      .mbutton{
        padding: 1px 8px;
        cursor: pointer;
        border: none;
        color: #fff;
        border-radius: 50%;
        font-size: 14px;
        font-weight: 600;
        background: #0000008f;
      }
      .cancel-button{
        background-color: #ff000073;
      }
      .text{
        font-size: 20px;
        font-weight: 500;
      }
      .fa-exclamation-triangle{
        color: #ffff0094;
        font-size: 60px;
      }
    `]
})
export class AlertComponent {
    @Input() message: string;
    showModal: boolean = false;
    constructor(private alertService: AlertService) { this.alertService.showAlert$.subscribe((show: boolean) => { this.showModal = show; }); }
    hide() { this.showModal = false; }
    Cancel() {
        this.alertService.isCanceled$.next(false);
        this.hide();
    }

    // uses 
    // private alertService: AlertService,  this.alertService.showAlert();
    // <app-alert [message]="'There was a problem with your network connection!'"></app-alert>

}