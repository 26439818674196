import { Component, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ApiService } from '../_services/api.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ModalService } from '../_services/modal.service';
import { ToastrService } from 'ngx-toastr';
import { GridOptions } from 'ag-grid-community';
import { CustomButtonRendererComponent, CustomButtonRendererComponent1 } from '../table-test/custom-button-renderer.component';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-ricar-table',
	templateUrl: './ricar-table.component.html',
	styleUrls: ['./ricar-table.component.scss']
})
export class RicarTableComponent {
	dnaTableData = [];
	populatedData = [];
	paginationPageSize = 15;
	totalCount: any;
	changeButtun: boolean = false;
	rowData = [];
	tableName: string = "courier-management";
	view;
	timeRange: string = "00.00/00.00"; // Default time range
	invalidTimeRange: boolean = false;
	p: number = 1;
	popup = false;
	form: FormGroup;
	loader: boolean = false;

	@ViewChild("popupContainer", { read: TemplateRef })
	popupContainer: TemplateRef<any>;
	rowId: any;
	codeic: string | number;
	code: string;
	columnDefs = [
		{
			field: "toro",
			headerName: "Toro",
			width: 519,
			// flex: 1,
			cellRenderer: (params: any) => {
				return `${params.data.toro == 'undefined/undefined/' || params.data.toro == null ? 'N/A' : params.data.toro}`;
			},
		},

		{
			field: "matricola",
			headerName: "Matricola",
			width: 519,
			// flex: 1,
			cellRenderer: (params: any) => {
				return `${params.data.matricola == '' || params.data.matricola == null ? 'N/A' : params.data.matricola}`;
			},
		},
		{
			field: "lotto",
			headerName: "lotto",
			width: 519,
			// flex: 1,
			cellRenderer: (params: any) => {
				return `${params.data.lotto == '' || params.data.lotto == null ? 'N/A' : params.data.lotto}`;
			},
		},

		{
			field: "cod_dnaValue",
			headerName: "Cod Dna Value",
		},
	];
	codice_cam: any;
	codice_az: any;
	component: { exportRicar: (payloadObject: any) => any; };

	constructor(public apiService: ApiService, private router: Router, private viewContainerRef: ViewContainerRef, private formBuilder: FormBuilder, public toast: ToastrService, private modalService: ModalService, private activate: ActivatedRoute) {

	}
	defaultColDef = {
		// flex: 1,
		resizable: true,
		sortable: true,
		filter: true,
	};
	gridOptions: GridOptions = {
		defaultColDef: this.defaultColDef,
		pagination: true,
		paginationAutoPageSize: true,
		frameworkComponents: {
			customButtonRenderer: CustomButtonRendererComponent, CustomButtonRendererComponent1 // Register the custom cell renderer
		},
	}
	ngOnInit(): void {
		this.activate.queryParams.subscribe((res: any) => {
			this.codice_cam = res.codice_cam;
			this.codice_az = res.codice_az
		})
		this.getRicarData();
	}

	onCellClicked(data) { }
	getRicarData() {
		this.loader = true
		this.apiService.getRicarData(this.codice_cam, this.codice_az).subscribe({
			next: (data) => {
				if (data.success) {
					this.loader = false
					this.dnaTableData = data.result;
					this.totalCount = data.totalCount;
					this.rowData = this.dnaTableData;
				}
			},
		});
	}
	onBtExport() {
		const newrowData2: any[] = this.rowData.map((el: any, index: number) => {
			return {
				number: index + 1,
				toro: el?.toro ? el?.toro : '',
				matricola: el?.matricola ? el?.matricola : '',
				lotto: el?.lotto ? el?.lotto : '',
				cod_dnaValue: el?.cod_dnaValue ? el?.cod_dnaValue : ''
			}
		});
		const fieldToHeaderMap = {};
		this.columnDefs.forEach(item => { fieldToHeaderMap[item?.field] = item?.headerName });
		const payload = newrowData2.map(obj => {
			const newObj = {};
			for (const key in obj) {
				if (fieldToHeaderMap[key]) { newObj[fieldToHeaderMap[key]] = obj[key]; }
			}
			return newObj;
		});
		let newPayload = payload;
		let payloadObject = { data: newPayload, type: "ricar" }
		let staticData = '';
		let fullData = '';

		if (payloadObject.type == "ricar") {
			staticData =
								`			ISTITUTO SPERIMENTALE ITALIANO "LAZZARO SPALLANZANI"
	Direzione e Sede Centrale: Località La Quercia - 26027 Rivolta d'Adda (CR)
		Tel 0363-78883 - Fax 0363-371021 - https://www.istitutospallanzani.it


						Spett.le
						COSAPAM
						VIA FRATELLI ROSSI 10-12
						SECUGNAGO (LO)
Rivolta d'Adda 27-10-2023
		RICHIESTA di CAMPIONI / AUTOCERTIFICAZIONI / RIFERIMENTI (O_RICAR-01)

Con la presente si comunica che in data 19-10-2023 verrà eseguito
il CAMPIONAMENTO (DDMM 403/2000, 27.12.94)(Ns Rif E4151     )
relativo alle sottoriportate partite delle quali si richiede, entro 15 giorni
dalla data di camp., l'AUTOCERTIFICAZIONE, possibilmente in formato elettronico
all'indirizzo e-mail: mailto:cus@istitutospallanzani.it .
Il Documento di Trasporto, predisposto per Soggetto/Partita (come da
elenco sottostante), fungerà da Verbale di Campionamento e dovrà
riportare:
	- come luogo di destinazione l'indirizzo in testa alla presente richiesta,
	- come causale di trasporto la dicitura "Campionamento Legge 30/91".
Se il Campione Biologico di RIFERIMENTO risulta ASSENTE inviarlo entro 15 giorni.

VERIFICARE la correttezza delle MATRICOLE sottoriportate ed in caso di errori
indicarli nel documento di trasporto.`;


			const dynamicData = payloadObject?.data; // Access the "data" property in the dynamic data

			// Header for dynamic data
			const dynamicDataHeader = 'Nome Riproduttore      Matricola          Lotto       Campione Biologico di RIFERIMENTO';
			const dynamicDataSeparator = '______________     ______________   ___________         ________________';
			const bottomfooter1="Il Responsabile VCA"                                                         
const bottomfooter2="Valeria Bornaghi"
			// Format dynamic data
			const formattedDynamicData = dynamicData.map(item => {
				return `${item['Toro'].padEnd(16)}      ${item['Matricola'].padEnd(15)}   ${item['lotto'].padEnd(11)}      ${item['Cod Dna Value'].padEnd(18)}`;				
			});
			
			// Combine the static data, dynamic data header, and dynamic data
			fullData = `${staticData}\n${dynamicDataHeader}\n${dynamicDataSeparator}\n${formattedDynamicData.join('\n')}\n\n\n\n\n ${bottomfooter1.padStart(90)} \n ${bottomfooter2.padStart(90)}`;

			const blob = new Blob([fullData], { type: 'text/plain' });
			const url = window.URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.href = url;
			a.download = 'data.txt';
			document.body.appendChild(a);
			a.click();
			window.URL.revokeObjectURL(url);
		};
	}



	back() {
		this.router.navigate(["campionamenti"]);
	}
}
