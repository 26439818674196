<table class="styled-table" id="tori-table" [ngStyle]="{'margin' : custMarging}">
    <thead>
        <tr>
            <th>Nome</th>
            <th>Matricola</th>
            <th>Matricola2</th>
            <th>Specie</th>
            <th>Razza</th>
            <th>DNA</th>
            <th>Nascita</th>
            <th>Provato</th>
            <th>Top100</th>
            <th>MatricolaANA</th>
        </tr>
    </thead>
    <tbody>

        <tr *ngxtFor="
        let toro of tori; 
        itemsAtOnce: 5;
        intervalLength: 5">
            <td>{{toro.Nome}}</td>
            <td>{{toro.Matricola}}</td>
            <td>{{toro.Matricola2}}</td>
            <td>{{toro.Specie}}</td>
            <td>{{toro.Razza}}</td>
            <td>{{toro.DNA}}</td>
            <td>{{toro.Nascita}}</td>
            <td>{{toro.Provato}}</td>
            <td>{{toro.Top100}}</td>
            <td>{{toro.MatricolaANA}}</td>
        </tr>
    </tbody>
</table>