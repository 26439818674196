<!-- <app-loader *ngIf="loader" ></app-loader> -->
<div class="main-overview">
	<div class="main">
		<!-- overview -->
		<div class="specie">
			<h1 class="overview">{{'Overview'|uppercase}}</h1>
			<div class="export">
				<button class="button1" (click)="generateFrequency()">FREQUENZA NON CONFORMITÀ</button>
				<button class="button1" (click)="genrateReferto()">TEMPI DI EMISSIONE DEL REFERTO</button>
			</div>
		</div>
		<div class="row" *ngIf="!loader">
			<div class="wrapper">
				<div class="column" *ngFor="let item of cards[0]?.overview">
					<div class="card">
						<div>
							<p class="title">{{ item?.title }}</p>
						</div>
						<!-- <div class="custom-loader"*ngIf="loader"></div> -->
						<div class="inner-2">
							<h1>{{ item?.counter ? item?.counter : (0 | number) }}</h1>
							<p *ngIf="item?.percentage">{{ item?.percentage | number }}%</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row" *ngIf="loader">
			<div class="wrapper">
				<div class="column" *ngIf="loader">
					<div class="card_new">
						<div>
							<p class="title"></p>
						</div>
						<div class="custom-loader" *ngIf="loader"></div>
						<div class="inner-2">
							<!-- <h1>...</h1> -->
							<!-- <p >...</p> -->
						</div>
					</div>
				</div>
				<div class="column" *ngIf="loader">
					<div class="card_new">
						<div>
							<p class="title"></p>
						</div>
						<!-- <div class="custom-loader"></div> -->
						<div class="custom-loader" *ngIf="loader"></div>
						<div class="inner-2">
							<!-- <h1>0</h1> -->
							<!-- <p >0%</p> -->
						</div>
					</div>
				</div>
				<div class="column" *ngIf="loader">
					<div class="card_new">
						<div>
							<p class="title"></p>
						</div>
						<!-- <div class="custom-loader"></div> -->
						<div class="custom-loader" *ngIf="loader"></div>
						<div class="inner-2">
							<!-- <h1>0</h1> -->
							<!-- <p >0%</p> -->
						</div>
					</div>
				</div>
				<div class="column" *ngIf="loader">
					<div class="card_new">
						<div>
							<p class="title"></p>
						</div>
						<!-- <div class="custom-loader"></div> -->
						<div class="custom-loader" *ngIf="loader"></div>
						<div class="inner-2">
							<!-- <h1>0</h1> -->
							<!-- <p >0%</p> -->
						</div>
					</div>
				</div>
				<div class="column" *ngIf="loader">
					<div class="card_new">
						<div>
							<p class="title"></p>
						</div>
						<!-- <div class="custom-loader"></div> -->
						<div class="custom-loader" *ngIf="loader"></div>
						<div class="inner-2">
							<!-- <h1>0</h1> -->
							<!-- <p >0%</p> -->
						</div>
					</div>
				</div>
				<div class="column" *ngIf="loader">
					<div class="card_new">
						<div>
							<p class="title"></p>
						</div>
						<!-- <div class="custom-loader"></div> -->
						<div class="custom-loader" *ngIf="loader"></div>
						<div class="inner-2">
							<!-- <h1>0</h1> -->
							<!-- <p >0%</p> -->
						</div>
					</div>
				</div>
				<div class="column" *ngIf="loader">
					<div class="card_new">
						<div>
							<p class="title"></p>
						</div>
						<!-- <div class="custom-loader"></div> -->
						<div class="custom-loader" *ngIf="loader"></div>
						<div class="inner-2">
							<!-- <h1>0</h1> -->
							<!-- <p >0%</p> -->
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- isils -->
		<div class="row isils" *ngIf="!loader">
			<div class="specie">
				<h1 class="overview">ISILS SOURCE</h1>
			</div>
			<div class="wrapper">
				<div class="column" *ngFor="let item of cards[0]?.isilsSource">
					<div class="card">
						<div>
							<p class="title">{{ item?.title }}</p>
						</div>
						<div class="inner-2">
							<h1>{{ transform(item?.counter) }}</h1>
							<p *ngIf="item?.percentage">{{ item?.percentage | number }}%</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row isils" *ngIf="loader">
			<div class="specie">
				<h1 class="overview">ISILS SOURCE</h1>
				<!-- <app-speciedetail [year]="true" (dataToParent)="receiveDataFromChild($event)" (SelectedYear)="recevedate($event)"></app-speciedetail> -->
			</div>
			<div class="wrapper">
				<div class="column" *ngIf="loader">
					<div class="card_new">
						<div>
							<p class="title"></p>
						</div>
						<!-- <div class="custom-loader"></div> -->
						<div class="custom-loader" *ngIf="loader"></div>
						<div class="inner-2">
							<!-- <h1>0</h1> -->
							<!-- <p >0%</p> -->
						</div>
					</div>
				</div>
				<div class="column" *ngIf="loader">
					<div class="card_new">
						<div>
							<p class="title"></p>
						</div>
						<!-- <div class="custom-loader"></div> -->
						<div class="custom-loader" *ngIf="loader"></div>
						<div class="inner-2">
							<!-- <h1>0</h1> -->
							<!-- <p >0%</p> -->
						</div>
					</div>
				</div>
				<div class="column" *ngIf="loader">
					<div class="card_new">
						<div>
							<p class="title"></p>
						</div>
						<!-- <div class="custom-loader"></div> -->
						<div class="custom-loader" *ngIf="loader"></div>
						<div class="inner-2">
							<!-- <h1>0</h1> -->
							<!-- <p >0%</p> -->
						</div>
					</div>
				</div>
				<div class="column" *ngIf="loader">
					<div class="card_new">
						<div>
							<p class="title"></p>
						</div>
						<!-- <div class="custom-loader"></div> -->
						<div class="custom-loader" *ngIf="loader"></div>
						<div class="inner-2">
							<!-- <h1>0</h1> -->
							<!-- <p >0%</p> -->
						</div>
					</div>
				</div>
				<div class="column" *ngIf="loader">
					<div class="card_new">
						<div>
							<p class="title"></p>
						</div>
						<!-- <div class="custom-loader"></div> -->
						<div class="custom-loader" *ngIf="loader"></div>
						<div class="inner-2">
							<!-- <h1>0</h1> -->
							<!-- <p >0%</p> -->
						</div>
					</div>
				</div>
				<div class="column" *ngIf="loader">
					<div class="card_new">
						<div>
							<p class="title"></p>
						</div>
						<div class="custom-loader" *ngIf="loader"></div>
						<div class="inner-2">
							<!-- <h1>0</h1> -->
							<!-- <p >0%</p> -->
						</div>
					</div>
				</div>
				<div class="column" *ngIf="loader">
					<div class="card_new">
						<div>
							<p class="title"></p>
						</div>
						<!-- <div class="custom-loader"></div> -->
						<div class="custom-loader" *ngIf="loader"></div>
						<div class="inner-2">
							<!-- <h1>0</h1> -->
							<!-- <p >0%</p> -->
						</div>
					</div>
				</div>
				<div class="column" *ngIf="loader">
					<div class="card_new">
						<div>
							<p class="title"></p>
						</div>
						<!-- <div class="custom-loader"></div> -->
						<div class="custom-loader" *ngIf="loader"></div>
						<div class="inner-2">
							<!-- <h1>0</h1> -->
							<!-- <p >0%</p> -->
						</div>
					</div>
				</div>
				<div class="column" *ngIf="loader">
					<div class="card_new">
						<div>
							<p class="title"></p>
						</div>
						<!-- <div class="custom-loader"></div> -->
						<div class="custom-loader" *ngIf="loader"></div>
						<div class="inner-2">
							<!-- <h1>0</h1> -->
							<!-- <p >0%</p> -->
						</div>
					</div>
				</div>
				<div class="column" *ngIf="loader">
					<div class="card_new">
						<div>
							<p class="title"></p>
						</div>
						<!-- <div class="custom-loader"></div> -->
						<div class="custom-loader" *ngIf="loader"></div>
						<div class="inner-2">
							<!-- <h1>0</h1> -->
							<!-- <p >0%</p> -->
						</div>
					</div>
				</div>
				<div class="column" *ngIf="loader">
					<div class="card_new">
						<div>
							<p class="title"></p>
						</div>
						<!-- <div class="custom-loader"></div> -->
						<div class="custom-loader" *ngIf="loader"></div>
						<div class="inner-2">
							<!-- <h1>0</h1> -->
							<!-- <p >0%</p> -->
						</div>
					</div>
				</div>
				<div class="column" *ngIf="loader">
					<div class="card_new">
						<div>
							<p class="title"></p>
						</div>
						<!-- <div class="custom-loader"></div> -->
						<div class="custom-loader" *ngIf="loader"></div>
						<div class="inner-2">
							<!-- <h1>0</h1> -->
							<!-- <p >0%</p> -->
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Centro Source -->
		<div class="row isils" *ngIf="!loader">
			<div class="specie">
				<h1 class="overview">CENTRO SOURCE</h1>
				<!-- <app-speciedetail [year]="true" (dataToParent)="receiveDataFromChild($event)" (SelectedYear)="recevedate($event)"></app-speciedetail> -->
			</div>
			<div class="wrapper">
				<div class="column" *ngFor="let item of cards[0]?.centroSource">
					<div class="card">
						<div>
							<p class="title">{{ item?.title }}</p>
						</div>
						<div class="inner-2">
							<h1>{{ transform(item?.counter) }}</h1>
							<p *ngIf="item?.percentage">{{ item?.percentage | number }}%</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row isils" *ngIf="loader">
			<div class="specie">
				<h1 class="overview">CENTRO SOURCE</h1>
				<!-- <app-speciedetail [year]="true" (dataToParent)="receiveDataFromChild($event)" (SelectedYear)="recevedate($event)"></app-speciedetail> -->
			</div>
			<div class="wrapper">
				<div class="column" *ngIf="loader">
					<div class="card_new">
						<div>
							<p class="title"></p>
						</div>
						<!-- <div class="custom-loader"></div> -->
						<div class="custom-loader" *ngIf="loader"></div>
						<div class="inner-2">
							<!-- <h1>0</h1> -->
							<!-- <p >0%</p> -->
						</div>
					</div>
				</div>
				<div class="column" *ngIf="loader">
					<div class="card_new">
						<div>
							<p class="title"></p>
						</div>
						<!-- <div class="custom-loader"></div> -->
						<div class="custom-loader" *ngIf="loader"></div>
						<div class="inner-2">
							<!-- <h1>0</h1> -->
							<!-- <p >0%</p> -->
						</div>
					</div>
				</div>
				<div class="column" *ngIf="loader">
					<div class="card_new">
						<div>
							<p class="title"></p>
						</div>
						<!-- <div class="custom-loader"></div> -->
						<div class="custom-loader" *ngIf="loader"></div>
						<div class="inner-2">
							<!-- <h1>0</h1> -->
							<!-- <p >0%</p> -->
						</div>
					</div>
				</div>
				<div class="column" *ngIf="loader">
					<div class="card_new">
						<div>
							<p class="title"></p>
						</div>
						<!-- <div class="custom-loader"></div> -->
						<div class="custom-loader" *ngIf="loader"></div>
						<div class="inner-2">
							<!-- <h1>0</h1> -->
							<!-- <p >0%</p> -->
						</div>
					</div>
				</div>
				<div class="column" *ngIf="loader">
					<div class="card_new">
						<div>
							<p class="title"></p>
						</div>
						<!-- <div class="custom-loader"></div> -->
						<div class="custom-loader" *ngIf="loader"></div>
						<div class="inner-2">
							<!-- <h1>0</h1> -->
							<!-- <p >0%</p> -->
						</div>
					</div>
				</div>
				<div class="column" *ngIf="loader">
					<div class="card_new">
						<div>
							<p class="title"></p>
						</div>
						<!-- <div class="custom-loader"></div> -->
						<div class="custom-loader" *ngIf="loader"></div>
						<div class="inner-2">
							<!-- <h1>0</h1> -->
							<!-- <p >0%</p> -->
						</div>
					</div>
				</div>
				<div class="column" *ngIf="loader">
					<div class="card_new">
						<div>
							<p class="title"></p>
						</div>
						<!-- <div class="custom-loader"></div> -->
						<div class="custom-loader" *ngIf="loader"></div>
						<div class="inner-2">
							<!-- <h1>0</h1> -->
							<!-- <p >0%</p> -->
						</div>
					</div>
				</div>
				<div class="column" *ngIf="loader">
					<div class="card_new">
						<div>
							<p class="title"></p>
						</div>
						<!-- <div class="custom-loader"></div> -->
						<div class="custom-loader" *ngIf="loader"></div>
						<div class="inner-2">
							<!-- <h1>0</h1> -->
							<!-- <p >0%</p> -->
						</div>
					</div>
				</div>
				<div class="column" *ngIf="loader">
					<div class="card_new">
						<div>
							<p class="title"></p>
						</div>
						<!-- <div class="custom-loader"></div> -->
						<div class="custom-loader" *ngIf="loader"></div>
						<div class="inner-2">
							<!-- <h1>0</h1> -->
							<!-- <p >0%</p> -->
						</div>
					</div>
				</div>
				<div class="column" *ngIf="loader">
					<div class="card_new">
						<div>
							<p class="title"></p>
						</div>
						<!-- <div class="custom-loader"></div> -->
						<div class="custom-loader" *ngIf="loader"></div>
						<div class="inner-2">
							<!-- <h1>0</h1> -->
							<!-- <p >0%</p> -->
						</div>
					</div>
				</div>
				<div class="column" *ngIf="loader">
					<div class="card_new">
						<div>
							<p class="title"></p>
						</div>
						<!-- <div class="custom-loader"></div> -->
						<div class="custom-loader" *ngIf="loader"></div>
						<div class="inner-2">
							<!-- <h1>0</h1> -->
							<!-- <p >0%</p> -->
						</div>
					</div>
				</div>
				<div class="column" *ngIf="loader">
					<div class="card_new">
						<div>
							<p class="title"></p>
						</div>
						<!-- <div class="custom-loader"></div> -->
						<div class="custom-loader" *ngIf="loader"></div>
						<div class="inner-2">
							<!-- <h1>0</h1> -->
							<!-- <p >0%</p> -->
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
