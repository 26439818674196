import { Component, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { InnerWorkSheets, WorkSheets } from '../_models';
import { CellClickedEvent, ColDef, GridOptions } from 'ag-grid-community';
import { CustomButtonRendererComponent } from '../table-test/custom-button-renderer.component';
import { ApiService } from '../_services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-detail-page',
  templateUrl: './detail-page.component.html',
  styleUrls: ['./detail-page.component.scss']
})
export class DetailPageComponent {
  loader: boolean = false;
  popup = false;
  rowData1$: WorkSheets[];
  rowData2$: InnerWorkSheets[];
  rowData3$: WorkSheets[];
  clickedRowData1: any;
  clickedRowData2: any;
  view: any;
  @ViewChild("popupContainer", { read: TemplateRef })
  table1Name: string = 'newdnadata';
  table2Name: string = 'innerdatatable';
  itemPerPage: number = 10;
  pageNumber: number = 1;
  totalCount: number = 1;
  subjectValue: boolean = false;
  defaultColDef: ColDef = {
    // flex: 1,
    resizable: true,
    sortable: true,
    filter: true,
  };
  fagfilename: string;


  colDefs2: ColDef<InnerWorkSheets>[] = [
    // { field: "number", headerName: "SEQUENZIATORE", valueGetter: "node.rowIndex + 1" },
    {
      field: "toro", width: 255, headerName: "RIPRODUTTORE", cellRenderer: (params: any) => {
        return `${params.data?.toro == 'NULL' || params.data?.toro == null ? 'N/A' : params.data?.toro}`;
      },
    },
    {
      field: "matricola", width: 260, headerName: "MATRICOLA", cellRenderer: (params: any) => {
        return `${params.data?.tori[0]?.matricola == 'NULL' || params.data?.tori[0]?.matricola == null ? 'N/A' : params.data?.tori[0]?.matricola}`;
      },
    },
    { field: "lotto", width: 265, headerName: "LOTTO" },
    { field: "codice_lab", headerName: "ID LAB" },
    {
      field: "cod_dna", headerName: "ID DNA", cellRenderer: (params: any) => {
        return `${params.data?.tori[0]?.cod_dna == 'NULL' || params.data?.tori[0]?.cod_dna == null ? 'N/A' : params.data?.tori[0]?.cod_dna}`;
      },
    },
    {
      field: "tipo_seq", headerName: "Sequenziatore", cellRenderer: (params: any) => {
        return `${params?.data?.tori[0]?.dna[0]?.tipo_seq == 'NULL' || params?.data?.tori[0]?.dna[0]?.tipo_seq == null ? 'N/A' : params?.data?.tori[0]?.dna[0]?.tipo_seq}`;
      },
    },
    {
      field: "contenit", headerName: "Sito DNA", cellRenderer: (params: any) => {
        let dataObject = params?.data?.tori[0]?.dna[0]
        this.concatenatedString = `${dataObject?.contenit}_${dataObject?.settore}_${dataObject?.cilindro}_${dataObject?.piano}`;
        return `${this.concatenatedString == 'NULL' || this.concatenatedString == null || this.concatenatedString=="undefined_undefined_undefined_undefined" ? 'N/A' : this.concatenatedString}`;
      },
    },
    {
      field: "dna_ug", headerName: "UG",width:255, cellRenderer: (params: any) => {
        return `${params?.data?.tori[0]?.dna_ug == 'NULL' || params?.data?.tori[0]?.dna_ug == null ? 'NA' :params?.data?.tori[0]?.dna_ug?.cod_dna_ug }`;
      },
    }

  ];
  concatenatedString: string;

  gridOptions2: GridOptions<InnerWorkSheets> = {
    columnDefs: this.colDefs2,
    defaultColDef: this.defaultColDef,
    rowSelection: "single",
    animateRows: true,
    pagination: true,
    paginationAutoPageSize: true,
    frameworkComponents: { customButtonRenderer: CustomButtonRendererComponent },
    onCellClicked: (event: CellClickedEvent) => {
      this.clickedRowData2 = event?.data;
    },
  };
  codice_cam: any;
  codice_az: any;
  constructor(
    public apiService: ApiService,
    private viewContainerRef: ViewContainerRef,
    private activate: ActivatedRoute,
    private router: Router,
    public toastr: ToastrService,
    private http: HttpClient,
  ) { }
  ngOnInit() {
    this.activate.queryParams.subscribe((res: any) => {
      this.innerDatabale(res?.codice_cam)
      this.codice_cam = res?.codice_cam
      this.codice_az = res?.codice_az
    })
  }


  innerDatabale(data: any) {
    const codice_cam = data ? data : null;
    if (codice_cam !== null) {
      this.apiService.innerWorksheetData(codice_cam).subscribe({
        next: (res) => {
          this.rowData2$ = res?.result?.rows;
        },
        error: (error) => {
        },
        complete: () => {

        }
      });
    }
  }
  onBtExport() {
    const newrowData2: any[] = this.rowData2$.map((el: any, index: number) => {
      return {
        number: index + 1,
        toro: el?.toro ? el?.toro : '',
        codice_lab: el?.codice_lab ? el?.codice_lab : '',
        lotto: el?.lotto ? el?.lotto : '',
        cod_dna: el?.tori[0]?.cod_dna ? el?.tori[0]?.cod_dna : '',
        matricola: el?.tori[0]?.matricola ? el?.tori[0]?.matricola : '',
        tipo_seq: el.tori[0]?.dna[0]?.tipo_seq ? el.tori[0]?.dna[0]?.tipo_seq : '',
        contenit: this.concatenatedString,
        dna_ug: el.tori[0]?.dna_ug ? '*' : ''

      }

    });
    const fieldToHeaderMap = {};
    this.colDefs2.forEach(item => { fieldToHeaderMap[item?.field] = item?.headerName });
    const payload = newrowData2.map(obj => {
      const newObj = {};
      for (const key in obj) {
        if (fieldToHeaderMap[key]) { newObj[fieldToHeaderMap[key]] = obj[key]; }
      }
      return newObj;
    });
    let newPayload = payload;
    if (newPayload.length == 0) {
      this.toastr.error('Data not found')
      return
    }
    this.apiService.getExcelData(newPayload).subscribe({
      next: (res) => {
        if (res instanceof Blob) {
          const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          a.download = 'data.xlsx';
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        } else {
          console.error('Invalid response format. Expected a Blob.');
        }

      },
      error: (error) => {
        console.error('An error occurred:', error);
      },
      complete: () => {
      }
    });
  }
  closePopup() {
    const domElem = this.view.rootNodes[0] as HTMLElement;
    domElem.classList.add("animate-out");
    domElem.addEventListener("click", (event: AnimationEvent) => {
      this.viewContainerRef.clear();
      this.apiService.modaBackDrop.next(false);
    });
  }
  back() {
    this.router.navigate(["worksheet-dna"]);
  }
  getFoglioText( fileType: any) {
    this.apiService.getFoglio(this.codice_cam, this.codice_az, fileType).subscribe({
      next: (data) => {
        if (data.success) {
          let url = environment.url + data.downloadLink;
          this.downloadTextFile(url)
          this.fagfilename = data.filenames
        } else {
          this.toastr.error(data.description);
        }
      },
    });
  }

  downloadTextFile(url: string): void {
    this.http.get(url, { responseType: 'text' }).subscribe((data: string) => {
      const blob = new Blob([data], { type: 'text/plain' });
      const anchor = document.createElement('a');
      anchor.href = window.URL.createObjectURL(blob);
      anchor.download = this.fagfilename;
      document.body.appendChild(anchor);
      anchor.click();
      window.URL.revokeObjectURL(anchor.href);
      document.body.removeChild(anchor);
    });
  }
}
