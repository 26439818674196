import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { ApiService } from '../_services/api.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private router: Router, private apiService: ApiService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const token = localStorage.getItem('token');
		this.apiService.modaBackDrop.next(false);
		if (token) {
			// If we have a token, we set it to the header
			request = request.clone({
				setHeaders: { Authorization: `Bearer ${token}` },
			});
		}
		return next.handle(request).pipe(
			catchError((err) => {
				if (err instanceof HttpErrorResponse) {
					if (err.status === 401) {
						localStorage.clear();
						window.sessionStorage.clear()
						this.router.navigate(["/login"]);
						// redirect user to the logout page
					}
				}
				return throwError(() => {});
			})
		);
  }
}
