import { Component, HostListener } from "@angular/core";
import { Centro, GenericResponse } from "src/app/_models";
import { ApiService } from "src/app/_services/api.service";
import { AuthenticationService } from "src/app/_services/authentication.service";
import { StorageService } from "src/app/_services/storage.service";
import FuzzySearch from "fuzzy-search";
import { Router } from "@angular/router";
@Component({
	selector: "app-dash",
	templateUrl: "./dash.component.html",
	styleUrls: ["./dash.component.scss"],
})
export class DashComponent {
	userPermision: any = [];
	centriCodiceAzName: any;
	notificationValue: any;
	notificationCount: number = 0;
	yellownotification:any;
	constructor(protected storageService: StorageService, private http: ApiService, private authService: AuthenticationService,public router: Router,) {}
	public centroID: number = 0;
	isLoggedIn = false;
	boolShowInputText = false;
	searcher: FuzzySearch;
	searchText: string = localStorage.getItem("centriCodiceAzName") ? localStorage.getItem("centriCodiceAzName") : "";
	result = [];
	centroNome: string = null;
	centri: Centro[] = [];
	centroIndirizzo: string = null;
	profileToggle: boolean = false;
	notificationToggle:boolean = false
	darkMode: boolean = true;
	user = {
		id: 1,
		firstName: "Utente",
		lastName: "Uno",
		email: "Utente 2",
		phone: "+39333333333",
		role: "User",
		office: "LabSeme",
		colorScheme: "dark",
		Id: 1,
		Denominazione: "Cosapam",
		Indirizzo: localStorage.getItem("Indirizzo") ? localStorage.getItem("Indirizzo") : "VIA FRATELLI ROSSI 10-12",
	};
	allUserpermision: any = [];
	selectedText: string = localStorage.getItem("centriCodiceAzName") ? localStorage.getItem("centriCodiceAzName") : "Cosapam";
	isModalBackDrop: boolean = false;

	ngOnInit() {
		
		// this.enableDisableScroll()
		this.retrieveCentri();
		if (this.storageService.isLoggedIn()) {
			this.isLoggedIn = true;
		}
		this.checkingPermision();
		this.userpermision();
		this.user.colorScheme == "light" ? this.set_dark_mode() : this.set_light_mode();
		this.storageService.saveCentro(this.user.Id);
		 this.http.notification.subscribe((res)=>{
			if (res && res?.red) {
				this.notificationValue = res?.red
				this.notificationCount = res?.red.length;
				this.yellownotification = res.yellow
			}
		})
		this.http.modaBackDrop.subscribe((res) => {
			this.isModalBackDrop = res;
			setTimeout(() => {
				
				this.setScrollPosition();
			}, 10);
		})
		if(localStorage.getItem('notificationCount')) {
			this.notificationCount = +localStorage.getItem('notificationCount');
		  }
		// console.log(notificationvalue)
		
	}

	setScrollPosition() {
		let innerPageElement = document.getElementsByClassName('inner-page')[0];
		let scrollTop = innerPageElement.scrollTop;
		
		if (document.querySelector('.modal-dropbox')) {
			const absoluteDiv = document.querySelector('.modal-dropbox') as HTMLElement;
			const bottom = -scrollTop + 'px';
			absoluteDiv.style.top = scrollTop + 'px';
			absoluteDiv.style.bottom = bottom;
		}
	}

	handleScroll(event: Event): void {
		const outerDiv = event.target as HTMLElement;
		if (document.querySelector('.modal-dropbox')) {
			const absoluteDiv = document.querySelector('.modal-dropbox') as HTMLElement;
			const scrollTop = outerDiv.scrollTop;
			const bottom = -scrollTop + 'px';
			absoluteDiv.style.top = scrollTop + 'px';
			absoluteDiv.style.bottom = bottom;
		}
	  }

	userpermision() {
		this.authService.menuItem.subscribe((res) => {
			if (res == true) {
				this.checkingPermision();
			}
		});
	}
	checkingPermision() {
		let data = sessionStorage.getItem("auth-user");
		if (data != null) {
			this.userPermision = JSON.parse(data).data.user.permission_groups.permissions;

			this.userPermision.forEach((element) => {
				this.allUserpermision.push(element.name);
			});
		}
	}
	set_light_mode() {
		this.darkMode = false;
		document.documentElement.style.setProperty("--Crust", "#fff");
		document.documentElement.style.setProperty("--Mantle", "#e6fbe8");
		document.documentElement.style.setProperty("--Base", "#a8e5a9");
		document.documentElement.style.setProperty("--Accent", "#6B9E6A");
		document.documentElement.style.setProperty("--AccentDark", "#546953");
		document.documentElement.style.setProperty("--Surface0", "#ccd0da");
		document.documentElement.style.setProperty("--Surface1", "#586855");
		document.documentElement.style.setProperty("--Surface2", "#acb0be");
		document.documentElement.style.setProperty("--Overlay0", "#9ca0b0");
		document.documentElement.style.setProperty("--Overlay1", "#8c8fa1");
		document.documentElement.style.setProperty("--Overlay2", "#7c7f93");
		document.documentElement.style.setProperty("--Subtext0", "#6c6f85");
		document.documentElement.style.setProperty("--Subtext1", "#5c5f77");
		document.documentElement.style.setProperty("--Text", "#4c4f69");
		document.documentElement.style.setProperty("--Lavender", "#7287fd");
		document.documentElement.style.setProperty("--Blue", "#1e66f5");
		document.documentElement.style.setProperty("--Sapphire", "#209fb5");
		document.documentElement.style.setProperty("--Sky", "#04a5e5");
		document.documentElement.style.setProperty("--Teal", "#179299");
		document.documentElement.style.setProperty("--Green", "#6B9E6A");
		document.documentElement.style.setProperty("--Yellow", "#df8e1d");
		document.documentElement.style.setProperty("--Peach", "#fe640b");
		document.documentElement.style.setProperty("--Maroon", "#e64553");
		document.documentElement.style.setProperty("--Red", "#d20f39");
		document.documentElement.style.setProperty("--Mauve", "#8839ef");
		document.documentElement.style.setProperty("--Pink", "#ea76cb");
		document.documentElement.style.setProperty("--Flamingo", "#dd7878");
		document.documentElement.style.setProperty("--Rosewater", "#dc8a78");
		document.documentElement.style.setProperty("--Text", "#333333");
		document.documentElement.style.setProperty("--ChartBg", "#E3F2E2");
	}
	set_dark_mode() {
		this.darkMode = true;
		document.documentElement.style.setProperty("--Crust", "#11111b");
		document.documentElement.style.setProperty("--Mantle", "#181825");
		document.documentElement.style.setProperty("--Base", "#1e1e2e");
		document.documentElement.style.setProperty("--Accent", "#6B9E6A");
		document.documentElement.style.setProperty("--AccentDark", "#546953");
		document.documentElement.style.setProperty("--Surface0", "#313244");
		document.documentElement.style.setProperty("--Surface1", "#45475a");
		document.documentElement.style.setProperty("--Surface2", "#585b70");
		document.documentElement.style.setProperty("--Overlay0", "#6c7086");
		document.documentElement.style.setProperty("--Overlay1", "#7f849c");
		document.documentElement.style.setProperty("--Overlay2", "#9399b2");
		document.documentElement.style.setProperty("--Subtext0", "#a6adc8");
		document.documentElement.style.setProperty("--Subtext1", "#bac2de");
		document.documentElement.style.setProperty("--Text", "#cdd6f4");
		document.documentElement.style.setProperty("--Lavender", "#b4befe");
		document.documentElement.style.setProperty("--Blue", "#89b4fa");
		document.documentElement.style.setProperty("--Sapphire", "#74c7ec");
		document.documentElement.style.setProperty("--Sky", "#89dceb");
		document.documentElement.style.setProperty("--Teal", "#94e2d5");
		document.documentElement.style.setProperty("--Green", "#a6e3a1");
		document.documentElement.style.setProperty("--Yellow", "#f9e2af");
		document.documentElement.style.setProperty("--Peach", "#fab387");
		document.documentElement.style.setProperty("--Maroon", "#eba0ac");
		document.documentElement.style.setProperty("--Red", "#f38ba8");
		document.documentElement.style.setProperty("--Mauve", "#cba6f7");
		document.documentElement.style.setProperty("--Pink", "#f5c2e7");
		document.documentElement.style.setProperty("--Flamingo", "#f2cdcd");
		document.documentElement.style.setProperty("--Rosewater", "#f5e0dc");
		document.documentElement.style.setProperty("--Text", "#ffffff");
		document.documentElement.style.setProperty("--ChartBg", "#181825");
	}
	set_color_scheme() {
		if (this.darkMode) {
			this.set_dark_mode();
		} else {
			this.set_light_mode();
		}
	}

	toggle_dark_mode() {
		this.darkMode = !this.darkMode;
		this.set_color_scheme();
	}

	retrieveCentri() {
		this.http
			.genericTableRetrieve("Centro", {
				select: ["Id", "denominaz", "Indirizzo"],
			})
			.subscribe((data: GenericResponse<Centro>) => {
				if (data.success) {
					this.centri = data.result;
					this.searcher = new FuzzySearch(this.centri, ["denominaz", "Indirizzo"]);
					this.result = this.searcher.search(this.searchText);
				} else {
				}
			});
	}

	onSearchText() {
		this.result = this.searcher.search(this.searchText);
	}

	selectCentro(id, data) {
		this.retrieveCentri();
		this.selectedText = data?.denominaz ? data?.denominaz : "";
		this.searchText = data?.denominaz ? data?.denominaz : "";
		this.centroIndirizzo = data?.Indirizzo;
		this.http.current.next(data);
		this.http.autocertizion.next(data);
		localStorage.setItem("centriCodiceAz", data.codice_az);
		this.centriCodiceAzName = localStorage.setItem("centriCodiceAzName", data.denominaz);
		localStorage.setItem("Indirizzo", data.Indirizzo);

		if (typeof id === "number") {
			this.centroID = id;
			this.storageService.saveCentro(this.centroID);
			let centro = this.centri.find((centro) => centro.Id === id);
			this.searchText = "";
			this.centroNome = centro.Denominazione;
			this.centroIndirizzo = centro.Indirizzo;
		}
	}

	focusIn(_) {
		this.boolShowInputText = true;
	}

	focusOut(evt: Event) {
		this.boolShowInputText = false;
	}

	toggleProfile(evt) {
		this.profileToggle = !this.profileToggle;
		this.notificationToggle = false
		evt.preventDefault();
		evt.stopPropagation();
	}

	@HostListener('document:click', ['$event'])
	onClick(event: MouseEvent) {
	  const target = event.target as HTMLElement;
	  const isProfileButton = target.classList.contains('profile-button'); 
	  if (!isProfileButton) {
		this.profileToggle = false;
	  }
	}

	toggleNotification(evt) {
		this.notificationToggle = !this.notificationToggle;
		this.profileToggle = false
		evt.preventDefault();
		evt.stopPropagation();
	}

	themeToggle() {
		if (this.darkMode) {
			this.set_light_mode();
		} else {
			this.set_dark_mode();
		}
	}

	reloadPage(): void {
		window.location.reload();
	}

	logout() {
		if (this.storageService.isLoggedIn()) {
			this.storageService.clean();
			localStorage.clear();
			this.reloadPage();
		}
	}

	closeOpenStuff() {
		if (this.profileToggle) {
			this.profileToggle = false;
		}
		if(this.notificationToggle){
			this.notificationToggle = false;
			
		}
	}
	changePassword() {}
	notification(){
		// if(data == 'instrument'){
			this.router.navigate(['dna-table'])
// 
		// }

	}
	enableDisableScroll(){
		document.addEventListener('DOMContentLoaded', function () {
			var elements = document.querySelectorAll('#vert-nav-dashboard ul li');

			elements.forEach(function (element) {
			  element.addEventListener('mouseenter', function () {
				console.log('Mouse entered the tooltip!');
				let el = document.getElementsByClassName('vertical-nav')[0] as HTMLElement;
				el.style.overflow = 'visible';
			  });
		
			  element.addEventListener('mouseleave', function () {
				console.log('Mouse left the tooltip!');
				let el = document.getElementsByClassName('vertical-nav')[0] as HTMLElement;
				el.style.overflow = 'auto';
			  });
			});

		  });
		  
	}
}
