<!-- <app-loader *ngIf="loader"></app-loader> -->

<app-conform [message]="message" (confirm)="confirm($event)"></app-conform>
<div class="header d-flex mt-2">
    <h1 class="semi-bold">LOTTI</h1>
    <div class="export">
        <!-- <button class="button1 mr-1" (click)="openCqFormPopup()">CREATE</button> -->
    </div>
</div>
<ag-grid-angular style="height: 700px;" class="ag-theme-alpine-dark basic-table" [rowData]="cqTableData$"
    [gridOptions]="GridOptions" (gridReady)="onGridReady($event)" (cellClicked)="onCellClicked($event)">
</ag-grid-angular>

<!-- <div class="pagination">
    <app-pagination [dataLength]="totalCount" [tableName]="tableName" [itemPerPage]="itemPerPage" [currentYear]="currentYear"
    (dataOnPage)="onReceiveData($event)" [columnName]="columnName" [sortingOrder]="sortingOrder"></app-pagination>
</div> -->

<ng-template #PopupContainer let-options>
    <div class="popup-window animate-in popup-form p-1">
        <form [formGroup]="cqForm" (ngSubmit)="onSubmitCqForm()">
            <div class="d-flex gap-16">
                <div class="field w-100">
                    <label class="d-block">data_prot:</label>
                    <input type="date" class="w-100" formControlName="data_prot" required />
                    <div *ngIf="(cqFormSubmitted || fc['data_prot'].touched) && fc['data_prot'].errors" class="alert">
                        <div *ngIf="(cqFormSubmitted || fc['data_prot'].touched)"> Please enter data_prot</div>
                    </div>
                </div>
                <div class="field w-100">
                    <label class="d-block">cod_toro:</label>
                    <input type="number" class="w-100" formControlName="cod_toro" />
                    <div *ngIf="(cqFormSubmitted || fc['cod_toro'].touched) && fc['cod_toro'].errors" class="alert">
                        <div *ngIf="(cqFormSubmitted || fc['cod_toro'].touched)"> Please enter cod_toro</div>
                    </div>
                </div>
            </div>
            <div class="d-flex gap-16">
                <div class="field w-100">
                    <label class="d-block">codice_az:</label>
                    <input type="number" class="w-100" formControlName="codice_az" />
                    <div *ngIf="(cqFormSubmitted || fc['codice_az'].touched) && fc['codice_az'].errors" class="alert">
                        <div *ngIf="(cqFormSubmitted || fc['codice_az'].touched)"> Please enter codice_az</div>
                    </div>
                </div>
                <div class="field w-100">
                    <label class="d-block">specie:</label>
                    <input type="text" class="w-100" formControlName="specie" />
                    <div *ngIf="(cqFormSubmitted || fc['specie'].touched) && fc['specie'].errors" class="alert">
                        <div *ngIf="(cqFormSubmitted || fc['specie'].touched)"> Please enter specie</div>
                    </div>
                </div>
            </div>
            <div class="d-flex gap-16">
                <div class="field w-100">
                    <label class="d-block">*anno:</label>
                    <input type="number" class="w-100" formControlName="anno" />
                    <div *ngIf="(cqFormSubmitted || fc['anno'].touched) && fc['anno'].errors" class="alert">
                        <div *ngIf="(cqFormSubmitted || fc['anno'].touched)"> Please enter anno</div>
                    </div>
                </div>
                <div class="field w-100">
                    <label class="d-block">toro:</label>
                    <input type="text" class="w-100" formControlName="toro" />
                    <div *ngIf="(cqFormSubmitted || fc['toro'].touched) && fc['toro'].errors" class="alert">
                        <div *ngIf="(cqFormSubmitted || fc['toro'].touched)"> Please enter toro</div>
                    </div>
                </div>
            </div>
            <div class="d-flex gap-16">
                <div class="field w-100">
                    <label class="d-block">*lotto:</label>
                    <input type="text" class="w-100" formControlName="lotto" required />
                    <div *ngIf="(cqFormSubmitted || fc['lotto'].touched) && fc['lotto'].errors" class="alert">
                        <div *ngIf="(cqFormSubmitted || fc['lotto'].touched)"> Please enter lotto</div>
                    </div>
                </div>
                <div class="field w-100">
                    <label class="d-block">dosi:</label>
                    <input type="number" class="w-100" formControlName="dosi" />
                    <div *ngIf="(cqFormSubmitted || fc['dosi'].touched) && fc['dosi'].errors" class="alert">
                        <div *ngIf="(cqFormSubmitted || fc['dosi'].touched)"> Please enter dosi</div>
                    </div>
                </div>
            </div>
            <div class="d-flex gap-16">
                <div class="field w-100">
                    <label class="d-block">prov:</label>
                    <input type="number" class="w-100" formControlName="prov" />
                    <div *ngIf="(cqFormSubmitted || fc['prov'].touched) && fc['prov'].errors" class="alert">
                        <div *ngIf="(cqFormSubmitted || fc['prov'].touched)"> Please enter prov</div>
                    </div>
                </div>
            </div>


            <div class="main-footer">
                <div class="delete">
                    <i class="fa fa-trash customtrash" aria-hidden="true" (click)="openmodel()"></i>
                </div>
                <div class="popup-footer">
                    <button class="button1 mr-1" type="submit">Aggiorna</button>
                    <button class="button1" (click)="closePopup()" type="button">ANNULLA</button>
                </div>
            </div>
        </form>
    </div>
</ng-template>