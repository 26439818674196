
<svg width="100" height="100" style="visibility: hidden; position:absolute">
  <rect x="10" y="10" width="80" height="80" fill="url(#gradient)" />
  <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
    <stop offset="0%" stop-color="#546953" />
    <stop offset="50%" stop-color="#C3EAC7" />
    <stop offset="100%" stop-color="#E6FBE8"/>
  </linearGradient>
</svg>
<svg width="100" height="100" style="visibility: hidden; position:absolute">
  <rect x="10" y="10" width="80" height="80" fill="url(#gradient1)" />
  <linearGradient id="gradient1" x1="0%" y1="0%" x2="0%" y2="100%">
    <stop offset="0%" stop-color="#769D6F" />
    <stop offset="50%" stop-color="#C3EAC7" />
    <stop offset="100%" stop-color="#546953"/>
  </linearGradient>
</svg>

<svg width="100" height="100" style="visibility: hidden; position:absolute">
  <rect x="10" y="10" width="80" height="80" fill="url(#gradient1)" />
  <linearGradient id="gradient2" x1="0%" y1="0%" x2="0%" y2="100%">
    <stop offset="0%" stop-color="#546953" />
    <stop offset="50%" stop-color="#C3EAC7" />
    <stop offset="100%" stop-color="#E6FBE8"/>
  </linearGradient>
</svg>
<div id="{{chartId}}" style="width: 600px; height: 378px; margin: auto;"></div>
  