import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Razza } from '../_models/index';
import { ApiService } from '../_services/api.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-aggiungi-anagrafica',
  templateUrl: './aggiungi-anagrafica.component.html',
  styleUrls: ['./aggiungi-anagrafica.component.css'],
})
export class AggiungiAnagraficaComponent implements OnInit, AfterViewInit,OnDestroy {
  form: FormGroup;
  Popupform: FormGroup;
  razze: Razza[] = [];
  razzeData: Razza[] = [];
  @Input() name: string;
  @Input() matricola: string;
  loader: boolean = false;
  popup = false;
  view: any;
  changeButtun: boolean = false;
  @ViewChild("popupContainer", { read: TemplateRef })
  popupContainer: TemplateRef<any>;
  spaciesData: any[] ;
  showDialog = false;
  location: any;
  saveanimal: boolean = false;
  isDneUser: boolean = false;
  lastCodToro: any;
  private destroy$: Subject<void> = new Subject<void>();


  constructor(private formBuilder: FormBuilder, 
    public apiService: ApiService, public toastr: ToastrService, public router: Router, 
    public viewContainerRef: ViewContainerRef,
    private route: ActivatedRoute) {
    this.form = this.formBuilder.group({
      toro: [null, Validators.required],
      matricola: [null, Validators.required],
      matricola2: [null],
      specie: [null],
      razza: [null],
      cod_dna: [null],
      cod_toro: [null],
      provato: [null],
      top100: [null],
      data_nasc: [null],
      matric_ana: [null],
    });
    if(localStorage.getItem('userType') === 'DNE') {
      this.isDneUser = true;
    }

  }
  codeDna: any;
  ngOnInit(): void {
    this.getSpeciesList();
    this.route.queryParams.subscribe(params => {
      this.lastCodToro = params['lastCodToro'];
    });
    this.retrieveRazze();
     this.codeDna = localStorage.getItem('codedna')
    // this.form.controls['cod_dna'].setValue(codeDna)
    if(this.lastCodToro) {
      this.form.controls['cod_toro'].setValue(this.lastCodToro);
    }
    let locations = localStorage.getItem('location')
    this.location = JSON.parse(locations)

  }

  ngAfterViewInit(): void {
    // this.openPopup();
    if (this.location) {
      this.Popupform = this.formBuilder.group({
        settore: [this.location.settore],
        cilindro: [this.location.cilindro],
        piano: [this.location.piano],
        contenit: [this.location.contenit],
        sequenziat: [null],
        note: [null]
      });
    }

  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  onSubmitpopup() {
    this.closePopup()
    this.onSubmit()
  }
  getSpeciesList(){
  
    this.apiService?.getSpecies().subscribe({
      next: (res: any) => {
        if (res) {
         this.spaciesData= res.data;

        }
      },
      error: (err:HttpErrorResponse) => {
    
      
        if(err?.error.errorMessage){
          this.toastr.error(err?.error.errorMessage);
        }
        else{
        this.toastr.error("An error occurred");
        }
      },
    });
  }
  openPopup() {
    this.apiService.modaBackDrop.next(true);
    setTimeout(() => {
      this.view = this.viewContainerRef?.createEmbeddedView(this.popupContainer);
      const domElem = this.view.rootNodes[0] as HTMLElement;
      domElem.classList.add("animate-in");
    });
  }

  closePopup() {
    this.saveanimal = true
    const domElem = this.view.rootNodes[0] as HTMLElement;
    domElem.classList.add("animate-out");
    domElem.addEventListener("animationend", (event: AnimationEvent) => {
      this.viewContainerRef.clear();
      this.apiService.modaBackDrop.next(false);
    });
  }

  retrieveRazze() {
    this.apiService.next_dna_cod.pipe(takeUntil(this.destroy$)).subscribe({
      next: (data) => {
        // this.form.controls['cod_dna'].setValue(data);
      }, error: (err) => { }
    });
    this.apiService.retrieveRazze().subscribe({
      next: (data) => {
        this.razze = data.result as Razza[];
      },
      error: (err) => {
      },
    });
  }

  patchFrom() {
    this.form.setValue({
      toro: [this.name],
      matricola: [this.matricola],
      matricola2: [null],
      specie: [''],
      razza: [null],
      cod_dna: [null],
      provato: [null],
      top100: [null],
      data_nc: [null],
      matric_ana: [null],
    });
  }

  onSubmit() {
      let userType = localStorage.getItem('userType')
      if(userType == 'DNE'){
        if (this.saveanimal) {
          this.loader = true;
          if (this.form.invalid) {
            return
          } else {
            let dataNasc = this.formatDate1(this.form.value.data_nasc)
            const payload = { ...this.form.value, data_nasc: dataNasc, location: this.Popupform.value };
            this.apiService?.createRecord(payload).subscribe({
              next: (res) => {
                if (res.status) {
                  this.loader = false;
                  // this.openDialog();
                  this.router.navigate(['statistiche'])
                  const new_code_dna = this.apiService.incrementString(res?.data?.cod_dna);
                  this.apiService.new_dna_cod.next(new_code_dna);
                  this.toastr.success(res.message);
                }else{
                  this.toastr.error(res.message);
                  this.loader = false;
                }
              },
              error: (err) => {
                this.toastr.error(err.message)
              }
            });
          }
        }else{ 
            this.openPopup();
        }
      }else{
        let dataNasc = this.formatDate1(this.form.value.data_nasc)
            const payload = { ...this.form.value, data_nasc: dataNasc, location: this.Popupform.value };
            this.apiService?.createRecord(payload).subscribe({
              next: (res) => {
                if (res.status) {
                  this.loader = false;
                  // this.openDialog();
                  this.router.navigate(['statistiche'])
                  const new_code_dna = this.apiService.incrementString(res?.data?.cod_dna);
                  this.apiService.new_dna_cod.next(new_code_dna);
                  this.toastr.success(res.message);
                }else{
                  this.toastr.error(res.message);
                  this.loader = false;

                }
              },
              error: (err) => {
                this.toastr.error(err.message)
              }
            });
      }

  }

  selectSpecie(event) {
    const specie = event?.target?.value;
    this.razzeData = this.razze.filter((sp: any) => sp?.specie === specie);
  }
  back() {
    this.router.navigate(['/statistiche']);
  }
  formatDate1(inputDate): any {
    if (inputDate != null) {
      const [year, month, day] = inputDate.split('-');
      const formattedDate = `${day}/${month}/${year}`;
      return formattedDate;
    }
  }

  openDialog() {
    let userType = localStorage.getItem('userType')
    if(userType == 'DNE'){
      this.showDialog = true;
    }
  }

  confirmation(confirmed: boolean) {
    if (confirmed) {
      this.router.navigate(['/new-dna-ref']);
      this.form.reset();
    } else {
      this.router.navigate(['/statistiche']);
      this.form.reset();
    }
    this.showDialog = false;
  }
}

@Component({
  selector: 'cf-dialog',
  template: `<div class="modal-overlay">
    <div class="modal">
      <div class="modal-content">
      <p>Creazione scheda DNA-RIFERIMENTO?</p>
        <div class="modal-buttons">
          <button class="cancel-button mbutton" (click)="cancel()">NO</button>
          <button class="confirm-button mbutton" (click)="confirm()">SI</button>
        </div>
      </div>
    </div>
  </div>`,
  styleUrls: ['./aggiungi-anagrafica.component.css']
})
export class ConfirmationDialog {
  @Output() confirmed: EventEmitter<boolean> = new EventEmitter<boolean>();
  confirm() {
    this.confirmed.emit(true);
  }
  cancel() {
    this.confirmed.emit(false);
  }


}
