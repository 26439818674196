import { Component, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Maga } from '../_models';
import { CellClickedEvent, ColDef, ColumnApi, GridOptions } from 'ag-grid-community';
import { CustomRendererBtnComponent } from './custom-renderer-btn';
import { ApiService } from '../_services/api.service';
import { ToastrService } from 'ngx-toastr';
import { ModalService } from '../_services/modal.service';
import { takeWhile } from 'rxjs';

@Component({
  selector: 'app-magazzino',
  templateUrl: './magazzino.component.html',
  styleUrls: ['./magazzino.component.scss']
})

export class MagazzinoComponent {
  centriCodiceAz: string | number;
  specie: string;
  anno: string | number;
  loader: boolean = false;
  rowData$: Maga[];
  clickedRowData: any;
  subjectValue: boolean = false;
  years: number[] = [];
  addL30Qs: FormGroup;
  view: any;
  isAlive = true;
  col: any = 0;
  @ViewChild("popupContainer", { read: TemplateRef })
  popupContainer: TemplateRef<any>;
  sortingOrder: string = '';
  columnApi: ColumnApi;
  tableName: string = 'magzino'; 
  filterValue: string = '';
  columnName: string = '';
  formSubmitted: boolean = false;
  defaultColDef: ColDef = {
    resizable: true,
    sortable: true,
    filter: true,
  };

  colDefs: ColDef<Maga>[] = [
    { field: "cod_dna", headerName: "COD DNA", width: 150 },
    { field: "data", headerName: "DATA", width: 150,comparator: this.dateComparator,

    },
    { field: "dna_seme", 
      headerName: "DNA SEME", 
      width: 150,
      valueParser: (params:any) => Number(params?.data?.dna_seme),
      comparator: (valueA, valueB) => Number(valueA) - Number(valueB),
      filter: 'agNumberColumnFilter',
      cellRenderer: (params: any) => { return `${params?.data?.dna_seme == null ? 'N/A' : params?.data?.dna_seme}` }  },
    { field: "riceviment", headerName: "Ricevimento", width: 150 ,cellRenderer: (params: any) => { return `${params?.data?.riceviment == null ? 'N/A' : params?.data?.riceviment}` } },
    // { field: "estrazione", headerName: "Estrazione", width: 150 ,cellRenderer: (params: any) => { return `${params?.data?.estrazione == null ? 'N/A' : params?.data?.estrazione}` }},
    // { field: "diluizione", headerName: "Diluizione", width: 150 ,cellRenderer: (params: any) => { return `${params?.data?.diluizione == null ? 'N/A' : params?.data?.diluizione}` }},
    { field: "contenit", headerName: "Contenitore", width: 150,comparator: this.customSortFunction.bind(this) },
    { field: "settore", headerName: "Settore", width: 150 },
    { field: "cilindro", headerName: "Cilindro", width: 150 },
    { field: "piano", headerName: "Piano", width: 165 },
    { field: "tipo_seq", headerName: "TIPO SEQUENZA", width: 200 ,cellRenderer: (params: any) => { return `${params?.data?.tipo_seq == null ? 'N/A' : params?.data?.tipo_seq}` } },
    { field: "note", headerName: "Note", width: 400 ,cellRenderer: (params: any) => { return `${params?.data?.note == null ? 'N/A' : params?.data?.note}` }},

  ];

  gridOptions: GridOptions<Maga> = {
    onFilterChanged: this.onFilterChanged.bind(this),
    columnDefs: this.colDefs,
    defaultColDef: this.defaultColDef,
    rowSelection: "single",
    animateRows: true,
    // pagination: true,
    // paginationAutoPageSize: true,
    frameworkComponents: { customButtonRenderer: CustomRendererBtnComponent },
    onCellClicked: (event: CellClickedEvent) => {
      this.clickedRowData = event?.data
      this.patchDNAForm();
      this.openPopup();
      this.changeButtun = true;
    },
  };
  changeButtun: boolean = false;
  itemPerPage: number = 14;
  pageNumber: number = 1;
  totalCount: number = 1;
  deleteId: any;
  selectYears: any;
  newCodiceLab1: string;
  calc: any;
  lastcodiccam: any;
  codeDna: any;
  constructor(
    public apiService: ApiService,
    public viewContainerRef: ViewContainerRef,
    public formBuilder: FormBuilder,
    public toastr: ToastrService,
    public modalService: ModalService
  ) {
    this.gridOptions.onSortChanged = this.onSortChanged.bind(this);
    this.newDNAForm();
    this.deleteRow();
    this.apiService.current.pipe(takeWhile(() => this.isAlive)).subscribe((res) => {
      if (res) {
        this.subjectValue = true
        this.centriCodiceAz = res.codice_az ? res.codice_az : localStorage.getItem('centriCodiceAz')
        this.specie = res.specie ? res.specie : localStorage.getItem('specie');
        this.anno = res.anna ? res.anna : localStorage.getItem('anna')
        this.tableData();
      }
    })
  }

  ngOnInit() {
    this.tableData();
  }


  customDateSort(date1: string, date2: string, isInverted: boolean): number {
    // Convert date strings to Date objects
    const [day1, month1, year1] = date1.split('/').map(Number);
    const [day2, month2, year2] = date2.split('/').map(Number);
    const dateObj1 = new Date(year1, month1 - 1, day1); // Month is 0-indexed in JavaScript Date
    const dateObj2 = new Date(year2, month2 - 1, day2);

    // Perform comparison based on ascending order
    if (dateObj1 < dateObj2) return -1;
    if (dateObj1 > dateObj2) return 1;
    return 0;
  }
  customSortFunction(valueA: any, valueB: any, nodeA: any, nodeB: any, isInverted: boolean): number {
    // Convert values to numbers for comparison
    const numA = Number(valueA);
    const numB = Number(valueB);
    
    // Perform custom comparison based on ascending order
    if (numA < numB) return -1;
    if (numA > numB) return 1;
    return 0;
}


  onButtonClicked(data: any) {
    this.loader = true;
    this.apiService.deleteMag(data?.id).subscribe((res: any) => {
      if (res) {
        this.closePopup()
        this.tableData();
        this.loader = false;
        this.toastr.success(res.description);
      }
    });
  }

  onGridReady(params) {
    this.columnApi = params.columnApi;
  }

  onSortChanged(event) {
    if(this.col == 0){
    const sortedColumns:any = this.columnApi.getAllColumns().filter(column => column.getSort())[0];
    this.sortingOrder = sortedColumns?.getSort() ?? '';
    this.columnName = sortedColumns?.getColDef().field ?? '';
    this.tableData();
    }
  }

  tableData() {
    this.apiService.getmagData(this.pageNumber,this.itemPerPage,this.columnName,this.filterValue,this.sortingOrder).subscribe({
      next: (res) => {
        this.rowData$ = res?.result?.rows;
        this.totalCount = res?.result?.count;
        this.codeDna = res?.lastCodDna?.max_cod_dna + 1
      },
      error: (error) => {
      },  
      complete: () => { }
    });
  }

  openPopup() {
    this.apiService.modaBackDrop.next(true);
    this.view = this.viewContainerRef.createEmbeddedView(this.popupContainer);
    const domElem = this.view.rootNodes[0] as HTMLElement;
    domElem.classList.add("animate-in");
  }
  openPopup1(){
    this.addL30Qs.controls['cod_dna'].setValue(this.codeDna);

  }

  patchDNAForm() {
    let Date = this.formatDate(this.clickedRowData.data)
    this.addL30Qs.patchValue({
      cod_dna: this.clickedRowData.cod_dna,
      data: Date,
      dna_seme: this.clickedRowData.dna_seme,
      riceviment: this.clickedRowData.riceviment,
      estrazione: this.clickedRowData.estrazione,
      diluizione: this.clickedRowData.diluizione,
      contenit: this.clickedRowData.contenit,
      settore: this.clickedRowData.settore,
      cilindro: this.clickedRowData.cilindro,
      piano: this.clickedRowData.piano,
      tipo_seq: this.clickedRowData.tipo_seq,
      note: this.clickedRowData.note,
    });

  }

  newDNAForm() {
    this.addL30Qs = this.formBuilder.group({
      cod_dna: [null, Validators.required],
      data: [null, Validators.required],
      dna_seme: [null],
      riceviment: [null],
      estrazione: [null],
      diluizione: [null],
      contenit: [null],
      settore: [null],
      cilindro: [null],
      piano: [null],
      tipo_seq: [null],
      note: [null],
    });

  }

  deleteRow() {
    this.apiService.delete.subscribe((res) => {
      this.deleteId = res.data
      if ((res?.data?.id) && (res?.table === 'newdnadata')) {
        this.modalService.openModal('newdnadata');
      }
    })
  }
  openmodel() {
    this.modalService.openModal('newdnadata');
  }
  confirm(event) {
    this.onButtonClicked(this.clickedRowData);
  }

  receiveData(event: any) {
    if (event?.table === this.tableName) {
      this.rowData$ = event?.data?.result?.rows;
      this.totalCount = event?.data?.result?.count;
      this.pageNumber = event?.page
    }
  }
  get fc() { return this.addL30Qs.controls; }

  onSubmit() {
    this.loader = true;
    this.formSubmitted = true;
    if (this.addL30Qs.invalid) {
      this.loader = false;
      return
    } else {
      const payload = this.addL30Qs.value;
      if (this.changeButtun) {
        let data = this.formatDate1(this.addL30Qs.value.data)

        this.updateRecord({ id: this.clickedRowData.id, ...payload ,data:data});
        this.formSubmitted = false;
      } else {
        let data = this.formatDate1(this.addL30Qs.value.data)
        this.createRecord({ ...payload,data:data });
        this.formSubmitted = false;
      }
    }
  }

  createRecord(payload: any) {
    this.loader = true
    this.apiService?.createMag(payload).subscribe({
      next: (res: any) => {
        if (res) {
          this.loader = false;
          this.toastr.success(res.description);
          this.closePopup();
          this.tableData();
        }
      },
      error: (err) => {
        this.loader = false;
        this.toastr.error('An error occurred');
      }
    });
  }

  updateRecord(payload: any) {
    this.loader = true;
    this.apiService?.updateMag(payload).subscribe({
      next: (res: any) => {
        if (res) {
          this.loader = false;
          this.toastr.success(res.description);
          this.closePopup();
          this.tableData();
        }
      },
      error: (err) => {
        this.loader = false;
        this.toastr.error('An error occurred');
      } 
    });
  }

  closePopup() {
    this.addL30Qs.reset();
    this.changeButtun = false;
    const domElem = this.view.rootNodes[0] as HTMLElement;
    domElem.classList.add("animate-out");
    domElem.addEventListener("click", (event: AnimationEvent) => {
      this.viewContainerRef.clear();
      this.apiService.modaBackDrop.next(false);
    });
  }
  
	formatDate1(inputDate):any {
		if (inputDate != null) {
			const [year, month, day] = inputDate.split('-');
	
			// Create the formatted date string in dd/mm/yyyy format
			const formattedDate = `${day}/${month}/${year}`;
	
			return formattedDate;
		}
	}

  formatDate(date):any {
		if (date !== 'NULL' && date !== null) {
			const dateComponents = date.split('/');
			const formattedDate = new Date(`${dateComponents[2]}-${dateComponents[1]}-${dateComponents[0]}`);
			
			if (!isNaN(formattedDate.getTime())) {
				// Check if the formattedDate is a valid Date object
				const timeZoneOffset = formattedDate.getTimezoneOffset() * 60000;
				const adjustedDate = new Date(formattedDate.getTime() - timeZoneOffset);
				const formattedDateString = adjustedDate.toISOString().split('T')[0];
				return formattedDateString;
			} else {
				// Handle invalid date
				return 'Invalid Date';
			}
		}
	}
  onFilterChanged(params: any): void {
    // Iterate through all columns
    this.filterValue = '';
    this.columnName = params.columns[0].colId;

    const filterModel = params.api.getFilterModel();
    const activeFilters = Object.keys(params.api.getFilterModel());
    console.log(activeFilters)
    this.col = activeFilters.length;
    for (const columnId in filterModel) {
      if (filterModel.hasOwnProperty(columnId)) {
        const specificColumnFilterModel = filterModel[columnId];
        if (specificColumnFilterModel) {
          this.columnName = columnId;
          this.filterValue = specificColumnFilterModel.filter;
          break; // Exit loop after finding the first column with a filter
        }
      }
    }

    if(this.col <= 1 ) {

    this.apiService.getmagData(this.pageNumber = 1,this.itemPerPage,this.columnName,this.filterValue,this.sortingOrder).subscribe({
      next: (res) => {
        this.rowData$ = res?.result?.rows;
        this.totalCount = res?.result?.count;
        this.codeDna = res?.lastCodDna?.max_cod_dna + 1
        this.apiService.selectedPage.next(1);

      },
      error: (error) => {
      },  
      complete: () => { }
    });
  }
    
  }

  dateComparator(date1: string | null, date2: string | null): number {
		// Check if either date is null or undefined
		if (!date1 || !date2) {
		  // You can decide what to return in case of null values
		  // For example, if date1 is null, consider it as smaller than any non-null date2
		  return date1 ? 1 : -1;
		}
	  
		const dateParts1 = date1.split('/');
		const dateParts2 = date2.split('/');
		
		// Create date objects in the format YYYY-MM-DD
		const d1 = new Date(Number(dateParts1[2]), Number(dateParts1[1]) - 1, Number(dateParts1[0]));
		const d2 = new Date(Number(dateParts2[2]), Number(dateParts2[1]) - 1, Number(dateParts2[0]));
		
		return d1.getTime() - d2.getTime();
	  }
}
