<app-loader *ngIf="loader"></app-loader>
<div class="specie-main">
    <h1>VCI SUI 1° CAMPIONI</h1>
    <div class="head-btn">
        <button class="button1"  (click)="getButtonFile('dna1')">DNA Risultati 1° ANALISI</button>
        <button class="button1" (click)="getButtonFile('dna2')">DNA Risultati 2° ANALISI</button>
    </div>
</div>
<div>
    <!-- <h1>Table title</h1> -->
    <ag-grid-angular style="height: 600px;" class="ag-theme-alpine-dark basic-table" [rowData]="rowData$"
        [gridOptions]="gridOptions">
    </ag-grid-angular>
    <h1>VCI SUI 2° CAMPIONI</h1>
    <ag-grid-angular style="height: 600px;" class="ag-theme-alpine-dark basic-table" [rowData]="rowData2"
        [gridOptions]="gridOptions">
    </ag-grid-angular>
    <h1>ANALISI CON PIÙ MICROSATELLITI</h1>
    <ag-grid-angular style="height: 600px;" class="ag-theme-alpine-dark basic-table" [rowData]="rowData3"
    [gridOptions]="gridOptions">
</ag-grid-angular>
</div>