<div class="page">
<div class="container login-container">
    <form class="login-form styling" (ngSubmit)="resetPassword()">
      <div class="form-group">
        <label for="email" class="form-label">Email</label>
        <input type="email" class="form-control" id="email" [value]="user"  name="email" required disabled>
      </div>
      <div class="form-group">
        <label for="password" class="form-label">New Password</label>
        <input type="password" class="form-control" id="new_password"  [(ngModel)]="form.new_password"  name="new_password"
          required>
      </div>
      <button type="submit" class="btn btn-primary login-button">Reset</button>
    </form>
  </div>
</div>
