import { Component, OnInit } from '@angular/core';
import { StorageService } from '../_services/storage.service';
import { AuthenticationService } from '../_services/authentication.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../_services/api.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  form: any = {
    username: null,
    password: null,
  };
  isLoggedIn = false;
  isLoginFailed = false;
  errorMessage = '';
  roles: string[] = [];
  loader:boolean = false;
  notificationValue:[]=[];

  constructor(
    private authService: AuthenticationService,
    private storageService: StorageService,
    private router: Router,
    private toastr:ToastrService,
    private apiService: ApiService,
  ) {}

  ngOnInit(): void {
    if (this.storageService.isLoggedIn()) {
      this.isLoggedIn = true;
      //this.roles = this.storageService.getUser().roles; //TODO role management
    }
  }

  onSubmit(): void {
    this.loader = true;
    let payload ={
      email:this.form.username,
      password:this.form.password
    }
    // const { username, password } = this.form;
    this.authService.newLogIn(payload).subscribe({
      next: (data:any) => {
        if(data.status == true){
          this.loader = false;
          this.storageService.saveUser(data);
          localStorage.setItem('token',data?.data?.token)
          localStorage.setItem('userType',data?.data?.user?.permission_groups?.name)

          localStorage.setItem('centriCodiceAz', '1')
          localStorage.setItem('anna',JSON.stringify(new Date().getFullYear()))
          localStorage.setItem('specie',"BI")
          // this.isLoginFailed = false;
          this.isLoggedIn = true;
          // this.roles = this.storageService.getUser().roles;
          if(data?.data?.user?.isPasswordUpdated == true){
            this.router.navigate(['dashboard']);
          }else{
            this.router.navigate(['change-password']);
          }
          // localStorage.setItem('isPasswordUpdated',data.data.user.isPasswordUpdated)
          // this.authService.menuItem.next(true)
          this.toastr.success(data.message);
          this.notifications()

          // this.reloadPage()
        }else{
          this.loader = false;
          this.isLoginFailed = true;
          this.errorMessage = data.message
          this.toastr.error( this.errorMessage);
        }
      },
      error: (err) => {
        this.loader = false;
        this.errorMessage = err.error.message;
        this.isLoginFailed = true;
      },
    });

  }
  reloadPage(): void {
    window.location.reload();
  }
  notifications(){
    this.authService.notification().subscribe({
      next: (data:any) => {
        if(data.success == true){
          this.notificationValue = data?.result
          this.apiService.notification.next(this.notificationValue)
          if(data?.result && data?.result.red && data?.result.red.length) {
            localStorage.setItem('notificationCount',data?.result.red.length)
          }
          
          // this.reloadPage()
        }else{
   
        }
      },
      error: (err) => {
      
      },
    });
  }
}
