<ng-container *ngIf="uploadedFile; then fileUploadedTemplate; else fileNotUploadedTemplate">
</ng-container>

<ng-container *ngIf="newEntry; then createNewEntry;">
</ng-container>

<ng-template #fileUploadedTemplate>
    <div class="container">

        <div class="row" style="border-bottom: 1px dashed;">
            <div class="row-table" id="file-description">
                <h3 *ngIf="files.length > 0">Il file {{files[0].name}} è stato correttamente
                    caricato.</h3>
            </div>

            <div class="row-section" id="row-file-buttons">
                <div class="button-container"><button class="button danger" (click)="clear()">PULISCI</button></div>
                <div class="button-container"><button class="button" (click)="import()">IMPORTA</button></div>


            </div>
        </div>


        <div class="row" style="height: 9%; min-height: 80px;align-items: center;">
            <div
                style="height: 100%;aspect-ratio: 1 / 1;background-color: #6B9E6A;border-radius: 50%;text-align: center;">
            </div>
            <p style="border-right: 1px solid;padding-right: 1rem;">PRONTO ALL' IMPORTAZIONE</p>
            <div
                style="height: 100%;aspect-ratio: 1 / 1;background-color: #f9e2af;border-radius: 50%;text-align: center;">
            </div>
            <p style="border-right: 1px solid;padding-right: 1rem;">NOME DIVERSO NEL DATABASE</p>
            <div
                style="height: 100%;aspect-ratio: 1 / 1;background-color: #89b4fa;border-radius: 50%;text-align: center;">
            </div>
            <p style="border-right: 1px solid;padding-right: 1rem;">DUPLICATO (AGGIUNTA DOSI)</p>
            <div
                style="height: 100%;aspect-ratio: 1 / 1;background-color: #808080;border-radius: 50%;text-align: center;">
            </div>
            <p style="border-right: 1px solid;padding-right: 1rem;">NON TROVATO A DATABASE</p>
            <div
                style="height: 100%;aspect-ratio: 1 / 1;background-color: #f38ba8;border-radius: 50%;text-align: center;">
            </div>
            <p>ERRORE DI CONFORMITA'</p>
        </div>


        <div class="container-table">
            <div style=" overflow: hidden; flex: 1">
                <ag-grid-angular #agGrid1 style="width: 100%; height: 100%;" class="ag-theme-alpine-dark"
                    [columnDefs]="columnDefs" [defaultColDef]="defaultColDef" [rowSelection]="'single'"
                    [animateRows]="true" (cellClicked)="onCellClicked($event)" [overlayNoRowsTemplate]="noRowsTemplate"
                    [gridOptions]="gridOptions" [columnDefs]="columnDefs">
                </ag-grid-angular>
            </div>

        </div>
    </div>


</ng-template>

<ng-template #fileNotUploadedTemplate>
    <div class="container">
        <div class="download">
            <button class="button1" (click)="downloadTemplete()">Scarica Template</button>
        </div>

        <div id="drop-zone" class="drop-zone " [ngClass]="{'drag-over' : dragOver}" (dragover)="onDragOver($event)"
            (drop)="onDrop($event)" (dragleave)="onDragLeave($event)" (click)="fileField.click()">
            <h1>Trascina il file da importare qui dentro, oppure clicca per sceglierlo.</h1>
        </div>
        <input type="file"
            accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            (change)="loadFile($event)" #fileField hidden>

    </div>
</ng-template>


<!-- <ng-template #popupContainer let-options>
    <div class="popup-window">
        <div class="container">
            <div class="section-90">
                <app-aggiungi-anagrafica [name]="options.nome"
                    [matricola]="options.matricola"></app-aggiungi-anagrafica>
            </div>
            <div class="section-10">
                <div class="column popup-button">
                    <button (click)="closePopup()">Chiudi</button>
                </div>
                <div class="column popup-button">
                    <button (click)="saveData()">Salva</button>
                </div>
            </div>
        </div>
    </div>
</ng-template> -->

<ng-template #popupContainer let-options>
    <ng-container *ngIf="review; then reviewContainer; else riproduttoriFuzzyTemplate">
    </ng-container>
</ng-template>

<ng-template #riproduttoriFuzzyTemplate>
    <div class="popup-window">
        <div class="container" style="display: flex; flex-direction: column !important;">
            <div style="display: flex; justify-content: space-between; align-items: center;">
                <input id="search-toro" type="text" [value]="selectedText" style="align-self: self-start; width: 50%;"
                    placeholder="Cerca Toro (puoi usare il nome, la matricola o la matricola2)" (input)="onSearchText()"
                    [(ngModel)]="searchText" />
                <button class="button1" (click)="openCreateEntry()">CREA NUOVO</button>
            </div>
            <div id="fuzzy-result-number">
                <h6>Match effettuati: {{ fuzResult.length }}</h6>
            </div>
            <div class="popup-fuzzy-result" *ngFor="let riproduttore of fuzResult | slice : 0 : 10"
                (mousedown)="selectToro(riproduttore)">
                <div style="display: flex;flex-direction: column;">
                    <h6>Nome Toro:</h6>
                    <h3>{{ riproduttore.Toro }}</h3>
                </div>
                <div style="display: flex;flex-direction: column;">
                    <h6>Matricola:</h6>
                    <h3>{{ riproduttore.Matricola }}</h3>
                </div>
                <div *ngIf="riproduttore.Matricola2" style="display: flex;flex-direction: column;">
                    <h6>Matricola2:</h6>
                    <h3>{{ riproduttore.Matricola2 }}</h3>
                </div>
                <!-- <h6>{{ centro.Indirizzo }}</h6> -->
            </div>
            <div style="display: flex; justify-content: flex-end; justify-self: flex-end;">
                <div class="column popup-button">
                    <button (click)="closePopup()">Chiudi</button>
                </div>
            </div>

        </div>
    </div>
</ng-template>

<ng-template #reviewContainer>
    <div class="popup-window">
        <div class="d-flex gap-16">
            <div class="field w-100">
                <label class="d-block">DATA PROTOCOLLO:</label>
                <form [formGroup]="form">
                    <input type="date" class="w-100" formControlName="dataCampo">
                    <label for="">Codice OPERATORE:</label>
                    <input type="text" class="w-100" formControlName="operator">
                </form>
            </div>
        </div>
        <div [innerHTML]="listatoSummary">
        </div>
        <div style="display: flex; justify-content: flex-end; justify-self: flex-end; margin-top: 10px;">
            <div class="column popup-button">
                <button (click)="closePopup()">Chiudi</button>
            </div>
            <div class="column popup-button">
                <button (click)="saveData()">Importa</button>
            </div>
        </div>
    </div>
</ng-template>


<!-- (ngSubmit)="onSubmit()" -->

<ng-template #createNewEntry>
    <div class="popup-window1 animate-in1">
        <div class="form-container1">
            <form [formGroup]="form1" (ngSubmit)="onSubmit()">
                <div class="row1">
                    <div class="column1">
                        <label>*Nome del Toro:</label>
                        <input type="text" formControlName="toro" required readonly />
                        <div *ngIf="(form1?.controls['toro']?.invalid) && (form1?.controls['toro']?.dirty || form1?.controls['toro']?.touched)"
                            class="alert">
                            <div *ngIf="form1?.controls['toro']?.hasError('required')">
                                Please enter toro
                            </div>
                        </div>
                    </div>
                    <div class="column1">
                        <label>*Matricola:</label>
                        <input type="text" formControlName="matricola" required readonly />
                        <div *ngIf="(form1?.controls['matricola'].invalid) && (form1?.controls['matricola'].dirty || form1?.controls['matricola'].touched)"
                            class="alert">
                            <div *ngIf="form1?.controls['matricola']?.hasError('required')">
                                Please enter matricola
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row1">
                    <div class="column1">
                        <label>Matricola2:</label>
                        <input type="text" formControlName="matricola2" />
                    </div>
                    <div class="column1">
                        <label>MatricolaANA:</label>
                        <input type="text" formControlName="matric_ana" />
                    </div>
                    <div class="column1">
                        <label>DNA:</label>
                        <input type="text" formControlName="cod_dna" readonly />
                    </div>
                </div>
                <div class="row1">
                    <div class="column1">
                        <label>Cod Toro:</label>
                        <input type="number" formControlName="cod_toro" />
                    </div>
                    <div class="column1">
                        <label>Nascita:</label>
                        <input type="date" formControlName="data_nasc" />
                    </div>
                </div>
                <div class="row1">
                    <!-- <div class="column1">
                        <label><span style="color: red;">*</span>Specie:</label>
                        <select formControlName="specie" required (change)="selectSpecie($event)">
                            <option [value]="specie?.value" *ngFor="let specie of spaciesData;">{{specie?.name}}</option>
                        </select>
                        <div *ngIf="form1?.controls['specie'] === 'null' || form1?.controls['specie']?.invalid || (form1?.controls['specie']?.touched || form1?.controls['specie']?.dirty)"
                            class="alert">
                            <div *ngIf="form1?.controls['specie']?.hasError('required')" style="color: red;">
                                Please select a valid specie.
                            </div>
                            <div *ngIf="form1?.controls['specie'].value === ''" style="color: red;">
                                Please select a specie.
                            </div>
                        </div>
                    </div> -->
                    <div class="column1">
                        <label><span style="color: red;">*</span>Specie:</label>
                        <select formControlName="specie" required (change)="selectSpecie($event)">
                            <option [ngValue]="specie?.value" *ngFor="let specie of spaciesData;">{{specie?.name}}</option>
                        </select>
                        <div *ngIf="form1?.controls['specie']?.invalid || (form1?.controls['specie']?.touched || form1?.controls['specie']?.dirty)"
                             class="alert">
                            <!-- Required error -->
                            <div *ngIf="form1?.controls['specie']?.hasError('required')" style="color: red;">
                                Please select a valid specie.
                            </div>
                            <!-- Custom error for empty selection -->
                            <!-- <div *ngIf="form1?.controls['specie'].value === null" style="color: red;">
                                Please select a specie.
                            </div> -->
                        </div>
                    </div>
                    <div class="column1">
                        <label>Razza:</label>
                        <select formControlName="razza">
                            <option [ngValue]="null">Scegli la Razza</option>
                            <option *ngFor="let razza of razzeData" [ngValue]="razza.razza">
                                {{ razza.razza_nome }}
                            </option>
                        </select>
                    </div>
                </div>
                <button type="submit" class="button1" [disabled]="!form1.valid">Salva Anagrafica</button>
                <button class="button1" style="margin-left: 20px;" (click)="closePopup()">Chiudi</button>
            </form>
        </div>  
    </div>
</ng-template>