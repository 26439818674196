import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { BehaviorSubject, Observable, Subject, of, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { GenericResponse } from "../_models";
import { StorageService } from "./storage.service";
import { environment } from "../../environments/environment";

@Injectable({
	providedIn: "root",
})
export class ApiService {
	delete = new Subject<any>();
	current = new Subject<any>();
	autocertizion = new Subject<any>();
	selectedPage = new Subject<any>();
	modaBackDrop = new BehaviorSubject<boolean>(false);
	ricar = new BehaviorSubject<any>(null);
	next_dna_cod = new BehaviorSubject<any>(null);
	new_dna_cod = new BehaviorSubject<any>(null);
	redirectButton = new BehaviorSubject<boolean>(false);
	notification = new BehaviorSubject<any>({});
	specieSubject = new BehaviorSubject<{ specie: string; isChanged: boolean }>({ specie: '', isChanged: false });
	private MODULE_API = {
		CARICO: environment.url + "carica-listato",
		IMPORTLIST: environment.url + "importa-listato",
		CHECKDUPLICATE: environment.urlPython + "controlla-duplicato",
		GETCENTRO: environment.url + "table-with-filters/Centro",
		GETRAZZA: environment.url + "table-with-filters/Razza",
		REPRODUTTORI: environment.url + "riproduttori-test",
		REPRODUTTORINOTFILTER: environment.url + "riproduttori-test-data",
		GETDNATABLE: environment.url + "str-id/allData",
		UPDATEDNA: environment.url + "str-id/update",
		allDataByidPip: environment.url + "pip-ver/allDataByidPip",
		DATAONPAGE: environment.url + "riproduttori-test",
		RIPRODUTTORIFUZZY: environment.url + "riproduttori-fuzzy",
		CREATEANIMALRECORD: environment.url + "animal-management/create-record",
		UPDATEANIMAL: environment.url + "animal-management/updateAnimal",
		DELETEANIMAL: environment.url + "animal-management/deleteAnimal",
		UPDATECODTOROANIMAL: environment.url + "animal-management/update-cod-toro",
		COURIERMANAGEMENTFREQUANZA: environment.url + "courier-management/frequenza-cal",
		COURIERMANAGEMENTREFERO: environment.url + "courier-management/get-tempi-data",

		GETSAMPLEDATA: environment.url + "sampling-management/based-on-centri-id",
		GETl30LOTTIDATA: environment.url + "sampling-management/getl30lottiData",
		CREATESAMPLEDATA: environment.url + "sampling-management/create-sample-record",
		UPDATESAMPLEDATA: environment.url + "sampling-management/update-sample-record",
		DELETESAMPLEDATA: environment.url + "sampling-management/delete-sample-record",

		GETALLCOURIERATA: environment.url + "courier-management/getAllData",
		DELETECOURIERDATA: environment.url + "courier-management/delete-courier-record",
		UPDATECOURIERDATA: environment.url + "courier-management/update-courier-record",

		GETSPECIESVALUE: environment.url + "courier-management/getSpeciesVal",
		GETUGUNIQUE: environment.url + "dna-ug/getUnique",
		STATSCAL: environment.url + "courier-management/stats-cal",

		GETNEWDNADATA: environment.url + "dna-rif/getAllData",
		CREATE: environment.url + "dna-rif/create",
		CREATENEWDNA: environment.url + "dna-rif/uploadtxt",
		UPDATENEWDNA: environment.url + "dna-rif/update",
		DELETENEWDNA: environment.url + "dna-rif",
		WORKSHEETDATA: environment.url + "l30-camp/allData",
		FILLDOWN: environment.url + "l30-camp/fillDown",
		INNERWORKSHEETDATA: environment.url + "l30-camp/codice_az",
		STATORIF: environment.url + "l30-camp/stato-rif",
		GETEXCELDATA: environment.url + "l30-camp/getxlData",
		GETDNADATA: environment.url + "l30-camp/getdnadata-by-cod-seme",
		GETDASHBOARDDATA: environment.url + "l30-qs",
		CALCULATEWEEKLYAVRG: environment.url + "l30-qs/calculateWeeklyAverages",

		CREATEDNADETIAL: environment.url + "pip-ver/new/create",
		UPDATEINNERDNA: environment.url + "pip-ver/update",
		GETRICARDATA: environment.url + "sampling-management/getRicarData",
		EXPORTRICAR: environment.url + "sampling-management/exportData",
		GETFAGLIO: environment.url + "sampling-management/getFoglioData",
		GETBOLLA: environment.url + "sampling-management/getbollaData",
		GETFOGLIO: environment.url + "l30-camp/codice_az/getTxtFile",
		LS30DATA: environment.url + "l30-qs/getl30QsData",
		CREATEL30QS: environment.url + "l30-qs/new/create",
		UPDATEL30QS: environment.url + "l30-qs/update",
		DELETELS30: environment.url + "l30-qs",
		GETCALFORL30: environment.url + "l30-qs/getAcTableData",
		GETTEXTUALDATA: environment.url + "l30-qs/textual-data",

		GETDNADATATABLE: environment.url + "dna-oper/getdnaOper",
		CREATENEWDATA: environment.url + "dna-oper/new/create",
		UPDATEDNARECOARD: environment.url + "dna-oper/update",
		DELETEDNARECORD: environment.url + "dna-oper",

		GETL30DNADATA: environment.url + "l30-dna/getAllData",
		CREATEL30DNA: environment.url + "l30-dna/uploadtxt",
		UPDATEL30DNA: environment.url + "l30-dna/update",
		DELETEL30DNA: environment.url + "l30-dna",
		GETMAGZINO: environment.url + "dna/getalldna",
		CREATEMAGZINO: environment.url + "dna/create-dna",
		POSTSTRID: environment.url + "str-id/new/create",
		UPDATESTRID: environment.url + "str-id/updateStrData",
		UPDATEMAGZINO: environment.url + "dna/update",
		DELETEMAGZINO: environment.url + "dna",
		REGISTSEME: environment.url + "l30-camp/getRegistroSemeDna",

		DNA_DASHBOARD: environment.url + "seme-dashboard",
		GETSTORICODATA: environment.url + "seme-dashboard/getStoricoData",

		ARCHIVO: environment.url + "archivo",
		ADDNEWL30DNAOPERDATA: environment.url + "l30-camp/add_newl30_dna_oper_data",
		ESTRATOFILE: environment.url + "l30-camp/estratto",
		PIASTRA: environment.url + "l30-camp/piastra",
		SAVEPIASTRA: environment.url + "l30-camp/save-piastra-data",

		SEMERISULTATI: environment.url + "sampling-management/getRisultatiData",

		GETL30ACDATA: environment.url + "l30-ac/get-l30-ac-data",
		CARICAAUTO: environment.urlPython + "carica-autocertificazione",
		IMPORTAAUTOCERT: environment.url + "importa-autocertificazione",
		GETREPROPOPUPDATA: environment.url + "riproduttoriDatabycodDna",
		UPDATEDNAOPR: environment.url + "dna-oper/update",
		EXPORTARCHIVO: environment.url + "archivo/getTableData",
		GETCQ: environment.url + "cq/getCqAllData",
		DELETECQ: environment.url + "cq",
		UPDATECQ: environment.url + "cq/updateCqData",
		POSTCQ: environment.url + "cq/post",
		STATISTICADESCRITTIVA: environment.url + "cq/calculation",
		EXPORTCQ: environment.url + "cq/exportToExcel",
		Analisi1: environment.url + "sampling-management/getresultatisemeanalisi",
		INAD1: environment.url + "sampling-management/semelegione",
		VCAFILE: environment.url + "sampling-management/ComRegioneVCA",
		GETDNARESULTATI1: environment.url + "sampling-management/getDnaRisultati_1a_Analisi",
		GETL30LOTTI: environment.url + "lotti/getalldata",
		UPDATEL30LOTTI: environment.url + "lotti/update/lotti",
		GETCENTRIDATA: environment.url + "centri/get-all-centri-data",
		CREATECENTRI: environment.url + "centri/create-centri",
		UPDATECENTRI: environment.url + "centri/update-centri",
		LOTTIDELETE: environment.url + "lotti/delete",
		LOTTIDELETELOTTI: environment.url + "lotti/delete/lotti",
		UPDATEAUTOCERTI: environment.url + "l30-ac/update-l30_ac-data",
		GETIDPIASTRA: environment.url + "l30-camp/allPiastreValue",
		PREVIEWPIASTA: environment.url + "l30-camp/generate-preview",

		GETL30VFOR: environment.url + "l30-vfor/get-alldata",
		CREATEL30VFOR: environment.url + "l30-vfor/add-newrecords",
		UPDATEL30VFOR: environment.url + "l30-vfor/update",
		GETL30FOR: environment.url + "l30-for/get-alldata",
		CREATEL30FOR: environment.url + "l30-for/add-newrecords",
		UPDATEL30FOR: environment.url + "l30-for/update",
		SITUAZIONI: environment.url + "l30-vfor/get-Situazioni-data"
	};
	static importaListato: any;

	constructor(public http: HttpClient, private storageService: StorageService) {}

	getSettingYear() {
		return localStorage.getItem("settingYear") || "";
	}

	retrieveTori(): Observable<any> {
		let response = this.http.get("/api/table/Toro");
		return response;
	}

	uploadFile(file: FileList | File): Observable<any> {
		return of("File Uploaded");
	}

	addFiles2(images: File): Observable<any> {
		const response = JSON.parse('{"details": "ok"}');

		let obs = new Observable((subscriber) => {
			setTimeout(() => {
				subscriber.next(response);
				subscriber.complete();
			}, 6000);
		});
		return obs;
	}

	addFiles(images: File) {
		var arr = [];
		var formData = new FormData();
		arr.push(images);
		arr[0].forEach((item, i) => {
			formData.append("avatar", arr[0][i]);
		});
		return this.http
			.post("/apidev/upload", formData, {
				reportProgress: true,
				observe: "events",
			})
			.pipe(catchError(this.errorMgmt));
	}
	errorMgmt(error: HttpErrorResponse) {
		let errorMessage = "";
		if (error.error instanceof ErrorEvent) {
			// Get client-side error
			errorMessage = error.error.message;
		} else {
			// Get server-side error
			errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
		}
		return throwError(() => new Error(errorMessage));
	}

	retrieveCentri(): Observable<any> {
		let response = this.http.post(this.MODULE_API.GETCENTRO, { select: ["Id", "Denominazione", "Indirizzo"] });
		return response;
	}

	retrieveRazze(): Observable<any> {
		let response = this.http.post(this.MODULE_API.GETRAZZA, { select: ["Id", "Nome"] });
		return response;
	}

	genericTableRetrieve(
		table: string,
		filters: {
			select?: string[];
			where?: Map<string, string>;
			comparison?: string[];
		}
	): Observable<GenericResponse> {
		return this.http.post<GenericResponse>(`${this.MODULE_API.GETCENTRO}`, filters);
	}

	controlloListato(file: File) {
		let centro = localStorage.getItem("centriCodiceAz");
		let specie = localStorage.getItem("specie");  //TODO change this for production
		console.log(centro);
		let annoImportazione = localStorage.getItem("anna"); // TODO change this
		// let annoImportazione = 2015;
		const formData = new FormData();
		formData.append("file", file);
		return this.http.post(this.MODULE_API.CARICO + "/" + centro + "/" + annoImportazione + "/" + specie, formData);
	}

	controlloAutoceritizio(file: File) {
		let centro = localStorage.getItem("centriCodiceAz"); //TODO change this for production
		console.log(centro);
		let annoImportazione = localStorage.getItem("anna"); // TODO change this
		// let annoImportazione = 2015;
		const formData = new FormData();
		formData.append("file", file);
		return this.http.post(this.MODULE_API.CARICAAUTO + "/" + centro + "/" + annoImportazione, formData);
	}
	importaAutocertizion(data) {
		let centro = localStorage.getItem("centriCodiceAz"); //TODO change this for production
		console.log(centro);
		let annoImportazione = localStorage.getItem("anna"); // TODO change this
		// let annoImportazione = 2015;
		return this.http.post(this.MODULE_API.IMPORTAAUTOCERT + "/" + centro + "/" + annoImportazione, data);
	}
	// importaListato(listato: any[], dataProt: string,operator:any) {
	// 	let centro = localStorage.getItem("centriCodiceAz"); //TODO change this for production
	// 	let annoImportazione = localStorage.getItem("anna"); // TODO change this
	// 	const urlEncodedCentro = encodeURIComponent(centro);
	// 	const urlEncodedAnnoImp = encodeURIComponent(annoImportazione);
	// 	const urlEncodedDataProt = encodeURIComponent(dataProt);
	// 	const urlEncodedOperator = encodeURIComponent(operator);

	// 	console.log(dataProt, urlEncodedDataProt);
	// 	return this.http.post(`${this.MODULE_API.IMPORTLIST}/${urlEncodedCentro}/${urlEncodedAnnoImp}/${urlEncodedDataProt}/${urlEncodedOperator}`, listato, { responseType: "arraybuffer" });
	// }

	importaListato(listato: any[], dataProt: string, operator: any) {
		let centro = localStorage.getItem("centriCodiceAz"); //TODO change this for production
		let annoImportazione = localStorage.getItem("anna"); 
		let specie = localStorage.getItem("specie");// TODO change this
		const urlEncodedCentro = encodeURIComponent(centro);
		const urlEncodedAnnoImp = encodeURIComponent(annoImportazione);
		const urlEncodedDataProt = encodeURIComponent(dataProt);
		const urlEncodedOperator = encodeURIComponent(operator);

		console.log(dataProt, urlEncodedDataProt);
		return this.http.post(`${this.MODULE_API.IMPORTLIST}/${urlEncodedCentro}/${urlEncodedAnnoImp}/${urlEncodedDataProt}/${urlEncodedOperator}/${specie}`, listato);
	}

	retrieveRiproduttori() {
		let centro = this.storageService.getCentro();
		let response = this.http.get(this.MODULE_API.REPRODUTTORI);
		return response;
	}

	retrieveRiproduttori2<Tdata>(centriCodiceAz: any, specie: any, anno: any): Observable<any> {
		let centro = this.storageService.getCentro();
		let response = this.http.get<Tdata>(`${this.MODULE_API.REPRODUTTORI}?centriCodiceAz=${centriCodiceAz}&specie=${specie}&anno=${anno}`);
		return response;
	}

	getDnaTable(page: any, limit: any): Observable<any> {
		return this.http.get<any>(`${this.MODULE_API.GETDNATABLE}?page=${page}&limit=${limit}`);
	}

	allDataByidPip(payload: any, order: any): Observable<any> {
		return this.http.get<any>(`${this.MODULE_API.allDataByidPip}?${payload.paramName}=${payload.id_lab}&order=${order}`);
	}

	updateRecord(data: any) {
		return this.http.post(`${this.MODULE_API.UPDATEANIMAL}?isUpdate=0`, data);
	}
	updateRecordwith1(data: any) {
		return this.http.post(`${this.MODULE_API.UPDATEANIMAL}?isUpdate=1`, data);
	}
	deleteAnimal(data: any) {
		return this.http.post(this.MODULE_API.DELETEANIMAL, data);
	}
	updateAnimal(data: any) {
		return this.http.post(this.MODULE_API.UPDATECODTOROANIMAL, data);
	}

	getAllData(page: number, limit: number): Observable<any> {
		const params = { page: page.toString(), limit: limit.toString() };
		return this.http.get(this.MODULE_API.DATAONPAGE, { params });
	}

	createRecord(data: any): Observable<any> {
		return this.http.post(`${this.MODULE_API.CREATEANIMALRECORD}`, data).pipe(
			map((response: any) => {
				return response;
			}),
			catchError((err: any) => {
				return err;
			})
		);
	}
	getSampleData(page: any, limit: any, centriCodiceAz: any, specie: any, anno: any): Observable<any> {
		return this.http.get<any>(`${this.MODULE_API.GETSAMPLEDATA}?page=${page}&limit=${limit}&centriCodiceAz=${centriCodiceAz}&specie=${specie}&anno=${anno}&year=${this.getSettingYear()}`);
	}
	createSampleData(data) {
		return this.http.post(this.MODULE_API.CREATESAMPLEDATA, data);
	}
	updateSampleData(data) {
		return this.http.post(this.MODULE_API.UPDATESAMPLEDATA, data);
	}
	deleteSampleData(data) {
		return this.http.post(this.MODULE_API.DELETESAMPLEDATA, data);
	}

	getNewDNAData(page: number, limit: number, columnName, keyword, sortingOrder): Observable<any> {
		return this.http.get<any>(`${this.MODULE_API.GETNEWDNADATA}?page=${page}&limit=${limit}&columnName=${columnName}&keyword=${keyword}&order=${sortingOrder}`);
	}

	getCourierAllData(page: any, limit: any): Observable<any> {
		return this.http.get<any>(`${this.MODULE_API.GETALLCOURIERATA}?page=${page}&limit=${limit}&year=${this.getSettingYear()}`);
	}
	deleteCourierData(data) {
		return this.http.post(this.MODULE_API.DELETECOURIERDATA, data);
	}
	updateCourierData(data) {
		return this.http.post(this.MODULE_API.UPDATECOURIERDATA, data);
	}

	createNewDNARecord(data: any): Observable<any> {
		return this.http.post(`${this.MODULE_API.CREATENEWDNA}`, data).pipe(
			map((response: any) => {
				return response;
			}),
			catchError((err: any) => {
				return err;
			})
		);
	}

	updateNewDNARecord(data: any): Observable<any> {
		return this.http.post(`${this.MODULE_API.UPDATENEWDNA}`, data).pipe(
			map((response: any) => {
				return response;
			}),
			catchError((err: any) => {
				return err;
			})
		);
	}
	create(data: any): Observable<any> {
		return this.http.post(`${this.MODULE_API.CREATE}`, data).pipe(
			map((response: any) => {
				return response;
			}),
			catchError((err: any) => {
				return err;
			})
		);
	}
	deleteNewDNA(id: string): Observable<any> {
		const url = `${this.MODULE_API.DELETENEWDNA}/${id}`;
		return this.http.delete(url).pipe(
			map((response: any) => {
				return response;
			}),
			catchError((err: any) => {
				return err;
			})
		);
	}
	getSpecieValue() {
		return this.http.get(this.MODULE_API.GETSPECIESVALUE);
	}

	Statscal(data) {
		return this.http.post(this.MODULE_API.STATSCAL, data);
	}

	worksheetData(page: number, limit: number, codice_az: any, anno_rif: any): Observable<any> {
		const params = { page: page.toString(), limit: limit.toString() };
		return this.http.get(`${this.MODULE_API.WORKSHEETDATA}?codice_az=${codice_az}&anno_rif=${anno_rif}`, { params }).pipe(
			map((response: any) => {
				return response;
			}),
			catchError((err: any) => {
				return err;
			})
		);
	}

	innerWorksheetData(codice_cam: string): Observable<any> {
		const params = { codice_cam: codice_cam.toString() };
		return this.http.get(`${this.MODULE_API.INNERWORKSHEETDATA}`, { params }).pipe(
			map((response: any) => {
				return response;
			}),
			catchError((err: any) => {
				return err;
			})
		);
	}

	getExcelData(data: any): Observable<any> {
		return this.http.post(`${this.MODULE_API.GETEXCELDATA}`, data, { responseType: "blob" }).pipe(
			map((response: any) => {
				return response;
			}),
			catchError((err: any) => {
				return err;
			})
		);
	}

	// getSampleData(page: any, limit: any, centriCodiceAz: any,specie:any,anno:any): Observable<any> {
	//   return this.http.get<any>(`${this.MODULE_API.GETSAMPLEDATA}?page=${page}&limit=${limit}&centriCodiceAz=${centriCodiceAz}&specie=${specie}&anno=${anno}`);
	// }

	getTextualData(codice_az: string, year: string, specie: string) {
		return this.http.get(`${this.MODULE_API.GETTEXTUALDATA}?codice_az=${codice_az}&year=${year}&specie=${specie}`);
	}
	getChartData(specie: any, anno_rif: any, codice_az: any): Observable<any> {
		return this.http.get<any>(`${this.MODULE_API.GETDASHBOARDDATA}?specie=${specie}&anno_rif=${anno_rif}&codice_az=${codice_az}`).pipe(
			map((response: any) => {
				return response;
			}),
			catchError((err: any) => {
				return err;
			})
		);
	}

	getAndmentoChartData(codice_az: any, specie: any, anno_rif: any, dataType: any): Observable<any> {
		return this.http.get<any>(`${this.MODULE_API.CALCULATEWEEKLYAVRG}?codice_az=${codice_az}&specie=${specie}&anno_rif=${anno_rif}&dataType=${dataType}`).pipe(
			map((response: any) => {
				return response;
			}),
			catchError((err: any) => {
				return err;
			})
		);
	}
	updateDna(data: any) {
		return this.http.post(this.MODULE_API.UPDATEDNA, data);
	}
	createDnaData(data: any) {
		return this.http.post(this.MODULE_API.CREATEDNADETIAL, data);
	}
	updateInnerDna(data) {
		return this.http.post(this.MODULE_API.UPDATEINNERDNA, data);
	}
	geLastTenRecords(data: any): Observable<any> {
		return this.http.get(`${this.MODULE_API.DNA_DASHBOARD}/last-ten-days?page=${data.page_number}&limit=${data.per_page}`);
	}
	geLastFiftyRecords(data: any): Observable<any> {
		return this.http.get(`${this.MODULE_API.DNA_DASHBOARD}/last-fifty-days?page=${data.page_number}&limit=${data.per_page}`);
	}
	getStoricoData(data: any): Observable<any> {
		return this.http.get(`${this.MODULE_API.GETSTORICODATA}?page=${data.page_number}&currentYear=${data.currentYear}&limit=${data.per_page}`);
	}
	getdnaOperData(data: any): Observable<any> {
		return this.http.get(`${this.MODULE_API.DNA_DASHBOARD}/getdnaOperData?page=${data.page_number}&limit=${data.per_page}`);
	}
	getDbTables(data: any, columnName, keyword, order): Observable<any> {
		return this.http.get(`${this.MODULE_API.ARCHIVO}/getData?tableName=${data.db_name}&page=${data.page_number}&limit=${data.per_page}&columnName=${columnName}&keyword=${keyword}&order=${order}`);
	}
	getDbTableNames(): Observable<any> {
		return this.http.get(`${this.MODULE_API.ARCHIVO}/getAllTableNames`);
	}

	getDnaData(code_seme: any): Observable<any> {
		return this.http.get(`${this.MODULE_API.GETDNADATA}?cod_seme=${code_seme}`);
	}

	addNewOperData(data: any, buttonType: string) {
		return this.http.post(`${this.MODULE_API.ADDNEWL30DNAOPERDATA}?buttonType=${buttonType}`, data);
	}

	getRicarData(codice_cam: any, codice_az: any): Observable<any> {
		return this.http.get<any>(`${this.MODULE_API.GETRICARDATA}?codice_cam=${codice_cam}&codice_az=${codice_az}`);
	}
	exportRicar(data: any): Observable<any> {
		return this.http.post(`${this.MODULE_API.EXPORTRICAR}`, data, { responseType: "blob" }).pipe(
			map((response: any) => {
				return response;
			}),
			catchError((err: any) => {
				return err;
			})
		);
	}

	getFaglio(codice_cam: any, codice_az: any, type: any): Observable<any> {
		return this.http.get<any>(`${this.MODULE_API.GETFAGLIO}?codice_cam=${codice_cam}&codice_az=${codice_az}&type=${type}`);
	}
	getCqData(currentYear: any, page, limit, columnName, sortingOrder) {
		return this.http.get<any>(`${this.MODULE_API.GETCQ}?currentYear=${currentYear}&page=${page}&limit=${limit}&year=${this.getSettingYear()}&columnName=${columnName}&order=${sortingOrder}`);
	}
	submitCqData(data) {
		return this.http.post(this.MODULE_API.POSTCQ, data);
	}
	getStatisticaData(currentYear: string) {
		return this.http.get<any>(`${this.MODULE_API.STATISTICADESCRITTIVA}?currentYear=${currentYear}`);
	}
	exportStatisticaDescrittiva(requestedPayload) {
		return this.http.post<any>(this.MODULE_API.EXPORTCQ, requestedPayload);
	}
	getBolla(codice_cam: any, codice_az: any): Observable<any> {
		return this.http.get<any>(`${this.MODULE_API.GETBOLLA}?codice_cam=${codice_cam}&codice_az=${codice_az}`);
	}
	getL30qs(specie: any, anno_rif: any, codice_az: any): Observable<any> {
		return this.http.get<any>(`${this.MODULE_API.LS30DATA}?specie=${specie}&anno_rif=${anno_rif}&codice_az=${codice_az}`);
	}
	createL30qs(data: any) {
		return this.http.post(this.MODULE_API.CREATEL30QS, data);
	}
	updateL30qs(data) {
		return this.http.post(this.MODULE_API.UPDATEL30QS, data);
	}
	deleteL30qs(id: string): Observable<any> {
		const url = `${this.MODULE_API.DELETELS30}/${id}`;
		return this.http.delete(url).pipe(
			map((response: any) => {
				return response;
			}),
			catchError((err: any) => {
				return err;
			})
		);
	}
	getACL30qs(codice_az: any, codice_cam: any, cod_toro: any, lotto: any): Observable<any> {
		return this.http.get<any>(`${this.MODULE_API.GETCALFORL30}?codice_az=${codice_az}&codice_cam=${codice_cam}&cod_toro=${cod_toro}&lotto=${lotto}`);
	}
	getdnaTable(): Observable<any> {
		return this.http.get<any>(`${this.MODULE_API.GETDNADATATABLE}`);
	}
	CreateNewDna(data: any) {
		return this.http.post(this.MODULE_API.CREATENEWDATA, data);
	}
	updateDnaRecord(data) {
		return this.http.post(this.MODULE_API.UPDATEDNARECOARD, data);
	}
	deleteDna(id: string): Observable<any> {
		const url = `${this.MODULE_API.DELETEDNARECORD}/${id}`;
		return this.http.delete(url).pipe(
			map((response: any) => {
				return response;
			}),
			catchError((err: any) => {
				return err;
			})
		);
	}
	getl30dnaData(page, limit, columnName, keyword, order): Observable<any> {
		return this.http.get<any>(`${this.MODULE_API.GETL30DNADATA}?page=${page}&limit=${limit}&columnName=${columnName}&keyword=${keyword}&order=${order}`);
	}
	CreateNewL30dna(data: any) {
		return this.http.post(this.MODULE_API.CREATEL30DNA, data);
	}
	updateL30Dna(data) {
		return this.http.post(this.MODULE_API.UPDATEL30DNA, data);
	}
	deletel30Dna(id: string): Observable<any> {
		const url = `${this.MODULE_API.DELETEL30DNA}/${id}`;
		return this.http.delete(url).pipe(
			map((response: any) => {
				return response;
			}),
			catchError((err: any) => {
				return err;
			})
		);
	}
	// getl30lotiSampleData(codice_az: any, specie: any, anno: any,order:any): Observable<any> {
	// 	return this.http.get<any>(`${this.MODULE_API.GETl30LOTTIDATA}?codice_az=${codice_az}&specie=${specie}&anno=${anno}&order=${order}`);
	// }
	getl30lotiSampleData(codice_az: any, specie: any, anno: any, order: any, dosi: any): Observable<any> {
		return this.http.get<any>(`${this.MODULE_API.GETl30LOTTIDATA}?codice_az=${codice_az}&specie=${specie}&anno=${anno}&order=${order}&dosi=${dosi}`);
	}

	getFoglio(codice_cam: any, codice_az: any, fileType: any): Observable<any> {
		return this.http.get<any>(`${this.MODULE_API.GETFOGLIO}?codice_cam=${codice_cam}&codice_az=${codice_az}&fileType=${fileType}`);
	}

	incrementString(inputString) {
		const newinput = inputString.toString();
		if (newinput !== null) {
			const numericPartMatch = newinput?.match(/\d+/);
			if (numericPartMatch) {
				const numericPart = parseInt(numericPartMatch[0], 10);
				const incrementedNumericPart = numericPart + 1;
				const outputString = newinput.replace(/\d+/, incrementedNumericPart);
				return outputString;
			} else {
				return inputString;
			}
		}
	}

	getRiproduttoriFuzzy() {
		return this.http.get(this.MODULE_API.RIPRODUTTORIFUZZY);
	}
	checkDuplicate(data) {
		let centro = localStorage.getItem("centriCodiceAz"); //TODO change this for production
		let annoImportazione = localStorage.getItem("anna"); // TODO change this
		return this.http.post(this.MODULE_API.CHECKDUPLICATE + "/" + centro + "/" + annoImportazione, data);
	}
	getmagData(page, limit, columnName, keyword, sortingOrder): Observable<any> {
		return this.http.get<any>(`${this.MODULE_API.GETMAGZINO}?page=${page}&limit=${limit}&columnName=${columnName}&keyword=${keyword}&year=${this.getSettingYear()}&order=${sortingOrder}`);
	}
	createMag(data: any) {
		return this.http.post(this.MODULE_API.CREATEMAGZINO, data);
	}
	createStrId(data: any) {
		return this.http.post(this.MODULE_API.POSTSTRID, data);
	}
	updateStrId(data: any) {
		return this.http.post(this.MODULE_API.UPDATESTRID, data);
	}
	updateMag(data: any) {
		return this.http.post(this.MODULE_API.UPDATEMAGZINO, data);
	}
	deleteMag(id: string): Observable<any> {
		const url = `${this.MODULE_API.DELETEMAGZINO}/${id}`;
		return this.http.delete(url).pipe(
			map((response: any) => {
				return response;
			}),
			catchError((err: any) => {
				return err;
			})
		);
	}
	getRegSem(codice_cam): Observable<any> {
		return this.http.get<any>(`${this.MODULE_API.REGISTSEME}?codice_cam=${codice_cam}`);
	}
	fillDownPost(data: any) {
		return this.http.post(this.MODULE_API.FILLDOWN, data);
	}

	getUniqueUgDna() {
		return this.http.get(this.MODULE_API.GETUGUNIQUE);
	}

	generateFrequency() {
		return this.http.get(this.MODULE_API.COURIERMANAGEMENTFREQUANZA);
	}

	generatetextRefer(anno, codice_az) {
		return this.http.get(`${this.MODULE_API.COURIERMANAGEMENTREFERO}?anno=${anno}&codice_az=${codice_az}`);
	}

	getEstrato(codice_cam, codice_az): Observable<any> {
		return this.http.get<any>(`${this.MODULE_API.ESTRATOFILE}?codice_cam=${codice_cam}&codice_az=${codice_az}`);
	}
	getPiastra(codice_cam, codice_az, id_piastra, buttonType): Observable<any> {
		return this.http.get<any>(`${this.MODULE_API.PIASTRA}?codice_cam=${codice_cam}&codice_az=${codice_az}&id_piastra=${id_piastra}&buttonType=${buttonType}`);
	}

	getPiastraManual(codice_az, id_piastra, buttonType): Observable<any> {
		return this.http.get<any>(`${this.MODULE_API.PIASTRA}?codice_az=${codice_az}&id_piastra=${id_piastra}&buttonType=${buttonType}`);
	}

	getIdPiastra(codice_cam, codice_az, buttonType) {
		return this.http.get<any>(`${this.MODULE_API.GETIDPIASTRA}?codice_cam=${codice_cam}&codice_az=${codice_az}&buttonType=${buttonType}`);
	}

	getIdPiastraMannul(codice_az, buttonType) {
		return this.http.get<any>(`${this.MODULE_API.GETIDPIASTRA}?codice_az=${codice_az}&buttonType=${buttonType}`);
	}
	savePiastra(codice_cam, codice_az, data): Observable<any> {
		const url = `${this.MODULE_API.SAVEPIASTRA}?codice_cam=${codice_cam}&codice_az=${codice_az}`;
		return this.http.post(url, data);
	}

	savePiastraManual(codice_az, data): Observable<any> {
		const url = `${this.MODULE_API.SAVEPIASTRA}?codice_az=${codice_az}`;
		return this.http.post(url, data);
	}
	semeRIsultati(codice_cam): Observable<any> {
		return this.http.get<any>(`${this.MODULE_API.SEMERISULTATI}?codice_cam=${codice_cam}`);
	}
	getL30Ac(centro: any, specie: any, anno: any) {
		return this.http.get<any>(`${this.MODULE_API.GETL30ACDATA}?centro=${centro}&specie=${specie}&anno=${anno}`);
	}
	getAnimaldata(data: any) {
		return this.http.post(this.MODULE_API.GETREPROPOPUPDATA, data);
	}
	reproduttoriNotFilter(page, limit, columnName, keyword, sortingOrder) {
		return this.http.get<any>(`${this.MODULE_API.REPRODUTTORINOTFILTER}?page=${page}&limit=${limit}&columnName=${columnName}&keyword=${keyword}&order=${sortingOrder}`);
	}

	updateDnaOpr(data: any) {
		return this.http.post(this.MODULE_API.UPDATEDNAOPR, data);
	}
	getStatoRif(codice_cam: any, codice_az: any) {
		return this.http.get<any>(`${this.MODULE_API.STATORIF}?codice_cam=${codice_cam}&codice_az=${codice_az}`);
	}
	exportArchivo(fileName, format) {
		return this.http.get<any>(`${this.MODULE_API.EXPORTARCHIVO}?tableName=${fileName}&format=${format}`);
	}
	getAnalisi1(codice_cam: any, codice_az: any, buttonType: any) {
		return this.http.get<any>(`${this.MODULE_API.Analisi1}?codice_cam=${codice_cam}&codice_az=${codice_az}&buttonType=${buttonType}`);
	}
	getInad1(codice_cam: any, codice_az: any, buttonType: any) {
		return this.http.get<any>(`${this.MODULE_API.INAD1}?codice_cam=${codice_cam}&codice_az=${codice_az}&buttonType=${buttonType}`);
	}
	getVca(codice_cam: any, codice_az: any) {
		return this.http.get<any>(`${this.MODULE_API.VCAFILE}?codice_cam=${codice_cam}&codice_az=${codice_az}`);
	}
	getResultatiDna1(codice_cam: any, codice_az: any, buttonType: any) {
		return this.http.get<any>(`${this.MODULE_API.GETDNARESULTATI1}?codice_cam=${codice_cam}&codice_az=${codice_az}&buttonType=${buttonType}`);
	}
	deleteCq(id): Observable<any> {
		const url = `${this.MODULE_API.DELETECQ}/${id}`;
		return this.http.delete(url).pipe(
			map((response: any) => {
				return response;
			}),
			catchError((err: any) => {
				return err;
			})
		);
	}

	updateCqData(data: any) {
		return this.http.post(this.MODULE_API.UPDATECQ, data);
	}

	getL30lotti(codice_az: any, year: any, specie: any): Observable<any> {
		return this.http.get<any>(`${this.MODULE_API.GETL30LOTTI}?codice_az=${codice_az}&year=${year}&specie=${specie}`);
	}
	updateLottiData(data: any) {
		return this.http.post(this.MODULE_API.UPDATEL30LOTTI, data);
	}
	getCentriData(): Observable<any> {
		return this.http.get<any>(`${this.MODULE_API.GETCENTRIDATA}`);
	}
	createCentri(data: any) {
		return this.http.post(this.MODULE_API.CREATECENTRI, data);
	}
	updateCentri(data: any) {
		return this.http.post(this.MODULE_API.UPDATECENTRI, data);
	}
	lottiDelete(data: any): Observable<any> {
		return this.http.delete(this.MODULE_API.LOTTIDELETE, { body: data });
	}

	lottiDeleteLotti(data: any) {
		return this.http.delete(this.MODULE_API.LOTTIDELETELOTTI, { body: data });
	}

	updateAutocerti(data: any) {
		return this.http.post(this.MODULE_API.UPDATEAUTOCERTI, data);
	}

	priviewPiastra(codice_cam, codice_az, id_piastra) {
		return this.http.get(`${this.MODULE_API.PREVIEWPIASTA}?codice_cam=${codice_cam}&codice_az=${codice_az}&id_piastra=${id_piastra}`);
	}

	priviewPiastraManual(codice_az, id_piastra) {
		return this.http.get(`${this.MODULE_API.PREVIEWPIASTA}?codice_az=${codice_az}&id_piastra=${id_piastra}`);
	}
	getL30vFor(id): Observable<any> {
		return this.http.get<any>(`${this.MODULE_API.GETL30VFOR}?id_for=${id}`);
	}
	createL30Vfor(data: any) {
		return this.http.post(this.MODULE_API.CREATEL30VFOR, data);
	}
	updateL30Vfor(data: any) {
		return this.http.post(this.MODULE_API.UPDATEL30VFOR, data);
	}
	getL30For(): Observable<any> {
		return this.http.get<any>(`${this.MODULE_API.GETL30FOR}`);
	}
	createL30for(data: any) {
		return this.http.post(this.MODULE_API.CREATEL30FOR, data);
	}
	updateL30for(data: any) {
		return this.http.post(this.MODULE_API.UPDATEL30FOR, data);
	}
	getSituazioni(id_for) {
		return this.http.get(`${this.MODULE_API.SITUAZIONI}?id_for=${id_for}`);
	}

}
