<app-loader *ngIf="loader"></app-loader>
<!-- <h2 class="title">LOTTI CAMPIONATI</h2> -->
<div>
	<div class="header">
		<h3 class="semi-bold mb-1">CAMPIONAMENTI IN CORSO</h3>
		<button class="button1" (click)="openPopup('PIASTRA_MANUALE')" >PIASTRA MANUALE</button>
	</div>
	<ag-grid-angular style="height: 500px" class="ag-theme-alpine-dark basic-table" [rowData]="rowData1$" [gridOptions]="gridOptions1"> </ag-grid-angular>
</div>
<div>
	<h3 class="semi-bold mb-1">CAMPIONAMENTI COMPLETATI</h3>
	<ag-grid-angular style="height: 500px" class="ag-theme-alpine-dark basic-table" [rowData]="rowData3$" [gridOptions]="gridOptions2"> </ag-grid-angular>
</div>


<ng-template #popupContainer let-options  >

	<div class="popup-window animate-in">
		<form [formGroup]="piastraFrom" (ngSubmit)="onSubmitPiastra()" class="form-container">
			<div class="row">
			  <div class="column">
				<label>ID_PIASTRA:</label>
				<select formControlName="ID_PIASTRA" (change)="onSelectionChange($event)">
				  <option *ngFor="let item of piastraData" [value]="item?.ID_PIASTRA">
					{{ item?.ID_PIASTRA + ' - ' + 'Posti Disponibili: ' + item?.Avail_Slots  }}
				  </option>
				</select>
			  </div>
			  <div class="column">
				<label>Tipo Estrattore:</label>
				<input type="text" formControlName="tipo_seq"/>
			  </div>
			</div>
			<div class="row">
			  <div class="column">
				<label>DATA_RIF:</label>
				<input type="date" formControlName="DATA_RIF" [(ngModel)]="currentDate"/>
			  </div>
			  <div class="column radio">
				<label>File Type:</label>
				<input type="hidden" formControlName="CELLEPIENE" />
				<label class="container">xlsx
				  <input type="radio" value="xlsx" formControlName="buttonType"/>
				  <span class="check"></span>
				</label>
				<label class="container">Txt
				  <input type="radio" value="text" formControlName="buttonType" checked/>
				  <span class="check"></span>
				</label>
			  </div>
			</div>
			<div class="custombutton">
				<button type="button" class="button1" (click)="addItem()">Add Item</button>
			</div>
			<div class="row">
			  <div formArrayName="id_seme" class="column">
				  <label>ID Seme:</label>
				<div *ngFor="let item of id_seme.controls; let i = index" [formGroupName]="i" class="mainclass">
				  <div class="columns">
					  <input type="text" formControlName="value"/>
				  </div>
				  <div class="removebutton" *ngIf="i == id_seme.controls.length - 1 && i != 0">
					  <button type="button" class="button1" (click)="removeItem(i)">Remove</button>
				  </div>
				</div>
			</div>
	
			</div>
			<div class="popup-footer">
			  <button type="button" class="button" (click)="previewPisatraButton()">Preview</button>
			  <button type="submit" class="button" [disabled]="!piastraFrom.valid">Aggiorna</button>
			  <button type="button" class="button" (click)="closePopup()">Chiudi</button>
			</div>
		  </form> 
		</div> 
</ng-template>