import { Component, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ApiService } from '../_services/api.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ModalService } from '../_services/modal.service';
import { ToastrService } from 'ngx-toastr';
import { GridOptions } from 'ag-grid-community';
import { CustomButtonRendererComponent, CustomButtonRendererComponent1 } from '../table-test/custom-button-renderer.component';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-bolla-table',
	templateUrl: './bolla-table.component.html',
	styleUrls: ['./bolla-table.component.scss']
})
export class BollaTableComponent {
	dnaTableData = [];
	populatedData = [];
	paginationPageSize = 15;
	totalCount: any;
	changeButtun: boolean = false;
	rowData = [];
	tableName: string = "courier-management";
	view;
	timeRange: string = "00.00/00.00"; // Default time range
	invalidTimeRange: boolean = false;
	p: number = 1;
	popup = false;
	form: FormGroup;
	loader: boolean = false;

	@ViewChild("popupContainer", { read: TemplateRef })
	popupContainer: TemplateRef<any>;
	rowId: any;
	codeic: string | number;
	code: string;
	columnDefs = [
		{
			field: "tori_Ripro",
			headerName: "Ripro",
			width: 290,
		},

		{
			field: "tori_matricola",
			headerName: "Matricola",
			width: 290,
			cellRenderer: (params: any) => {
				return `${params.data.tori_matricola == '' || params.data.tori_matricola == null ? 'N/A' : params.data.tori_matricola}`;
			},
		},
		{
			field: "tori_matricola2",
			headerName: "Matricola2",
			width: 290,
			cellRenderer: (params: any) => {
				return `${params.data.tori_matricola2 == '' || params.data.tori_matricola2 == null ? 'N/A' : params.data.tori_matricola2}`;
			},
		},
		{
			field: "lotto",
			headerName: "Lotto",
			width: 290,
			// flex: 1,
			cellRenderer: (params: any) => {
				return `${params.data.lotto == '' || params.data.lotto == null ? 'N/A' : params.data.lotto}`;
			},
		},
		{
			field: "id_lab",
			headerName: "ID.Lab",
			width: 290,
		},
		{
			field: "NC",
			headerName: "NC",
			width: 290,
		},
	];
	codice_cam: any;
	codice_az: any;
	centriCodiceAzName: any;


	constructor(public apiService: ApiService, private router: Router, private viewContainerRef: ViewContainerRef, private formBuilder: FormBuilder, public toast: ToastrService, private modalService: ModalService, private activate: ActivatedRoute) {

	}
	defaultColDef = {
		// flex: 1,
		resizable: true,
		sortable: true,
		filter: true,
	};
	gridOptions: GridOptions = {
		defaultColDef: this.defaultColDef,
		pagination: true,
		paginationAutoPageSize: true,
		frameworkComponents: {
			customButtonRenderer: CustomButtonRendererComponent, CustomButtonRendererComponent1 // Register the custom cell renderer
		},
	}
	ngOnInit(): void {
		this.activate.queryParams.subscribe((res: any) => {
			this.codice_cam = res.codice_cam;
			this.codice_az = res.codice_az
			this.centriCodiceAzName=res.centriCodiceAzName
		})
		this.getRicarData();
	}

	onCellClicked(data) { }
	getRicarData() {
		this.loader = true

		// this.apiService.getFaglio(this.codice_cam, this.codice_az).subscribe({
		// 	next: (data) => {
		// 		if (data.status) {
		// 			this.loader = false
		// 			this.dnaTableData = data.data;
		// 			this.totalCount = data.totalCount;
		// 			this.rowData = this.dnaTableData;
		// 		}
		// 	},
		// });
	}
	onBtExport() {
		const newrowData2: any[] = this.rowData.map((el: any, index: number) => {
			return {
			number: index+1,
			tori_Ripro: el?.tori_Ripro ? el?.tori_Ripro : '',
			tori_matricola: el?.tori_matricola ? el?.tori_matricola : '',
			tori_matricola2:el?.tori_matricola2?el?.tori_matricola2:'',
			lotto: el?.lotto ? el?.lotto : '',
			id_lab: el?.id_lab ? el?.id_lab : '',
			NC: el?.NC ? el?.NC : '',
			}
		});
		const fieldToHeaderMap = {};
		this.columnDefs.forEach(item => { fieldToHeaderMap[item?.field] = item?.field });
		const payload = newrowData2.map(obj => {
			const newObj = {};
			for (const key in obj) {
				if (fieldToHeaderMap[key]) { newObj[fieldToHeaderMap[key]] = obj[key]; }
			}
			return newObj;
		});
		let newPayload = payload;
		let payloadObject = { data: newPayload, type: "bolla",codice_cam:this.codice_cam,centriCodiceAzName:this.centriCodiceAzName }


		const today=new Date();
		const day=today.getDate().toString().padStart(2,'0');
		const month=(today.getMonth()+1).toString().padStart(2,'0');
		const year=today.getFullYear();
		const formattedDate=`${day}-${month}-${year}`
		let fileName="";
		let staticData="";
		let fullData="";
		fileName = "bolla";
		staticData =
		`Istituto Sperimentale Italiano Lazzaro Spallanzani
		CONTROLLO UFFICIALE DEL SEME
		Lab.SEME - BOLLA INTERNA per Lab.DNA (O_BIS-01)(emesso in data ${formattedDate})
		Azienda = ${payloadObject.centriCodiceAzName}                        
		Campionamento = ${payloadObject.codice_cam} `;

	  const dynamicData = payloadObject.data; // Access the "data" property in the dynamic data

	  // Header for dynamic data
	  const dynamicDataHeader =    'Ripro            Matricola             Matricola2         Lotto              ID.Lab           NC';
	  const dynamicDataSeparator = '___________     ______________         ___________    _______________      __________       ___________ ';

	  // Format dynamic data
	  const formattedDynamicData = dynamicData.map(item => {
		return `${item['tori_Ripro'].padEnd(16)}      ${item['tori_matricola'].padEnd(15)}   ${item['tori_matricola2'].padEnd(11)}    ${item['lotto'].padEnd(18)}   ${item['id_lab'].padEnd(18)}  ${item['NC'].padEnd(18)}`;
	  });

const bottomfooter1="CONSEGNA/RICEVIMENTO - Lab.SEME __________________  Lab.DNA __________________";

	  // Combine the static data, dynamic data header, and dynamic data
	  fullData = `${staticData}\n${dynamicDataHeader}\n${dynamicDataSeparator}\n${formattedDynamicData.join('\n')}\n\n\n\n\n${bottomfooter1.padStart(15)}`;
	  const blob = new Blob([fullData], { type: 'text/csv' });
	  const url = window.URL.createObjectURL(blob);
	  const a = document.createElement('a');
	  a.href = url;
	  a.download = 'data.csv';
	  document.body.appendChild(a);
	  a.click();
	  window.URL.revokeObjectURL(url);



		// this.apiService.exportRicar(payloadObject).subscribe({
		// 	next: (res) => {
				
		// 		if (res instanceof Blob) {
					// const blob = new Blob([res], { type: 'text/csv' });
					// const url = window.URL.createObjectURL(blob);
					// const a = document.createElement('a');
					// a.href = url;
					// a.download = 'data.csv';
					// document.body.appendChild(a);
					// a.click();
					// window.URL.revokeObjectURL(url);

		// 		} else {
		// 			console.error('Invalid response format. Expected a Blob.');
		// 		}
		// 	},
		// 	error: (error) => {
		// 		console.error('An error occurred:', error);
		// 	},
		// 	complete: () => {
		// 	}
		// });

	}
	convertArrayToCSV(data: any[]): string {
		const header = Object.keys(data[0]).join(',');
		const csvRows = data.map(row => Object.values(row).join(','));
		return header + '\n' + csvRows.join('\n');
	}
	back() {
		this.router.navigate(["campionamenti"]);
	}
}
