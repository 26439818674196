<app-loader *ngIf="loader"></app-loader>
<div class="back">
    <button class="btn backbtn fs-18 fw-600" (click)="back()"><i
            class="fa-solid fa-arrow-left pr-1"></i>INDIETRO</button>
    <div class="form-container">
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="row">
                <div class="column">
                    <label>*Nome del Toro:</label>
                    <input type="text" formControlName="toro" required />
                    <div *ngIf="(form?.controls['toro']?.invalid) && (form?.controls['toro']?.dirty || form?.controls['toro']?.touched)"
                        class="alert">
                        <div *ngIf="form?.controls['toro']?.hasError('required')">
                            Please enter toro
                        </div>
                    </div>
                </div>
                <div class="column">
                    <label>*Matricola:</label>
                    <input type="text" formControlName="matricola" required />
                    <div *ngIf="(form?.controls['matricola'].invalid) && (form?.controls['matricola'].dirty || form?.controls['matricola'].touched)"
                        class="alert">
                        <div *ngIf="form?.controls['matricola']?.hasError('required')">
                            Please enter matricola
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <label>Matricola2:</label>
                    <input type="text" formControlName="matricola2" />
                </div>
                <div class="column" *ngIf="!isDneUser">
                    <label>MatricolaANA:</label>
                    <input type="text" formControlName="matric_ana" />
                </div>
                <div class="column" *ngIf="isDneUser">
                    <label>DNA: <small class="lastCodDna">(last coddna available {{codeDna}})</small></label>
                    <input type="text" formControlName="cod_dna" />
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <label>Cod Toro:</label>
                    <input type="number" formControlName="cod_toro" [readonly]="isDneUser" />
                </div>
                <div class="column">
                    <label>Nascita:</label>
                    <input type="date" formControlName="data_nasc" />
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <label>*Specie:</label>
                    <select formControlName="specie" required (change)="selectSpecie($event)">
                        <option [value]="specie?.value" *ngFor="let specie of spaciesData;">{{specie?.name}}</option>
                    </select>
                    <div *ngIf="(form?.controls['specie']?.invalid) && (form?.controls['specie']?.dirty || form?.controls['specie']?.touched)"
                        class="alert">
                        <div *ngIf="form?.controls['specie']?.hasError('required')">
                            Please enter specie
                        </div>
                    </div>
                </div>
                <div class="column">
                    <label>Razza:</label>
                    <select formControlName="razza">
                        <option [ngValue]="null">Scegli la Razza</option>
                        <option *ngFor="let razza of razzeData" [ngValue]="razza.razza">
                            {{ razza.razza_nome }}
                        </option>
                    </select>
                </div>
            </div>
            <!-- <div class="row" *ngIf="!isDneUser">
            <div class="column">
                <label>Provato:</label>
                <input type="text" formControlName="provato" />
            </div>
            <div class="column">
                <label>Top100:</label>
                <input type="text" formControlName="top100" />
            </div>
        </div> -->
            <!-- <div class="row"  *ngIf="!isDneUser">   
            <div class="column">
                <label>MatricolaANA:</label>
                <input type="text" formControlName="matric_ana" />
            </div>
        </div> -->
            <button type="submit" class="button1" [disabled]="!form.valid">Salva Anagrafica</button>
        </form>
        <cf-dialog *ngIf="showDialog" (confirmed)="confirmation($event)"></cf-dialog>
    </div>

    <ng-template #popupContainer let-options>
        <div class="popup-window animate-in">
            <div class="form-container">
                <form class="popForm" [formGroup]="Popupform" (ngSubmit)="onSubmitpopup()">
                    <div class="row">
                        <div class="column field">
                            <label>*settore:</label>
                            <input type="text" formControlName="settore" readonly [value]="location.settore" />
                        </div>
                        <div class="column field">
                            <label>*cilindro:</label>
                            <input type="text" formControlName="cilindro" readonly [value]="location.cilindro" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="column field">
                            <label>piano:</label>
                            <input type="text" formControlName="piano" readonly [value]="location.piano" />
                        </div>
                        <div class="column field">
                            <label>contenit:</label>
                            <input type="text" formControlName="contenit" [value]="location.contenit" />
                        </div>
                    </div>

                    <div class="row">
                        <div class="column field">
                            <label>NOTE :</label>
                            <input type="textarea" formControlName="note" />
                        </div>
                    </div>
                    <div class="main-footer">
                        <div class="popup-footer">
                            <!-- <div><button class="button customSave " type="submit">save</button></div> -->
                            <div><button class="button customtrash" type="submit">Chiudi</button></div>
                        </div>
                    </div>

                </form>
            </div>
        </div>
    </ng-template>