import { Component, ElementRef, OnInit, Renderer2, TemplateRef, ViewChild, ViewContainerRef } from "@angular/core";
import { ApiService } from "../_services/api.service";
import { ToastrService } from "ngx-toastr";
import { HttpClient } from "@angular/common/http";
import { ColDef, ColumnApi, GridOptions } from "ag-grid-community";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ModalService } from "../_services/modal.service";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { saveAs } from 'file-saver';

@Component({
	selector: "app-fornitori",
	templateUrl: "./fornitori.component.html",
	styleUrls: ["./fornitori.component.scss"],
})
export class FornitoriComponent {
	rowdata$: any[];
	rowdata1$: any[];
	defaultColDef: ColDef = { resizable: true, sortable: true, filter: true };
	sortingOrder: string = "";
	columnApi: ColumnApi;
	columnName: string = "";
	changeButtun: boolean = false;
	message: string = "";
	cqColDefs = [
		{
			field: "id_for",
			headerName: "ID Fornitore",
		},
		{
			field: "stato",
			headerName: "QUALIFICA",
		},
		{
			field: "for_unico",
			headerName: "TIPO",
		},
		{
			field: "rag_soc",
			headerName: "Ragione Sociale",
			width: 250,
		},
		{
			field: "via",
			headerName: "Via",
			width: 250,
		},
		{
			field: "comune",
			headerName: "Comune",
		},
		{
			field: "cap",
			headerName: "CAP",
		},
		{
			field: "nazione",
			headerName: "Nazione",
		},
		{
			field: "fax",
			headerName: "Fax",
		},
		{
			field: "email",
			headerName: "Email",
			width: 300,
		},
		{
			field: "ref",
			headerName: "Riferimento",
		},
		{
			field: "note",
			headerName: "Note",
			width: 300,
		},
		{
			field: "",
			headerName: "SITUAZIONE",
			minWidth: 250,
			cellRenderer: (params: any) => {
				const button = document.createElement("button");
				button.className = "btn btn-sm";
				button.style.backgroundColor = "#fff";
				button.style.color = "grey";
				button.innerText = "SITUAZIONE";

				button.addEventListener("click", () => {
					const uniqueId = params.data.id_for; // Assuming `id_for` exists in row data
					params.context.componentParent.openSituazioniPopup(uniqueId);
				});

				return button;
			},
		},
		{
			field: "",
			headerName: "STAMPA",
			minWidth: 250,
			cellRenderer: (params: any) => {
			  const button = document.createElement("button");
			  button.className = "btn btn-sm";
			  button.style.backgroundColor = "#fff";
			  button.style.color = "grey";
			  button.innerText = "STAMPA";
		  
			  button.addEventListener("click", (event) => {
				event.stopPropagation();
				this.id_For = params.data.id_for;
				params.context.componentParent.stampButtonClick();
			  });
		  
			  return button;
			},
		  },
		  
		{
			field: "",
			headerName: "FORNITURE",
			minWidth: 250,
			cellRenderer: (params: any) => {
				const button = document.createElement("button");
				button.className = "btn btn-sm";
				button.style.backgroundColor = "#fff";
				button.style.color = "grey";
				button.innerText = "FORNITURE";

				button.addEventListener("click", () => {
					const uniqueId = params.data.id_for;
					const angularRouter = params.context.componentParent.router;
					angularRouter.navigate(["/fornituri-section", uniqueId]);
				});

				return button;
			},
		},
	];
	GridOptions: GridOptions<any> = {
		columnDefs: this.cqColDefs,
		defaultColDef: this.defaultColDef,
		rowSelection: "single",
		animateRows: true,
		pagination: true,
		paginationAutoPageSize: true,
		context: {
			componentParent: this,
		},
	};

	tableName: string = "lotti";
	itemPerPage: number = 14;
	pageNumber: number = 1;
	totalCount: number = 1;
	loader: boolean = false;
	Form: FormGroup;
	cqFormSubmitted: boolean = false;

	@ViewChild("PopupContainer", { read: TemplateRef })
	PopupContainer: TemplateRef<any>;
	view: any;
	currentYear: string = "";
	clickedData: any;
	centriCodiceAz: string | number;
	specie: string;
	anno: string | number;
	isAlive = true;
	subjectValue: boolean = false;
	lastIdFor: any;
	FormSituazioni: FormGroup;
	movimenti: any[] = [];
	id_For: any;

	constructor(
		public apiService: ApiService,
		public toasterService: ToastrService,
		private http: HttpClient,
		private viewContainerRef: ViewContainerRef,
		private formBuilder: FormBuilder,
		private modalService: ModalService,
		private renderer: Renderer2,
		private elementRef: ElementRef,
		private router: Router
	) {
		this.Form = this.formBuilder.group({
			id_for: [null, Validators.required],
			stato: [null, Validators.required],
			for_unico: [null],
			rag_soc: [null],
			via: [null],
			comune: [null],
			cap: [null],
			nazione: [null],
			fax: [null],
			email: [null],
			ref: [null],
			note: [null],
		});
		this.FormSituazioni = this.formBuilder.group({
			RICHIESTE: [null],
			PREVENTIVI: [null],
			ORDINI: [null],
			RICEVIMENTI: [null],
			RIMANENZE: [null],
			FINE: [null],
			PUNTI: [null],
			NCP: [null],
		});
		this.GridOptions.onSortChanged = this.onSortChanged.bind(this);
	}

	ngOnInit(): void {
		this.getTableData();
		this.centriCodiceAz = localStorage.getItem('centriCodiceAz') ? localStorage.getItem('centriCodiceAz') : '1';
	}
	ngOnDestroy() {
		this.isAlive = false;
		this.apiService.redirectButton.next(false);
	}
	fagliofile: any;
	stampButtonClick() {
		this.apiService.getStampData(this.id_For, this.centriCodiceAz).subscribe({
		  next: (data: any) => {
			if (data) {
			  let url = environment.url + data.dowloadLink;
			  this.downloadStampdoc(url, data.filenames);
			  this.loader = false;
			} else {
			  this.toasterService.error(data.description);
			  this.loader = false;
			}
		  },
		  error: (err) => {
			this.toasterService.error(err.message);
			this.loader = false;
		  }
		});
	  }
	  
	  downloadStampdoc(url: string, filename: string): void {
	
		this.http.get(url, { responseType: 'blob' }).subscribe(
		  (data: Blob) => {
			const blob = new Blob([data], { type: 'application/pdf' });
			saveAs(blob, filename);
		  },
		  (error) => {
			this.toasterService.error('File download failed.');
		  }
		);
	  }
	  
	  
	onSortChanged(event) {
		const sortedColumns: any = this.columnApi.getAllColumns().filter((column) => column.getSort())[0];
		this.sortingOrder = sortedColumns?.getSort() ?? "";
		this.columnName = sortedColumns?.getColDef().field ?? "";
		this.getTableData();
	}
	showPopup = false;

	openSituazioniPopup(id_for) {
		this.FormSituazioni.reset();
		this.movimenti =[];
		this.showPopup = true;
		this.apiService.getSituazioni(id_for).subscribe({
			next: (data: any) => {
				this.FormSituazioni.patchValue(data.result);
				if (data?.result?.MOVIMENTI) {
					this.movimenti = data.result.MOVIMENTI;
				}
			},
			error: (err) => {
				this.toasterService.error(err.message);
			},
		});
	}

	closeSituazioniPopup() {
		this.showPopup = false;
	}
	onGridReady(params) {
		this.columnApi = params.columnApi;
	}

	onSubmitCqForm() {
		this.cqFormSubmitted = true;
		if (this.Form.invalid) {
			return;
		}
		if (!this.changeButtun) {
			this.apiService.createL30for({ ...this.Form.value }).subscribe({
				next: (data: any) => {
					if (data.status) {
						this.toasterService.success(data.message);
						this.closePopup();
						this.getTableData();
					} else {
						this.toasterService.error(data.message);
					}
					this.loader = false;
				},
				error: (err) => {
					this.toasterService.error(err.message);
				},
			});
		} else {
			this.apiService.updateL30for({ ...this.Form.value, id: this.clickedData.id }).subscribe({
				next: (data: any) => {
					if (data.status) {
						this.toasterService.success(data.message);
						this.closePopup();
						this.getTableData();
					} else {
						this.toasterService.error(data.message);
					}
					this.loader = false;
				},
				error: (err) => {
					this.toasterService.error(err.message);
				},
			});
		}
	}

	getTableData() {
		this.loader = true;
		this.apiService.getL30For().subscribe({
			next: (data) => {
				if (data.status) {
					this.totalCount = data?.result?.count;
					this.lastIdFor = +data?.maxIdFor + 1;
					this.rowdata$ = data?.result?.rows;
				} else {
					this.toasterService.error(data.message);
				}
				this.loader = false;
			},
			error: (err) => {
				this.rowdata$ = [];
				this.loader = false;
				this.toasterService.error(err.message);
			},
		});
	}

	get fc() {
		return this.Form.controls;
	}

	openCqFormPopup() {
		this.apiService.modaBackDrop.next(true);
		const bodyElement = this.elementRef.nativeElement.ownerDocument.body;
		this.renderer.setStyle(bodyElement, "overflow", "hidden");
		this.view = this.viewContainerRef.createEmbeddedView(this.PopupContainer);
		const domElem = this.view.rootNodes[0] as HTMLElement;
		domElem.classList.add("animate-in");
	}
	openPopup() {
		this.Form.get("id_for").setValue(this.lastIdFor);
	}
	closePopup() {
		this.cqFormSubmitted = false;
		this.Form.reset();
		this.changeButtun = false;
		const bodyElement = this.elementRef.nativeElement.ownerDocument.body;
		this.renderer.setStyle(bodyElement, "overflow", "auto");
		const domElem = this.view.rootNodes[0] as HTMLElement;
		domElem.classList.add("animate-out");
		domElem.addEventListener("click", (event: AnimationEvent) => {
			this.viewContainerRef.clear();
		
		});
	     this.viewContainerRef.clear();
		this.apiService.modaBackDrop.next(false);
	}
	// closePopup() {
	// 	this.cqFormSubmitted = false;
	// 	this.Form.reset();
	// 	this.changeButtun = false;
	// 	this.viewContainerRef.clear();
	// 	this.apiService.modaBackDrop.next(false);
	// }
	

	onCellClicked(event: any) {
		if (event.colDef.headerName == "SITUAZIONE") {
			return;
		}
		this.changeButtun = true;
		this.clickedData = event.data;
		this.openCqFormPopup();
		this.Form.patchValue({
			id_for: this.clickedData.id_for,
			stato: this.clickedData.stato,
			for_unico: this.clickedData.for_unico,
			rag_soc: this.clickedData.rag_soc,
			via: this.clickedData.via,
			comune: this.clickedData.comune,
			cap: this.clickedData.cap,
			nazione: this.clickedData.nazione,
			fax: this.clickedData.fax,
			email: this.clickedData.email,
			stampa: this.clickedData.stampa,
			ref: this.clickedData.ref,
			note: this.clickedData.note,
		});
	}
}
