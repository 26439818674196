<app-loader *ngIf="loader"></app-loader>
<app-conform [message]="'Sei sicuro di voler eliminare questo riproduttore?'" (confirm)="confirm($event)"></app-conform>
<div>
    <div class="specie-main">
        <!-- <h1 class="semi-bold">RIPRODUTTORI {{centroName?centroName:'Cosapam'}}</h1> -->
        <h1 class="semi-bold">{{isDneUser?'TUTTI I RIPRODUTTORI':'RIPRODUTTORI CPS'}}</h1>
        <div style="position: relative;">
            <div class="container" style="display: flex;flex-direction: column !important;"
                [ngStyle]="{ 'flex-basis': boolShowInputText ? 'calc(100%/3)' : 'calc(100%/6)' }">
                <input id="search-toro" type="text" [value]="selectedText"
                    placeholder="Cerca Toro tramite nome, matricola, matricola o DNA" (input)="onSearchText()"
                    [(ngModel)]="searchText" (focusin)="focusIn($event)" (focusout)="focusOut($event)" />

                <div id="fuzzy-result-number">
                    <h6>Match effettuati: {{ fuzResult.length }}</h6>
                </div>

                <div class="popup-fuzzy-result1" *ngIf="boolShowInputText">
                    <div class="popup-fuzzy-result" *ngFor="let riproduttore of fuzResult | slice : 0 : 8"
                        (mousedown)="selectToro(riproduttore)" (click)="clickedResult(riproduttore)">
                        <div class="reprodata">
                            <h6>Nome Toro:</h6>
                            <h6>{{ riproduttore.Toro?riproduttore.Toro:"" }}</h6>
                        </div>
                        <div class="reprodata">
                            <h6>Matricola:</h6>
                            <h6>{{ riproduttore.Matricola?riproduttore.Matricola:"" }}</h6>
                        </div>
                        <div class="reprodata">
                            <h6>Matricola2:</h6>
                            <h6>{{ riproduttore.Matricola2?riproduttore.Matricola2:""}}</h6>
                        </div>
                        <div class="reprodata">
                            <h6>DNA:</h6>
                            <h6>{{ riproduttore.Cod_dna?riproduttore.Cod_dna:"" }}</h6>
                        </div>
                        <div class="reprodata">
                            <h6>COD TORO:</h6>
                            <h6>{{ riproduttore.cod_toro ? riproduttore.cod_toro : "" }}</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <button [ngStyle]="{'visibility': lastCodToro? 'visible' : 'hidden'}" class="button1"
                (click)="navigate()">ANAGRAFICHE </button>
        </div>
    </div>
    <!-- <div style="position: relative;">
        <div class="container" style="display: flex;flex-direction: column !important;"
            [ngStyle]="{ 'flex-basis': boolShowInputText ? 'calc(100%/3)' : 'calc(100%/6)' }">
            <input id="search-toro" type="text" [value]="selectedText"
                placeholder="Cerca Toro (puoi usare il nome, la matricola o la matricola2)" (input)="onSearchText()"
                [(ngModel)]="searchText" (focusin)="focusIn($event)" (focusout)="focusOut($event)" />

            <div id="fuzzy-result-number">
                <h6>Match effettuati: {{ fuzResult.length }}</h6>
            </div>

            <div class="popup-fuzzy-result1" *ngIf="boolShowInputText">
                <div class="popup-fuzzy-result" *ngFor="let riproduttore of fuzResult | slice : 0 : 8"
                    (mousedown)="selectToro(riproduttore)" (click)="clickedResult(riproduttore)">
                    <div class="reprodata">
                        <h6>Nome Toro:</h6>
                        <h6>{{ riproduttore.Toro?riproduttore.Toro:"" }}</h6>
                    </div>
                    <div class="reprodata">
                        <h6>Matricola:</h6>
                        <h6>{{ riproduttore.Matricola?riproduttore.Matricola:"" }}</h6>
                    </div>
                    <div class="reprodata">
                        <h6>Matricola2:</h6>
                        <h6>{{ riproduttore.Matricola2?riproduttore.Matricola2:""}}</h6>
                    </div>
                    <div class="reprodata">
                        <h6>DNA:</h6>
                        <h6>{{ riproduttore.Cod_dna?riproduttore.Cod_dna:"" }}</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div>
        <button class="button1" (click)="navigate()">ANAGRAFICHE </button>
    </div> -->
</div>
<div *ngIf="!isDneUser">
    <ag-grid-angular style=" height: 750px;" class="ag-theme-alpine-dark basic-table" [rowData]="rowData$"
        [gridOptions]="gridOptions">
    </ag-grid-angular>
</div>
<div class="heading-bar mt-1" *ngIf="!isDneUser">
    <h1 class="semi-bold">TUTTI I RIPRODUTTORI</h1>
</div>
<ag-grid-angular style=" height: 750px;" class="ag-theme-alpine-dark basic-table" [rowData]="rowData1$"
    [gridOptions]="gridOptions1" (gridReady)="onGridReady($event)">
</ag-grid-angular>
<div class="pagination">
    <app-pagination [dataLength]="totalCount" [tableName]="tableName" [itemPerPage]="itemPerPage"
        [columnName]="columnName" [filterValue]="filterValue" [sortingOrder]="sortingOrder"
        (dataOnPage)="onReceiveData($event)"></app-pagination>
</div>


<ng-template #popupContainer let-options>
    <div class="popup-window animate-in ">
        <form [formGroup]="form" (ngSubmit)="checkDNAChange()">
            <div class="row">
                <div class="column">
                    <label>*Cod toro:</label>
                    <input type="text" formControlName="cod_toro" required />
                </div>
                <div class="column">
                    <label>*DNA:<small class="lastCodDna">last coddna available {{codeDna}}</small></label>
                    <input type="text"  formControlName="cod_dna" required  />
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <label>*Nome del Toro:</label>
                    <input type="text" formControlName="toro" required />
                </div>
                <div class="column">
                    <label>*Matricola:</label>
                    <input type="text" formControlName="matricola" required />
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <label>Matricola2:</label>
                    <input type="text" formControlName="matricola2" />
                </div>
                <div class="column">
                    <label>MatricolaANA:</label>
                    <input type="text" formControlName="matric_ana" />
                </div>
            </div>

            <div class="row">
                <!-- <div class="column" *ngIf="isDneUser">
                    <label>DNA:</label>
                    <input type="text" formControlName="cod_dna" readonly />
                </div> -->
                <div class="column">
                    <label>Nascita:</label>
                    <input type="date" formControlName="data_nasc" />
                </div>
                <!-- <div class="column" >
                    <label>Provato:</label>
                    <input type="text" formControlName="provato" />
                </div> -->
            </div>
            <div class="row">
                <!-- <div class="column">
                    <label>Top100:</label>
                    <input type="text" formControlName="top100" />
                </div> -->
                <!-- <div class="column">
                    <label>Nascita:</label>
                    <input type="date" formControlName="data_nasc" />
                </div> -->
            </div>
            <div class="row">
                <div class="column">
                    <label>*Specie:</label>
                    <select formControlName="specie" required (change)="selectSpecie($event)">
                        <option [value]="specie?.value" *ngFor="let specie of spaciesData;">{{specie?.name}}
                        </option>
                    </select>
                </div>
                <div class="column">
                    <label>Razza:</label>

                    <select formControlName="razza">
                        <option [ngValue]="null">Scegli la Razza</option>
                        <option *ngFor="let razza of razzeData" [ngValue]="razza.razza">
                            {{ razza.razza_nome }}
                        </option>
                    </select>
                </div>
            </div>
            <!-- <div class="row">
                <div class="column field">
                    <label>*settore:</label>
                    <input type="text" formControlName="settore" readonly />
                </div>
                <div class="column field">
                    <label>*cilindro:</label>
                    <input type="text" formControlName="cilindro" readonly />
                </div>
            </div>
            <div class="row">
                <div class="column field">
                    <label>piano:</label>
                    <input type="text" formControlName="piano" readonly />
                </div>
                <div class="column field">
                    <label>contenit:</label>
                    <input type="text" formControlName="contenit" />
                </div>
            </div> -->

            <div class="row" style="display: none;">
                <div class="column field">
                    <label>NOTE :</label>
                    <input type="textarea" formControlName="note" hidden />
                </div>
            </div>
            <div class="main-footer">
                <div class="delete">
                    <i class="fa fa-trash customtrash" aria-hidden="true" (click)="openmodel()"></i>
                </div>
                <div class="popup-footer">
                    <div><button type="submit" class="button danger">Salva</button></div>
                    <div><button type="button" class="button " (click)="closePopup1()">Chiudi</button></div>
                </div>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #popupContainer1 let-options>
    <div class="popup-window1 animate-in">
        <div>
            <form [formGroup]="formCodToro" (ngSubmit)="onCodTorosub()">
                <div class="row">
                    <div class="column">
                        <label>*Code Toro:</label>
                        <input type="text" formControlName="cod_toro" required />
                    </div>
                </div>
                <div class="main-footer">
                    <div class="popup-footer">
                        <div><button type="submit" class="button danger">Conferma</button></div>
                        <div><button type="button" class="button " (click)="closePopup1()">Annulla</button></div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>


<ng-template #popupContainer2 let-options>
    <div class="popup-window animate-in">
        <div>
            <form class="popForm" [formGroup]="Popupform" (ngSubmit)="onSubmitpopup()">
                <div class="row">
                    <div class="column field">
                        <label>*settore:</label>
                        <input type="text" formControlName="settore" readonly />
                    </div>
                    <div class="column field">
                        <label>*cilindro:</label>
                        <input type="text" formControlName="cilindro" readonly />
                    </div>
                </div>
                <div class="row">
                    <div class="column field">
                        <label>piano:</label>
                        <input type="text" formControlName="piano" readonly />
                    </div>
                    <div class="column field">
                        <label>contenit:</label>
                        <input type="text" formControlName="contenit" />
                    </div>
                </div>
                <div class="row">
                    <div class="column field">
                        <label>NOTE :</label>
                        <input type="textarea" formControlName="note" />
                    </div>
                </div>
                <div class="main-footer">
                    <div class="popup-footer">
                        <!-- <div><button class="button customSave " type="submit">salve</button></div> -->
                        <div><button class="button customtrash" type="submit" (click)="closePopup1()">Chiudi</button></div>
                    </div>
                </div>

            </form>
        </div>
    </div>
</ng-template>