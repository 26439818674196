import { Component, ElementRef, Renderer2, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { NewDNA, Razza1 } from '../_models';
import { CellClickedEvent, ColDef, GridOptions } from "ag-grid-community";
import { CustomButtonRendererComponent } from '../table-test/custom-button-renderer.component';
import { ApiService } from '../_services/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ModalService } from '../_services/modal.service';
import { take, takeWhile } from 'rxjs';
@Component({
  selector: 'app-l30qs-table',
  templateUrl: './l30qs-table.component.html',
  styleUrls: ['./l30qs-table.component.scss']
})
export class L30qsTableComponent {
  centriCodiceAz: string | number;
  specie: string;
  anno: string | number;
  loader: boolean = false;
  rowData$: NewDNA[];
  clickedRowData: any;
  subjectValue: boolean = false;
  years: number[] = [];

  addL30Qs: FormGroup;
  view: any;
  isAlive = true;
  @ViewChild("popupContainer", { read: TemplateRef })
  popupContainer: TemplateRef<any>;
  tableName: string = 'newdnadata';
  formSubmitted: boolean = false;
  defaultColDef: ColDef = {
    // flex: 1,
    resizable: true,
    sortable: true,
    filter: true,
  };


  colDefs: ColDef<NewDNA>[] = [
    { field: "codice_cam", headerName: "Cod Cam", width: 150 },
    { field: "toro", headerName: "RIPRODUTTORE" , width:200},
    { field: "specie", headerName: "SPECIE", width: 150 },
    { field: "lotto", headerName: "LOTTO", width: 150 },

    { field: "anno_rif", headerName: "ANNO Rif", width: 150, cellRenderer: (params: any) => { return `${params?.data?.anno_rif == null ? 'N/A' : params?.data?.anno_rif}` } },
    // { field: "autocertif", headerName: "autocertif", width:150, cellRenderer: (params: any) => { return `${params?.data?.autocertif == null||params?.data?.autocertif == null?'N/A':params?.data?.autocertif}`} },
    // { field: "cod_dna", headerName: "cod_dna" , width:150 },
    { field: "cod_toro", headerName: "Cod SOGGETTO", width: 150 },
    { field: "codice_lab", headerName: "Cod Lab", width: 150 },
    { 
      field: "vca", 
      headerName: "VCA", 
      width: 150, 
      // cellRenderer: (params: any) => {
      //   const nspm = parseFloat(params?.data?.nspm);
      //   const nspm_c = parseFloat(params?.data?.l30_ac?.nspm_c);
      //   const mp = parseFloat(params?.data?.mp);

      //   // const nspm = parseFloat(item['nspm']);
      //   const rif = parseFloat((nspm_c / 1.75).toFixed(2));
    
      //   // if (nspm === null || nspm_c === null || isNaN(nspm) || isNaN(nspm_c)) {
      //   //   return 'N/A';
      //   // } else if (nspm >= nspm_c) {
      //   //   return 'S';
      //   // } else {
      //   //   return 'N';
      //   // }
      // }
    },
    { field: "edit_oper", headerName: "Op EDIT", width: 150 },
    { field: "numero_cam",
       headerName: "Num ANAL",
       width: 150,
      valueParser: (params:any) => Number(params?.data?.numero_cam),
			comparator: (valueA, valueB) => Number(valueA) - Number(valueB),
			filter: 'agNumberColumnFilter',
     },
    { field: "non_conf", headerName: "NON CONFOR", width: 150 },
    { field: "non_conf2", headerName: "NON CONFOR 2", width: 150 },
    { field: "data_anal", headerName: "Data ANALISI", width: 150,
      comparator: this.dateComparator,
    },
    { field: "data_prod", headerName: "Data PROD", width: 150,
      comparator: this.dateComparator,
    },
    { field: "tipo_dose", headerName: "Tipo DOSE", width: 150 },
    { field: "scong_ora", headerName: "Ora Scong", width: 150 },
    { field: "scong_bagn", headerName: "Cod Bagno", width: 150 },
    { field: "scong_oper", headerName: "Op SCONG", width: 150 },
    // { field: "mestruo", headerName: "mestruo" , width:150},
    { field: "paraf_ora", headerName: "Ora PARAF", width: 150 },
    // { field: "vodice_vhs", headerName: "vodice_vhs" , width:150},
    // { field: "vhs_ora", headerName: "vhs_ora" , width:150},
    // { field: "vhs_oper", headerName: "vhs_oper" , width:150},
    { field: "conc", headerName: "CONC", width: 150,
      valueParser: (params:any) => Number(params?.data?.conc),
			comparator: (valueA, valueB) => Number(valueA) - Number(valueB),
			filter: 'agNumberColumnFilter',
    },
    // { field: "conc_um", headerName: "conc_um" , width:150},
    { field: "conc_setup", headerName: "Setup CONC", width: 150 },
    { field: "conc_oper", headerName: "CONC_OPER", width: 150 },
    { field: "mt", headerName: "MT", width: 150 },
    { field: "mp", headerName: "MP", width: 150,
      valueParser: (params:any) => Number(params?.data?.mp),
			comparator: (valueA, valueB) => Number(valueA) - Number(valueB),
			filter: 'agNumberColumnFilter',
     },
    { field: "vm", headerName: "VM", width: 150 },
    { field: "mot_setup", headerName: "Setup MOT", width: 150 },
    { field: "mot_oper", headerName: "Oper MOT", width: 150 },
    { field: "nspm", headerName: "NSPM", width: 150, 
      valueParser: (params:any) => Number(params?.data?.nspm),
			comparator: (valueA, valueB) => Number(valueA) - Number(valueB),
			filter: 'agNumberColumnFilter',
      // cellRenderer: (params: any) => { return `${params?.data?.conc == null ? 'N/A' : (params?.data?.conc * params?.data?.mp)}` }
     },
    { field: "conc_c", headerName: "Conc c", width: 150 , cellRenderer: (params: any) => { return `${params?.data?.l30_ac?.conc_c == null ? 'N/A' : params?.data?.l30_ac?.conc_c}`
     }},
    { field: "mp_c", headerName: "Mp c", width: 150 , cellRenderer: (params: any) => { return `${params?.data?.l30_ac?.mp_c == null ? 'N/A' : params?.data?.l30_ac?.mp_c}`
  }},
    { field: "nspm_c", headerName: "Nspm c", width: 150 , cellRenderer: (params: any) => { return `${params?.data?.l30_ac?.nspm_c == null ? 'N/A' : params?.data?.l30_ac?.nspm_c}`
  }},



    

    // {
    //   field: "Action",
    //   headerName: "AZIONE",
    //   // flex: 1,
    //   width:150,
    //   cellRenderer: 'customButtonRenderer',
    //   cellRendererParams: {
    //     onClick: this.onButtonClicked.bind(this),
    //     tableName: 'newdnadata',
    //   },
    // },
  ];

  gridOptions: GridOptions<NewDNA> = {
    columnDefs: this.colDefs,
    defaultColDef: this.defaultColDef,
    rowSelection: "single",
    animateRows: true,
    pagination: true,
    paginationAutoPageSize: true,
    frameworkComponents: { customButtonRenderer: CustomButtonRendererComponent },
    onCellClicked: (event: CellClickedEvent) => {
      this.clickedRowData = event?.data
      this.patchDNAForm();
      this.openPopup();
      this.changeButtun = true;
    },
  };

  razze: Razza1[] = [];
  changeButtun: boolean = false;
  itemPerPage: number = 50;
  pageNumber: number = 1;
  totalCount: number = 1;
  deleteId: any;
  spaciesData: any[] = [
    { value: "B", name: "Bovini" },
    { value: "BI", name: "Bovini(i)" },
    { value: "E", name: "Equini" },
    { value: "EI", name: "EQUINI(I)" },
    { value: "S", name: "Suini" },
    { value: "SI", name: "SUINI(I)" },
    { value: "C", name: "CONIGLI" },
    { value: "CI", name: "CONIGLI(I)" },
    { value: "BU", name: "Bufali" },
    { value: "O", name: "OVINI" },
    { value: "OI", name: "OVINI(I)" },

  ]
  selectYears: any;
  newCodiceLab1: string;
  calc: any;
  lastcodiccam: any;
  constructor(
    public apiService: ApiService,
    public viewContainerRef: ViewContainerRef,
    public formBuilder: FormBuilder,
    public toastr: ToastrService,
    public modalService: ModalService,
    private renderer: Renderer2,
    private elementRef: ElementRef
  ) {
    this.newDNAForm();
    this.deleteRow();
    this.apiService.current.pipe(takeWhile(() => this.isAlive)).subscribe((res) => {
      if (res) {
        this.subjectValue = true
        this.centriCodiceAz = res.codice_az ? res.codice_az : localStorage.getItem('centriCodiceAz')
        this.specie = res.specie ? res.specie : localStorage.getItem('specie');
        this.anno = res.anna ? res.anna : localStorage.getItem('anna')
        this.tableData();
      }
    })
  }

  ngOnInit() {
    this.yearselect()
    // this.apiService.current.pipe(takeWhile(() => this.isAlive)).subscribe((res) => {
    // 	if (res) {
    // 		this.subjectValue = true
    // 		this.centriCodiceAz = res.codice_az ? res.codice_az : localStorage.getItem('centriCodiceAz')
    // 		this.specie = res.specie ? res.specie : localStorage.getItem('specie');
    // 		this.anno = res.anna ? res.anna : localStorage.getItem('anna')
    // 		this.tableData();
    // 	}
    // })
    this.tableData();
  }

  selectSpecie(event) {
    this.specie = event?.target?.value;
    // localStorage.setItem('specie',this.specie)
    // this.apiService.current.next({specie:this.specie, anna:this.selectYears });
    // this.dataToParent.emit(this.specie)
    // this.razzeData = this.razze.filter((sp: any) => sp?.specie === this.specie);
  }

  selectYear(event) {
    this.selectYears = event.target.value

  }
  yearselect() {
    const currentYear = new Date().getFullYear();
    for (let i = 0; i < 4; i++) {
      this.years.push(currentYear - i);
    }
  }

  onButtonClicked(data: any) {
    this.loader = true;
    this.apiService.deleteL30qs(data?.id).subscribe((res: any) => {
      if (res) {
        this.closePopup()
        this.tableData();
        this.loader = false;
        this.toastr.success(res.description);
      }
    });
  }

  tableData() {
    this.loader = true;
    if (!this.subjectValue) {
      this.centriCodiceAz = localStorage.getItem('centriCodiceAz') ? localStorage.getItem('centriCodiceAz') : 1
      this.specie = localStorage.getItem('specie') ? localStorage.getItem('specie') : "BI"
      this.anno = localStorage.getItem('anna') ? localStorage.getItem('anna') : 2022
    }
    this.subjectValue = false
    this.apiService.getL30qs(this.specie, this.anno, this.centriCodiceAz).subscribe({
      next: (res) => {
        this.loader = false
        this.rowData$ = res?.result?.rows;
        this.totalCount = res?.totalCount;
        let lastValue = res?.codice_labLast
        const [value, year] = lastValue.split('.');
        // const currentYear = new Date().getFullYear();
        const currentYear = new Date().getFullYear().toString().slice(-2);
        let newCodiceLab: string;
        const incrementedValue = parseInt(value, 10) + 1;
        newCodiceLab = `${incrementedValue}.${currentYear}`;
        this.newCodiceLab1 = newCodiceLab
        this.lastcodiccam = this.incrementString(res.getLastCODICECAM.codice_cam)
      },
      error: (error) => {
        this.loader = false

      },
      complete: () => { 
        this.loader = false

      }
    });
  }

  openPopup() {
    this.apiService.modaBackDrop.next(true);
    const bodyElement = this.elementRef.nativeElement.ownerDocument.body;
    this.renderer.setStyle(bodyElement, 'overflow', 'hidden');
    this.view = this.viewContainerRef.createEmbeddedView(this.popupContainer);
    const domElem = this.view.rootNodes[0] as HTMLElement;
    domElem.classList.add("animate-in");
  }
  openPopup1() {
    this.addL30Qs.get('codice_lab').setValue(this.newCodiceLab1)
    this.addL30Qs.controls['codice_cam'].setValue(this.lastcodiccam)
    this.addL30Qs.controls['numero_cam'].setValue(1)
  }

  patchDNAForm() {

    let date = this.formatDate(this.clickedRowData.data_anal)
    let data_prod = this.formatDate(this.clickedRowData.data_prod)
    let nspm = (this.clickedRowData.conc && this.clickedRowData.mp) ? ((this.clickedRowData.conc * this.clickedRowData.mp) / 100) : 0;
    this.addL30Qs.patchValue({
      // id: this.clickedRowData.id,
      codice_cam: this.clickedRowData.codice_cam,
      codice_az: this.clickedRowData.codice_az,
      specie: this.clickedRowData.specie,
      lotto: this.clickedRowData.lotto,
      anno_rif: this.clickedRowData.anno_rif,
      cod_toro: this.clickedRowData.cod_toro,
      codice_lab: this.clickedRowData.codice_lab,
      edit_oper: this.clickedRowData.edit_oper,
      numero_cam: this.clickedRowData.numero_cam,
      non_conf: this.clickedRowData.non_conf,
      non_conf2: this.clickedRowData.non_conf2,
      data_anal: date,
      data_prod: data_prod,
      tipo_dose: this.clickedRowData.tipo_dose,
      scong_ora: this.clickedRowData.scong_ora,
      scong_bagn: this.clickedRowData.scong_bagn,
      scong_oper: this.clickedRowData.scong_oper,
      paraf_ora: this.clickedRowData.paraf_ora,
      conc: this.clickedRowData.conc,
      conc_setup: this.clickedRowData.conc_setup,
      conc_oper: this.clickedRowData.conc_oper,
      mt: this.clickedRowData.mt,
      mp: this.clickedRowData.mp,
      vm: this.clickedRowData.vm,
      mot_setup: this.clickedRowData.mot_setup,
      mot_oper: this.clickedRowData.mot_oper,
      nspm: nspm.toFixed(1),

      // vca: nspm >= this.clickedRowData?.l30_ac?.nspm_c ? 'S' : 'N',
      vca:this.clickedRowData.vca,
      total_conc_c: this.clickedRowData?.l30_ac?.conc_c,
      total_mp_c:  this.clickedRowData?.l30_ac?.mp_c,
      total_nspm_c:  this.clickedRowData?.l30_ac?.nspm_c
    });
    // this.apiService.getACL30qs(this.centriCodiceAz, this.clickedRowData.codice_cam, this.clickedRowData.cod_toro, this.clickedRowData.lotto).subscribe((res) => {
    //   this.calc = res?.result[0]
    //   this.addL30Qs.patchValue({
    //     total_conc_c: this.calc?.total_conc_c,
    //     total_mp_c: this.calc?.total_mp_c,
    //     total_nspm_c: this.calc?.total_nspm_c
    //   })
    // })

  }

  newDNAForm() {
    this.addL30Qs = this.formBuilder.group({
      codice_cam: [null, Validators.required],
      codice_az: [null, Validators.required],
      specie: [null, Validators.required],
      lotto: [null],
      anno_rif: [null],
      cod_toro: [null],
      codice_lab: [null],
      edit_oper: [null],
      numero_cam: [null],
      non_conf: [null],
      non_conf2: [null],
      data_anal: [null],
      data_prod: [null],
      tipo_dose: [null],
      scong_ora: [null],
      scong_bagn: [null],
      scong_oper: [null],
      paraf_ora: [null],
      conc: [null],
      conc_setup: [null],
      conc_oper: [null],
      mt: [null],
      mp: [null],
      vm: [null],
      mot_setup: [null],
      mot_oper: [null],
      nspm: [null],
      vca: [null],
      total_conc_c: [null],
      total_mp_c: [null],
      total_nspm_c: [null]
    });

  }

  deleteRow() {
    this.apiService.delete.subscribe((res) => {
      this.deleteId = res.data
      if ((res?.data?.id) && (res?.table === 'newdnadata')) {
        this.modalService.openModal('newdnadata');
      }
    })
  }

  openmodel() {
    this.modalService.openModal('newdnadata');
  }
  confirm(event) {
    // if (event?.modal == "newdnadata" && event?.value) {
    this.onButtonClicked(this.clickedRowData);
    // }

  }

  receiveData(event: any) {
    if (event?.table === this.tableName) {
      this.rowData$ = event?.data?.result;
      this.totalCount = event?.data?.totalCount;
    }
  }
  get fc() { return this.addL30Qs.controls; }

  onSubmit() {
    this.loader = true;
    this.formSubmitted = true;
    if (this.addL30Qs.invalid) {
      this.loader = false;
      return
    } else {
      const payload = this.addL30Qs.value;
      if (this.changeButtun) {
        let dataanal = this.addL30Qs.value.data_anal == '' ? null : this.addL30Qs.value.data_anal
        let dataprod = this.addL30Qs.value.data_prod == '' ? null : this.addL30Qs.value.data_prod
        let date = this.formatDate1(dataanal)
        let data_prod = this.formatDate1(dataprod)
        this.updateRecord({ id: this.clickedRowData.id, ...payload, data_anal: date, data_prod: data_prod });
        this.formSubmitted = false;
      } else {
        let dataanal = this.addL30Qs.value.data_anal == '' ? null : this.addL30Qs.value.data_anal
        let dataprod = this.addL30Qs.value.data_prod == '' ? null : this.addL30Qs.value.data_prod
        let date = this.formatDate1(dataanal)
        let data_prod = this.formatDate1(dataprod)
        this.createRecord({ ...payload, data_anal: date, data_prod: data_prod });
        this.formSubmitted = false;
      }
    }
  }

  createRecord(payload: any) {
    this.loader = true
    this.apiService?.createL30qs(payload).subscribe({
      next: (res: any) => {
        if (res.success) {
          this.loader = false;
          this.toastr.success(res.description);
          this.tableData();
          this.closePopup();
        }else{
          this.toastr.error(res.description);
          this.loader = false;
        }
      },
      error: (err) => {
        this.loader = false;
        this.toastr.error('An error occurred');
      }
    });
  }

  updateRecord(payload: any) {
    this.loader = true;
    this.apiService?.updateL30qs(payload).subscribe({
      next: (res: any) => {
        if (res) {
          this.loader = false;
          this.tableData();
          this.toastr.success(res.description);
          this.closePopup();
        }
      },
      error: (err) => {
        this.loader = false;
        this.toastr.error('An error occurred');
      }
    });
  }

  retrieveRazze() {
    this.apiService.retrieveRazze().subscribe({
      next: (data) => {
        this.razze = data.result as Razza1[];
      },
      error: (err) => {
      },
    });
  }

  closePopup() {
    this.addL30Qs.reset();
    this.changeButtun = false;
    const bodyElement = this.elementRef.nativeElement.ownerDocument.body;
    this.renderer.setStyle(bodyElement, 'overflow', 'auto');
    const domElem = this.view.rootNodes[0] as HTMLElement;
    domElem.classList.add("animate-out");
    domElem.addEventListener("click", (event: AnimationEvent) => {
      this.viewContainerRef.clear();
      this.apiService.modaBackDrop.next(false);
    });
  }

  formatDate1(inputDate): any {
    if (inputDate != null) {
      const [year, month, day] = inputDate.split('-');

      // Create the formatted date string in dd/mm/yyyy format
      const formattedDate = `${day}/${month}/${year}`;

      return formattedDate;
    }
  }
  formatDate(date): any {
    if (date !== 'NULL' && date !== null) {
      const dateComponents = date.split('/');
      const formattedDate = new Date(`${dateComponents[2]}-${dateComponents[1]}-${dateComponents[0]}`);

      if (!isNaN(formattedDate.getTime())) {
        // Check if the formattedDate is a valid Date object
        const timeZoneOffset = formattedDate.getTimezoneOffset() * 60000;
        const adjustedDate = new Date(formattedDate.getTime() - timeZoneOffset);
        const formattedDateString = adjustedDate.toISOString().split('T')[0];
        return formattedDateString;
      } else {
        // Handle invalid date
        return 'Invalid Date';
      }
    }
  }
  incrementString(inputString: any) {
    if (inputString !== null) {
      const numericPartMatch = inputString.match(/\d+/);
      if (numericPartMatch) {
        const numericPart = parseInt(numericPartMatch[0], 10);
        const incrementedNumericPart = numericPart + 1;
        const outputString = inputString.replace(/\d+/, incrementedNumericPart);
        return outputString;
      } else {
        return inputString;
      }
    }
  }

  getnspm() {
    const conc = this.addL30Qs?.value?.conc;
    const mp = this.addL30Qs?.value?.mp;
    const total_nspm_c = this.addL30Qs?.value?.total_nspm_c ;
    let nspm = 0;
    if (conc && mp) {
      nspm = (conc * mp) / 100;
      this.addL30Qs.controls['nspm'].setValue(nspm.toFixed(1));

      const rif = parseFloat((total_nspm_c / 1.75).toFixed(2));
      const vca = (nspm >= rif && mp >= 10) ? 'S' : 'N';
      // const vca = nspm >= total_nspm_c ? 'S' : 'N';
      this.addL30Qs.controls['vca'].setValue(vca);
    }
  }

  dateComparator(date1: string | null, date2: string | null): number {
		// Check if either date is null or undefined
		if (!date1 || !date2) {
		  // You can decide what to return in case of null values
		  // For example, if date1 is null, consider it as smaller than any non-null date2
		  return date1 ? 1 : -1;
		}
	  
		const dateParts1 = date1.split('/');
		const dateParts2 = date2.split('/');
		
		// Create date objects in the format YYYY-MM-DD
		const d1 = new Date(Number(dateParts1[2]), Number(dateParts1[1]) - 1, Number(dateParts1[0]));
		const d2 = new Date(Number(dateParts2[2]), Number(dateParts2[1]) - 1, Number(dateParts2[0]));
		
		return d1.getTime() - d2.getTime();
	  }

  // getnspm(data:string) {
  //   if(data == 'nspm'){
  //     let nspm = this.addL30Qs?.value?.nspm?parseFloat(this.addL30Qs?.value?.nspm):0;
  //     this.addL30Qs.controls['nspm'].setValue(nspm);

  //   }else{
  //     const conc = this.addL30Qs?.value?.conc;
  //     const mp = this.addL30Qs?.value?.mp;
  //     const total_nspm_c = this.addL30Qs?.value?.total_nspm_c ? this.addL30Qs?.value?.total_nspm_c : 0;
  //     let nspm = 0;
  //     if (conc && mp) {
  //       nspm = (conc * mp) / 100;
  //       this.addL30Qs.controls['nspm'].setValue(nspm.toFixed(1));
  //       const vca = nspm > total_nspm_c ? 'S' : 'N';
  //       this.addL30Qs.controls['vca'].setValue(vca);
  //     }
  //   }
  // }

}
