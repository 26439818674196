<ag-grid-angular
  #agGrid1
  style="width: 100%; height: 600px"
  class="ag-theme-alpine-dark"
  [columnDefs]="columnDefs"
  [defaultColDef]="defaultColDef"
  [rowSelection]="'single'"
  [rowData]="rowData"
  [animateRows]="true"
  (cellClicked)="onCellClicked($event)"
>
</ag-grid-angular>
