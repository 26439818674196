import { HttpClient } from '@angular/common/http';
import { Component, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ColDef, GridOptions } from 'ag-grid-community';
import { ModalService } from '../_services/modal.service';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../_services/api.service';
import { saveAs } from 'file-saver';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-risultati',
  templateUrl: './risultati.component.html',
  styleUrls: ['./risultati.component.scss']
})
export class RisultatiComponent {
  rowData$: any[];
  rowData2: any[];
  colDefs: ColDef<any>[] = [
    { field: "codice_cam", headerName: "CAMP", width: 195 },
    { field: "toro", headerName: "Nome", width: 195 },
    {
      field: "matricola", headerName: "Matr", width: 195, cellRenderer: (params: any) => {
        //  return `${parseInt(params?.data?.nspm) > params?.data?.nspm_c? "S" : "N"}`
        return `${params?.data?.tori[0]?.matricola}`

      }
    },
    { field: "lotto", headerName: "Lotto", width: 195 },
    {
      field: "vca", headerName: "VCA", width: 195, 
      // cellRenderer: (params: any) => {
      //   // return `${parseInt(params?.data?.nspm) > params?.data?.nspm_c ? "S" : "N"}`
      //   return `${(parseInt(params?.data?.conc) * params?.data?.mp / 100) > (params.data.l30_ac.conc_c * params.data.l30_ac.mp_c / 100) ? "S" : "N"}`
      // }
    },
    { field: "non_conf", headerName: "NC", width: 195 },
    { 
       field: "conc",
       headerName: "CONC", width: 195,
       valueParser: (params) => Number(params?.data?.conc),
       comparator: (valueA, valueB) => Number(valueA) - Number(valueB),
       filter: 'agNumberColumnFilter'
     },
    { 
      field: "mp", 
      headerName: "MP", 
      width: 210 ,
      valueParser: (params) => Number(params?.data?.mp),
       comparator: (valueA, valueB) => Number(valueA) - Number(valueB),
       filter: 'agNumberColumnFilter'

    },
    {
      field: "nspm", headerName: "NSPM", width: 250,
      valueParser: (params) => Number(params?.data?.nspm),
      comparator: (valueA, valueB) => Number(valueA) - Number(valueB),
      filter: 'agNumberColumnFilter' 
      // cellRenderer: (params: any) => {
      //   // return `${parseInt(params?.data?.nspm) > params?.data?.nspm_c ? "S" : "N"}`
      //   return `${(parseInt(params?.data?.conc) * params?.data?.mp / 100)}`

      // }
    },
  ];
  defaultColDef: ColDef = { resizable: true, sortable: true, filter: true };
  gridOptions: GridOptions<any> = {
    columnDefs: this.colDefs,
    defaultColDef: this.defaultColDef,
    rowSelection: "single",
    animateRows: true,
    pagination: true,
    paginationAutoPageSize: true,
  };
  codice_cam: any;
  fileName: any;
  constructor(
    public apiService: ApiService,
    public toastr: ToastrService,
    private modalService: ModalService,
    public activate: ActivatedRoute,
    private viewContainerRef: ViewContainerRef,
    private http: HttpClient,
  ) { }

  ngOnInit() {
    this.activate.queryParams.subscribe((res: any) => {
      this.codice_cam = res.codice_cam;
      this.getRisultati(this.codice_cam)
    })
  }


  getRisultati(data) {
    this.apiService.semeRIsultati(data).subscribe((res) => {
      if (res.status) {
        this.rowData$ = res?.l30qsDatahavingNumCamOne
        this.rowData2 = res?.l30qsDatahavingNumCamNotOne
      }
    })
  }
  getButtonFile(type: any) {
    let codiceAz = localStorage.getItem('centriCodiceAz')
    if (type == 'Analisi1') {

      this.apiService.getAnalisi1(this.codice_cam, codiceAz, type).subscribe((res: any) => {
        if (res.status) {
          let url = environment.url + res?.filePath;
          this.fileName = res?.filename
          this.downloadPdfFile(url)
        } else {
          this.toastr.error(res?.message)
        }
      })
    } else if (type == 'Analisi2') {
      this.apiService.getAnalisi1(this.codice_cam, codiceAz, type).subscribe((res: any) => {
        if (res.status) {
          let url = environment.url + res?.filePath;
          this.fileName = res?.filename
          this.downloadPdfFile(url)
        } else {
          this.toastr.error(res?.message)
        }
      })
    } else if (type == 1) {
      this.apiService.getInad1(this.codice_cam, codiceAz, type).subscribe((res: any) => {
        if (res.status) {
          let url = environment.url + res?.filePath;
          this.fileName = res?.filename
          this.downloadPdfFile(url)
        } else {
          this.toastr.error(res?.message)
        }
      })
    } else if (type == 2) {
      this.apiService.getInad1(this.codice_cam, codiceAz, type).subscribe((res: any) => {
        if (res.status) {
          let url = environment.url + res?.filePath;
          this.fileName = res?.filename
          this.downloadPdfFile(url)
        } else {
          this.toastr.error(res?.message)
        }

      })
    } else if (type == 'vca') {
      this.apiService.getVca(this.codice_cam, codiceAz).subscribe((res: any) => {
        if (res.status) {
          let url = environment.url + res?.filePath;
          this.fileName = res?.filename
          this.downloadPdfFile(url)
        } else {
          this.toastr.error(res?.message)
        }
      })
    }
  }

  downloadPdfFile(url: string): void {
    this.http.get(url, { responseType: 'blob' }).subscribe((data: Blob) => {
      const blob = new Blob([data], { type: 'application/pdf' });
      saveAs(blob, this.fileName);
    });
  }
}
