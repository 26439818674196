import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LoginComponent } from "./login/login.component";
import { PagenotfoundComponent } from "./pagenotfound/pagenotfound.component";
import { AuthGuard } from "./_guards/auth.guard";
import { DashComponent } from "./dash/dash/dash.component";

const routes: Routes = [
  {
    path: "",
    canActivate: [AuthGuard],
    component: DashComponent,
    loadChildren: () => import("./dash/dash.module").then((m) => m.DashModule),
  },
  {
    path: "login",
    component: LoginComponent,
  },
  {
    path: "**",
    pathMatch: 'full',
    component: PagenotfoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
