import { ConfirmValue } from './../modal/conform.component';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Razza } from 'src/app/_models';
import { ApiService } from 'src/app/_services/api.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
@Component({
  selector: 'app-speciedetail',
  templateUrl: './speciedetail.component.html',
  styleUrls: ['./speciedetail.component.scss']
})
export class SpeciedetailComponent {
  @Output() dataToParent: EventEmitter<string> = new EventEmitter<string>();
  @Output() SelectedYear: EventEmitter<number> = new EventEmitter<number>();

  @Input('year') year = false
  defaultValue: string = localStorage.getItem('specie') ? localStorage.getItem('specie') : "BI";
  defaultvalYear: any = localStorage.getItem('anna') ? localStorage.getItem('anna') : 2024
  currentItem: boolean;
  years: number[] = [];
  selectedYear: number; // Property to store the selected year
  razzeData: Razza[] = [];
  razze: Razza[] = [];
  spaciesData: any[] = [
    { value: "B", name: "Bovini" },
    { value: "BI", name: "Bovini(i)" },
    { value: "E", name: "Equini" },
    { value: "EI", name: "EQUINI(I)" },
    { value: "S", name: "Suini" },
    { value: "SI", name: "SUINI(I)" },
    { value: "C", name: "CONIGLI" },
    { value: "CI", name: "CONIGLI(I)" },
    { value: "BU", name: "Bufali" },
    { value: "O", name: "OVINI" },
    { value: "OI", name: "OVINI(I)" },

  ]
  specie: any='';
  selectYears: any;
  constructor(private apiService: ApiService, private http: HttpClient, private router: Router) {
    this.yearselect();
  }

  ngOnInit(): void {
    // Get the date input element
  }

  // refreshRoute() {
  //   // Re-navigate to the current route
  //   console.log("************")
  //   // Navigate to a different route, then back to the current one
  //   this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
  //     this.router.navigate(['/importa-listati']);
  //   });
  // }
  selectSpecie(event) {
    this.specie = event?.target?.value;
    localStorage.setItem('specie', this.specie);
    this.apiService?.specieSubject.next({ specie: this.specie, isChanged: true });
    // }
    this.apiService.current.next({ specie: this.specie, anna: this.selectYears });
    this.dataToParent.emit(this.specie)
    this.razzeData = this.razze.filter((sp: any) => sp?.specie === this.specie);
  }
  yearselect() {
    const currentYear = new Date().getFullYear();
		for (let i = 0; i < 4; i++) {
		  this.years.push(currentYear - i);
		}
  }
  selectYear(event){
     this.selectYears = event.target.value
    this.apiService.current.next({specie:this.specie, anna:this.selectYears });
    localStorage.setItem('anna',this.selectYears)
    this.SelectedYear.emit(this.selectYears)

  }
}
