import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ApiService } from '../_services/api.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-custom-button-renderer',
  template: `
    <!-- <i class="fa fa-trash custom" aria-hidden="true"  (click)="onButtonClick($event)"></i> -->
    <button class="redirct white-btn" title="RICAR" [hidden]="buttonRedirect == false" (click)="onButtonClick1($event,'ricar')">RICAR</button>
    <button class="redirct white-btn" title="FOGLIO di LAVORO" [hidden]="buttonRedirect == false" (click)="onButtonClick1($event,'foglio')">FOGLIO</button>
    <button class="redirct white-btn" title="FOGLIO di LAVORO" [hidden]="buttonRedirect == false" (click)="onButtonClick1($event,'foglio2')">FOGLIO2</button>
    <button class="redirct white-btn" title="BOLLA INTERNA" [hidden]="buttonRedirect == false" (click)="onButtonClick1($event,'bolla')">BOLLA</button>
    <button class="redirct white-btn" title="RISULTATI" [hidden]="buttonRedirect == false" (click)="onButtonClick1($event,'RISULTATI')">RISULTATI</button>
    <button class="redirct white-btn" title="DNA Uguale" [hidden]="buttonRedirect == true" (click)="goToRegistro($event)">Registro Seme DNA</button> 
  `,
  styleUrls: ["./table-test.component.scss"],
})
export class CustomButtonRendererComponent implements ICellRendererAngularComp {
  buttonRedirect: boolean;
  constructor(
    private apiService: ApiService,
    private router: Router
  ) {

  }
  private params: any;
  @Input() tableName: string;
  @Output() buttonClicked: EventEmitter<any> = new EventEmitter<any>();
  agInit(params: any): void {
    this.params = params;
    this.apiService.redirectButton.subscribe((res) => {
      this.buttonRedirect = res
    })
  }

  onButtonClick(event: any): void {
    event.stopPropagation();
    this.apiService.delete.next({ data: this.params.node.data, table: this.params?.tableName })
    this.buttonClicked.emit(this.params.node.data);
  }
  onButtonClick1(event: any, data): void {
    event.stopPropagation();
    if (data == 'ricar') {
      this.apiService.ricar.next({ data: this.params.node.data, type: data })
    }else if (data == 'foglio') {
      this.apiService.ricar.next({ data: this.params.node.data, type: data })
    }else if(data == 'foglio2'){
      this.apiService.ricar.next({ data: this.params.node.data, type: data })
    } else if (data == 'bolla') {
      this.apiService.ricar.next({ data: this.params.node.data, type: data })
    }else if(data == 'RISULTATI'){
      this.apiService.ricar.next({ data: this.params.node.data, type: data })
    }
  }
  goToRegistro(event: any) {
    const payVal = this.params.node.data;
    this.router.navigate(['registrosemedna'], { queryParams: { codice_cam: payVal?.codice_cam } })
  }

  refresh(): boolean {
    return false;
  }
}

@Component({
  selector: 'app-custom-button-renderer',
  template: `
    <!-- <i class="fa fa-trash custom" aria-hidden="true"  (click)="onButtonClick2($event)"></i> -->
    <button class="redirct white-btn" title="FOGLIO di LAVORO" (click)="onButtonClick($event,'foglio')">FOGLIO DI LAVORO</button>
    <button class="redirct white-btn" title="DNA Uguale" (click)="onButtonClick($event,'dna_uguale')">DNA Uguale</button>  
    <button class="redirct white-btn" title="DNA Uguale" (click)="goToRegistro($event)">Registro Seme DNA</button>
    <button class="redirct white-btn" title="RISULTATI" (click)="goresultati($event)">RISULTATI</button>  

  `,
  styleUrls: ["./table-test.component.scss"],
})
export class CustomButtonRendererComponent1 implements ICellRendererAngularComp {
  constructor(
    private apiService: ApiService,
    private http: HttpClient,
    private toast: ToastrService,
    private router: Router,
  ) { }
  private params: any;
  @Input() tableName: string;
  fagfilename: string;
  @Output() buttonClicked: EventEmitter<any> = new EventEmitter<any>();
  agInit(params: any): void {
    this.params = params;
  }

  onButtonClick2(event: any): void {
    event.stopPropagation();
    this.apiService.delete.next({ data: this.params.node.data, table: this.params?.tableName })
    this.buttonClicked.emit(this.params.node.data);
  }
  onButtonClick(event: any, data: string): void {
    event.stopPropagation();
    const payVal = this.params.node.data;
    this.getFoglio(payVal?.codice_cam, payVal?.codice_az, data);
  }

  getFoglio(codice_cam, codice_az, fileType: any) {
    this.apiService.getFoglio(codice_cam, codice_az, fileType).subscribe({
      next: (data) => {
        if (data.success) {
          let url = environment.url + data.downloadLink;
          this.downloadTextFile(url)
          this.fagfilename = data.filenames
        } else {
          this.toast.error(data.description);
        }
      },
    });
  }

  downloadTextFile(url: string): void {
    this.http.get(url, { responseType: 'text' }).subscribe((data: string) => {
      const blob = new Blob([data], { type: 'text/plain' });
      const anchor = document.createElement('a');
      anchor.href = window.URL.createObjectURL(blob);
      anchor.download = this.fagfilename;
      document.body.appendChild(anchor);
      anchor.click();
      window.URL.revokeObjectURL(anchor.href);
      document.body.removeChild(anchor);
    });
  }

  goToRegistro(event: any) {
    const payVal = this.params.node.data;
    this.router.navigate(['registrosemedna'], { queryParams: { codice_cam: payVal?.codice_cam, codice_az: payVal?.codice_az } })
  }
  goresultati(event:any){
    const payVal = this.params.node.data;
    this.router.navigate(['risultatidna'], { queryParams: { codice_cam: payVal?.codice_cam } })
  }

  refresh(): boolean {
    return false;
  }
}


@Component({
  selector: 'app-custom-button-renderer',
  template: `
    <!-- <i class="fa fa-trash custom" aria-hidden="true"  (click)="onButtonClick2($event)"></i> -->
    <!-- <button class="redirct" title="FOGLIO di LAVORO" (click)="onButtonClick($event,'foglio')">FOGLIO DI LAVORO</button> -->
    <button class="redirct white-btn" title="DNA Uguale" (click)="onButtonClick($event,'dna_uguale')">DNA UGUALI</button>
  `,
  styleUrls: ["./table-test.component.scss"], 
})
export class CustomButtonRendererComponent2 implements ICellRendererAngularComp {
  constructor(
    private apiService: ApiService,
    private http: HttpClient,
    private toast: ToastrService,
  ) { }
  private params: any;
  @Input() tableName: string;
  fagfilename: string;
  @Output() buttonClicked: EventEmitter<any> = new EventEmitter<any>();
  agInit(params: any): void {
    this.params = params;
  }

  onButtonClick2(event: any): void {
    event.stopPropagation();
    this.apiService.delete.next({ data: this.params.node.data, table: this.params?.tableName })
    this.buttonClicked.emit(this.params.node.data);
  }
  onButtonClick(event: any, data: string): void {
    event.stopPropagation();
    const payVal = this.params.node.data;
    // this.getFoglio(payVal?.codice_cam, payVal?.codice_az, data);
  }

  getFoglio(codice_cam, codice_az, fileType: any) {
    this.apiService.getFoglio(codice_cam, codice_az, fileType).subscribe({
      next: (data) => {
        if (data.success) {
          let url = environment.url + data.downloadLink;
          this.downloadTextFile(url)
          this.fagfilename = data.filenames
        } else {
          this.toast.error(data.description);
        }
      },
    });
  }

  downloadTextFile(url: string): void {
    this.http.get(url, { responseType: 'text' }).subscribe((data: string) => {
      const blob = new Blob([data], { type: 'text/plain' });
      const anchor = document.createElement('a');
      anchor.href = window.URL.createObjectURL(blob);
      anchor.download = this.fagfilename;
      document.body.appendChild(anchor);
      anchor.click();
      window.URL.revokeObjectURL(anchor.href);
      document.body.removeChild(anchor);
    });
  }

  refresh(): boolean {
    return false;
  }
}

