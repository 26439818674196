<app-loader *ngIf="loader"></app-loader>

<div class="table-container">
    <div>
        <div class="worksheet">
            <div>
                
                <button class="btn backbtn fs-18 fw-600" (click)="back()"><i class="fa-solid fa-arrow-left pr-1"></i>INDIETRO</button>
            </div>
            <div class="export-button">
                <button class="button" type="button" (click)="getFoglioText('foglio')">FOGLIO DI LAVORO</button>
                <button class="button" type="button" (click)="getFoglioText('dna_uguale')">DNA Uguale</button>
            </div>
        </div>
    <h2 class="title">LOTTI CAMPIONATI</h2>

    </div>
    <ag-grid-angular style="height: 700px;" class="ag-theme-alpine-dark basic-table" [rowData]="rowData2$"
        [gridOptions]="gridOptions2">
    </ag-grid-angular>
</div>