import { Component, ElementRef, OnInit, Renderer2, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ApiService } from '../_services/api.service';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { ColDef, ColumnApi, GridOptions } from 'ag-grid-community';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalService } from '../_services/modal.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';

@Component({
	selector: 'app-furniture',
	templateUrl: './furniture.component.html',
	styleUrls: ['./furniture.component.scss']
})
export class FurnitureComponent {
	rowdata$: any[];
	rowdata1$: any[];
	defaultColDef: ColDef = { resizable: true, sortable: true, filter: true };
	sortingOrder: string = '';
	columnApi: ColumnApi;
	columnName: string = '';
	changeButtun: boolean = false;
	message: string = ''
	cqColDefs = [
		{
			field: "ID_FORNIT",
			headerName: "Fornitura ID",
		},
		{
			field: "ID_FOR",
			headerName: "Fornitore ID",
		},
		{
			field: "DATA_RPREV",
			headerName: "Richiesta",
			comparator: this.dateComparator,
		},
		{
			field: "DATA_APREV",
			headerName: "Preventivo",
			comparator: this.dateComparator,
		},
		{
			field: "GG_CONSEG",
			headerName: "Consegna (gg)",
		},
		{
			field: "DISPONIBIL",
			headerName: "Dispon.(S/N)",
		},
		{
			field: "RANK_COSTO",
			headerName: "Rank",
		},

		{
			field: "DATA_BDO",
			headerName: "BDO",
			comparator: this.dateComparator,

		},
		{
			field: "OP_BDO",
			headerName: "OP BDO",
		},
		{
			field: "NUM_BDO",
			headerName: "Num BDO",
		},
		{
			field: "DATA_ORDIN",
			headerName: "Ordine",
			comparator: this.dateComparator,

		},
		{
			field: "DATA_RICEV",
			headerName: "Ricevimento",
			comparator: this.dateComparator,
		},
		{
			field: "OP_RICEV",
			headerName: "OP Ricevimento",
			valueParser: (params) => Number(params?.data?.OP_RICEV),
      		comparator: (valueA, valueB) => Number(valueA) - Number(valueB),
      		filter: 'agNumberColumnFilter'

		},
		{
			field: "FORNIT_OK",
			headerName: "Merce OK",
		},

		{
			field: "NUM_BDO_2",
			headerName: "Num BDO 2",
		},
		{
			field: "DATA_RIMAN",
			headerName: "Rimanenza",
			comparator: this.dateComparator,
		},
		{
			field: "OP_RIMAN",
			headerName: "OP Rimanenza",
			valueParser: (params) => Number(params?.data?.OP_RIMAN),
      		comparator: (valueA, valueB) => Number(valueA) - Number(valueB),
      		filter: 'agNumberColumnFilter'
		},
		{
			field: "RIMAN_OK",
			headerName: "Merce OK Rimanenza",
		},
		{
			field: "FOR_FINITA",
			headerName: "Completata",
		},
		{
			field: "PUNTI",
			headerName: "Punti",
		},
		{
			field: "FOR_NC",
			headerName: "NCP",
		},
	];
	GridOptions: GridOptions<any> = {
		columnDefs: this.cqColDefs,
		defaultColDef: this.defaultColDef,
		rowSelection: "single",
		animateRows: true,
		pagination: true,
		paginationAutoPageSize: true,
	};

	tableName: string = 'lotti';
	itemPerPage: number = 14;
	pageNumber: number = 1;
	totalCount: number = 1;
	loader: boolean = false;
	Form: FormGroup;
	cqFormSubmitted: boolean = false;

	@ViewChild("PopupContainer", { read: TemplateRef })
	PopupContainer: TemplateRef<any>;
	view: any;
	currentYear: string = '';
	clickedData: any;
	centriCodiceAz: string | number;
	specie: string;
	anno: string | number;
	isAlive = true;
	subjectValue: boolean = false;
	lastCodiceAz: any;
	id: string;
	rowDataAperte$: any;


	constructor(public apiService: ApiService, public toasterService: ToastrService, private http: HttpClient,private route: ActivatedRoute,
		private viewContainerRef: ViewContainerRef, private formBuilder: FormBuilder, private modalService: ModalService, private renderer: Renderer2, private elementRef: ElementRef, private router: Router) {

		this.Form = this.formBuilder.group({
			ID_FORNIT: [null, Validators.required],
			ID_FOR: [null],
			DATA_RPREV: [null],
			DATA_APREV: [null],
			GG_CONSEG: [null, Validators.required],
			DISPONIBIL: [null],
			RANK_COSTO: [null],
			DATA_BDO: [null],
			OP_BDO: [null],
			NUM_BDO: [null],
			DATA_ORDIN: [null],
			DATA_RICEV: [null],
			OP_RICEV: [null],
			FORNIT_OK: [null],
			NUM_BDO_2: [null],
			DATA_RIMAN: [null],
			OP_RIMAN: [null],
			RIMAN_OK: [null],
			FOR_FINITA: [null],
			PUNTI: [null],
			FOR_NC: [null]

		})
		this.GridOptions.onSortChanged = this.onSortChanged.bind(this);
		this.id = this.route.snapshot.paramMap.get('id');
		this.Form.get('FOR_FINITA')?.valueChanges.subscribe(value => {
			this.calculatePunti();
		  });
	  
		  // Also listen for changes to other fields that affect PUNTI
		  this.Form.valueChanges.subscribe(() => {
			if (this.Form.get('FOR_FINITA')?.value === 'S' || this.Form.get('FOR_FINITA')?.value === 'N') {
			  this.calculatePunti();
			}
		  });
	}
	calculatePunti(): void {
		let punti = 0;
	
		const FOR_FINITA = this.Form.get('FOR_FINITA')?.value;
		const FOR_NC = this.Form.get('FOR_NC')?.value;
		const DATA_RPREV = this.Form.get('DATA_RPREV')?.value;
		const DISPONIBIL = this.Form.get('DISPONIBIL')?.value;
		const DATA_RICEV = this.Form.get('DATA_RICEV')?.value;
		const DATA_ORDIN = this.Form.get('DATA_ORDIN')?.value;
		const GG_CONSEG = this.Form.get('GG_CONSEG')?.value;
		const FORNIT_OK = this.Form.get('FORNIT_OK')?.value;
		const RIMAN_OK = this.Form.get('RIMAN_OK')?.value;
		const DATA_RIMAN = this.Form.get('DATA_RIMAN')?.value;
	
		if (FOR_FINITA === "S") {
			// console.log("FOR_FINITA is S");
			
			if (FOR_NC) {
			  punti = 0;
			//   console.log("FOR_NC is true, punti set to 0");
			} else {
			  punti += 1;
			//   console.log("Initial punti incremented to:", punti);
			}
		  
			  if (DATA_RPREV) {
				punti += 1;
				// console.log("DATA_RPREV is present, punti incremented by 1. Current punti:", punti);
			  }
		  
			  if (DISPONIBIL === "S") {
				punti += 0.5;
				// console.log("DISPONIBIL is S, punti incremented by 0.5. Current punti:", punti);
			  }
		  
			  if (DATA_RICEV && DATA_ORDIN) {
				const diffDays = moment(DATA_RICEV, "YYYY-MM-DD").diff(
				  moment(DATA_ORDIN, "YYYY-MM-DD"),
				  "days"
				);
				// console.log("DATA_RICEV and DATA_ORDIN are present. diffDays:", diffDays);
		  
				if (diffDays < 61) {
				  punti += 1;
				//   console.log("diffDays < 61, punti incremented by 1. Current punti:", punti);
				} else if (diffDays >= 61 && diffDays < 90) {
				  punti += 0.5;
				//   console.log("61 <= diffDays < 90, punti incremented by 0.5. Current punti:", punti);
				}
			  }
		  
			  if (GG_CONSEG && GG_CONSEG > 0 && moment(DATA_RICEV).diff(moment(DATA_ORDIN), "days") < GG_CONSEG) {
				punti += 1;
				// console.log("GG_CONSEG > 0 and DATA_RICEV < GG_CONSEG, punti incremented by 1. Current punti:", punti);
			  }
		  
			  if (FORNIT_OK === "S") {
				punti += 1;
				// console.log("FORNIT_OK is S, punti incremented by 1. Current punti:", punti);
			  }
		  
			  if (
				RIMAN_OK === "S" &&
				DATA_RIMAN &&
				DATA_ORDIN &&
				moment(DATA_RIMAN, "YYYY-MM-DD").diff(
				  moment(DATA_ORDIN, "YYYY-MM-DD"),
				  "days"
				) <= 90
			  ) {
				punti -= 1;
				// console.log("RIMAN_OK is S and DATA_RIMAN within 90 days, punti decremented by 1. Current punti:", punti);
			  }
			
		  } else if (FOR_FINITA === "N") {
			punti = 0;
			// console.log("FOR_FINITA is N, punti set to 0");
		  }
		  
		this.Form.get('PUNTI')?.setValue(punti);
	  }
	
	
	
	ngOnInit(): void {
		
		this.getTableData(this.id);
	}
	ngOnDestroy() {
		this.isAlive = false;
		this.apiService.redirectButton.next(false)
	}

	onSortChanged(event) {
		const sortedColumns: any = this.columnApi.getAllColumns().filter(column => column.getSort())[0];
		this.sortingOrder = sortedColumns?.getSort() ?? '';
		this.columnName = sortedColumns?.getColDef().field ?? '';
		this.getTableData();
	}

	onGridReady(params) {
		this.columnApi = params.columnApi;
	}

	onSubmitCqForm(){
		if (!this.changeButtun) {
			this.cqFormSubmitted = true;
			if (this.Form.invalid) {
				return;
			}
			let DATA_RPREV = this.formatDate(this.Form.value.DATA_RPREV)
			let DATA_APREV = this.formatDate(this.Form.value.DATA_APREV)
			let DATA_BDO = this.formatDate(this.Form.value.DATA_BDO)
			let DATA_ORDIN = this.formatDate(this.Form.value.DATA_ORDIN)
			let DATA_RICEV = this.formatDate(this.Form.value.DATA_RICEV)
			let DATA_RIMAN = this.formatDate(this.Form.value.DATA_RIMAN)

			this.apiService.createL30Vfor({ ...this.Form.value, DATA_RPREV: DATA_RPREV, DATA_APREV: DATA_APREV, DATA_BDO: DATA_BDO, DATA_ORDIN: DATA_ORDIN, DATA_RICEV: DATA_RICEV, DATA_RIMAN: DATA_RIMAN }).subscribe({
				next: (data: any) => {
					if (data.success) {
						this.toasterService.success(data.message);
						this.closePopup();
						this.getTableData();
					} else {
						this.toasterService.error(data.message);
					}
					this.loader = false
				},
				error: (err) => {
					this.toasterService.error(err.message)
				}
			});
		} else {
			this.cqFormSubmitted = true;
			if (this.Form.invalid) {
				return;
			}
			let DATA_RPREV = this.formatDate(this.Form.value.DATA_RPREV)
			let DATA_APREV = this.formatDate(this.Form.value.DATA_APREV)
			let DATA_BDO = this.formatDate(this.Form.value.DATA_BDO)
			let DATA_ORDIN = this.formatDate(this.Form.value.DATA_ORDIN)
			let DATA_RICEV = this.formatDate(this.Form.value.DATA_RICEV)
			let DATA_RIMAN = this.formatDate(this.Form.value.DATA_RIMAN)
			this.apiService.updateL30Vfor({ ...this.Form.value, id: this.clickedData.id, DATA_RPREV: DATA_RPREV, DATA_APREV: DATA_APREV, DATA_BDO: DATA_BDO, DATA_ORDIN: DATA_ORDIN, DATA_RICEV: DATA_RICEV, DATA_RIMAN: DATA_RIMAN }).subscribe({
				next: (data: any) => {
					if (data.success) {
						this.toasterService.success(data.description);
						this.closePopup();
						this.getTableData();
					} else {
						this.toasterService.error(data.description);
					}
					this.loader = false
				},
				error: (err) => {
					this.toasterService.error(err.description)
				}
			});
		}
	}

	getTableData(id?:any) {
		this.loader = true;
		this.apiService.getL30vFor(this.id).subscribe({
			next: (data) => {
				if (data.status) {
					this.totalCount = data?.result?.count;
					this.rowdata$ = data?.fornitureChiuse;
					this.rowDataAperte$ = data?.fornitureAperte
				} else {
					this.toasterService.error(data.message);
				}
				this.loader = false
			},
			error: (err) => {
				this.rowdata$ = [];
				this.loader = false
				this.toasterService.error(err.message)
			}
		});
	}

	get fc() { return this.Form.controls; }

	openCqFormPopup() {
		this.apiService.modaBackDrop.next(true);
		const bodyElement = this.elementRef.nativeElement.ownerDocument.body;
		this.renderer.setStyle(bodyElement, 'overflow', 'hidden');
		this.view = this.viewContainerRef.createEmbeddedView(this.PopupContainer);
		const domElem = this.view.rootNodes[0] as HTMLElement;
		domElem.classList.add("animate-in");
	}

	closePopup() {
		this.Form.reset();
		this.changeButtun = false
		const bodyElement = this.elementRef.nativeElement.ownerDocument.body;
		this.renderer.setStyle(bodyElement, 'overflow', 'auto');
		const domElem = this.view.rootNodes[0] as HTMLElement;
		domElem.classList.add("animate-out");
		domElem.addEventListener("click", (event: AnimationEvent) => {
			this.viewContainerRef.clear();
			this.apiService.modaBackDrop.next(false);
		});
	}
	onCellClicked(event: any) {
		this.changeButtun = true;
		this.clickedData = event.data;
		this.openCqFormPopup();
		const normalizeToUpperCase = (value: any) => (value && typeof value === 'string' ? value.toUpperCase() : value);
		this.Form.patchValue({
			ID_FORNIT: this.clickedData.ID_FORNIT,
			ID_FOR: this.clickedData.ID_FOR,
			DATA_RPREV: this.formatDate1(this.clickedData.DATA_RPREV),
			DATA_APREV: this.formatDate1(this.clickedData.DATA_APREV),
			GG_CONSEG: this.clickedData.GG_CONSEG,
			DISPONIBIL: normalizeToUpperCase(this.clickedData.DISPONIBIL),
			RANK_COSTO: this.clickedData.RANK_COSTO,
			DATA_BDO: this.formatDate1(this.clickedData.DATA_BDO),
			OP_BDO: this.clickedData.OP_BDO,
			NUM_BDO: this.clickedData.NUM_BDO,
			DATA_ORDIN: this.formatDate1(this.clickedData.DATA_ORDIN),
			DATA_RICEV: this.formatDate1(this.clickedData.DATA_RICEV),
			OP_RICEV: this.clickedData.OP_RICEV,
			FORNIT_OK:  normalizeToUpperCase(this.clickedData.FORNIT_OK),
			NUM_BDO_2: this.clickedData.NUM_BDO_2,
			DATA_RIMAN: this.formatDate1(this.clickedData.DATA_RIMAN),
			OP_RIMAN: this.clickedData.OP_RIMAN,
			RIMAN_OK: normalizeToUpperCase(this.clickedData.RIMAN_OK),
			FOR_FINITA: normalizeToUpperCase(this.clickedData.FOR_FINITA),
			PUNTI: this.clickedData.PUNTI,
			FOR_NC: this.clickedData.FOR_NC
		})
	}

	formatDate1(inputDate: string): string | null {
		if (inputDate != null) {
			const [day, month, year] = inputDate.split('/');
			const formattedDate = `${year}-${month}-${day}`;
			return formattedDate;
		}
		return null; // Return null if inputDate is null
	}
	formatDate(inputDate): any {
		if (inputDate != null) {
			const [year, month, day] = inputDate.split('-');
			const formattedDate = `${day}/${month}/${year}`;
			return formattedDate;
		}
	}

	dateComparator(date1: string | null, date2: string | null): number {
		// Check if either date is null or undefined
		if (!date1 || !date2) {
		  // You can decide what to return in case of null values
		  // For example, if date1 is null, consider it as smaller than any non-null date2
		  return date1 ? 1 : -1;
		}
	  
		const dateParts1 = date1.split('/');
		const dateParts2 = date2.split('/');
		
		// Create date objects in the format YYYY-MM-DD
		const d1 = new Date(Number(dateParts1[2]), Number(dateParts1[1]) - 1, Number(dateParts1[0]));
		const d2 = new Date(Number(dateParts2[2]), Number(dateParts2[1]) - 1, Number(dateParts2[0]));
		
		return d1.getTime() - d2.getTime();
	  }
}
