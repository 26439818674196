<!-- <app-loader *ngIf="loader"></app-loader> -->

<!-- <app-conform [message]="message" (confirm)="confirm($event)"></app-conform> -->
<div class="header d-flex mt-2">
    <h1 class="semi-bold">FORNITURE APERTE</h1>
    <div class="export">
        <button class="button1 mr-1" (click)="openCqFormPopup()">CREA</button>
    </div>
</div>
<div>
    <ag-grid-angular style="height: 750px;" class="ag-theme-alpine-dark basic-table" [rowData]="rowDataAperte$"
        [gridOptions]="GridOptions" (gridReady)="onGridReady($event)" (cellClicked)="onCellClicked($event)">
    </ag-grid-angular>
</div>

<div class="header d-flex mt-2">
    <h1 class="semi-bold">FORNITURE CHIUSE</h1>
    <!-- <div class="export">
        <button class="button1 mr-1" (click)="openCqFormPopup()">CREA</button>
    </div> -->
</div>
<div>
    <ag-grid-angular style="height: 750px;" class="ag-theme-alpine-dark basic-table" [rowData]="rowdata$"
        [gridOptions]="GridOptions" (gridReady)="onGridReady($event)" (cellClicked)="onCellClicked($event)">
    </ag-grid-angular>
</div>


<ng-template #PopupContainer let-options>
    <div class="popup-window animate-in popup-form p-1">
        <form [formGroup]="Form" (ngSubmit)="onSubmitCqForm()">
            <div class="d-flex gap-16">
                <div class="field w-100">
                    <label class="d-block">Fornitura ID:</label>
                    <input type="number" class="w-100" formControlName="ID_FORNIT" required [readOnly]="changeButtun" />
                    <div *ngIf="(cqFormSubmitted || fc['ID_FORNIT'].touched) && fc['ID_FORNIT'].errors" class="alert">
                        <div class="alert" *ngIf="(cqFormSubmitted || fc['ID_FORNIT'].touched)"> Please enter Fornitura
                            ID</div>
                    </div>
                </div>
                <div class="field w-100">
                    <label class="d-block">Fornitore ID:</label>
                    <input type="number" class="w-100" formControlName="ID_FOR" required [readOnly]="changeButtun" />
                    <div *ngIf="(cqFormSubmitted || fc['ID_FOR'].touched) && fc['ID_FOR'].errors" class="alert">
                        <div class="alert" *ngIf="(cqFormSubmitted || fc['ID_FOR'].touched)"> Please enter Fornitore
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex gap-16">
                <div class="field w-100">
                    <label class="d-block">Richiesta:</label>
                    <input type="date" class="w-100" formControlName="DATA_RPREV" />
                    <div *ngIf="(cqFormSubmitted || fc['DATA_RPREV'].touched) && fc['DATA_RPREV'].errors" class="alert">
                        <div class="alert" *ngIf="(cqFormSubmitted || fc['DATA_RPREV'].touched)"> Please enter
                            DATA_RPREV</div>
                    </div>
                </div>
                <div class="field w-100">
                    <label class="d-block">Preventivo:</label>
                    <input type="date" class="w-100" formControlName="DATA_APREV" />
                    <div *ngIf="(cqFormSubmitted || fc['DATA_APREV'].touched) && fc['DATA_APREV'].errors" class="alert">
                        <div class="alert" *ngIf="(cqFormSubmitted || fc['DATA_APREV'].touched)"> Please enter
                            DATA_APREV</div>
                    </div>
                </div>
            </div>
            <div class="d-flex gap-16">
                <div class="field w-100 field">
                    <label class="d-block">Consegna (gg):</label>
                    <input type="number" class="w-100" formControlName="GG_CONSEG">
                    <!-- <select class="w-100" formControlName="GG_CONSEG">
						<option value="S">S</option>
						<option value="N">N</option>
					</select> -->
                    <div *ngIf="(cqFormSubmitted || fc['GG_CONSEG'].touched) && fc['GG_CONSEG'].errors" class="alert">
                        <div class="alert" *ngIf="(cqFormSubmitted || fc['GG_CONSEG'].touched)"> Please enter Consegna</div>
                    </div> 
                </div>
                <div class="field w-100">
                    <label class="d-block">Dispon.(S/N):</label>
                    <select class="w-100" formControlName="DISPONIBIL">
                        <option value="S">S</option>
                        <option value="N">N</option>
                    </select>
                </div>
            </div>
            <div class="d-flex gap-16">
                <div class="field w-100">
                    <label class="d-block">Rank:</label>
                    <input type="number" class="w-100" formControlName="RANK_COSTO" />
                </div>
                <div class="field w-100">
                    <label class="d-block">BDO:</label>
                    <input type="date" class="w-100" formControlName="DATA_BDO" />
                </div>
            </div>
            <div class="d-flex gap-16">
                <div class="field w-100">
                    <label class="d-block">OP BDO:</label>
                    <input type="number" class="w-100" formControlName="OP_BDO" />
                </div>
                <div class="field w-100">
                    <label class="d-block">Num BDO:</label>
                    <input type="text" class="w-100" formControlName="NUM_BDO" />
                </div>
            </div>
            <div class="d-flex gap-16">
                <div class="field w-100">
                    <label class="d-block">Ordine:</label>
                    <input type="date" class="w-100" formControlName="DATA_ORDIN" />
                </div>
                <div class="field w-100">
                    <label class="d-block">Ricevimento:</label>
                    <input type="date" class="w-100" formControlName="DATA_RICEV" />
                </div>
            </div>
            <div class="d-flex gap-16">
                <div class="field w-100">
                    <label class="d-block">OP Ricevimento:</label>
                    <input type="number" class="w-100" formControlName="OP_RICEV" />
                </div>
                <div class="field w-100">
                    <label class="d-block">Merce OK:</label>
                    <select class="w-100" formControlName="FORNIT_OK">
                        <option value="S">S</option>
                        <option value="N">N</option>
                    </select>
                    <!-- <input type="tel" class="w-100" formControlName="FORNIT_OK" /> -->
                </div>
            </div>
            <div class="d-flex gap-16">
                <div class="field w-100">
                    <label class="d-block">Num BDO 2:</label>
                    <input type="text" class="w-100" formControlName="NUM_BDO_2" />
                </div>
                <div class="field w-100">
                    <label class="d-block">Rimanenza:</label>
                    <input type="date" class="w-100" formControlName="DATA_RIMAN" />
                </div>
            </div>
            <div class="d-flex gap-16">
                <div class="field w-100">
                    <label class="d-block">OP Rimanenza:</label>
                    <input type="number" class="w-100" formControlName="OP_RIMAN" />
                </div>
                <div class="field w-100">
                    <label class="d-block">Merce OK Rimanenza:</label>
                    <select class="w-100" formControlName="RIMAN_OK">
                        <option value="S">S</option>
                        <option value="N">N</option>
                    </select>
                </div>
            </div>
            <div class="d-flex gap-16">
                <div class="field w-100">
                    <label class="d-block">Completata:</label>
                    <!-- <input type="number" class="w-100" formControlName="FOR_FINITA" /> -->
                    <select class="w-100" formControlName="FOR_FINITA">
                        <option value="S">S</option>
                        <option value="N">N</option>
                    </select>
                </div>
                <div class="field w-100">
                    <label class="d-block">Punti:</label>
                    <input type="number" class="w-100" formControlName="PUNTI" [readOnly]="changeButtun" />
                </div>
            </div>
            <div class="d-flex gap-16">
                <div class="field w-100">
                    <label class="d-block">NCP:</label>
                    <input type="number" class="w-50" formControlName="FOR_NC" />
                </div>
            </div>


            <div class="main-footer">
                <div class="delete">
                    <!-- <i class="fa fa-trash customtrash" aria-hidden="true" (click)="openmodel()"></i> -->
                </div>
                <div class="popup-footer">
                    <button class="button1 mr-1" type="submit">{{changeButtun?'Aggiorna':'Salva'}}</button>
                    <button class="button1" (click)="closePopup()" type="button">ANNULLA</button>
                </div>
            </div>
        </form>
    </div>
</ng-template>