<!-- <div class="container">
    <div class="container-table">
        <div style=" overflow: hidden; flex: 1">
            <div #myGrid id="myGrid" style="width: 100%; height: 100%;" class="ag-theme-alpine-dark">
            </div>
        </div>
    </div>
</div> -->
<div style="height: 100%; width: 100%; display: flex; flex-direction: column">
    <div style="flex: 1 1 0px">
        <div id="myGrid" style="height: 100%" class="ag-theme-alpine">
        </div>
    </div>
</div>