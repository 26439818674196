import { Component } from '@angular/core';
import { ApiService } from '../_services/api.service';
import { take, takeWhile } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-statistics-overview',
  templateUrl: './statistics-overview.component.html',
  styleUrls: ['./statistics-overview.component.scss']
})
export class StatisticsOverviewComponent {
  loader: boolean = false;
  cards: any = []
  fagfilename: any;
  spaciesData: any[] = [
    { value: null, name: "Scegli Specie" },
    { value: "B", name: "B - Bovini" },
    { value: "BI", name: "BI - Bovini Importazione" },
    { value: "E", name: "E - Equini" },
    { value: "EI", name: "EI - Equini Importazione" },
    { value: "S", name: "S - Suini" },
    { value: "C", name: "C - Caprini" },
    { value: "CI", name: "CI - Caprini Importazione" },
    { value: "BU", name: "BU - Bufali" },
    { value: "O", name: "O - Ovini" },
  ]
  specie: any;
  loader2: boolean = true
  selectedData: any;
  centriCodiceAz: any;
  anna: string | number;
  isAlive = true;
  constructor(private apiService: ApiService, private http: HttpClient, private toastr: ToastrService) {

  }
  ngOnInit(): void {
    this.centriCodiceAz = localStorage.getItem('centriCodiceAz') ? localStorage.getItem('centriCodiceAz') : 1
    this.specie = localStorage.getItem('specie') ? localStorage.getItem('specie') : "BI"
    this.anna = localStorage.getItem('anna') ? localStorage.getItem('anna') : 2022
    this.topbar()

    // this.getSpecieValue()
  }

  ngOnDestroy() {
    this.isAlive = false;
  }
  topbar() {
    this.apiService.current.pipe(takeWhile(() => this.isAlive)).subscribe((res) => {
      if (res) {
        this.centriCodiceAz = res.codice_az ? res.codice_az : localStorage.getItem('centriCodiceAz')
        this.specie = res.specie ? res.specie : localStorage.getItem('specie');
        this.anna = res.anna ? res.anna : localStorage.getItem('anna');
        this.statsCalculation()
      }
    })
    this.statsCalculation()
  }
  getSpecieValue() {
    this.apiService.getSpecieValue().subscribe((res: any) => {
      this.spaciesData = res.result
      this.spaciesData.filter((res) => {
      })
    })
  }
  statsCalculation() {
    this.loader = true
    let payload = {
      centriCodiceAz: this.centriCodiceAz,
      anno: this.anna,
      specie: this.specie
    }
    this.apiService.Statscal(payload).subscribe((res: any) => {
      if (res?.success) {
        this.cards = res.result
        this.apiService.current?.next(null);
        this.loader = false;
      }
    })
  }

  downloadTextFile(url: string): void {
    this.http.get(url, { responseType: 'text' }).subscribe((data: string) => {
      const blob = new Blob([data], { type: 'text/plain' });
      const anchor = document.createElement('a');
      anchor.href = window.URL.createObjectURL(blob);
      anchor.download = this.fagfilename;
      document.body.appendChild(anchor);
      anchor.click();
      window.URL.revokeObjectURL(anchor.href);
      document.body.removeChild(anchor);
    });
  }

  downloadPDF(url: string): void {
    this.http.get(url, { responseType: 'blob' }).subscribe((data: Blob) => {
      const blob = new Blob([data], { type: 'application/pdf' });
      const anchor = document.createElement('a');
      anchor.href = window.URL.createObjectURL(blob);
      anchor.download = this.fagfilename; // The filename for the PDF
      document.body.appendChild(anchor);
      anchor.click();
      window.URL.revokeObjectURL(anchor.href);
      document.body.removeChild(anchor);
    });
  }

  generateFrequency() {
    this.apiService?.generateFrequency().subscribe({
      next: (res: any) => {
        if (res) {
          if (res.status) {
            this.toastr.success(res.message);
            let url = environment.url + res.filePath;
            this.fagfilename = res.fileName
            if (res.user_type === 'DNE')
              this.downloadTextFile(url);
            else
              this.downloadPDF(url);
          } else {
            this.toastr.error(res.message);
          }
        }
      },
      error: (err) => {
        this.loader = false;
        this.toastr.error('An error occurred');
      }
    });
    // this.loader = true;
    // let payload = {};
    // this.http.generateFrequency(payload).subscribe((res:any)=>{
    //   this.loader = false;
    //   if(res.success){
    //     console.log(res);
    //   }
    // })
  }

  // receiveDataFromChild(event){
  //   this.loader = true;
  //   this.specie = event
  //   let payload = {
  //     centriCodiceAz:this.centriCodiceAz,
  //     anno: this.selectedData,
  //     specie: this.specie
  //   }
  //   this.http.Statscal(payload).subscribe((res:any)=>{
  //     if(res.success){
  //       this.loader = false
  //       this.cards = res.result
  //     }
  //   })
  // }
  // recevedate(event){
  //   this.loader = true
  //   this.selectedData = event
  //   let payload = {
  //     centriCodiceAz:this.centriCodiceAz,
  //     anno: this.selectedData,
  //     specie: this.specie
  //   }
  //   this.http.Statscal(payload).subscribe((res:any)=>{
  //     if(res.success){
  //       this.loader = false
  //       this.cards = res.result
  //     }
  //   })
  // }
  transform(value: any): string {
    if (typeof value === 'string') {
      // If the input is a string, parse it to a float first
      value = parseFloat(value);
    }

    if (typeof value === 'number' && !isNaN(value)) {
      return value.toFixed(1);
    } else {
      return ''; // Handle invalid input, or return any default value you prefer
    }
  }

  genrateReferto() {
    let anno = localStorage.getItem('anna');
    let codiceAz = localStorage.getItem('centriCodiceAz');
    this.apiService?.generatetextRefer(anno, codiceAz).subscribe({
      next: (res: any) => {
        if (res) {
          if (res.status) {
            this.toastr.success(res.message);
            let url = environment.url + res.filePath;
            this.fagfilename = res.fileName
            if (res.user_type === 'DNE')
              this.downloadTextFile(url);
            else
              this.downloadPDF(url);
            this.downloadPDF(url);
          } else {
            this.toastr.error(res.message);
          }
        }
      },
      error: (err) => {
        this.loader = false;
        this.toastr.error('An error occurred');
      }
    });
  }
}



