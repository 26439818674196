<div class="top-bar">
    <div class="flex">
       <app-mobile-sidebar></app-mobile-sidebar>
    </div>
    <h2 class="flex text-center text-black">Spallanzani</h2>
    <div class="top-btns flex text-right">
        <div id="nav-profile-section" class="nav-section mr-1 d-inline-block" (click)="toggleNotification($event)"
            *ngIf="notificationValue">
            <!-- <i class="fa-regular fa-user"></i> -->
            <i class="fa fa-bell fa-shake" aria-hidden="true"></i>

            <ul id="notificarions" *ngIf="notificationToggle">
                <!-- <h4>Notifications</h4>
                    <hr> -->
                <!-- <div *ngFor="let item of notificationValue" class="notifcation">
                        <p class="notifcationmessage" (click)="notification(notificationValue.table)">{{item.message}}</p>
                        <hr>
                    </div>
                    <div *ngFor="let item of yellownotification" class="notifcation">
                        <p class="notifcationmessage" (click)="notification(notificationValue.table)">{{item.message}}</p>
                    </div> -->


                <ul class="notificationsbtn nav navbar-nav navbar-right">
                    <li id="notificationsli">

                        <div id="notification-container" class="dropdown-menu" role="menu" aria-labelledby="drop3">

                            <section class="panel">
                                <header class="panel-heading">
                                    <strong>Notifications</strong>
                                </header>
                                <div id="notification-list" class="list-group list-group-alt"
                                    *ngFor="let item of notificationValue">

                                    <div>
                                        <div class="noty-manager-list-item noty-manager-list-item-error">
                                            <div class="activity-item" (click)="notification()"> {{item.message}} </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="notification-list" class="list-group list-group-alt"
                                    *ngFor="let item of yellownotification">

                                    <div>
                                        <div class="noty-manager-list-item noty-manager-list-item-error">
                                            <div class="activity-item" (click)="notification()"> {{item.message}} </div>
                                        </div>
                                    </div>
                                </div>
                            </section>

                        </div>
                    </li>
                </ul>

                <!-- <li (click)="themeToggle()">
                        <i class="fa-solid" [ngClass]="darkMode ? 'fa-sun' : 'fa-moon'" id="themetoggle"></i>
                    </li>
                    <li>
                        <a routerLink="/setting">
                            <i class="fa-solid fa-gear"></i>
                        </a>
                    </li>
                    <li (click)="logout()">
                        <i class="fa-solid fa-right-from-bracket" id="logout"></i>
                    </li> -->
            </ul>
        </div>
        <div id="nav-profile-section" class="nav-section f d-inline-block" (click)="toggleProfile($event)">
            <i class="fa-regular fa-user"></i>
            <ul id="profile" *ngIf="profileToggle">
                <li (click)="themeToggle()">
                    <i class="fa-solid" [ngClass]="darkMode ? 'fa-sun' : 'fa-moon'" id="themetoggle"></i>
                </li>
                <li>
                    <a routerLink="/setting">
                        <!-- <i class="fa fa-key" aria-hidden="true"></i> -->
                        <i class="fa-solid fa-gear"></i>
                    </a>
                </li>
                <li (click)="logout()">
                    <i class="fa-solid fa-right-from-bracket" id="logout"></i>
                </li>
            </ul>
        </div>
    </div>
</div>
<div class="menu-bar">
    <div class="link-wrapper">
        <a routerLink="/dashboard" [routerLinkActive]="['active']">
            DASHBOARD
        </a>
    </div>
    <div class="link-wrapper" *ngIf="allUserpermision.includes('IMPORTA-LISTATI')">
        <a routerLink="/importa-listati" [routerLinkActive]="['active']">
            IMPORTA LISTATI
        </a>
    </div>
    <div class="link-wrapper" *ngIf="allUserpermision.includes('SAMPLING MANAGEMENT')">
        <a routerLink="/campionamenti" [routerLinkActive]="['active']">
            CAMPIONAMENTI
        </a>
    </div>
    <div class="link-wrapper" *ngIf="allUserpermision.includes('ANIMALS MANAGEMENT')">
        <a routerLink="/statistiche" [routerLinkActive]="['active']">
            RIPRODUTTORI
        </a>

    </div>
    <div class="link-wrapper" *ngIf="allUserpermision.includes('STATISTICS')">
        <a routerLink="/statistics-overview" [routerLinkActive]="['active']">
            STATISTICHE
        </a>
    </div>
    <div class="link-wrapper" *ngIf="allUserpermision.includes('LAB INSTRUMENT STATUS')">
        <a routerLink="/dna-table" [routerLinkActive]="['active']">
            STRUMENTAZIONE
            <small class="badge">{{notificationCount}} </small>
        </a>
    </div>
    <div class="link-wrapper" *ngIf="allUserpermision.includes('COURIER MANAGEMENTS')">
        <a routerLink="/courier-management" [routerLinkActive]="['active']">
            GESTIONE CORRIERE
        </a>
    </div>
    <div class="link-wrapper" *ngIf="allUserpermision.includes('DNA REFERENCES (BIOBANK)')">
        <a routerLink="/new-dna-ref" [routerLinkActive]="['active']">
            GENOTIPO DI RIFERIMENTO
        </a>
    </div>
    <div class="link-wrapper" *ngIf="allUserpermision.includes('WORKSHEETS AND SAME DNA')">
        <a routerLink="/worksheet-dna" [routerLinkActive]="['active']">
            PRE-ANALISI e ANALISI
        </a>
    </div>
    <div class="link-wrapper" *ngIf="allUserpermision.includes('L30_QS')">
        <a routerLink="/l30qs" [routerLinkActive]="['active']">
            QUALITÀ SEME
        </a>
    </div>
    <div class="link-wrapper" *ngIf="allUserpermision.includes('L30_DNA')">
        <a routerLink="/l30dna" [routerLinkActive]="['active']">
            L30 VCI
        </a>
    </div>
    <div class="link-wrapper" *ngIf="allUserpermision.includes('MAGGAZINO')">
        <a routerLink="/magazzino" [routerLinkActive]="['active']">
            CRIOBANCA
        </a>
    </div>
    <div class="link-wrapper">
        <a routerLink="/archivio" [routerLinkActive]="['active']">
            ARCHIVIO
        </a>
    </div>
    <div class="link-wrapper" *ngIf="allUserpermision.includes('AUTOCERTIFICAZIONI')">
        <a routerLink="/autocertifizion" [routerLinkActive]="['active']">
            AUTOCERTIFICAZIONI
        </a>
    </div>
    <div class="link-wrapper mb-0" *ngIf="allUserpermision.includes('CQ')">
        <a routerLink="/controllo-interno" [routerLinkActive]="['active']">
            CONTROLLO DI QUALITÀ INTERNO
        </a>
    </div>
    <div class="link-wrapper mb-0" *ngIf="allUserpermision.includes('L30Lotti')">
        <a routerLink="/lotti-section" [routerLinkActive]="['active']">
            LOTTI 
        </a>
    </div>
    <div class="link-wrapper mb-0" >
        <a routerLink="/centri-section" [routerLinkActive]="['active']">
            CENTRI 
        </a>
    </div>
    <div class="link-wrapper mb-0" >
        <a routerLink="/fornitori-section" [routerLinkActive]="['active']">
            FORNITORI 
        </a>
    </div>
    <!-- <div class="link-wrapper mb-0" >
        <a routerLink="/fornituri-section" [routerLinkActive]="['active']">
            FORNITURE 
        </a>
    </div> -->
</div>
<nav class="horizontal-nav">
    <div id="nav-logo-section" class="nav-section">
        <app-speciedetail [year]="true"></app-speciedetail>
    </div>
    <div id="nav-search-section" class="nav-section input-div">
        <div id="nav-content-section" class="nav-section1 ">
            <div id="centro" class="nav-centro">
                <h3 id="centro-nome">{{ selectedText || user.Denominazione }}</h3>
                <h6 id="centro-indirizzo">{{ centroIndirizzo || user.Indirizzo }}</h6>
            </div>
        </div>
        <label class="semi-bold">SELEZIONE CENTRO</label>
        <div class="position-relative">
            <input id="search-centro" type="text" [value]="selectedText" (focusin)="focusIn($event)"
                (input)="onSearchText()" [(ngModel)]="searchText" (focusout)="focusOut($event)" />
            <i class="fa-solid fa-magnifying-glass"></i>
        </div>

        <div *ngIf="boolShowInputText" class="fuzzy-result">
            <div id="fuzzy-result-number">
                <h6>Risultati {{ result.length }}</h6>
            </div>

            <div *ngFor="let centro of result | slice : 0 : 10" (mousedown)="selectCentro(centro.Id, centro)">
                <h3>{{ centro.denominaz }}</h3>
                <h6>{{ centro.Indirizzo }}</h6>
            </div>
        </div>
    </div>
</nav>
<div class="whole-page" (click)="closeOpenStuff()">

    <div class="inner-page" (scroll)="handleScroll($event)">
        <!-- <nav class="vertical-nav">
            <div id="vert-nav-dashboard" class="vert-nav-section">
                <ul>
                    <li>
                        <a routerLink="/dashboard">
                            <i class="fa-solid fa-house fa-md"></i>
                        </a>
                        <span class="tooltip">DASHBOARD</span>
                    </li>
                    <li *ngIf="allUserpermision.includes('IMPORTA-LISTATI')">
                        <a routerLink="/importa-listati">
                            <i class="fa-solid fa-file-import fa-md"></i>
                        </a>
                        <span class="tooltip">IMPORTA LISTATI</span>
                    </li>
                    <li *ngIf="allUserpermision.includes('SAMPLING MANAGEMENT')">
                        <a routerLink="/campionamenti">
                            <i class="fa-solid fa-flask-vial fa-md"></i>
                        </a>
                        <span class="tooltip">CAMPIONAMENTI</span>
                    </li>
                    <li *ngIf="allUserpermision.includes('ANIMALS MANAGEMENT')">
                        <a routerLink="/statistiche">
                            <i class="fa-solid fa-cow fa-md"></i>
                        </a>
                        <span class="tooltip">RIPRODUTTORI</span>

                    </li>
                    <li *ngIf="allUserpermision.includes('STATISTICS')">
                        <a routerLink="/statistics-overview">
                            <i class="fas fa-chart-pie"></i>
                        </a>
                        <span class="tooltip">STATISTICHE OVERVIEW</span>
                    </li>
                    <li *ngIf="allUserpermision.includes('LAB INSTRUMENT STATUS')">
                        <a routerLink="/dna-table">
                            <i class="fas fa-table"></i>
                        </a>
                        <span class="tooltip">STRUMENTAZIONE</span>
                    </li>
                    <li *ngIf="allUserpermision.includes('COURIER MANAGEMENTS')">
                        <a routerLink="/courier-management">
                            <i class="fas fa-shipping-fast"></i>
                        </a>
                        <span class="tooltip">GESTIONE CORRIERE</span>
                    </li>
                    <li *ngIf="allUserpermision.includes('DNA REFERENCES (BIOBANK)')">
                        <a routerLink="/new-dna-ref">
                            <i class="fa-solid fa-pills"></i>
                        </a>
                        <span class="tooltip">Genotipo di riferimento</span>
                    </li>
                    <li *ngIf="allUserpermision.includes('WORKSHEETS AND SAME DNA')">
                        <a routerLink="/worksheet-dna">
                            <i class="fa-solid fa-sheet-plastic"></i>
                        </a>
                        <span class="tooltip">PRE-ANALISI e ANALISI</span>
                    </li>
                    <li *ngIf="allUserpermision.includes('L30_QS')">
                        <a routerLink="/l30qs">
                            <i class="fas fa-vial"></i>
                        </a>
                        <span class="tooltip">QUALITÀ SEME</span>
                    </li>
                    <li *ngIf="allUserpermision.includes('L30_DNA')">
                        <a routerLink="/l30dna">
                            <i class="fa fa-window-maximize"></i>
                        </a>
                        <span class="tooltip">L30 VCI</span>
                    </li>
                    <li *ngIf="allUserpermision.includes('MAGGAZINO')">
                        <a routerLink="/magazzino">
                            <i class="fa fa-thermometer-quarter"></i>
                        </a>
                        <span class="tooltip">CRIOBANCA</span>
                    </li>
                    <li>
                        <a routerLink="/archivio">
                            <i class="fa-solid fa-database"></i>
                        </a>
                        <span class="tooltip">ARCHIVIO</span>
                    </li>
                    <li *ngIf="allUserpermision.includes('AUTOCERTIFICAZIONI')">
                        <a routerLink="/autocertifizion">
                            <i class="fa-brands fa-figma"></i>
                        </a>
                        <span class="tooltip">AUTOCERTIFICAZIONI</span>
                    </li>
                </ul>
            </div>
        </nav> -->
        <div class="main-container">
            <router-outlet centroId="centroId" style="display: flex; flex-direction: column"></router-outlet>
            <div class="modal-dropbox" *ngIf="isModalBackDrop"></div>
        </div>
    </div>
</div>