import { Component, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { NewDNA, Razza1 } from '../_models';
import { CellClickedEvent, ColDef, ColumnApi, GridApi, GridOptions } from "ag-grid-community";
import { CustomButtonRendererComponent } from '../table-test/custom-button-renderer.component';
import { ApiService } from '../_services/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ModalService } from '../_services/modal.service';
import { take, takeWhile } from 'rxjs';

@Component({
  selector: 'app-l30dna-table',
  templateUrl: './l30dna-table.component.html',
  styleUrls: ['./l30dna-table.component.scss']
})
export class L30dnaTableComponent {
  showModal = false;
  centriCodiceAz: string | number;
	specie: string;
	anno: string | number;
  loader: boolean = false;
  rowData$:any[];
  clickedRowData: any;
	subjectValue: boolean = false;
  years: number[] = [];
  columnName: string = 'cod_dna';
  filterValue: string = ''
  sortingOrder: string = '';
  col: any = 0;
  addL30Qs: FormGroup;
  view: any;
  isAlive = true;
  @ViewChild("popupContainer", { read: TemplateRef })
  popupContainer: TemplateRef<any>;
  tableName: string = 'l30_vci';
  formSubmitted:boolean = false;
  defaultColDef: ColDef = {
    resizable: true,
    sortable: true,
    filter: true,
  };


  colDefs: ColDef[] = [
    { field: "cod_dna", headerName: "cod_dna" , width:150,comparator: this.customSortFunction.bind(this)},
    { 
      field: "cod_seme", 
      headerName: "cod_seme" , 
      width:150,
      valueParser: (params) => Number(params.data.cod_seme),
      comparator: (valueA, valueB) => Number(valueA) - Number(valueB),
      filter: 'agNumberColumnFilter' 
    },
    { field: "sequenziat", headerName: "sequenziat" , width:150},
    { field: "microstat", headerName: "microstat" , width:150},
    { 
      field: "al1_seme", 
      headerName: "al1_seme", 
      width:150 ,
      valueParser: (params) => Number(params.data.al1_seme),
      comparator: (valueA, valueB) => Number(valueA) - Number(valueB),
      filter: 'agNumberColumnFilter',
      cellRenderer: (params: any) => { return `${params?.data?.al1_seme == null?'N/A':params?.data?.al1_seme}`}
    },
    { 
      field: "al2_seme", 
      headerName: "al2_seme", 
      width:150,
      valueParser: (params) => Number(params.data.al2_seme),
      comparator: (valueA, valueB) => Number(valueA) - Number(valueB),
      filter: 'agNumberColumnFilter',
    },
    { 
      field: "dif_al1", 
      headerName: "dif_al1" , 
      width:150,
      valueParser: (params) => Number(params.data.dif_al1),
      comparator: (valueA, valueB) => Number(valueA) - Number(valueB),
      filter: 'agNumberColumnFilter',
    },
    { 
      field: "dif_al2", 
      headerName: "dif_al2" , 
      width:180,
      valueParser: (params) => Number(params.data.dif_al2),
      comparator: (valueA, valueB) => Number(valueA) - Number(valueB),
      filter: 'agNumberColumnFilter',
    },
    { field: "vci", headerName: "vci" , width:200},
    { field: "codice_cam", headerName: "codice_cam" , width:200},
    { field: "num_cam", headerName: "num_cam" , width:200},
    // { field: "a", headerName: "a" , width:150},
    // { field: "b", headerName: "b" , width:150},
    // { field: "c", headerName: "c" , width:150},
    // { field: "d", headerName: "d" , width:150},
    // {
    //   field: "Action",
    //   headerName: "AZIONE",
    //   // flex: 1,
    //   width:150,
    //   cellRenderer: 'customButtonRenderer',
    //   cellRendererParams: {
    //     onClick: this.onButtonClicked.bind(this),
    //     tableName: 'newdnadata',
    //   },
    // },
  ];

  api: GridApi;
  columnApi: ColumnApi;
  changeButtun: boolean = false;
  itemPerPage: number = 15;
  pageNumber: number = 1;
  totalCount: number = 1;
  deleteId: any;
  selectYears: any;
  newCodiceLab1: string;
  calc: any;
  lastcodiccam: any;
  selectedFile: File;
  constructor(
    public apiService: ApiService,
    public viewContainerRef: ViewContainerRef,
    public formBuilder: FormBuilder,
    public toastr: ToastrService,
    public modalService: ModalService
  ) {
    this.newDNAForm();
    this.deleteRow();
    this.apiService.current.pipe(takeWhile(() => this.isAlive)).subscribe((res) => {
			if (res) {
				this.subjectValue = true
				this.centriCodiceAz = res.codice_az ? res.codice_az : localStorage.getItem('centriCodiceAz')
				this.specie = res.specie ? res.specie : localStorage.getItem('specie');
				this.anno = res.anna ? res.anna : localStorage.getItem('anna')
				this.tableData();
			}
		})
    this.gridOptions.onSortChanged = this.onSortChanged.bind(this);

  }

  ngOnInit() {
    this.yearselect()
    // this.apiService.current.pipe(takeWhile(() => this.isAlive)).subscribe((res) => {
		// 	if (res) {
		// 		this.subjectValue = true
		// 		this.centriCodiceAz = res.codice_az ? res.codice_az : localStorage.getItem('centriCodiceAz')
		// 		this.specie = res.specie ? res.specie : localStorage.getItem('specie');
		// 		this.anno = res.anna ? res.anna : localStorage.getItem('anna')
		// 		this.tableData();
		// 	}
		// })
    
    this.tableData();
  }

  gridOptions: GridOptions<any> = {
    onFilterChanged: this.onFilterChanged.bind(this),
    columnDefs: this.colDefs,
    defaultColDef: this.defaultColDef,
    rowSelection: "single",
    animateRows: true,
    // pagination: true,
    // paginationAutoPageSize: true,
    frameworkComponents: { customButtonRenderer: CustomButtonRendererComponent },
    onCellClicked: (event: CellClickedEvent) => {
      this.clickedRowData = event?.data
      this.patchDNAForm();
      this.openPopup();
      this.changeButtun = true;
    },
  };
  customSortFunction(valueA: any, valueB: any, nodeA: any, nodeB: any, isInverted: boolean): number {
    // Convert values to numbers for comparison
    const numA = Number(valueA);
    const numB = Number(valueB);
    
    // Perform custom comparison based on ascending order
    if (numA < numB) return -1;
    if (numA > numB) return 1;
    return 0;
}
  onGridReady(params) {
    this.api = params.api;
    this.columnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  }

  onSortChanged(event) {
    if(this.col == 0){
    this.loader = true
    const sortedColumns:any = this.columnApi.getAllColumns().filter(column => column.getSort())[0];
    this.sortingOrder = sortedColumns?.getSort() ?? '';
    this.columnName = sortedColumns?.getColDef().field ?? '';
    this.tableData();
    this.loader = false
    }
  }
  selectSpecie(event) {
    this.specie = event?.target?.value;
    // localStorage.setItem('specie',this.specie)
    // this.apiService.current.next({specie:this.specie, anna:this.selectYears });
    // this.dataToParent.emit(this.specie)
    // this.razzeData = this.razze.filter((sp: any) => sp?.specie === this.specie);
  }
  
  selectYear(event){
     this.selectYears = event.target.value

  }
  yearselect() {
    const currentYear = new Date().getFullYear();
		for (let i = 0; i < 4; i++) {
		  this.years.push(currentYear - i);
		}
  }

  onButtonClicked(data: any) {
    this.loader = true;
    this.apiService.deletel30Dna(data?.id).subscribe((res: any) => {
      if(res){
        this.closePopup()
        this.tableData();
        this.loader = false;
        this.toastr.success(res.description);
      }
    });
  }

  tableData() {
    this.loader = true

    // if(!this.subjectValue){
    //   this.centriCodiceAz = localStorage.getItem('centriCodiceAz') ? localStorage.getItem('centriCodiceAz') : 1
    //   this.specie = localStorage.getItem('specie') ? localStorage.getItem('specie') : "BI"
    //   this.anno = localStorage.getItem('anna') ? localStorage.getItem('anna') : 2022
    // }
    // this.subjectValue = false
    this.apiService.getl30dnaData(this.pageNumber,this.itemPerPage,this.columnName,this.filterValue, this.sortingOrder).subscribe({
      next: (res) => {
        this.rowData$ = res?.result?.rows;
        this.totalCount = res?.result?.count;
        this.loader = false

      },
      error: (error) => {
        this.loader = false
      },
      complete: () => {
        this.loader = false
        
       }
    });
  }

  openPopup() {
    this.apiService.modaBackDrop.next(true);
    this.view = this.viewContainerRef.createEmbeddedView(this.popupContainer);
    const domElem = this.view.rootNodes[0] as HTMLElement;
    domElem.classList.add("animate-in");
  }

  patchDNAForm() {

    this.addL30Qs.patchValue({
      // id: this.clickedRowData.id,
      cod_dna: this.clickedRowData.cod_dna,
      cod_seme: this.clickedRowData.cod_seme,
      sequenziat: this.clickedRowData.sequenziat,
      microstat: this.clickedRowData.microstat,
      al1_seme: this.clickedRowData.al1_seme,
      al2_seme: this.clickedRowData.al2_seme,
      dif_al1:this.clickedRowData.dif_al1,
      dif_al2: this.clickedRowData.dif_al2,
      vci: this.clickedRowData.vci,
      cod_cam: this.clickedRowData.cod_cam,
      num_cam: this.clickedRowData.num_cam,
      a: this.clickedRowData.a,
      b: this.clickedRowData.b,
      c: this.clickedRowData.c,
      d: this.clickedRowData.d,
    
    });

  }

  newDNAForm() {
    this.addL30Qs = this.formBuilder.group({
      cod_dna: [null],
      cod_seme: [null],
      sequenziat: [null],
      myfile:[null],
      microstat: [null],
      al1_seme: [null],
      al2_seme: [null],
      dif_al1:[null],
      dif_al2:[null],
      vci:[null],
      cod_cam:[null],
      pcr:[null],
      num_cam:[null],
      a:[null],
      b:[null],
      c:[null],
      d:[null],
      num_cam_dropdownValue:['1']
    });
  }

  deleteRow() {
		this.apiService.delete.subscribe((res) => {
      this.deleteId = res.data
			if ((res?.data?.id) && (res?.table === 'newdnadata')) {
				this.modalService.openModal('newdnadata');
			}
		})
  }
	openmodel(){
		this.modalService.openModal('newdnadata');
	}
  confirm(event){
		// if (event?.modal == "newdnadata" && event?.value) {
			this.onButtonClicked(this.clickedRowData);
		// }

	}

  receiveData(event: any) {
    if (event?.table === this.tableName) {
      this.rowData$ = event?.data?.result?.rows;
      this.totalCount = event?.data?.result?.count;
      this.pageNumber = event?.page
    }
  }
  get fc(){ return this.addL30Qs.controls; }
  
  onSubmit() {
    this.loader = true;
    this.formSubmitted = true;
    if (this.addL30Qs.invalid) {
      this.loader = false;
      return
    } else {
      const payload = this.addL30Qs.value;
      if (this.changeButtun) {
        this.updateRecord({ id: this.clickedRowData.id, ...payload , num_cam_dropdownValue:this.addL30Qs.value.num_cam_dropdownValue});
        this.formSubmitted = false;
      } else {
        const formData = new FormData();
        formData.append('file', this.selectedFile);
        formData.append('sequenziat', this.addL30Qs.value.sequenziat);
        formData.append('isUpdateData', 'false');
        formData.append('num_cam_dropdownValue',this.addL30Qs.value.num_cam_dropdownValue);
        this.createRecord(formData);
        this.formSubmitted = false;
      }
    }
  }
 
  createRecord(payload: any) {
    this.loader = true
    this.apiService?.CreateNewL30dna(payload).subscribe({
      next: (res:any) => {
        if (res.status) {
          this.loader = false;
          this.closePopup();
          this.toastr.success(res.message);
          this.tableData();
        }else{
          this.loader = false;
          this.toastr.success(res.message);
          this.showModal = true
        }
      },
      error: (err) => {
        this.loader = false;
        this.toastr.error('An error occurred');
      }
    });
  }

  updateRecord(payload: any) {
    this.loader = true;
    this.apiService?.updateL30Dna(payload).subscribe({
      next: (res:any) => {
        if (res) {
          this.loader = false;
          this.toastr.success(res.description);
          this.closePopup();
          this.tableData();
        }
      },
      error: (err) => {
        this.loader = false;
        this.toastr.error('An error occurred');
      }
    });
  }

  closePopup() {
    this.addL30Qs.reset();
    this.changeButtun = false;    
    const domElem = this.view.rootNodes[0] as HTMLElement;
    domElem.classList.add("animate-out");
    domElem.addEventListener("click", (event: AnimationEvent) => {
      this.viewContainerRef.clear();
      this.apiService.modaBackDrop.next(false);
    });
  }
  onFileSelected(event: any): void {
    this.selectedFile = event.target.files[0];
    console.log(this.selectedFile)
  }
  onOkClick() {
    const formData = new FormData();
    formData.append('file', this.selectedFile);
    formData.append('sequenziat', this.addL30Qs.value.sequenziat);
    formData.append('isUpdateData', 'true');
    formData.append('num_cam_dropdownValue', this.addL30Qs.value.num_cam_dropdownValue);
    this.createRecord(formData);
    this.showModal = false;
  }

  onCancelClick() {
    this.showModal = false;
  }

  onModalClick(event: Event) {
    // Prevent clicks inside the modal from closing it
    event.stopPropagation();
  }

  openPopup1(){
    // this.addDNAForm.controls['cod_dna'].setValue(this.codeDna);
    this.addL30Qs.controls['sequenziat'].setValue('D');

  }
  // onFilterChanged(params: any): void {
  //   // Iterate through all columns
  //   this.filterValue = '';
  //   this.columnName = params.columns[0].colId;
  //   this.colDefs.forEach((column) => {
  //     const specificColumnFilterModel = params.api.getFilterModel()[this.columnName];
  //     if (specificColumnFilterModel) {
  //        this.filterValue = specificColumnFilterModel?.filter;
  //     }
  //   });

    
  //   this.apiService.getl30dnaData(this.pageNumber,this.itemPerPage,this.columnName,this.filterValue,this.sortingOrder).subscribe({
  //     next: (res) => {
  //       this.rowData$ = res?.result?.rows;
  //       this.totalCount = res?.result?.count;
  //       this.apiService.selectedPage.next(1);

  //     },
  //     error: (error) => {
  //     },
  //     complete: () => { }
  //   });
    
  // }
  onFilterChanged(params: any): void {
    this.filterValue = '';
    this.columnName = params.columns[0].colId;

    const filterModel = params.api.getFilterModel();
    const activeFilters = Object.keys(params.api.getFilterModel());
    console.log(activeFilters)
    this.col = activeFilters.length;
    for (const columnId in filterModel) {
      if (filterModel.hasOwnProperty(columnId)) {
        const specificColumnFilterModel = filterModel[columnId];
        if (specificColumnFilterModel) {
          this.columnName = columnId;
          this.filterValue = specificColumnFilterModel.filter;
          break; // Exit loop after finding the first column with a filter
        }
      }
    }

    if(this.col <= 1 ) {
  
    this.apiService.getl30dnaData(this.pageNumber = 1, this.itemPerPage, this.columnName, this.filterValue, this.sortingOrder).subscribe({
      next: (res) => {
        this.rowData$ = res?.result?.rows;
        this.totalCount = res?.result?.count;
        this.apiService.selectedPage.next(1);
      },
      error: (error) => {
        // Handle error
      },
      complete: () => {
        // Handle completion
      }
    });
  }
  
  
    console.log(`Filter value for column ${this.columnName}:`, this.filterValue);
  }
  
  
}
