<div class="mobile-menu">
    <a class="open-icon" (click)="toggleSidebar()">
        <img src="../../../assets/images/vector.png" width="30" height="25" />
    </a>
    <div class="sidebar" [ngClass]="{'show': showSidebar, 'hide': !showSidebar}">
        <a class="close-icon" (click)="toggleSidebar()">&#10006;</a>
        <div class="menu">
            <div class="link-wrapper">
                <a routerLink="/dashboard" [routerLinkActive]="['active']">
                    <i class="fa-solid fa-house fa-md"></i>
                    DASHBOARD
                </a>
            </div>
            <div class="link-wrapper" *ngIf="allUserpermision.includes('IMPORTA-LISTATI')">
                <a routerLink="/importa-listati" [routerLinkActive]="['active']">
                    <i class="fa-solid fa-file-import fa-md"></i>
                    IMPORTA LISTATI
                </a>
            </div>
            <div class="link-wrapper" *ngIf="allUserpermision.includes('SAMPLING MANAGEMENT')">
                <a routerLink="/campionamenti" [routerLinkActive]="['active']">
                    <i class="fa-solid fa-flask-vial fa-md"></i>
                    CAMPIONAMENTI
                </a>
            </div>
            <div class="link-wrapper" *ngIf="allUserpermision.includes('ANIMALS MANAGEMENT')">
                <a routerLink="/statistiche" [routerLinkActive]="['active']">
                    <i class="fa-solid fa-cow fa-md"></i>
                    RIPRODUTTORI
                </a>
        
            </div>
            <div class="link-wrapper" *ngIf="allUserpermision.includes('STATISTICS')">
                <a routerLink="/statistics-overview" [routerLinkActive]="['active']">
                    <i class="fas fa-chart-pie"></i>
                    STATISTICHE
                </a>
            </div>
            <div class="link-wrapper" *ngIf="allUserpermision.includes('LAB INSTRUMENT STATUS')">
                <a routerLink="/dna-table" [routerLinkActive]="['active']">
                    <i class="fas fa-table"></i>
                    STRUMENTAZIONE
                </a>
            </div>
            <div class="link-wrapper" *ngIf="allUserpermision.includes('COURIER MANAGEMENTS')">
                <a routerLink="/courier-management" [routerLinkActive]="['active']">
                    <i class="fas fa-shipping-fast"></i>
                    GESTIONE CORRIERE
                </a>
            </div>
            <div class="link-wrapper" *ngIf="allUserpermision.includes('DNA REFERENCES (BIOBANK)')">
                <a routerLink="/new-dna-ref" [routerLinkActive]="['active']">
                    <i class="fa-solid fa-pills"></i>
                    GENOTIPO DI RIFERIMENTO
                </a>
            </div>
            <div class="link-wrapper" *ngIf="allUserpermision.includes('WORKSHEETS AND SAME DNA')">
                <a routerLink="/worksheet-dna" [routerLinkActive]="['active']">
                    <i class="fa-solid fa-sheet-plastic"></i>
                    PRE-ANALISI e ANALISI
                </a>
            </div>
            <div class="link-wrapper" *ngIf="allUserpermision.includes('L30_QS')">
                <a routerLink="/l30qs" [routerLinkActive]="['active']">
                    <i class="fas fa-vial"></i>
                    QUALITÀ SEME
                </a>
            </div>
            <div class="link-wrapper" *ngIf="allUserpermision.includes('L30_DNA')">
                <a routerLink="/l30dna" [routerLinkActive]="['active']">
                    <i class="fa fa-window-maximize"></i>
                    L30 VCI
                </a>
            </div>
            <div class="link-wrapper" *ngIf="allUserpermision.includes('MAGGAZINO')">
                <a routerLink="/magazzino" [routerLinkActive]="['active']">
                    <i class="fa fa-thermometer-quarter"></i>
                    CRIOBANCA
                </a>
            </div>
            <div class="link-wrapper">
                <a routerLink="/archivio" [routerLinkActive]="['active']">
                    <i class="fa-solid fa-database"></i>
                    ARCHIVIO
                </a>
            </div>
            <div class="link-wrapper" *ngIf="allUserpermision.includes('AUTOCERTIFICAZIONI')">
                <a routerLink="/autocertifizion" [routerLinkActive]="['active']">
                    <i class="fa-brands fa-figma"></i>
                    AUTOCERTIFICAZIONI
                </a>
            </div>
            <div class="link-wrapper" *ngIf="allUserpermision.includes('CQ')">
                <a routerLink="/controllo-interno" [routerLinkActive]="['active']">
                    <i class="fa-solid fa-medal"></i>
                    CONTROLLO DI QUALITÀ INTERNO
                </a>
            </div>
        </div>
    </div>
</div>