<div class="section">
<div class="setion-title">
  <i class="fa-solid fa-flask"></i>
  <h1>{{record.ID_STR}}</h1>
</div>

<div class="section-content">
  <div class="content">
    <i class="fa-regular fa-vial-virus"></i>
    <h3>{{record.TIPO_STR}}</h3>
  </div>
  <div class="content">
    <i class="fa-solid fa-vials"></i>
    <h3>{{record.ID_LAB}}</h3>
  </div>
  <div class="content">
    <i class="fa-solid fa-flask-vial"></i>
    <h3>{{record.LAB_RIF}}</h3>
  </div>
  <div class="content">
    <i class="fa-sharp fa-solid fa-flask-gear"></i>
    <h3>{{record.CATEGORIA}}</h3>
  </div>
  <div class="content">
    <i class="fa-brands fa-gitlab"></i>
    <h3>{{record.MARCA}}</h3>
  </div>
</div>
</div>

<div class="section">
  <div class="setion-title">
    <i class="fa-solid fa-flask"></i>
    <h1>CONFIGURAZIONE</h1>
  </div>
  
  <div class="section-content">
    <div class="content">
      <i class="fa-regular fa-vial-virus"></i>
      <h3>{{record.MESI_MAN}}</h3>
    </div>
    <div class="content">
      <i class="fa-solid fa-vials"></i>
      <h3>{{record.TIPO_MAN}}</h3>
    </div>
    <div class="content">
      <i class="fa-solid fa-flask-vial"></i>
      <h3>{{record.MANUALE}}</h3>
    </div>
    <div class="content">
      <i class="fa-sharp fa-solid fa-flask-gear"></i>
      <h3>{{record.ACCURATEZ}}</h3>
    </div>
    <div class="content">
      <i class="fa-brands fa-gitlab"></i>
      <h3>{{record.PRECISIONE}}</h3>
    </div>
  </div>
  </div>

  <div class="section">
    <div class="setion-title">
      <i class="fa-solid fa-flask"></i>
      <h1>MANUTENZIONE</h1>
    </div>
    
    <div class="section-content">
      <div class="content">
        <i class="fa-regular fa-vial-virus"></i>
        <h3>{{record.DATA_VER}}</h3>
      </div>
      <div class="content">
        <i class="fa-solid fa-vials"></i>
        <h3>{{record.ESITO_VER}}</h3>
      </div>
      <div class="content">
        <i class="fa-solid fa-flask-vial"></i>
        <h3>{{record.OP_VER}}</h3>
      </div>
    </div>
    </div>