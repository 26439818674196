import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ModalService } from "src/app/_services/modal.service";

export interface ConfirmValue {
  modal: string;
  value: boolean;
}

@Component({
  selector: "app-conform",
  template: `
  <div class="ismodal" [ngClass]="{'active': showModal?.value}">
  <div class="modal-overlay">
    <div class="modal">
      <div class="modal-content">
        <p class="text">{{ message }}</p>
        <!-- <div *ngIf="this.showModal.modal == 'animal-management'">
          <label for="">Code Toro</label>
          <input type="text" [(ngModel)]="codetoro" >
        </div> -->
        <div class="modal-buttons">
          <button class="cancel-button mbutton" (click)="onConfirmClick('Cancel')">Annulla</button>
          <button class="confirm-button mbutton" (click)="onConfirmClick('Confirm')">Conferma</button>
        </div>
      </div>
    </div>
  </div>
  </div>
    `,
  styles: [`
input {
  color: black;
  font-weight:bold;
}
label{
  color:black;
}
      .custom{
        color: red;
        font-size: 32px;
      }
      .modal-overlay{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 999;
      }
      .modal {
        background-color: #858282;
        padding: 20px;
        border-radius: 5px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
        max-width: 400px;
        text-align: center;
      }
      .ismodal {
        display: none;
      }
      .ismodal.active {
        display: block;
      }
      .mbutton{
       margin: 10px 0px 0px 10px;
       padding: 6px 10px;
       cursor: pointer;
       border: none;
       color: #fff;
       border-radius: 3px;
       font-size: 12px;
       font-weight: 600;
      }
      .confirm-button{
        background-color: #01a801;
      }
      .cancel-button{
        background-color: #ff000073;
      }
      .text{
        font-size: 20px;
        font-weight: 500;
      }
    `]
})
export class ConformComponent {
  codetoro:any;
  @Input() message: string;
  @Output() confirm= new EventEmitter();
  showModal: ConfirmValue = { modal: '', value: false };
  constructor(private modalService: ModalService) { this.modalService.showModal$.subscribe((showModal) => {
    this.showModal = showModal; }); }
  show() { this.showModal.value = true; }
  hide() { this.showModal.value = false; }
  onConfirmClick(value: string) {
    if (value === 'Confirm') {
      // this.modalService.isConfirmed$.next({ modal: this.showModal.modal, value: true });
      this.confirm.emit({ modal: this.showModal.modal, value: true,code_toro:this.codetoro })
      this.hide();
    }
    if (value === 'Cancel') {
      this.modalService.isConfirmed$.next({ modal: this.showModal.modal, value: false });
      this.hide();
    }
  }



}