<!-- <app-loader *ngIf="loader"></app-loader> -->
<div class="loader-container" *ngIf="loader">
    <div class="loader"></div>
</div> 
<div class="container">
    <div class="text-right pr-1 pt-1" *ngIf="!isDneUser">
        <div class="toggle-wrap">
            <!-- <small>Week</small> -->
            <label class="switch">
                <input type="checkbox" (change)="onChangeToggle($event)">
                <span class="slider round"></span>
            </label>
            <small class="text-data">All Weeks</small>
        </div>
    </div>
    <div *ngIf="isDneUser">
        <div class="table_grid mb-3">
            <h3 class="title mb-3">{{'Prossimi Campionamenti'|uppercase}}</h3>
            <ag-grid-angular style="height: 500px;" class="ag-theme-alpine-dark basic-table" [rowData]="rowDataTen$"
                [gridOptions]="gridOptions"  [columnDefs]="colDefs" >
            </ag-grid-angular>
        </div>

        <div class="table_grid mb-3">
            <h3 class="title mb-3">CAMPIONAMENTI IN CORSO</h3>
            <ag-grid-angular style="height: 500px;" class="ag-theme-alpine-dark basic-table" [rowData]="rowData$" [columnDefs]="columnDefs" 
                [gridOptions]="gridOptions1">
            </ag-grid-angular>
        </div>
        

        <div class="table_grid mb-3">
            <h3 class="title mb-3">{{'Storico Campionamenti'|uppercase}}</h3>
            <ag-grid-angular style="height: 500px;" class="ag-theme-alpine-dark basic-table" [rowData]="rowDataFifty$"
                [gridOptions]="gridOptions2" [columnDefs]="colDefsStorico"> 
            </ag-grid-angular>
        </div>
    </div>
    <div class="content_wraps" >
    

    
        <div class="grid-content dashboardChart last-chart">
            <div class="textualLoader" *ngIf="isTextualLoader">
                <div class="loader"></div>
            </div> 
            <div class="legendRow" *ngIf="!isDneUser && !isTextualLoader">
                <!-- <div class="legend-box" *ngIf="textualData">
                    <p class="legend-text" >SPECIES: {{specie}}</p>
                </div> -->
                <div class="legend-box" *ngIf="textualData">
                    <p class="legend-text" >LOTTI TOTALI: {{textualData?.number_lotti_totali}}</p>
                </div>
                <div class="legend-box py-2">
                    <p class="legend-text" >LOTTI CAMPIONATI: {{textualData?.number_lotti_campionati}} ({{textualData?.percentage_lotti_campionati}}%)</p>
                </div>
                <div class="legend-box">
                    <p class="legend-text" >{{textualData?.number_lotti_da_campionare}} LOTTI DA CAMPIONARE PER ARRIVARE A {{textualData?.x_percentage}}%</p>
                </div>
            </div>
            <!-- <div *ngIf="!(acqscgart1>0)" class="data-not-found">Data Not Found!</div>
            <div [ngStyle]="{'display':!(acqscgart1>0)?'none':''}" style="width: 100%;">
                <canvas #dashboardChart></canvas>
            </div> -->
        </div>
        <div class="grid-content dashboardChart" *ngIf="!isDneUser">
            <div *ngIf="!acqscgart" class="data-not-found">Data Not Found!</div>
            <div *ngIf="acqscgart">
                <highcharts-chart *ngIf="isHighcharts" [Highcharts]="Highcharts" [options]="chartOptions1"
                    [(update)]="updateFlag" style="width:  100%; height: 100%; display: block;"></highcharts-chart>
                    <!-- <app-dashboard-chart 
                        [response]="firstChart" 
                        chartId="firstChartConrainer"
                        >
                    </app-dashboard-chart> -->
            </div>
        </div>
        <div class="grid-content dashboardChart" *ngIf="!isDneUser">
            <div *ngIf="!acqscgart" class="data-not-found">Data Not Found!</div>
            <div *ngIf="acqscgart">
                <highcharts-chart *ngIf="isHighcharts" [Highcharts]="Highcharts" [options]="chartOptions2"
                    [(update)]="updateFlag" style="width:  100%; height: 100%; display: block;"></highcharts-chart>
                    <!-- <app-dashboard-chart [response]="secondChart" chartId="secondChartConrainer"></app-dashboard-chart> -->
            </div>
        </div>
        <div class="grid-content dashboardChart" *ngIf="!isDneUser">
            <div *ngIf="!acqscgart" class="data-not-found">Data Not Found!</div>
            <div *ngIf="acqscgart">
                <highcharts-chart *ngIf="isHighcharts" [Highcharts]="Highcharts" [options]="chartOptions3"
                    [(update)]="updateFlag" style="width:  100%; height: 100%; display: block;"></highcharts-chart>
                    <!-- <app-dashboard-chart [response]="thirdChart" chartId="thirdChartConrainer"></app-dashboard-chart> -->
            </div>
        </div>
       
        
        
        
             
        

        
        <!-- <div class="grid-content">
            <app-statistica-testuale [titolo]="'Statistiche Razza'" [dati]="statistiche"></app-statistica-testuale>
        </div> -->
        <!-- <div class="grid-content">
            <app-statistica-graph [chartName]="'GraficoRazza'"
                [labels]="['2022-05-10','2022-05-11','2022-05-12','2022-05-13','2022-05-14','2022-05-15','2022-05-16','2022-05-17']"
                [datasets]="[dataRazza]"></app-statistica-graph>
        </div> -->
        <!-- <div class="grid-content">
        <app-statistica-testuale [titolo]="'Statistiche Centro'" [dati]="statistiche"></app-statistica-testuale>
        </div> -->
        <!-- <div class="grid-content">
            <app-statistica-graph [chartName]="'GraficoCentro'"
                [labels]="['2022-05-10','2022-05-11','2022-05-12','2022-05-13','2022-05-14','2022-05-15','2022-05-16','2022-05-17']"
                [datasets]="[dataCentro]"></app-statistica-graph>
        </div> -->
    </div>
</div>