import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-statistica-testuale',
    templateUrl: './statistica-testuale.component.html',
    styleUrls: ['./statistica-testuale.component.scss']
})
export class StatisticaTestualeComponent implements OnInit {
    @Input() titolo: string;
    @Input() dati: { nome: string; valore: number }[];

    constructor() { }

    ngOnInit(): void { }
}
