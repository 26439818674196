import { Component, OnChanges, OnInit, SimpleChanges, ViewContainerRef } from "@angular/core";
import { ApiService } from "../_services/api.service";
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ColumnApi, GridApi, GridOptions } from "ag-grid-community";
import { CustomButtonRendererComponent } from "../table-test/custom-button-renderer.component";
import { ToastrService } from "ngx-toastr";
import { ModalService } from "../_services/modal.service";
import { Subject, takeUntil, takeWhile } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";

@Component({
	selector: "app-createsample",
	templateUrl: "./createsample.component.html",
	styleUrls: ["./createsample.component.scss"],
})
export class CreatesampleComponent implements OnInit, OnChanges {
	private gridApi;
	private componentDestroyed$: Subject<void> = new Subject<void>();
	dnaTableData: any[] = [];
	tableDatanew: any[] = [];
	paginationPageSize = 15;
	totalCount: any;
	year = "campionamentic";
	formSubmitted: boolean = false;
	code: any;
	columnDefs: any = [
		{
			field: "row_number",
			headerName: "ROW NUMBER",
			width: 215,

			// valueGetter: (params: any) => {
			//   // this.dnaTableData.push(params.node.data)
			//   return params.node.rowIndex + 1;
			// },
		},
		{
			field: "data_prot",
			headerName: "DATA PROT",
			comparator: this.customDateComparator,
			width: 215,
			type: "date",
			cellRenderer: (params: any) => {
				return `${params?.data?.data_prot == "NULL" || params?.data?.data_prot == null || params?.data?.data_prot == "" ? "N/A" : this.parseDateStringToDate(params?.data?.data_prot)}`;
			},
		},
		{
			field: "toro",
			headerName: "TORO",
			width: 215,
			cellRenderer: (params: any) => {
				return `${params?.data?.toro == "NULL" || params?.data?.toro == null || params?.data?.toro == "" ? "N/A" : params?.data?.toro}`;
			},
		},
		{
			field: "cod_toro",
			headerName: "COD TORO",
			width: 215,
			cellRenderer: (params: any) => {
				return `${params?.data?.cod_toro == "NULL" || params?.data?.cod_toro == null || params?.data?.cod_toro == "" ? "N/A" : params?.data?.cod_toro}`;
			},
		},
		{
			field: "lotto",
			headerName: "LOTTO",
			sortable: true,
			width: 215,
			cellRenderer: (params: any) => {
				return `${params?.data?.lotto == "NULL" || params?.data?.lotto == null || params?.data?.lotto == "" ? "N/A" : params?.data?.lotto}`;
			},
		},
		{
			field: "dosi",
			headerName: "DOSI",
			width: 215,
			cellRenderer: (params: any) => {
				return `${params?.data?.dosi == "NULL" || params?.data?.dosi == null || params?.data?.dosi == "" ? "N/A" : params?.data?.dosi}`;
			},
		},
	];
	rowData: any[];
	tableName: string = "sample_management";
	timeRange: string = "00.00/00.00";
	invalidTimeRange: boolean = false;
	p: number = 1;
	popup = false;
	form: FormGroup;
	loader: boolean = false;
	rowId: any;
	years: number[] = [];
	lastcodiccam: any;
	centriCodiceAz: string | number;
	specie: string;
	anno: string | number;
	isAlive = true;
	subjectValue: boolean = false;
	deleteId: any;
	codice_cam: any;
	codice_az: any;
	fagliofile: string;
	defaultColDef = { resizable: true, sortable: true, filter: true };
	gridOptions: GridOptions = {
		defaultColDef: this.defaultColDef,
		pagination: true,
		paginationAutoPageSize: true,
		frameworkComponents: { customButtonRenderer: CustomButtonRendererComponent },
	};
	currentYear: number;

	tableData: any[] = [];
	startRange: number;
	endRange: number;
	resultString: string = "";
	resultTable: any[] = [];
	currentDate: any;
	api: GridApi;
	columnApi: ColumnApi;
	columnName: string = "";
	sortingOrder: string = "DESC";
	fagfilename: string;
	dosiValue: string = "1";
	constructor(
		public apiService: ApiService,
		private router: Router,
		private viewContainerRef: ViewContainerRef,
		private formBuilder: FormBuilder,
		private toast: ToastrService,
		private modalService: ModalService,
		private http: HttpClient
	) {
		this.currentYear = new Date().getFullYear();
		const currentYear = new Date().getFullYear();
		for (let i = 0; i < 4; i++) {
			this.years.push(currentYear - i);
		}
		this.code = localStorage.getItem("centriCodiceAz");
		this.lastcodiccam = localStorage.getItem("lastcodiccam");
		this.form = this.formBuilder.group({
			codice_cam: [this.lastcodiccam, Validators.required],
			codice_az: [this.code, Validators.required],
			anno_riferimento: [this.currentYear],
			lotti_cam: ["50"],
			data_campl: [null],
			minumo_dosi: ["1"],
			massimo_tentivi: ["50"],
			darecord: [null],
			arecord: [null],
		});
		this.gridOptions.onSortChanged = this.onSortChanged.bind(this);
	}
	ngOnChanges(changes: SimpleChanges): void {}

	ngOnInit(): void {
		this.apiService.redirectButton.next(true);
		this.apiService.current.pipe(takeWhile(() => this.isAlive)).subscribe((res) => {
			if (res) {
				this.subjectValue = true;
				this.centriCodiceAz = res.codice_az ? res.codice_az : localStorage.getItem("centriCodiceAz");
				this.form.get("codice_az").setValue(this.centriCodiceAz);
				this.code = this.centriCodiceAz;
				this.specie = res.specie ? res.specie : localStorage.getItem("specie");
				this.anno = res.anna ? res.anna : localStorage.getItem("anna");
				this.getSampleTable();
			}
		});
		this.getSampleTable();

		const currentDate = new Date(); // Assuming this is your date
		const year = currentDate.getFullYear();
		const month = String(currentDate.getMonth() + 1).padStart(2, "0");
		const day = String(currentDate.getDate()).padStart(2, "0");

		this.currentDate = `${year}-${month}-${day}`;

		this.apiService.delete.subscribe((res) => {
			this.deleteId = res?.data;
			if (res?.data?.id && res?.table == "sample_management") {
				this.modalService.openModal("sample_management");
			} else if (res?.data?.id && res?.table == "sample_management1") {
				this.modalService.openModal("sample_management1");
			}
		});
	}
	ngOnDestroy() {
		this.isAlive = false;
		this.apiService.redirectButton.next(false);
		this.componentDestroyed$.next();
		this.componentDestroyed$.complete();
		this.apiService.ricar.next(null);
	}
	customDateComparator(valueA: any, valueB: any): number {
		if (!valueA && !valueB) {
			return 0; // Both values are null or empty
		}
		if (!valueA) {
			return -1; // ValueA is null or empty
		}
		if (!valueB) {
			return 1; // ValueB is null or empty
		}
		const dateA = new Date(valueA);
		const dateB = new Date(valueB);
		return dateA.getTime() - dateB.getTime();
	}
	onGridReady(params) {
		this.api = params.api;
		this.columnApi = params.columnApi;
		params.api.sizeColumnsToFit();
	}

	onSortChanged(event) {
		// this.loader = true
		const sortedColumns: any = this.columnApi.getAllColumns().filter((column) => column.getSort())[0];
		this.sortingOrder = sortedColumns?.getSort() ?? "";
		this.columnName = sortedColumns?.getColDef().field ?? "";
		this.getSampleTable();
		// this.loader = false
	}
	parseDateStringToDate(dateString: string): string {
		const [day, month, year] = dateString.split("/");
		// Create a new Date object
		const dateObject = new Date(Number(year), Number(month) - 1, Number(day));
		// Format the date to 'dd/mm/yyyy' format
		const formattedDate = `${this.padZero(dateObject.getDate())}/${this.padZero(dateObject.getMonth() + 1)}/${dateObject.getFullYear()}`;
		return formattedDate;
	}

	// Helper function to add leading zeros to single-digit numbers
	padZero(num: number): string {
		return num < 10 ? `0${num}` : `${num}`;
	}

	dateComparator(date1: Date, date2: Date): number {
		return date1.getTime() - date2.getTime();
	}
	openmodel() {
		this.modalService.openModal("sample_management");
	}

	formatDate(date): any {
		if (date !== "NULL" && date !== null) {
			const dateComponents = date.split("/");
			const formattedDate = new Date(`${dateComponents[2]}-${dateComponents[1]}-${dateComponents[0]}`);
			if (!isNaN(formattedDate.getTime())) {
				const timeZoneOffset = formattedDate.getTimezoneOffset() * 60000;
				const adjustedDate = new Date(formattedDate.getTime() - timeZoneOffset);
				const formattedDateString = adjustedDate.toISOString().split("T")[0];
				return formattedDateString;
			} else {
				return "Invalid Date";
			}
		}
	}

	isValidDate(d: any) {
		return d instanceof Date && !isNaN(+d);
	}

	getSampleTable(page = 1, limit = this.paginationPageSize) {
		this.loader = true;
		if (!this.subjectValue) {
			this.centriCodiceAz = localStorage.getItem("centriCodiceAz") ? localStorage.getItem("centriCodiceAz") : 1;
			this.specie = localStorage.getItem("specie") ? localStorage.getItem("specie") : "BI";
			this.anno = localStorage.getItem("anna") ? localStorage.getItem("anna") : 2022;
		}
		this.subjectValue = false;

		this.apiService.getl30lotiSampleData(this.centriCodiceAz, this.specie, this.anno, this.sortingOrder, this.dosiValue).subscribe({
			next: (data) => {
				if (data.status) {
					let filteredData = data.result.rows;
					let i = 1;
					filteredData.forEach((element) => {
						element["row_number"] = i;
						i++;
					});
					this.dnaTableData = filteredData;
					this.totalCount = data.result.count;
					this.rowData = this.dnaTableData;
					this.loader = false;
				} else {
					this.loader = false;
					this.rowData = [];
				}
			},
		});
	}

	back() {
		this.router.navigate(["/campionamenti"]);
	}
	receiveData(event: any) {
		if (event?.table === this.tableName) {
			this.rowData = event?.data?.result?.rows;
		}
	}

	get fc() {
		return this.form.controls;
	}

	filterRows(): void {
		this.startRange = this.form.value.darecord;
		this.endRange = this.form.value.arecord;
		if (this.startRange && this.endRange) {
			this.resultTable = [];

			for (let i = this.startRange; i <= this.endRange; i++) {
				if (i <= this.dnaTableData.length) {
					this.resultTable.push(this.dnaTableData[i - 1]);
					this.rowData = this.resultTable;
				}
			}
		} else {
			console.error("Invalid range");
		}
	}
	onSubmit() {
		this.filterRows();
		this.formSubmitted = true;
		if (this.form.invalid) {
			return;
		}
		this.loader = true;
		let data = this.formatDate1(this.form.value.data_campl);
		let date = new Date();
		let payload = { ...this.form.value, data_campl: data, lotitable: this.resultTable };
		this.apiService.createSampleData(payload).subscribe((res: any) => {
			if (res?.success) {
				let url = environment.url + res?.samplingFilePath;
				// this.downloadTextFile(url)
				this.downloadPdfFile(url);
				this.fagfilename = res?.fileName;
				this.toast.success(res?.description);
				this.router.navigate(["/campionamenti"]);
				this.getSampleTable();
				this.loader = false;
				this.formSubmitted = false;
			}
		});
	}

	downloadTextFile(url: string): void {
		this.http.get(url, { responseType: "text" }).subscribe((data: string) => {
			const blob = new Blob([data], { type: "text/plain" });
			const anchor = document.createElement("a");
			anchor.href = window.URL.createObjectURL(blob);
			anchor.download = this.fagfilename;
			document.body.appendChild(anchor);
			anchor.click();
			window.URL.revokeObjectURL(anchor.href);
			document.body.removeChild(anchor);
		});
	}

	downloadPdfFile(url: string): void {
		this.http.get(url, { responseType: "blob" }).subscribe((data: Blob) => {
			const blob = new Blob([data], { type: "application/pdf" });
			const anchor = document.createElement("a");
			anchor.href = window.URL.createObjectURL(blob);
			anchor.download = this.fagfilename;
			document.body.appendChild(anchor);
			anchor.click();
			window.URL.revokeObjectURL(anchor.href);
			document.body.removeChild(anchor);
		});
	}

	validateTimeRange() {
		const regex = /^(\d{2}\.\d{2})\/(\d{2}\.\d{2})$/;
		if (!regex.test(this.timeRange)) {
			this.invalidTimeRange = true;
		} else {
			this.invalidTimeRange = false;
		}
	}
	formatDate1(inputDate): any {
		if (inputDate != null) {
			const [year, month, day] = inputDate.split("-");
			const formattedDate = `${day}/${month}/${year}`;
			return formattedDate;
		}
	}
	annoChange(event) {
		console.log(event.target.value);
	}

	inputValueChangeDa(event) {
		this.startRange = parseInt(event.target.value);
		console.log(this.startRange);
		this.endRange = this.dnaTableData.length - 1;
		if (this.startRange && this.endRange) {
			this.rowData = this.dnaTableData.slice(this.startRange - 1, this.endRange);
			console.log(this.rowData);
			// this.resultTable = [];

			// for (let i = this.startRange; i <= this.endRange; i++) {
			//   if (i <= this.dnaTableData.length) {
			//     this.resultTable.push(this.dnaTableData[i-1]);

			//     console.log(this.rowData)
			//   }
			// }
			// this.rowData = this.resultTable
		} else {
			this.rowData = this.dnaTableData;
		}
	}
	inputValueChangeA(event) {
		this.endRange = parseInt(event.target.value);

		if (this.endRange) {
			this.resultTable = [];

			for (let i = this.startRange; i <= this.endRange; i++) {
				if (i <= this.dnaTableData.length) {
					this.resultTable.push(this.dnaTableData[i - 1]);
					this.rowData = this.resultTable;
				}
			}
		} else {
			if (!this.startRange) {
				this.rowData = this.dnaTableData;
			} else {
				this.endRange = this.dnaTableData.length - 1;
				this.rowData = this.dnaTableData.slice(this.startRange - 1, this.endRange);
			}
		}
	}
	getMinumoDosiValue(event: Event): void {
		this.dosiValue = (event.target as HTMLInputElement).value;
		this.uploaDosiValue();
	}
	uploaDosiValue() {
		this.apiService.getl30lotiSampleData(this.centriCodiceAz, this.specie, this.anno, this.sortingOrder, this.dosiValue).subscribe({
			next: (data) => {
				if (data.status) {
					let filteredData = data.result.rows;
					let i = 1;
					filteredData.forEach((element) => {
						element["row_number"] = i;
						i++;
					});
					this.dnaTableData = filteredData;
					this.totalCount = data.result.count;
					this.rowData = this.dnaTableData;
					this.loader = false;
				} else {
					this.loader = false;
					this.rowData = [];
				}
			},
		});
	}
}
