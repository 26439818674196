
<app-loader *ngIf="loader"></app-loader>

<div class="pl-2 pr-2 pb-2 pt-1">
	<div>
		<button class="btn backbtn fs-18 fw-600" (click)="back()"><i class="fa-solid fa-arrow-left pr-1"></i>INDIETRO</button>
	</div>
	<div class="header d-flex mt-2">
        <div>
            <h2>BOLLA Table</h2>
        </div>
        <div class="export">
            <button class="button" (click)="onBtExport()">Export BOLLA INTERNAO</button>
            <!-- <button class="button">Export Foglio di Lavoro</button>
            <button class="button">Export Bolla Interna</button> -->
        </div>
	</div>
	<div class="mt-1">
		<ag-grid-angular style="width: 100%; height: 600px" class="ag-theme-alpine-dark" [columnDefs]="columnDefs"
			[rowSelection]="'single'" [rowData]="rowData" [animateRows]="true" (cellClicked)="onCellClicked($event)"
			[gridOptions]="gridOptions">
		</ag-grid-angular>
	</div>
</div>
