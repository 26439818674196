import { Component, ElementRef, Input, Renderer2, TemplateRef, ViewChild, ViewContainerRef } from "@angular/core";
import { GenericResponse, Toro, ToroFuzzy } from "../_models";
import { CellClickedEvent, ColDef, ColumnApi, GridOptions } from "ag-grid-community";
import { takeWhile } from "rxjs";
import { ApiService } from "../_services/api.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Razza } from "../_models/index";
import { CustomButtonRendererComponent } from "./custom-button-renderer.component";
import { ToastrService } from "ngx-toastr";
import { ModalService } from "../_services/modal.service";
import { NavigationExtras, Router } from "@angular/router";
import FuzzySearch from "fuzzy-search";
@Component({
	selector: "app-table-test",
	templateUrl: "./table-test.component.html",
	styleUrls: ["./table-test.component.scss"],
})
export class TableTestComponent {
	@Input() item: boolean = false;
	view: any;
	form: FormGroup;
	formCodToro: FormGroup;
	p: number = 1;
	itemPerPage: number = 15;
	pageNumber: number = 1;
	totalCount: number = 1;
	rowData$: Toro[];
	rowData1$: any[];
	col: any = 0;
	razzeData: Razza[] = [];
	tableName: string = "reproduttori";
	searcher: FuzzySearch;
	searchText: string;
	selectedText: string;
	fuzResult = [];
	centroName: string;
	riproduttori: ToroFuzzy[] = [];
	filterValue: string = "";
	columnName: string = "";
	Popupform: FormGroup;
	sortingOrder: string = "";
	// disableDNa: boolean = true;
	spaciesData: any[] = [
		{ value: "B", name: "Bovini" },
		{ value: "BI", name: "Bovini(i)" },
		{ value: "E", name: "Equini" },
		{ value: "EI", name: "EQUINI(I)" },
		{ value: "S", name: "Suini" },
		{ value: "SI", name: "SUINI(I)" },
		{ value: "C", name: "CONIGLI" },
		{ value: "CI", name: "CONIGLI(I)" },
		{ value: "BU", name: "Bufali" },
		{ value: "O", name: "OVINI" },
		{ value: "OI", name: "OVINI(I)" },
	];
	colDefs: ColDef<Toro>[] = [
		{
			field: "cod_toro",
			headerName: "Cod Toro",
			width: 300,
			comparator: this.customSortFunction.bind(this),
			cellRenderer: (params: any) => {
				return `${params.data.cod_toro == "NULL" || params.data.cod_toro == null || params.data.cod_toro == "" ? "N/A" : params.data.cod_toro}`;
			},
		},
		{
			field: "toro",
			headerName: "Toro",
			width: 230,

			cellRenderer: (params: any) => {
				return `${params.data.toro == "NULL" || params.data.toro == null || params.data.toro == "" ? "N/A" : params.data.toro}`;
			},
		},
		{
			field: "matricola",
			headerName: "Matricola",
			width: 220,
			cellRenderer: (params: any) => {
				return `${params.data.matricola == "NULL" || params.data.matricola == null || params.data.matricola == "" ? "N/A" : params.data.matricola}`;
			},
		},
		{
			field: "matricola2",
			headerName: "Matricola2",
			width: 220,
			cellRenderer: (params: any) => {
				return `${params.data.matricola2 == "NULL" || params.data.matricola2 == null || params.data.matricola2 == "" ? "N/A" : params.data.matricola2}`;
			},
		},
		{
			field: "specie",
			headerName: "Specie",
			width: 250,
			cellRenderer: (params: any) => {
				return `${params.data.specie == "NULL" || params.data.specie == null || params.data.specie == "" ? "N/A" : params.data.specie}`;
			},
		},

		{
			field: "razza",
			headerName: "Razza",
			width: 300,
			cellRenderer: (params: any) => {
				return `${params?.data?.razza == "NULL" || params?.data?.razza == null || params?.data?.specie == "" || params?.data?.razze.length == 0 ? "N/A" : params?.data?.razze[0]?.razza_nome}`;
			},
		},
		{
			field: "cod_dna",
			headerName: "DNA",
			width: 300,
			comparator: this.customSortFunction.bind(this),
			cellRenderer: (params: any) => {
				return `${params.data.cod_dna == "NULL" || params.data.cod_dna == null || params.data.cod_dna == "" || params.data.cod_dna == null ? "N/A" : params.data.cod_dna}`;
			},
		},
		{
			field: "data_nasc",
			headerName: "Nascita",
			width: 300,
			cellRenderer: (params: any) => {
				return `${params.data.data_nasc == "NULL" || params.data.data_nasc == null || params.data.data_nasc == "" ? "N/A" : params.data.data_nasc}`;
			},
		},

		// {
		//   field: "provato", headerName: 'Provato',width: 250, cellRenderer: (params: any) => {
		//     return `${params.data.provato == 'NULL' || params.data.provato == null || params.data.provato == '' ? 'N/A' : params.data.provato}`;
		//   },
		// },
		// {
		//   field: "top100", headerName: 'Top100', width: 200, cellRenderer: (params: any) => {
		//     return `${params.data.top100 == 'NULL' || params.data.top100 == null || params.data.top100 == '' ? 'N/A' : params.data.top100}`;
		//   },
		// },
		// {
		//   field: "matric_ana", headerName: 'MatricolaANA', width: 160, cellRenderer: (params: any) => {
		//     return `${params.data.matric_ana == 'NULL' || params.data.matric_ana == null || params.data.top100 == '' ? 'N/A' : params.data.matric_ana}`;
		//   },
		// },
	];
	defaultColDef: ColDef = { resizable: true, sortable: true, filter: true };
	gridOptions: GridOptions<Toro> = {
		onFilterChanged: this.onFilterChanged.bind(this),
		columnDefs: this.colDefs,
		defaultColDef: this.defaultColDef,
		rowSelection: "single",
		animateRows: true,
		pagination: true,
		paginationAutoPageSize: true,
		frameworkComponents: {
			customButtonRenderer: CustomButtonRendererComponent,
		},
		onCellClicked: (event: CellClickedEvent) => {
			let locations = localStorage.getItem("location");
			this.location = JSON.parse(locations);
			this.clickedRowData = event.data;
			console.log(this.clickedRowData.cod_dna);
			let date = this.formatDate(this.clickedRowData.data_nasc);
			(this.specieValue = this.clickedRowData.specie), (this.razzeData = this.razze.filter((sp: any) => sp?.specie === this.specieValue));
			// this.selectSpecie()
			this.form.patchValue({
				id: this.clickedRowData.id,
				toro: this.clickedRowData.toro,
				matricola: this.clickedRowData.matricola,
				matricola2: this.clickedRowData.matricola2,
				specie: this.clickedRowData.specie,
				razza: this.clickedRowData.razza,
				cod_toro: this.clickedRowData?.cod_toro,
				cod_dna: this.clickedRowData.cod_dna ? this.clickedRowData.cod_dna : this.last_dna_code,
				provato: this.clickedRowData.provato,
				top100: this.clickedRowData.top100,
				data_nasc: date,
				settore: this.location.settore,
				cilindro: this.location.cilindro,
				piano: this.location.piano,
				contenit: this.location?.contenit,
				tipo_seq: this.clickedRowData?.dna?.tipo_seq,
				note: this.clickedRowData?.dna?.note,
				matric_ana: this.clickedRowData.matric_ana,
			});
			this.form.get("specie").patchValue(this.clickedRowData.specie);
			this.openPopup();
		},
	};

	gridOptions1: GridOptions<Toro> = {
		columnDefs: this.colDefs,
		defaultColDef: this.defaultColDef,
		onFilterChanged: this.onFilterChanged.bind(this),
		rowSelection: "single",
		animateRows: true,
		// pagination: false,
		// paginationAutoPageSize: false,
		frameworkComponents: {
			customButtonRenderer: CustomButtonRendererComponent,
		},
		onCellClicked: (event: CellClickedEvent) => {
			// let locations = localStorage.getItem('location')
			// this.location = JSON.parse(locations)
			this.clickedRowData = event.data;
			console.log(this.clickedRowData);
			let date = this.formatDate(this.clickedRowData.data_nasc);
			(this.specieValue = this.clickedRowData.specie), (this.razzeData = this.razze.filter((sp: any) => sp?.specie === this.specieValue));
			// this.selectSpecie()
			this.form.patchValue({
				id: this.clickedRowData?.id,
				toro: this.clickedRowData?.toro,
				matricola: this.clickedRowData?.matricola,
				matricola2: this.clickedRowData?.matricola2,
				specie: this.clickedRowData?.specie,
				razza: this.clickedRowData?.razza,
				cod_toro: this.clickedRowData?.cod_toro,
				cod_dna: this.clickedRowData?.cod_dna,
				provato: this.clickedRowData?.provato,
				top100: this.clickedRowData?.top100,
				data_nasc: date,
				settore: this.location?.settore,
				cilindro: this.location?.cilindro,
				piano: this.location?.piano,
				contenit: this.location?.contenit,
				tipo_seq: this.clickedRowData?.dna?.tipo_seq,
				note: this.clickedRowData?.dna?.note,
				matric_ana: this.clickedRowData?.matric_ana,
			});
			this.form.get("specie").patchValue(this.clickedRowData?.specie);
			this.openPopup();
		},
	};
	@ViewChild("popupContainer", { read: TemplateRef })
	popupContainer: TemplateRef<any>;
	@ViewChild("popupContainer1", { read: TemplateRef })
	popupContainer1: TemplateRef<any>;
	@ViewChild("popupContainer2", { read: TemplateRef })
	popupContainer2: TemplateRef<any>;
	clickedRowData: any;
	razze: Razza[] = [];
	loader: boolean = false;
	data: any = "B";
	centriCodiceAz: string | number;
	specie: string;
	anno: string | number;
	isAlive = true;
	subjectValue: boolean = false;
	specieValue: any;
	deleteId: any;
	last_dna_code: any;
	boolShowInputText: boolean = false;
	codetoroData: any = "";
	location: any;
	isDneUser: boolean = false;
	lastCodToro: number;
	columnApi: ColumnApi;
	constructor(
		private apiService: ApiService,
		private viewContainerRef: ViewContainerRef,
		private formBuilder: FormBuilder,
		public toastr: ToastrService,
		private modalService: ModalService,
		private router: Router,
		private renderer: Renderer2,
		private elementRef: ElementRef
	) {
		this.gridOptions1.onSortChanged = this.onSortChanged.bind(this);
		this.apiService.current.pipe(takeWhile(() => this.isAlive)).subscribe((res) => {
			if (res) {
				this.subjectValue = true;
				this.centriCodiceAz = res.codice_az ? res.codice_az : localStorage.getItem("centriCodiceAz");
				this.specie = res.specie ? res.specie : "BI";
				this.anno = res.anna ? res.anna : 2022;
				this.tabletest();
			}
		});
		this.form = this.formBuilder.group({
			cod_toro: [null],
			cod_dna: [null],

			toro: ["", Validators.required],
			matricola: ["", Validators.required],
			matricola2: [null],
			specie: ["", Validators.required],
			razza: [null],
			// cod_dna: [null],
			provato: [null],
			top100: [null],
			data_nasc: [null],
			matric_ana: [null],
			settore: [null],
			cilindro: [null],
			piano: [null],
			contenit: [null],
			tipo_seq: [null],
			note: [null],
		});
		this.Popupform = this.formBuilder.group({
			settore: [null],
			cilindro: [null],
			piano: [null],
			contenit: [null],
			note: [null],
		});
		this.formCodToro = this.formBuilder.group({
			cod_toro: ["", Validators.required],
		});

		this.apiService.delete.subscribe((res) => {
			if (res?.data?.id && res?.table === "statistic") {
				this.deleteId = res?.data;
				this.modalService.openModal("statistic");
			}
		});
		if (localStorage.getItem("userType") === "DNE") {
			this.isDneUser = true;
		}
	}

	ngOnInit() {
		// this.disableDNa = true;
		// this.form.get("cod_dna")?.disable();
		this.reproduttoriWithoutFilter();
		this.centroName = localStorage.getItem("centriCodiceAzName");
		this.retrieveRazze();
		this.tabletest();
		this.retrieveRiproduttoriFuzzy();
	}
	ngAfterViewInit(): void {
		// this.openPopup();
		if (this.location) {
			this.Popupform = this.formBuilder.group({
				settore: [this.location.settore],
				cilindro: [this.location.cilindro],
				piano: [this.location.piano],
				contenit: [this.location?.contenit],
				// sequenziat: [null],
				note: [],
			});
		}
	}
	ngOnDestroy() {
		this.isAlive = false;
	}

	customSortFunction(valueA: any, valueB: any, nodeA: any, nodeB: any, isInverted: boolean): number {
		// Convert values to numbers for comparison
		const numA = Number(valueA);
		const numB = Number(valueB);

		// Perform custom comparison based on ascending order
		if (numA < numB) return -1;
		if (numA > numB) return 1;
		return 0;
	}

	onGridReady(params) {
		this.columnApi = params.columnApi;
	}

	onSortChanged(event) {
		if (this.col == 0) {
			const sortedColumns: any = this.columnApi.getAllColumns().filter((column) => column.getSort())[0];
			this.sortingOrder = sortedColumns?.getSort() ?? "";
			this.columnName = sortedColumns?.getColDef().field ?? "";
			this.reproduttoriWithoutFilter();
		}
	}
	openmodel() {
		this.modalService.openModal("animal-management");
	}

	confirm(event) {
		this.codetoroData = event?.code_toro;

		this.onButtonClicked(this.clickedRowData);
	}

	tabletest() {
		this.loader = true;
		if (!this.subjectValue) {
			this.centriCodiceAz = localStorage.getItem("centriCodiceAz") ? localStorage.getItem("centriCodiceAz") : 1;
			this.specie = localStorage.getItem("specie") ? localStorage.getItem("specie") : "BI";
			this.anno = localStorage.getItem("anna") ? localStorage.getItem("anna") : 2022;
		}
		this.subjectValue = false;
		this.apiService.retrieveRiproduttori2<Toro[]>(this.centriCodiceAz, this.specie, this.anno).subscribe((res: any) => {
			this.loader = false;
			this.rowData$ = res.result;
			this.last_dna_code = this.apiService.incrementString(res?.LastCodDna);
			this.lastCodToro = this.apiService.incrementString(res?.lastCodToro);
			this.apiService.next_dna_cod.next(this.last_dna_code);
			localStorage.setItem("codedna", this.last_dna_code);
			localStorage.setItem("location", JSON.stringify(res?.location));
			this.centroName = localStorage.getItem("centriCodiceAzName");

			// this.totalCount = res?.total;
		});
	}
	reproduttoriWithoutFilter() {
		this.loader = true;
		this.apiService.reproduttoriNotFilter(this.pageNumber, this.itemPerPage, this.columnName, this.filterValue, this.sortingOrder).subscribe((res) => {
			if (res.success) {
				this.rowData1$ = res?.result;
				this.totalCount = res?.total;
				this.loader = false;
			} else {
				this.loader = false;
			}
		});
	}
	openPopup() {
		this.apiService.modaBackDrop.next(true);
		const bodyElement = this.elementRef.nativeElement.ownerDocument.body;
		this.renderer.setStyle(bodyElement, "overflow", "hidden");
		this.view = this.viewContainerRef.createEmbeddedView(this.popupContainer);
		const domElem = this.view.rootNodes[0] as HTMLElement;
		domElem.classList.add("animate-in");
	}

	closePopup() {
		const bodyElement = this.elementRef.nativeElement.ownerDocument.body;
		this.renderer.setStyle(bodyElement, "overflow", "auto");
		const domElem = this.view.rootNodes[0] as HTMLElement;
		domElem.classList.add("animate-out");
		domElem.addEventListener("animationend", (event: AnimationEvent) => {
			this.viewContainerRef.clear();
			this.gridOptions.api.deselectAll();
			this.apiService.modaBackDrop.next(false);
		});
	}

	openPopup1() {
		this.apiService.modaBackDrop.next(true);
		this.view = this.viewContainerRef.createEmbeddedView(this.popupContainer1);
		const domElem = this.view.rootNodes[0] as HTMLElement;
		domElem.classList.add("animate-in");
	}

	closePopup1() {
		const domElem = this.view.rootNodes[0] as HTMLElement;
		domElem.classList.add("animate-out");
		domElem.addEventListener("animationend", (event: AnimationEvent) => {
			this.viewContainerRef.clear();
			this.apiService.modaBackDrop.next(false);
		});
	}

	openPopup2() {
		this.apiService.modaBackDrop.next(true);
		this.view = this.viewContainerRef.createEmbeddedView(this.popupContainer2);
		const domElem = this.view.rootNodes[0] as HTMLElement;
		domElem.classList.add("animate-in");
	}
	closePopup2() {
		const domElem = this.view.rootNodes[0] as HTMLElement;
		domElem.classList.add("animate-out");
		domElem.addEventListener("animationend", (event: AnimationEvent) => {
			this.viewContainerRef.clear();
			this.apiService.modaBackDrop.next(false);
		});
	}
	retrieveRazze() {
		this.loader = true;
		this.apiService.retrieveRazze().subscribe({
			next: (data) => {
				this.loader = false;
				this.razze = data.result as Razza[];
			},
			error: (err) => {},
		});
	}
	onSubmit() {
		this.loader = true;
		let dataNasc = this.formatDate1(this.form.value.data_nasc);
		let formValue = { ...this.form.value, id: this.clickedRowData.id, data_nasc: dataNasc };
		this.apiService.updateRecord(formValue).subscribe((res: any) => {
			if (res.status == true) {
				this.location = res?.calculatedLocation;
				this.Popupform.patchValue({
					settore: this.location?.settore,
					cilindro: this.location?.cilindro,
					piano: this.location?.piano,
					contenit: this.location?.contenit,
				});
				this.loader = false;
				this.tabletest();
				// this.closePopup()
				this.toastr.success(res.message);
				if (!this.clickedRowData.cod_dna) {
					this.openPopup2();
				} else {
					this.closePopup();
				}
				this.reproduttoriWithoutFilter();
			} else {
				this.loader = false;
				this.toastr.error(res.message);
			}
		});
	}

	onCodTorosub() {
		this.loader = true;
		let formValue = { ...this.formCodToro.value, id: this.clickedRowData.id };
		this.updateCodToro(formValue);
	}

	onButtonClicked(data: any) {
		this.loader = true;
		let payload = { id: data?.id, cod_toro: this.codetoroData ? this.codetoroData : "" };
		this.apiService.deleteAnimal(payload).subscribe((res: any) => {
			if (res.status) {
				this.closePopup();
				this.tabletest();
				this.loader = false;
				this.toastr.success(res.message);

				this.retrieveRiproduttoriFuzzy();
				this.reproduttoriWithoutFilter();
			} else {
				this.loader = false;
				this.openPopup1();
				this.toastr.error(res.message);
			}
		});
	}

	updateCodToro(payload: any) {
		this.loader = true;
		this.apiService.deleteAnimal(payload).subscribe((res: any) => {
			if (res.status) {
				this.closePopup1();
				this.loader = false;
				this.toastr.success(res.message);
				this.formCodToro.reset();
				this.tabletest();
			} else {
				this.toastr.error(res.message);
				this.loader = false;
				// this.tabletest();
			}
		});
	}

	navigate() {
		if (this.lastCodToro) {
			const navigationExtras: NavigationExtras = {
				queryParams: {
					lastCodToro: this.lastCodToro,
				},
			};
			this.router.navigate(["/aggiungi-anagrafica"], navigationExtras);
		}
	}
	formatDate1(inputDate): any {
		if (inputDate != null) {
			const [year, month, day] = inputDate.split("-");
			const formattedDate = `${day}/${month}/${year}`;
			return formattedDate;
		}
	}
	formatDate(date): any {
		if (date !== "NULL" && date !== null) {
			const dateComponents = date.split("/");
			const formattedDate = new Date(`${dateComponents[2]}-${dateComponents[1]}-${dateComponents[0]}`);
			if (!isNaN(formattedDate.getTime())) {
				const timeZoneOffset = formattedDate.getTimezoneOffset() * 60000;
				const adjustedDate = new Date(formattedDate.getTime() - timeZoneOffset);
				const formattedDateString = adjustedDate.toISOString().split("T")[0];
				return formattedDateString;
			} else {
				return "Invalid Date";
			}
		}
	}

	selectSpecie(event) {
		const specie = event?.target?.value;
		this.razzeData = this.razze.filter((sp: any) => sp?.specie === specie);
	}

	retrieveRiproduttoriFuzzy() {
		this.apiService.getRiproduttoriFuzzy().subscribe((data: GenericResponse<ToroFuzzy>) => {
			if (data.success) {
				this.riproduttori = data.result;
				this.searcher = new FuzzySearch(this.riproduttori, ["Toro", "Matricola", "Matricola2", "Cod_dna", "cod_toro"], { sort: true });
				this.fuzResult = this.searcher.search(this.searchText);
			}
		});
	}

	onSearchText() {
		this.fuzResult = this.searcher.search(this.searchText);
	}

	selectToro(riproduttoreSelected) {
		var toroToUpdate = JSON.parse(localStorage.getItem("rowClickedData"));
		var listato = JSON.parse(localStorage.getItem("listato"));
		var rowIndex = parseInt(localStorage.getItem("rowClickedIndex"));
		toroToUpdate.Nome = riproduttoreSelected?.Toro;
		toroToUpdate.Matricola = riproduttoreSelected?.Matricola;
		if (riproduttoreSelected.Matricola2 !== undefined) {
			toroToUpdate.Matricola2 = riproduttoreSelected.Matricola2;
		}
		this.apiService.checkDuplicate(toroToUpdate).subscribe((data: any) => {
			switch (data) {
				case 0:
					toroToUpdate.Stato = "OK";
					break;
				case 1:
					toroToUpdate.Stato = "DUPLICATE";
					break;
				default:
					break;
			}
			listato[rowIndex] = Object.assign(listato[rowIndex], toroToUpdate);
			localStorage.setItem("listato", JSON.stringify(listato));
			this.closePopup();
			// this.listatoAltreadyUploaded();
		});
	}
	focusIn(_) {
		this.boolShowInputText = true;
	}

	focusOut(evt: Event) {
		this.boolShowInputText = false;
	}
	clickedResult(data) {
		this.focusOut(data);
		this.apiService.getAnimaldata(data).subscribe((res: any) => {
			if (res.success) {
				this.openPopup();
				this.focusOut(data);
				this.boolShowInputText = false;
				let locations = localStorage.getItem("location");
				this.location = JSON.parse(locations);
				this.clickedRowData = res?.result;
				const specie = this.clickedRowData?.specie;
				this.razzeData = this.razze.filter((sp: any) => sp?.specie === specie);
				let date = this.formatDate(this.clickedRowData.data_nasc);
				(this.specieValue = this.clickedRowData.specie),
					this.form.patchValue({
						id: this.clickedRowData.id,
						toro: this.clickedRowData.toro,
						matricola: this.clickedRowData.matricola,
						matricola2: this.clickedRowData.matricola2,
						specie: this.clickedRowData.specie,
						razza: this.clickedRowData.razza,
						cod_toro: this.clickedRowData.cod_toro,
						cod_dna: this.clickedRowData.cod_dna,
						provato: this.clickedRowData.provato,
						top100: this.clickedRowData.top100,
						data_nasc: date,
						settore: this.location.settore,
						cilindro: this.location.cilindro,
						piano: this.location.piano,
						contenit: this.location?.contenit,
						tipo_seq: this.clickedRowData?.dna?.tipo_seq,
						note: this.clickedRowData?.dna?.note,
						matric_ana: this.clickedRowData.matric_ana,
					});
			}
		});
	}
	onReceiveData(event: any) {
		if (event?.table === this.tableName) {
			this.rowData1$ = event?.data?.result;
			this.totalCount = event?.data?.total;
			this.pageNumber = event?.page;
		}
	}

	// onFilterChanged(params: any): void {
	//   this.filterValue = '';
	//   this.columnName = params.columns[0].colId;
	//   this.colDefs.forEach((column) => {
	//     const specificColumnFilterModel = params.api.getFilterModel()[this.columnName];
	//     if (specificColumnFilterModel) {
	//        this.filterValue = specificColumnFilterModel?.filter;
	//     }
	//   });

	//     this.apiService.reproduttoriNotFilter(this.pageNumber,this.itemPerPage,this.columnName,this.filterValue,this.sortingOrder).subscribe((res)=>{
	//       console.log(res)
	//       if(res.success){
	//         this.rowData1$ = res?.result
	//         this.totalCount = res?.total
	//         this.apiService.selectedPage.next(1);
	//       }
	//     })

	// }

	onFilterChanged(params: any): void {
		this.loader = true;
		this.filterValue = "";
		this.columnName = params.columns[0].colId;

		const filterModel = params.api.getFilterModel();
		const activeFilters = Object.keys(params.api.getFilterModel());
		console.log(activeFilters);
		this.col = activeFilters.length;
		for (const columnId in filterModel) {
			if (filterModel.hasOwnProperty(columnId)) {
				const specificColumnFilterModel = filterModel[columnId];
				if (specificColumnFilterModel) {
					this.columnName = columnId;
					this.filterValue = specificColumnFilterModel.filter;
					break; // Exit loop after finding the first column with a filter
				}
			}
		}
		if (this.col >= 1) {
			this.loader = false;
		}
		if (this.col <= 1) {
			this.apiService.reproduttoriNotFilter((this.pageNumber = 1), this.itemPerPage, this.columnName, this.filterValue, this.sortingOrder).subscribe({
				next: (res) => {
					console.log(res);
					if (res.success) {
						this.rowData1$ = res?.result;
						this.totalCount = res?.total;
						this.apiService.selectedPage.next(1);
						this.loader = false;
					}
				},
				error: (error) => {
					// Handle error
					this.loader = false;
				},
				complete: () => {
					// Handle completion
					this.loader = false;
				},
			});
		}
		console.log(`Filter value for column ${this.columnName}:`, this.filterValue);
		// this.loader = false
	}

	onSubmitpopup() {
		this.loader = true;
		this.apiService.updateRecordwith1({ ...this.location, note: this.Popupform.value.note }).subscribe((res: any) => {
			if (res.status == true) {
				// this.location = res?.calculatedLocation
				this.loader = false;
				this.tabletest();
				this.closePopup();
				this.toastr.success(res.message);
				// this.openPopup2()
			}
		});
	}
}
