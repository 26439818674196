import { Component, OnInit, HostListener, ViewChildren, ViewChild, OnDestroy, TemplateRef, ViewContainerRef } from "@angular/core";
import { FormGroup, FormControl, FormBuilder, Validators } from "@angular/forms";
import { AgGridAngular } from "ag-grid-angular";
import { CellClickedEvent, ColDef, GridOptions, GridReadyEvent, RowSelectedEvent } from "ag-grid-community";
import { ApiService } from "../_services/api.service";
import { Centro, GenericResponse, ToroFuzzy, Toro, Razza } from "../_models/index";
import FuzzySearch from "fuzzy-search";
import { Router } from "@angular/router";
import { StorageService } from "../_services/storage.service";
import { dummyData } from "../../assets/data/data";
import * as XLSX from "xlsx";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { ToastrService } from "ngx-toastr";
import { Subject, takeUntil } from "rxjs";

export interface Campionati {
	message: string;
	details: Map<string, ArrayBuffer>;
}

@Component({
	selector: "app-importa-listati",
	templateUrl: "./importa-listati.component.html",
	styleUrls: ["./importa-listati.component.scss"],
})
export class ImportaListatiComponent implements OnInit {
	files: File[] = [];
	uploadedFile: boolean;
	dragOver = false;
	review = false;
	importWarnings = true;
	rowData: any = [];
	test = "abc";
	searcher: FuzzySearch;
	searchText: string;
	fuzResult = [];
	riproduttori: ToroFuzzy[] = [];
	selectedText: string;
	noRowsTemplate: string;
	form = new FormGroup({
		dataCampo: new FormControl(new Date().toISOString().substring(0, 10)),
		operator: new FormControl(null),
	});
	listatoSummary: any;
	// Each Column Definition results in one Column.

	specie: string;
	newEntry: boolean;
	form1: FormGroup;
	razzeData: Razza[] = [];
	razze: Razza[] = [];
	loader: boolean = false;
	Popupform: FormGroup;
	location: any;
	lastCodToro: any;
	lastCodDna: any;
	data: any



	private destroy$: Subject<void> = new Subject<void>();

	spaciesData: any[] = [
		{ value: null, name: "Scegli Specie" },
		{ value: "B", name: "B - Bovini" },
		{ value: "BI", name: "BI - Bovini Importazione" },
		{ value: "E", name: "E - Equini" },
		{ value: "EI", name: "EI - Equini Importazione" },
		{ value: "S", name: "S - Suini" },
		{ value: "C", name: "C - Caprini" },
		{ value: "CI", name: "CI - Caprini Importazione" },
		{ value: "BU", name: "BU - Bufali" },
		{ value: "O", name: "O - Ovini" },
	];


	public columnDefs: ColDef[] = [
		{
			field: "Matricola",
			headerClass: "custom-header-class",
		},
		{ field: "Partita" },
		{ field: "Nome" },
		{
			field: "Dosi",
		},
		{
			cellRenderer: (params) => (params.value == undefined ? params.data.Nome || params.value : params.value),
			field: "Nome DB",
		},
		{
			cellRenderer: (params) => (params.data?.["somma Dosi"]),

			field: "Vecchie dosi"
		},

		{
			cellRenderer: (params) => {

				if (params.data?.Specie !== this?.specie) {
					let html = `<div style="background:${"red"}; width:100px;display:flex;align-items: center; justify-content:center">
				${params.data?.Specie || ""}
				</div>`;
					return html;
				}

				else {
					let html = `<div style="width:100px;display:flex;align-items: center; justify-content:center">
				${params.data?.Specie || ""}
				</div>`;
					return html;
				}


			},

			field: "Specie"
		},

		{
			cellRenderer: function (params) {
				var color = "#f38ba8";
				if (params.data.Stato == "OK") {
					color = "#6B9E6A";
				}
				if (params.data.Stato == "NOT_FOUND") {
					color = "grey";
				}
				if (params.data.Stato == "WARNING") {
					color = "#f9e2af";
				}
				if (params.data.Stato == "DUPLICATE") {
					color = "#89b4fa";
				}
				let html = `<div style="display:flex;height: 100%;aspect-ratio: 1 / 1;align-items: center;">\
          <div style="height: 50%;aspect-ratio: 1 / 1;background-color: ${color};border-radius: 50%;text-align: center;border: 0.5px solid ${"black"}"></div>\
        </div>`;
				return html;
			},
			field: "Stato",
		},
	];

	// DefaultColDef sets props common to all Columns
	public defaultColDef: ColDef = {
		flex: 1,
		resizable: true,
		sortable: true,
	};

	@ViewChild("agGrid1") agGrid1!: AgGridAngular;
	@ViewChild("popupContainer", { read: TemplateRef })
	popupContainer: TemplateRef<any>;
	fileName: any;
	subscription: any;
	gridOptions: GridOptions;
	constructor(private formBuilder: FormBuilder, private http: ApiService, private https: HttpClient, private router: Router, private viewContainerRef: ViewContainerRef, private storageService: StorageService,
		public apiService: ApiService, public toastr: ToastrService,
	) {
		this.noRowsTemplate = `<span>Non sono presenti righe</span>`;
		this.gridOptions = {
			defaultColDef: {
				sortable: true,
				filter: true,
				resizable: true,
			},
		};

		this.form1 = this.formBuilder.group({
			toro: [null, Validators.required],
			matricola: [null, Validators.required],
			matricola2: [null],
			specie: [null, Validators.required],
			razza: [null],
			cod_dna: [null],
			cod_toro: [null],
			provato: [null],
			top100: [null],
			data_nasc: [null],
			matric_ana: [null],
		});
	}

	retrieveRazze() {
		this.apiService.next_dna_cod.pipe(takeUntil(this.destroy$)).subscribe({
			next: (data) => {
				// this.form1.controls['cod_dna'].setValue(data);
			}, error: (err) => { }
		});
		this.apiService.retrieveRazze().subscribe({
			next: (data) => {
				this.razze = data.result as Razza[];
			},
			error: (err) => {
			},
		});
	}

	ngOnInit(): void {
		this.specie = localStorage.getItem("specie");
		this.subscription = this.http.specieSubject.subscribe(
			({ specie, isChanged }) => {

				this.specie = localStorage.getItem("specie");
				if (!isChanged) {
					// console.log("Initial load, do not trigger update");
				} else {
					this.specie = localStorage.getItem("specie");
					this.columnDefs = this.getUpdatedColumnDefs();
					this.gridOptions.api?.setColumnDefs?.(this.columnDefs);
					this.gridOptions.api?.refreshCells();
				}
			}
		);
		this.uploadedFile = localStorage.getItem("listato") ? true : false;
		if (this.uploadedFile) {
			setTimeout(() => {
				// FIXME: check if there is a better solution for waiting the state of ViewlChild
				this.listatoAltreadyUploaded();
			}, 1);
		}
		this.retrieveRiproduttoriFuzzy(); //TODO: save to LS?

		this.retrieveRazze();
		let codeDna = localStorage.getItem('codedna')
		let lastCodDna = localStorage.getItem("lastCodDna");
		let lastCodToro = localStorage.getItem("lastCodToro");
		if (lastCodToro) {
			this.lastCodDna = lastCodDna
			this.form1.controls['cod_dna'].setValue(lastCodDna)
		}
		if (lastCodToro) {
			this.lastCodToro = lastCodToro
			this.form1.controls['cod_toro'].setValue(lastCodToro);
		}
		let locations = localStorage.getItem('location')
		this.location = JSON.parse(locations)

	}

	ngAfterViewInit(): void {
		// this.openPopup();
		if (this.location) {
			this.Popupform = this.formBuilder.group({
				settore: [this.location.settore],
				cilindro: [this.location.cilindro],
				piano: [this.location.piano],
				contenit: [this.location.contenit],
				sequenziat: [null],
				note: [null]
			});
		}

	}
	getUpdatedColumnDefs(): ColDef[] {
		return [
			{
				field: "Matricola",
				headerClass: "custom-header-class",
			},
			{ field: "Partita" },
			{ field: "Nome" },
			{
				field: "Dosi",
			},
			{
				cellRenderer: (params) => (params.value == undefined ? params.data.Nome || params.value : params.value),
				field: "Nome DB",
			},
			{
				cellRenderer: (params) => (params.data?.["somma Dosi"]),
				field: "Vecchie dosi",
			},
			{
				cellRenderer: (params) => {
					if (params.data?.Specie !== this.specie) {
						let html = `<div style="background:${"red"}; width:100px;display:flex;align-items: center; justify-content:center">
				${params.data?.Specie || ""}
				</div>`;
						return html;
					} else {
						let html = `<div style="width:100px;display:flex;align-items: center; justify-content:center">
				${params.data?.Specie || ""}
				</div>`;
						return html;
					}
				},
				field: "Specie",
			},
			{
				cellRenderer: function (params) {
					var color = "#f38ba8";
					if (params.data.Stato == "OK") {
						color = "#a6e3a1";
					}
					if (params.data.Stato == "NOT_FOUND") {
						color = "#808080";
					}
					if (params.data.Stato == "WARNING") {
						color = "#f9e2af";
					}
					if (params.data.Stato == "DUPLICATE") {
						color = "#89b4fa";
					}
					let html = `<div style="display:flex;height: 100%;aspect-ratio: 1 / 1;align-items: center;">\
				<div style="height: 50%;aspect-ratio: 1 / 1;background-color: ${color};border-radius: 50%;text-align: center;"></div>\
			  </div>`;
					return html;
				},
				field: "Stato",
			},
		];
	}

	retrieveRiproduttoriFuzzy() {
		this.http.getRiproduttoriFuzzy().subscribe((data: GenericResponse<ToroFuzzy>) => {
			if (data.success) {
				this.riproduttori = data.result;
				this.searcher = new FuzzySearch(this.riproduttori, ["Toro", "Matricola", "Matricola2"], { sort: true });
				this.fuzResult = this.searcher.search(this.searchText);
				// console.log("this.riproduttori:", this.riproduttori);
				// console.log("this.fuzResult:", this.fuzResult);
				// console.log("this.selectedText:", this.selectedText);
				// console.log("this.searchText:", this.searchText);
			}
		});
	}

	onSearchText() {
		this.fuzResult = this.searcher.search(this.searchText);
		// console.log("this.riproduttori:", this.riproduttori);
		// console.log("this.fuzResult:", this.fuzResult);
		// console.log("this.selectedText:", this.selectedText);
		// console.log("this.searchText:", this.searchText);
	}

	selectToro(riproduttoreSelected) {
		var toroToUpdate = JSON.parse(localStorage.getItem("rowClickedData"));
		var listato = JSON.parse(localStorage.getItem("listato"));
		var rowIndex = parseInt(localStorage.getItem("rowClickedIndex"));
		toroToUpdate.Nome = riproduttoreSelected.Toro;
		toroToUpdate.Matricola = riproduttoreSelected.Matricola;
		if (riproduttoreSelected.Matricola2 !== undefined) {
			toroToUpdate.Matricola2 = riproduttoreSelected.Matricola2;
		}
		this.http.checkDuplicate(toroToUpdate).subscribe((data: any) => {
			switch (data) {
				case 0:
					toroToUpdate.Stato = "OK";
					break;
				case 1:
					toroToUpdate.Stato = "DUPLICATE";
					break;
				default:
					break;
			}
			listato[rowIndex] = Object.assign(listato[rowIndex], toroToUpdate);
			localStorage.setItem("listato", JSON.stringify(listato));
			this.closePopup();
			this.listatoAltreadyUploaded();
		});
	}

	@HostListener("dragover", ["$event"]) onDragOver(evt) {
		evt.preventDefault();
		evt.stopPropagation();
		evt.dataTransfer.dropEffect = "copy";
		this.dragOver = true;
	}

	@HostListener("dragleave", ["$event"]) onDragLeave(evt) {
		evt.preventDefault();
		evt.stopPropagation();
		this.dragOver = false;
	}

	@HostListener("drop", ["$event"]) onDrop(evt) {
		evt.preventDefault();
		evt.stopPropagation();
		this.dragOver = false;
		this.files = evt.dataTransfer.files;
		this.sendFiles();
	}

	loadFile(evt) {
		this.files = evt.target.files;
		this.sendFiles();
	}

	// Example using Grid's API
	clearSelection(): void {
		this.agGrid1.api.deselectAll();
	}

	onCellClicked(e: CellClickedEvent): void {
		localStorage.setItem("rowClickedIndex", e.rowIndex.toString());
		localStorage.setItem("rowClickedData", JSON.stringify(e.data));
		console.log("cellClicked", e);
		let data = e.data;
		this.searchText = data.Matricola || data.Nome;
		this.onSearchText();
		this.openPopup(data.Nome, data.Matricola);
		this.rowData = data

		this.form1.patchValue({
			toro: data.Nome || null,
			matricola: data.Matricola || null,
			matricola2: data.Matricola2 || null,  // Ensure null handling
			specie: null,  // Assign null values explicitly
			razza: null,
			cod_dna: this.lastCodDna,
			cod_toro: this.lastCodToro,
			provato: null,
			top100: null,
			data_nasc: null,
			matric_ana: null,
		});
	}

	setGridData(grid: AgGridAngular, data: any[]) {
		grid.api.setRowData(data);
		grid.api.sizeColumnsToFit();
	}

	convertDetails(details: Map<string, ArrayBuffer>): Array<Map<string, string>> {
		console.log(details);
		var arr: Array<Map<string, string>> = [];
		for (const [key, value] of Object.entries(details)) {
			if (value.length > 0) {
				value.forEach((element) => {
					element["Stato"] = key;
					arr.push(element);
				});
			}
		}
		return arr;
	}

	listatoAltreadyUploaded() {
		let table = JSON.parse(localStorage.getItem("listato"));
		this.setGridData(this.agGrid1, table);
	}

	sendFiles() {
		let formData = new FormData();
		formData.append("file", this.files[0]);
		this.uploadedFile = true;

		this.http.controlloListato(this.files[0]).subscribe({
			next: (data) => {
				console.log(data);
				var d = data as any;
				// let table = this.convertDetails(d.details);
				let table: any = d.details;
				// console.log(table);
				this.data = table
				this.lastCodDna = d.lastCodDna,
					this.lastCodToro = d.lastCodToro

				localStorage.setItem("listato", JSON.stringify(table));
				localStorage.setItem("lastCodDna", d.lastCodDna);
				localStorage.setItem("lastCodToro", d.lastCodToro);
				this.setGridData(this.agGrid1, table);
			},
			error: (err) => {
				console.log({ err });
			},
		});
	}

	// sendFiles() {
	//   console.log(this.storageService.getCentro());
	//   let formData = new FormData();
	//   formData.append('file', this.files[0]);
	//   this.uploadedFile = true;
	//   this.http
	//     .controlloListato(this.files[0], this.storageService.getCentro())
	//     .subscribe({
	//       next: (data) => {
	//         var d = data as Campionati;
	//         this.setGridData(this.agGrid1, d.details['OK']);
	//         this.setGridData(this.agGrid2, d.details['WARNING']);
	//         this.setGridData(this.agGrid3, d.details['ERROR']);
	//         this.setGridData(this.agGrid4, d.details['DUPLICATE']);
	//         this.setGridData(this.agGrid5, d.details['NOT_FOUND']);
	//       },
	//       error: (err) => {
	//         console.log({ err });
	//       },
	//     });
	//   return 1;
	// }

	import() {
		this.http.modaBackDrop.next(true);
		this.viewContainerRef.createEmbeddedView(this.popupContainer);
		this.review = true;

		var listato = JSON.parse(localStorage.getItem("listato"));
		let conteggio = { ok: 0, agg: 0, no: 0 };

		listato.forEach((oggetto) => {
			if (oggetto.Stato === "OK" || oggetto.Stato === "WARNING") {
				conteggio.ok++;
			} else if (oggetto.Stato === "DUPLICATE") {
				conteggio.agg++;
			} else if (oggetto.Stato === "ERROR") {
				conteggio.no++;
			}
		});
		this.listatoSummary = `Verranno importate ${conteggio.ok} partita/e.`;
		if (conteggio.agg) {
			this.listatoSummary = this.listatoSummary + `<br>Verranno aggiunte dosi a ${conteggio.agg} partita/e.`;
		}
		if (conteggio.no) {
			this.listatoSummary = this.listatoSummary + `<br>NON Verranno importate ${conteggio.no} partita/e.`;
		}

		return 1;
	}

	downLoadFile(data: any, type: string) {
		let blob = new Blob([data], { type: type });
		let url = window.URL.createObjectURL(blob);
		let pwa = window.open(url);
		if (!pwa || pwa.closed || typeof pwa.closed == "undefined") {
			alert("Sembrerebbe che tu abbia disabilitato i popup. Abilitali per visualizzare il PDF.");
			// this.router.navigate(["/dashboard"]);
		} else {
			// this.router.navigate(["/dashboard"]);
		}
	}

	openPopup(nome, matricola) {
		this.http.modaBackDrop.next(true);
		const context = {
			$implicit: {
				nome: nome,
				matricola: matricola,
			},
		};
		this.viewContainerRef.createEmbeddedView(this.popupContainer, context);
	}

	closePopup() {
		localStorage.removeItem("rowClickedData");
		localStorage.removeItem("rowClickedIndex");
		this.http.modaBackDrop.next(false);
		this.newEntry = false;
		this.review = false;
		this.viewContainerRef.clear();
	}

	saveData() {
		// var arrayToUpload = [];

		// this.agGrid1.api.forEachNode((rowNode, _) => {
		// 	arrayToUpload.push(rowNode.data);
		// });

		// console.log(arrayToUpload);

		// this.http.importaListato(arrayToUpload).subscribe((response) => {
		// 	console.log(response);
		// 	this.downLoadFile(response, "application/pdf");
		// });
		console.log(this.form.value.operator)
		var listato = JSON.parse(localStorage.getItem("listato"));
		listato = listato.filter((item) => ["OK", "DUPLICATE", "WARNING"].includes(item.Stato));
		this.http.importaListato(listato, this.form.value.dataCampo, this.form.value.operator).subscribe({
			next: (response: any) => {
				console.log(response);
				if (response?.status) {
					let url = environment.url + response?.filePath
					this.fileName = response?.fileName
					// this.downloadTextFile(url)
					this.downloadPdfFile(url)
				}
				// this.downLoadFile(response, "application/pdf");

			},
			error: (err) => {
				console.log({ err });
			},
		});

		this.clearLocalStorage();
		this.closePopup();
	}



	downloadTextFile(url: string): void {
		this.https.get(url, { responseType: 'text' }).subscribe((data: string) => {
			const blob = new Blob([data], { type: 'text/plain' });
			const anchor = document.createElement('a');
			anchor.href = window.URL.createObjectURL(blob);
			anchor.download = this.fileName;
			document.body.appendChild(anchor);
			anchor.click();
			window.URL.revokeObjectURL(anchor.href);
			document.body.removeChild(anchor);
		});
	}


	downloadPdfFile(url: string): void {
		this.https.get(url, { responseType: "blob" }).subscribe(
			(data: Blob) => {
				if (data.size > 0) {
					const blob = new Blob([data], { type: "application/pdf" });
					const anchor = document.createElement("a");
					const fileName = this.fileName || 'download.pdf';

					anchor.href = window.URL.createObjectURL(blob);
					anchor.download = fileName;
					document.body.appendChild(anchor);
					anchor.click();
					window.URL.revokeObjectURL(anchor.href);
					document.body.removeChild(anchor);
				} else {
					console.error('Received empty Blob data');
				}
			},
			(error) => {
				console.error('Error downloading file:', error);
			}
		);
	}

	downloadXlsFile(url: string): void {
		this.https.get(url, { responseType: 'blob' }).subscribe((data: Blob) => {
			const blob = new Blob([data], { type: 'application/vnd.ms-excel' });
			const anchor = document.createElement('a');
			anchor.href = window.URL.createObjectURL(blob);
			anchor.download = this.fileName;
			document.body.appendChild(anchor);
			anchor.click();
			window.URL.revokeObjectURL(anchor.href);
			document.body.removeChild(anchor);
		});
	}

	provaGenerics() {
		this.http.genericTableRetrieve("Centro", { select: ["Id", "Nascita"] }).subscribe((data: GenericResponse<Centro>) => {
			if (data.success) {
				console.log(data.result);
			} else {
				console.log(data.type);
			}
		});
	}

	clearLocalStorage() {
		this.uploadedFile = false;
		localStorage.removeItem("listato");
		localStorage.removeItem("lastCodToro");
		localStorage.removeItem("lastCodDna");
		localStorage.removeItem("rowClickedData");
		localStorage.removeItem("rowClickedIndex");
	}

	clear() {
		this.clearLocalStorage();
		this.ngOnInit();
	}

	saveFileToLS() {
		const reader = new FileReader();

		reader.onload = (e: any) => {
			const data = new Uint8Array(e.target.result);
			const workbook = XLSX.read(data, { type: "array" });

			const worksheetName = workbook.SheetNames[0];
			const worksheet = workbook.Sheets[worksheetName];

			const jsonData = XLSX.utils.sheet_to_json(worksheet);
			localStorage.setItem("fileListato", JSON.stringify(jsonData));
			// console.log(jsonData);
		};

		reader.readAsArrayBuffer(this.files[0]);
	}

	getFileFromLS() {
		var listato = JSON.parse(localStorage.getItem("fileListato"));
		const worksheet = XLSX.utils.json_to_sheet(listato);
		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, worksheet, "Listato");
		const wbout: any = XLSX.write(workbook, { bookType: "xls", type: "binary" });
		// Converte la binary string in un array di byte
		const buf = new ArrayBuffer(wbout.length);
		const view = new Uint8Array(buf);
		for (let i = 0; i < wbout.length; i++) {
			view[i] = wbout.charCodeAt(i) & 0xff;
		}
		// Crea un file blob e restituisce un oggetto File
		const blob = new Blob([buf], { type: "application/vnd.ms-excel" });
		const file = new File([blob], "data.xls", { type: "application/vnd.ms-excel" });
		return file;
	}

	downloadTemplete() {
		let url = environment.url + 'public/files/importa_listati_template.xls'
		this.fileName = 'importa_listati_template.xls'
		this.downloadXlsFile(url)
	}


	///////////////////

	openCreateEntry() {
		this.newEntry = true
		this.review = false;
		this.viewContainerRef.clear();
	}

	onSubmitpopup() {
		this.closePopup()
		// this.onSubmit()
	}

	formatDate1(inputDate): any {
		if (inputDate != null) {
			const [year, month, day] = inputDate.split('-');
			const formattedDate = `${day}/${month}/${year}`;
			return formattedDate;
		}

	}


	onSubmit() {
		let dataNasc = this.formatDate1(this.form1.value.data_nasc)
		const payload = { ...this.form1.value, data_nasc: dataNasc, location: this.Popupform?.value };
		this.apiService?.createRecord(payload).subscribe({
			next: (res) => {
				if (res.status) {
					this.loader = false;
					// const new_code_dna = this.apiService.incrementString(res?.data?.cod_dna);
					// this.apiService.new_dna_cod.next(new_code_dna);
					var listato = JSON.parse(localStorage.getItem("listato"));

					const updatedData = listato.map((item) => {
				
						if (item.Matricola === res.data?.matricola) {
						
							return { ...item, Stato: "OK", Specie: res.data?.specie }; 
						}
						return item; 
					});

					// Set the updated data
					this.data = updatedData;
					this.setGridData(this.agGrid1, updatedData);
					localStorage.setItem("listato", JSON.stringify(updatedData));
					localStorage.setItem("lastCodDna", res.data?.cod_dna);
					localStorage.setItem("lastCodToro", res.data?.cod_toro);
					this.toastr.success(res.message);
					this.newEntry = false;
					this.review = false;
					this.viewContainerRef.clear();
					// this.ngOnInit();
					this.retrieveRiproduttoriFuzzy();

				} else {
					this.toastr.error(res.message);
					this.loader = false;

				}
			},
			error: (err) => {
				this.toastr.error(err.message)
			}
		});


	}


	selectSpecie(event) {
		const specie = event?.target?.value;
		this.razzeData = this.razze.filter((sp: any) => sp?.specie === specie);
	}



}

// FIXME: Mostra errore nella lettura del file
// FIXME: Aggiunta toro da importa listati
// FIXME: row empty in middle causes problems
