import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";


@Injectable({
    providedIn: "root",
})
export class AlertService {
    private showAlertSubject = new BehaviorSubject<boolean>(false);
    public isCanceled$ = new Subject<boolean>();
    public showAlert$ = this.showAlertSubject.asObservable();
    constructor() { }
    showAlert() { this.showAlertSubject.next(true); }

}