<div>
<app-loader *ngIf="loader"></app-loader>
<app-conform [message]="'Are you sure you want to delete?'"(confirm)="confirm($event)"></app-conform>

<div class="header d-flex mt-2">
    <h1 class="title">QUALITÀ SEME</h1>
    <button class="button mr-1" (click)="openPopup();openPopup1()">Crea</button>
</div>

<ag-grid-angular style="height: 750px;"
    class="ag-theme-alpine-dark basic-table" [rowData]="rowData$" [gridOptions]="gridOptions">
</ag-grid-angular>

<div class="pagination">
    <!-- <app-pagination [dataLength]="totalCount" [tableName]="tableName" [itemPerPage]="itemPerPage"
        (dataOnPage)="receiveData($event)"></app-pagination> -->
</div>

<ng-template #popupContainer let-options>
    <div class="popup-window animate-in">
        <!-- <div class="popup-footer">
            <div><button class="button danger" type="submit">{{changeButtun?'Update':'Save'}}</button></div>
            <div><button class="button" type="button" (click)="closePopup()">Chiudi</button></div>
        </div> -->
        <div class="form-container">
            <form [formGroup]="addL30Qs" (ngSubmit)="onSubmit()">
                <div class="row">
                    <div class="column field">
                        <label>*Cod Cam:</label>
                        <input type="text" formControlName="codice_cam" required />
                        <div *ngIf="(formSubmitted || fc['codice_cam'].touched) && fc['codice_cam'].errors" class="alert">
                            <div *ngIf="(formSubmitted || fc['codice_cam'].touched)"> Please enter Cod Cam </div>
                        </div>
                    </div>
                    <div class="column field">
                        <label>*Cod Az:</label>
                        <input type="number" formControlName="codice_az" required />
                        <!-- <div *ngIf="(formSubmitted || fc['codice_az'].touched) && fc['codice_az'].errors" class="alert">
                            <div *ngIf="(formSubmitted || fc['codice_az'].touched)"> Please enter seme code name</div>
                        </div> -->
                    </div>
                </div>
                <div class="row">
                    <div class="column field">
                        <label>SPECIE:</label>
                        <select  (change)="selectSpecie($event)" class="specieSelect" formControlName="specie">
                            <option [value]="specie?.value" *ngFor="let specie of spaciesData;">{{specie?.name}}</option>
                        </select>
                        <!-- <input type="number" formControlName="specie" /> -->
                    </div>
                    <div class="column field">
                        <label>LOTTO:</label>
                        <input type="text" formControlName="lotto" />
                    </div>
                </div>

                <div class="row">
                    <div class="column field">
                        <label>ANNO Rif:</label>
                        <select id="yearSelect"  name="year" class="specieSelect2" (change)="selectYear($event)" formControlName="anno_rif">
                            <option *ngFor="let year of years" [value]="year">{{ year }}</option>
                        </select>
                        <!-- <input type="number" formControlName="anno_rif" /> -->
                    </div>
                    <div class="column field">
                        <label>Cod SOGGETTO:</label>
                        <input type="number" formControlName="cod_toro" />
                    </div>
                </div>
                <div class="row">
                    <div class="column field">
                        <label>Cod Lab:</label>
                        <input type="number" formControlName="codice_lab"/>
                    </div>
                    <div class="column field">
                        <label>Op EDIT:</label>
                        <input type="number" formControlName="edit_oper" />
                    </div>
                </div>
                <div class="row">
                    <div class="column field">
                        <label>Num ANAL:</label>
                        <input type="number" formControlName="numero_cam" />
                    </div>
                    <div class="column field">
                        <label>NON CONFOR:</label>
                        <input type="number" formControlName="non_conf" />
                    </div>
                </div>
                <div class="row">
                    <div class="column field">
                        <label>NON CONFOR 2:</label>
                        <input type="number" formControlName="non_conf2" />
                    </div>
                    <div class="column field">
                        <label>Data ANALISI:</label>
                        <input type="date" formControlName="data_anal" />
                    </div>
                </div>
                <div class="row">
                    <div class="column field">
                        <label>Data PROD:</label>
                        <input type="date" formControlName="data_prod" />
                    </div>
                    <div class="column field">
                        <label>Tipo DOSE:</label>
                        <input type="number" formControlName="tipo_dose" />
                    </div>
                </div>
                <div class="row">
                    <div class="column field">
                        <label>Ora Scong:</label>
                        <input type="number" formControlName="scong_ora" />
                    </div>
                    <div class="column field">
                        <label>Cod Bagno:</label>
                        <input type="number" formControlName="scong_bagn" />
                    </div>
                </div>
                <div class="row">
                    <div class="column field">
                        <label>Op SCONG:</label>
                        <input type="number" formControlName="scong_oper" />
                    </div>
                    <div class="column field">
                        <label>Ora PARAF:</label>
                        <input type="number" formControlName="paraf_ora" />
                    </div>
                </div>
                <div class="row">
                    <div class="column field">
                        <label>CONC:</label>
                        <input type="number" formControlName="conc" (input)="getnspm()"/>
                    </div>
                    <div class="column field">
                        <label>Setup CONC:</label>
                        <input type="number" formControlName="conc_setup" />
                    </div>
                </div>
                <div class="row">
                    <div class="column field">
                        <label>CONC_OPER:</label>
                        <input type="number" formControlName="conc_oper" />
                    </div>
                    <div class="column field">
                        <label>MT:</label>
                        <input type="number" formControlName="mt" />
                    </div>
                </div>
                <div class="row">
                    <div class="column field">
                        <label>MP:</label>
                        <input type="number" formControlName="mp" (input)="getnspm()"/>
                    </div>
                    <div class="column field">
                        <label>VM:</label>
                        <input type="number" formControlName="vm" />
                    </div>
                </div>
                <div class="row">
                    <div class="column field">
                        <label>Setup MOT:</label>
                        <input type="number" formControlName="mot_setup" />
                    </div>
                    <div class="column field">
                        <label>Oper MOT:</label>
                        <input type="number" formControlName="mot_oper" />
                    </div>
                </div>
                <div class="row">
                    <div class="column field">
                        <label>NSPM:</label>
                        <input type="number" formControlName="nspm" (input)="getnspm()"/>
                    </div>
                    <div class="column field">
                        <label>VCA:</label>
                        <input type="text" formControlName="vca" />
                    </div>
                </div>
                <div class="row">
                    <div class="column field">
                        <label>CONC(c):</label>
                        <input type="number" formControlName="total_conc_c" readonly/>
                    </div>
                    <div class="column field">
                        <label>MP(c):</label>
                        <input type="number" formControlName="total_mp_c" readonly/>
                    </div>
                </div>
                <div class="row">
                    <div class="column field">
                        <label>NSPM(c)</label>
                        <input type="number" formControlName="total_nspm_c" (input)="getnspm()" readonly/>
                    </div>
                    <!-- <div class="column field">
                        <label>MP(c):</label>
                        <input type="text" formControlName="total_mp_c" />
                    </div> -->
                </div>
                <!-- <div class="popup-footer">
                    <div><button class="button danger" type="submit">{{changeButtun?'Update':'Save'}}</button></div>
                    <div><button class="button" type="button" (click)="closePopup()">Chiudi</button></div>
                </div> -->

                <div class="main-footer"  [ngClass]="changeButtun?'main-footer':'main-footer1'">
                    <div class="delete" *ngIf="changeButtun">
                        <i class="fa fa-trash customtrash" aria-hidden="true" (click)="openmodel()"></i>
                    </div>
                    <div class="popup-footer">
                        <div><button class="button danger" type="submit">{{changeButtun?'Aggiorna':'Salva'}}</button></div>
                        <div><button class="button" type="button" (click)="closePopup()">Chiudi</button></div>
                    </div>
                </div>
                
            </form>
        </div>

    </div>
</ng-template>
</div>