import { Component } from "@angular/core";
import { ApiService } from "../_services/api.service";
import { take, takeWhile } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { environment } from "src/environments/environment";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { saveAs } from 'file-saver';
@Component({
	selector: "app-statistics-overview",
	templateUrl: "./statistics-overview.component.html",
	styleUrls: ["./statistics-overview.component.scss"],
})
export class StatisticsOverviewComponent {
	loader: boolean = false;
	cards: any = [];
	fagfilename: any;
	spaciesData: any[];
	specie: any;
	loader2: boolean = true;
	selectedData: any;
	centriCodiceAz: any;
	anna: string | number;
	isAlive = true;

	anno: string;
	codiceAz: string;
	userType: string;
	constructor(private apiService: ApiService, private http: HttpClient, private toastr: ToastrService) {
		this.anno = localStorage.getItem("anna");
		this.codiceAz = localStorage.getItem("centriCodiceAz");
	}
	ngOnInit(): void {
		this.getSpeciesList();
		this.centriCodiceAz = localStorage.getItem("centriCodiceAz") ? localStorage.getItem("centriCodiceAz") : 1;
		this.specie = localStorage.getItem("specie") ? localStorage.getItem("specie") : "BI";
		this.anna = localStorage.getItem("anna") ? localStorage.getItem("anna") : 2022;
		this.userType = localStorage.getItem("userType");
		this.topbar();

		// this.getSpecieValue()
	}

	ngOnDestroy() {
		this.isAlive = false;
	}
	topbar() {
		this.apiService.current.pipe(takeWhile(() => this.isAlive)).subscribe((res) => {
			if (res) {
				this.centriCodiceAz = res.codice_az ? res.codice_az : localStorage.getItem("centriCodiceAz");
				this.specie = res.specie ? res.specie : localStorage.getItem("specie");
				this.anna = res.anna ? res.anna : localStorage.getItem("anna");
				this.statsCalculation();
			}
		});
		this.statsCalculation();
	}
	getSpecieValue() {
		this.apiService.getSpecieValue().subscribe((res: any) => {
			this.spaciesData = res.result;
			this.spaciesData.filter((res) => {});
		});
	}
	statsCalculation() {
		this.loader = true;
		let payload = {
			centriCodiceAz: this.centriCodiceAz,
			anno: this.anna,
			specie: this.specie,
		};
		this.apiService.Statscal(payload).subscribe((res: any) => {
			if (res?.success) {
				this.cards = res.result;
				this.apiService.current?.next(null);
				this.loader = false;
			}
		});
	}

	downloadTextFile(url: string): void {
		this.http.get(url, { responseType: "text" }).subscribe((data: string) => {
			const blob = new Blob([data], { type: "text/plain" });
			const anchor = document.createElement("a");
			anchor.href = window.URL.createObjectURL(blob);
			anchor.download = this.fagfilename;
			document.body.appendChild(anchor);
			anchor.click();
			window.URL.revokeObjectURL(anchor.href);
			document.body.removeChild(anchor);
		});
	}

	downloadPDF(url: string): void {
		this.http.get(url, { responseType: "blob" }).subscribe((data: Blob) => {
			const blob = new Blob([data], { type: "application/pdf" });
			const anchor = document.createElement("a");
			anchor.href = window.URL.createObjectURL(blob);
			anchor.download = this.fagfilename; // The filename for the PDF
			document.body.appendChild(anchor);
			anchor.click();
			window.URL.revokeObjectURL(anchor.href);
			document.body.removeChild(anchor);
		});
	}

	generateFrequency() {
		this.anno = localStorage.getItem("anna");
		this.apiService?.generateFrequency(this.anno).subscribe({
			next: (res: any) => {
				if (res) {
					if (res.status) {
						this.toastr.success(res.message);
						let url = environment.url + res.filePath;
						this.fagfilename = res.fileName;
						if (res.user_type === "DNE") this.downloadTextFile(url);
						else this.downloadPDF(url);
					} else {
						this.toastr.error(res.message);
					}
				}
			},
			error: (err) => {
				this.loader = false;
				this.toastr.error("An error occurred");
			},
		});
		// this.loader = true;
		// let payload = {};
		// this.http.generateFrequency(payload).subscribe((res:any)=>{
		//   this.loader = false;
		//   if(res.success){
		//     console.log(res);
		//   }
		// })
	}

	// receiveDataFromChild(event){
	//   this.loader = true;
	//   this.specie = event
	//   let payload = {
	//     centriCodiceAz:this.centriCodiceAz,
	//     anno: this.selectedData,
	//     specie: this.specie
	//   }
	//   this.http.Statscal(payload).subscribe((res:any)=>{
	//     if(res.success){
	//       this.loader = false
	//       this.cards = res.result
	//     }
	//   })
	// }
	// recevedate(event){
	//   this.loader = true
	//   this.selectedData = event
	//   let payload = {
	//     centriCodiceAz:this.centriCodiceAz,
	//     anno: this.selectedData,
	//     specie: this.specie
	//   }
	//   this.http.Statscal(payload).subscribe((res:any)=>{
	//     if(res.success){
	//       this.loader = false
	//       this.cards = res.result
	//     }
	//   })
	// }
	transform(value: any): string {
		if (typeof value === "string") {
			// If the input is a string, parse it to a float first
			value = parseFloat(value);
		}

		if (typeof value === "number" && !isNaN(value)) {
			return value.toFixed(1);
		} else {
			return ""; // Handle invalid input, or return any default value you prefer
		}
	}
	getSpeciesList(){
  
		this.apiService?.getSpecies().subscribe({
		  next: (res: any) => {
			if (res) {
			 this.spaciesData= res.data;
	
			}
		  },
		  error: (err:HttpErrorResponse) => {
		
		  
			if(err?.error.errorMessage){
			  this.toastr.error(err?.error.errorMessage);
			}
			else{
			this.toastr.error("An error occurred");
			}
		  },
		});
	  }
	genrateReferto() {
		this.anno = localStorage.getItem("anna");
		this.codiceAz = localStorage.getItem("centriCodiceAz");
		this.apiService?.generatetextRefer(this.anno,this.codiceAz).subscribe({
			next: (res: any) => {
				if (res) {
					if (res.status) {
						this.toastr.success(res.message);
						let url = environment.url + res.filePath;
						this.fagfilename = res.fileName;
						if (res.user_type === "DNE") this.downloadTextFile(url);
						else this.downloadPDF(url);
						
					} else {
						this.toastr.error(res.message);
					}
				}
			},
			error: (err) => {
				this.loader = false;
				this.toastr.error("An error occurred");
			},
		});
	}

	genrateLiberto() {
		// Get the current year
		const currentYear = new Date().getFullYear();

		// Subtract 1 from the current year
		this.anno = (currentYear - 1).toString();
		// this.anno = localStorage.getItem("anna");
		this.apiService.generatetextLiberto(this.anno).subscribe({
			next: (data) => {
			  if (data['success']) {
				let url = environment.url + data['filePath'];
				let filename = data['filenames']; // Get the filename directly
				this.downloadLibertodoc(url, filename);
				this.loader = false;
			  } else {
				this.loader = false;
			  }
			},
			error: (err) => {
			  console.log(err);
			  this.loader = false;
			}
		  });
	}
	downloadLibertodoc(url: string, filename: string): void {
		this.http.get(url, { responseType: 'blob' }).subscribe(
		  (data) => {
			const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
			saveAs(blob, filename); 
		  },
		  (error) => {
			console.error('Error downloading file:', error);
		  }
		);
	  }
	getmipaafe() {
		this.anno = localStorage.getItem("anna");
		this.codiceAz = localStorage.getItem("centriCodiceAz");
		this.apiService?.getMipaafeData(this.anno).subscribe({
			next: (res: any) => {
				if (res) {
					let url = environment.url + res.filepath;
					this.fagfilename = res.filename;
					this.downloadPDF(url);
				}
			},
			error: (err) => {
				this.loader = false;
				this.toastr.error("An error occurred");
			},
		});
	}

	getvcaDelCentro() {
		this.anno = localStorage.getItem("anna");
		this.codiceAz = localStorage.getItem("centriCodiceAz");
		this.apiService?.vcaDelCentroData(this.anno, this.codiceAz).subscribe({
			next: (res: any) => {
				if (res) {
					let url = environment.url + res.filepath;
					this.fagfilename = res.filename;
					this.downloadPDF(url);
				}
			},
			error: (err) => {
				this.loader = false;
				this.toastr.error("An error occurred");
			},
		});
	}

	isModalOpen = false; // Controls the modal visibility
	fields = {
		"CONC.SP100-1": "conc_sp100",
		"CONC.SP100-2": "conc2sp100",
		"MT.IVOS12": "mt_ivos12",
		"VM.IVOS12": "vm_ivos12",
		"MT.IVOS14": "mt_ivos14",
		"MP.IVOS14": "mp_ivos14",
		"VM.IVOS14": "vm_ivos14",
		"MP.IVOS12": "mp_ivos12",
	};
	selectedField: string = "";
	openFieldSelectionModal() {
		this.isModalOpen = true;
	}
	objectKeys(obj: any): string[] {
		return Object.keys(obj);
	}
	closeModal() {
		this.isModalOpen = false;
	}
	confirmSelection() {
		if (this.selectedField) {
			const body = {
				column_name: this.selectedField,
			};

			this.anno = localStorage.getItem("anna");

			this.apiService?.getCartaDiControlloStandardizzataData(body, this.anno).subscribe({
				next: (res: any) => {
					if (res) {
						// this.toastr.success(res.message);
						let url = environment.url + res.filepath;
						this.fagfilename = res.filename;
						this.downloadPDF(url);
					}
				},
				error: (err) => {
					this.loader = false;
					this.toastr.error("An error occurred");
				},
			});
		}
		this.closeModal();
	}

	getDescrittiva() {
		this.anno = localStorage.getItem("anna");
		this.codiceAz = localStorage.getItem("centriCodiceAz");
		this.apiService?.descrittiva(this.anno, this.codiceAz).subscribe({
			next: (res: any) => {
				if (res) {
					let url = environment.url + res.filePath;
					this.fagfilename = res.fileName;
					this.downloadPDF(url);
				}
			},
			error: (err) => {
				this.loader = false;
				this.toastr.error("An error occurred");
			},
		});
	}

	getandamenti() {
		this.anno = localStorage.getItem("anna");
		this.codiceAz = localStorage.getItem("centriCodiceAz");
		this.apiService?.andamenti(this.anno, this.codiceAz).subscribe({
			next: (res: any) => {
				if (res) {
					let url = environment.url + res.filePath;
					this.fagfilename = res.fileName;
					if(res.status == false){
						this.toastr.error(res.message);
					} else {
						this.downloadPDF(url);
					}
					
				}
			},
			error: (err:HttpErrorResponse) => {
		
				this.loader = false;
				if(err?.error.errorMessage){
					this.toastr.error(err?.error.errorMessage);
				}
				else{
				this.toastr.error("An error occurred");
				}
			},
		});
	}
	getsospesi(){
		this.anno = localStorage.getItem("anna");
		this.apiService?.sospesi(this.anno).subscribe({
			next: (res: any) => {
				if (res) {
					let url = environment.url + res.filePath;
					this.fagfilename = res.fileName;
					if(res.status == false){
						this.toastr.error(res.message);
					} else {
						this.downloadPDF(url);
					}
					
				}
			},
			error: (err:HttpErrorResponse) => {
		
				this.loader = false;
				if(err?.error.errorMessage){
					this.toastr.error(err?.error.errorMessage);
				}
				else{
				this.toastr.error("An error occurred");
				}
			},
		});
	}
	getIE(){
		this.anno = localStorage.getItem("anna");
		this.apiService?.ie(this.anno).subscribe({
			next: (res: any) => {
				if (res) {
					let url = environment.url + res.filePath;
					this.fagfilename = res.fileName;
					if(res.status == false){
						this.toastr.error(res.message);
					} else {
						this.downloadPDF(url);
					}
					
				}
			},
			error: (err:HttpErrorResponse) => {
		
				this.loader = false;
				if(err?.error.errorMessage){
					this.toastr.error(err?.error.errorMessage);
				}
				else{
				this.toastr.error("An error occurred");
				}
			},
		});
	}
	getDosiEterospermiche() {
		this.anno = localStorage.getItem("anna");
		this.apiService?.dosiEterospermiche(this.anno).subscribe({
			next: (res: any) => {
				if (res) {
					let url = environment.url + res.filePath;
					this.fagfilename = res.fileName;
					if(res.status == false){
						this.toastr.error(res.message);
					} else {
						this.downloadPDF(url);
					}
					
				}
			},
			error: (err:HttpErrorResponse) => {
		
				this.loader = false;
				if(err?.error.errorMessage){
					this.toastr.error(err?.error.errorMessage);
				}
				else{
				this.toastr.error("An error occurred");
				}
			},
		});
	}
}
