import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ApiService } from '../_services/api.service';

@Component({
    selector: 'custom-renderer-btn',
    template: `
    <button class="redirct white-btn" title="RICAR" [hidden]="buttonRedirect == false" (click)="onButtonClick1($event,'ricar')">RICAR</button>
    <button class="redirct white-btn" title="FOGLIO di LAVORO" [hidden]="buttonRedirect == false" (click)="onButtonClick1($event,'foglio')">FOGLIO</button>
    <button class="redirct white-btn" title="BOLLA INTERNA" [hidden]="buttonRedirect == false" (click)="onButtonClick1($event,'bolla')">BOLLA</button>
  `,
    styleUrls: ["./magazzino.component.scss"],
})
export class CustomRendererBtnComponent implements ICellRendererAngularComp {
    buttonRedirect: boolean;
    constructor(private apiService: ApiService) { }
    private params: any;
    @Input() tableName: string;
    @Output() buttonClicked: EventEmitter<any> = new EventEmitter<any>();
    agInit(params: any): void {
        this.params = params;
        this.apiService.redirectButton.subscribe((res) => {
            this.buttonRedirect = res;
        })
    }

    onButtonClick(event: any): void {
        event.stopPropagation();
        this.buttonClicked.emit(this.params.node.data);
    }
    onButtonClick1(event: any, data): void {
        event.stopPropagation();
    }
    refresh(): boolean {
        return false;
    }
}