import { Component, AfterViewInit, Input, OnChanges, SimpleChanges } from '@angular/core';

declare var ej: any; // Declare ej as any to avoid TypeScript errors
@Component({
  selector: 'app-dashboard-chart',
  templateUrl: './dashboard-chart.component.html',
  styleUrls: ['./dashboard-chart.component.scss']
})
export class DashboardChartComponent implements OnChanges {
  @Input() response: any;
  @Input() chartId: any;

  ngOnChanges(changes: SimpleChanges): void {
    
    if (changes['response'] && !changes['response'].firstChange) {
      this.response = changes['response'].currentValue;
      this.renderChart();
    }
  }
  
  ngAfterViewInit(): void {
    if (this.response) {
      this.renderChart();
    }
  }

  renderChart() {
    console.log(this.response.maximum, this.chartId)
    const defaultData = this.response.map(item => {
      return {
        week: item.week,
        Rate: this.response.maximum,
        Literacy_Rate: 0,
        Text: '0'
      };
    });

    const newData = this.response.map(item => {
      return {
        week: item.week,
        Rate: this.response.maximum,
        Literacy_Rate: item.data,
        Text: item.data
      };
    });

    const chart = new ej.charts.Chart({
      container: '#'+this.chartId,
      margin: { bottom: 20 },
      // Chart Properties
      primaryXAxis: {
        title: 'SETTIMANA',
        valueType: 'Category',
        interval: 1,
        majorGridLines: { width: 0 },
      },
      primaryYAxis: {
        title: 'VALORI',
        labelFormat: '{value}',
        minimum: 0,
        maximum: this.response.maximum,
        interval: this.response.interval,
        majorGridLines: { width: 0 }
      },
      title: this.response.mainTitle,
      tooltip: {
        enable: true,
        format: '<b>SETTIMANA ${point.x}<b><br/> ISILS ${point.y}',
    },
      legendSettings: { visible: false },
      highlightColor: this.response.fillColor,
      enableSideBySidePlacement: true,
      width: '100%',
      // Data Source
      series: [
        {
          type: 'Column',
          dataSource: defaultData,
          xName: 'week',
          yName: 'Rate',
          columnWidth: 0.4,
          cornerRadius: { topLeft: 10, topRight: 10 },
          fill: this.response.fillColor
        },
        {
          type: 'Column',
          dataSource: newData,
          xName: 'week',
          yName: 'Literacy_Rate',
          columnWidth: 0.4,
          cornerRadius: { topLeft: 10, topRight: 10 },
          fill: this.response.fillColor
        }
      ]
    });

    chart.appendTo('#'+this.chartId);
  }
}
