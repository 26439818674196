import { Component } from '@angular/core';

@Component({
  selector: 'app-animals-management',
  templateUrl: './animals-management.component.html',
  styleUrls: ['./animals-management.component.scss']
})
export class AnimalsManagementComponent {

}
