import { Component, Input, SimpleChanges } from '@angular/core';
import Chart from 'chart.js/auto';

interface Dataset {
    label: string;
    data: Array<string>;
    backgroundColor: string;
    borderColor: string;

}
@Component({
    selector: 'app-statistica-graph',
    templateUrl: './statistica-graph.component.html',
    styleUrls: ['./statistica-graph.component.scss']
})
export class StatisticaGraphComponent {
    barchart: any;
    @Input() chartName: string;
    @Input() labels: Array<string>;
    @Input() datasets: Array<Dataset>;

    ngAfterViewInit() {
        this.barchart = this.createBarChart()
    }

    createBarChart(type = 'bar') {
        return new Chart(this.chartName, {
            type: 'bar',

            data: {
                labels: this.labels,
                datasets: this.datasets
            },
            options: {
            },
        });
    }


}
